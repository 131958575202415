import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

import { TAddHealth, addInsuranceSchema } from "./schema";
import { useInsurance } from "./useInsurance";
import InsuranceForm from "./InsuranceForm";
import PagesContainer from "../../../../components/UI/PagesContainer";

const AddInsurance = () => {
  const methods = useForm<TAddHealth>({
    resolver: zodResolver(addInsuranceSchema),
  });

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { isEditMode } = useInsurance(methods);

  const handleSuccess = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <PagesContainer
      title={
        isEditMode
          ? t("page.schedules.insurance.addInsurance.edit")
          : t("page.schedules.insurance.addInsurance.add")
      }
    >
      <InsuranceForm
        onSuccess={handleSuccess}
        sx={(t) => ({
          [t.breakpoints.up("md")]: {
            width: "60%",
            pt:1
          },
        })}
      />
    </PagesContainer>
  );
};

export default AddInsurance;
