import { Box, IconButton, Tooltip } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Fragment, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalComponent from "../../UI/Modal";
import { IExamsRedirectFileProps } from "./models";
import ExamsRedirectModal from "./ExamsRedirectModal";

const translationPath = "components.examsRedirectFile.";

const ExamsRedirectFile = ({
  sequencialImageControls,
  sequencialControl,
}: IExamsRedirectFileProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleCloseModal = () => setOpen(false);
  const handleOpenModal = () => setOpen(true);

  return (
    <>
      <ModalComponent
        open={open}
        onClose={handleCloseModal}
        dialogMaxWidth="xl"
      >
        <ExamsRedirectModal
          sequencialControl={sequencialControl}
          sequencialImageControls={sequencialImageControls}
        />
      </ModalComponent>
      <Tooltip title={t(translationPath + "download_image")} placement="left">
        <Box>
          <IconButton onClick={handleOpenModal} size="small">
            <OpenInNewIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    </>
  );
};

export default memo(ExamsRedirectFile);
