import { memo, useCallback, useEffect, useState } from "react";
import { Box, SxProps, Theme, useMediaQuery, useTheme } from "@mui/material";

import { useBanners } from "../../../store/contexts/BannersContext/useBanners";
import { EBannerLocale } from "../../../models/banners";
import HomeBannersCarousel from "../BannersCarousel";

interface IScreenBanners {
  localeCode: EBannerLocale;
  sx?: SxProps<Theme>;
}

const FULL_BANNER_HEIGHT = 300;
const MINI_BANNER_HEIGHT = 200;

const FullBanners: React.FC<IScreenBanners> = ({ localeCode, sx }) => {
  const { banners } = useBanners({ localeCode });

  const { breakpoints } = useTheme();

  const [height, setHeight] = useState(300);

  const isDownMd = useMediaQuery(breakpoints.down("md"));

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const height =
      localeCode !== EBannerLocale.FULL_BANNER
        ? MINI_BANNER_HEIGHT
        : FULL_BANNER_HEIGHT;

    setHeight(isDownMd ? height / 1.5 : height);
  }, [isDownMd, localeCode]);

  const onClickHandler = useCallback(
    (index: number) => {
      const url = banners[index].targetingLink;

      if (url) {
        window.open(url, "_blank");
      }
    },
    [banners]
  );

  if (banners.length === 0) {
    return null;
  }

  return (
    <Box width={"100%"} gap={1} sx={sx}>
      <HomeBannersCarousel
        currentSlide={currentSlide}
        changeSlide={(index) => setCurrentSlide(index)}
        onClickItem={onClickHandler}
      >
        {banners.map((banner) => (
          <Box
            sx={{
              "&:hover": {
                cursor: banner.targetingLink ? "pointer" : "default",
                opacity: 0.9,
              },
            }}
          >
            <img
              src={banner.path}
              key={banner.id}
              alt={banner.alternativeText}
              style={{
                width: "100%",
                height: height,
                objectFit: "cover",
                objectPosition: "center",
                borderRadius: localeCode === EBannerLocale.FULL_BANNER ? 0 : 10,
              }}
            />
          </Box>
        ))}
      </HomeBannersCarousel>
    </Box>
  );
};

export default memo(FullBanners);
