import {
  ButtonTypeMap,
  CircularProgress,
  IconButton as MuiIconButton,
} from "@mui/material";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";

type IIconButtonProps = {
  children: JSX.Element;
  loading?: boolean;
} & DefaultComponentProps<ButtonTypeMap>;

const IconButton = ({
  children,
  loading,
  ...iconButtonProps
}: IIconButtonProps) => {
  return (
    <MuiIconButton
      disabled={loading || iconButtonProps.disabled}
      {...iconButtonProps}
    >
      {loading && <CircularProgress size={20} />}
      {!loading && children}
    </MuiIconButton>
  );
};

export default IconButton;
