import { memo, useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import Button from "../../../../../components/UI/Button";
import ModalComponent from "../../../../../components/UI/Modal";
import { ETasyScheduleTypeCode, IScheduleAppointment } from "../../../model";
import useDate from "../../../../../hooks/useDate";
import { TCancel, cancelSchema } from "./schema";
import FormInput from "../../../../../components/UI/Inputs/FormInput";
import Reason from "./Reason";
import useFetch from "../../../../../hooks/useFetch";
import { ScheduleAppointmentService } from "../../../appointment.service";
import { notificationSliceActions } from "../../../../../store/slices/notification";
import { useAppDispatch } from "../../../../../store/store";

const keys = cancelSchema.keyof().enum;

interface ICancelSchedule {
  data: IScheduleAppointment;
  type: string;
  onCancelled: () => void;
  onClose: () => void;
  open: boolean;
  reschedule?: boolean;
}

const CancelSchedule: React.FC<ICancelSchedule> = ({
  data: { descriptionSite, calendarDate, identifier, calendarIdentifier },
  type,
  open,
  onClose,
  onCancelled,
  reschedule = false,
}) => {
  const { t } = useTranslation();

  const methods = useForm<TCancel>({
    resolver: zodResolver(cancelSchema),
  });

  const dispatch = useAppDispatch();

  const { sendRequest: cancelExam, loading } = useFetch(
    ScheduleAppointmentService.calendarExamScheduleCancellation
  );
  const { sendRequest: cancelConsultation, loading: consultationLoading } =
    useFetch(
      ScheduleAppointmentService.calendarConsultationScheduleCancellation
    );

  const { dayjs } = useDate();

  const onSubmit = useCallback(
    async (v: TCancel) => {
      const { success } = await (type === ETasyScheduleTypeCode.CONSULTATION
        ? cancelConsultation
        : cancelExam)({
        calendarDate: calendarDate,
        cancellationReasonCode: v.reason.code,
        cancellationReasonDescription: v.justification,
        identifier: String(identifier),
        identifierCalendar: String(calendarIdentifier),
      });

      if (success) {
        dispatch(
          notificationSliceActions.showNotification({
            message: `${t("page.schedules.scheduleDetails.cancel.success")}`,
            type: "success",
          })
        );
        onClose();
        onCancelled();
      }
    },
    [
      calendarDate,
      identifier,
      calendarIdentifier,
      onClose,
      onCancelled,
      cancelConsultation,
      cancelExam,
      t,
      dispatch,
      type,
    ]
  );

  return (
    <ModalComponent onClose={onClose} open={open}>
      <FormProvider {...methods}>
        <Stack gap={2}>
          <Typography variant="h5">
            {t(
              `page.schedules.scheduleDetails.cancel.${
                reschedule ? "title2" : "title"
              }`
            )}
          </Typography>
          {reschedule && (
            <Typography variant="body2">
              {t("page.schedules.scheduleDetails.cancel.description2")}
            </Typography>
          )}
          <Typography variant="body2">
            {t("page.schedules.scheduleDetails.cancel.description", {
              date: dayjs(calendarDate).format("LLL"),
              professional: descriptionSite,
            })}
          </Typography>
          <Reason code={type} />
          <FormInput
            name={keys.justification}
            label={t(
              "page.schedules.scheduleDetails.cancel.justification.label"
            )}
            size="small"
            placeholder={
              t(
                "page.schedules.scheduleDetails.cancel.justification.placeholder"
              )!
            }
            multiline
            fullWidth
            minRows={4}
          />
          <Stack flexDirection={"row"} justifyContent={"flex-end"} gap={1}>
            <Button onClick={onClose}>
              {t("page.schedules.scheduleDetails.cancel.cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={methods.handleSubmit(onSubmit)}
              loading={consultationLoading || loading}
            >
              {t("page.schedules.scheduleDetails.cancel.confirm")}
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
    </ModalComponent>
  );
};

export default memo(CancelSchedule);
