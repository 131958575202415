import {
  Autocomplete,
  AutocompleteRenderOptionState,
  Chip,
  TextField,
  TextFieldProps,
  createFilterOptions,
} from "@mui/material";
import {
  HTMLAttributes,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Controller, useFormContext } from "react-hook-form";
import useFetch from "../../../hooks/useFetch";
import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";
import useDebounce from "../../../hooks/useDebounce";

interface IBank {
  id: string;
  shortName: string;
  name: string;
  ispbCode: string;
  bacenCode: string;
  ibgeCityCode: string;
}

export interface IBankData {
  bacenCode: number;
  shortName: string;
  bankBranch: string;
  bankAccount: string;
  bankAccountDigit: string;
  holderBankAccountName: string;
  holderBirthDate: string;
  holderDocument: string;
  integrationId: string;
  bank: IBank;
}

type IFormInputProps = {
  name: string;
  options?: IBankData[];
  onValueSelected?: (data: IBankData) => void;
  request?: (params?: any) => Promise<AxiosResponse<IBankData[]>>;
  serviceType?: "List" | "Page";
  params?: any;
  SearchAsYouTypeParams?: (typedValue: string) => any;
  isOptionEqualToValue?: (option: IBankData, value: IBankData) => boolean;
  filterSelectedOptions?: boolean;
  disable?: boolean;
  renderOption?:
    | ((
        props: HTMLAttributes<HTMLLIElement>,
        option: IBankData,
        state: AutocompleteRenderOptionState
      ) => ReactNode)
    | undefined;
} & TextFieldProps;

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option: IBankData) => option.shortName,
});

const translationPath = "components.autoCompleteBankData.";

const AutoCompleteBankData = ({
  name,
  options,
  onValueSelected,
  params,
  request,
  SearchAsYouTypeParams,
  isOptionEqualToValue = (o, v) => {
    return o.integrationId === v.integrationId;
  },
  disable,
  filterSelectedOptions = false,
  renderOption = (props, option) => {
    return (
      <li {...props} key={option.integrationId}>
        {option.shortName}
      </li>
    );
  },
  ...otherProps
}: IFormInputProps) => {
  const { control } = useFormContext();
  const [realOptions, setOptions] = useState<IBankData[]>(options || []);
  const { loading, sendRequest } = useFetch(request);
  const { t } = useTranslation();
  const [value, setValue] = useState<string>("");

  const searchQuery: string = useDebounce(value, 500);

  const loadRequest = useCallback(
    async (parameters?: any) => {
      const { data, success } = await sendRequest(parameters);

      if (success) {
        setOptions(data);
      }
    },
    [sendRequest]
  );

  //Debounce get function
  useEffect(() => {
    if (SearchAsYouTypeParams) {
      if (searchQuery !== "" && searchQuery.length > 2) {
        loadRequest(SearchAsYouTypeParams(searchQuery));
      }
    }
  }, [searchQuery, SearchAsYouTypeParams, loadRequest]);

  useEffect(() => {
    if (!SearchAsYouTypeParams) {
      loadRequest(params);
    }
  }, [loadRequest, SearchAsYouTypeParams, params]);

  return (
    <Controller
      render={({ fieldState: { error }, field }) => {
        const { onChange, value, ref } = field;
        return (
          <Autocomplete
            value={value}
            loading={loading}
            disabled={disable ? true : false}
            noOptionsText={t("components.autocomplete.noOptions")}
            renderOption={renderOption}
            isOptionEqualToValue={isOptionEqualToValue}
            filterSelectedOptions={filterSelectedOptions}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option.shortName}
                  color="primary"
                  size="small"
                  {...getTagProps({ index })}
                />
              ))
            }
            filterOptions={params ? filterOptions : (x) => x}
            options={options ? options : realOptions}
            getOptionLabel={(option) => {
              return (
                `${t(translationPath + "short_name")}: ` +
                option.shortName +
                ` ${t(translationPath + "bank_branch")}: ` +
                option.bankBranch +
                ` ${t(translationPath + "bank_account")}: ` +
                option.bankAccount +
                "-" +
                option.bankAccountDigit +
                ` ${t(translationPath + "holder_account")}: ` +
                option.holderBankAccountName
              );
            }}
            onChange={(event: any, newValue) => {
              if (newValue) {
                onChange(newValue);
                if (onValueSelected) {
                  onValueSelected(newValue);
                }
              }
            }}
            clearIcon={null}
            renderInput={(params) => (
              <TextField
                {...params}
                {...otherProps}
                error={!!error}
                onChange={(e) => setValue(e.target.value)}
                inputRef={ref}
                helperText={
                  error
                    ? t(error?.message || "")
                    : SearchAsYouTypeParams
                    ? t("components.autocomplete.hint")
                    : ""
                }
                fullWidth
              />
            )}
          />
        );
      }}
      defaultValue={null}
      name={name}
      control={control}
    />
  );
};

export default memo(AutoCompleteBankData);
