import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FamilyGroupService } from "../../FamilyGroup/familyGroup.service";
import useFetch from "../../../../hooks/useFetch";
import AutoComplete, {
  ISelectType,
} from "../../../../components/UI/Inputs/AutoComplete";
import { addInsuranceSchema } from "./schema";

const KEYS = addInsuranceSchema.keyof().Enum;

interface ISelectFamilyMemberProps {
  disable?: boolean;
}

const SelectFamilyMember: React.FC<ISelectFamilyMemberProps> = ({
  disable = false,
}) => {
  const [options, setOptions] = useState<ISelectType[]>([]);

  const { t } = useTranslation();

  const { sendRequest } = useFetch(FamilyGroupService.get);

  const fetch = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (data && success) {
      setOptions(
        data
          .filter((f) => !!f.individual)
          .map((i) => ({
            id: i.individual!.id,
            name: i.individual!.name,
          }))
      );
    }
  }, [sendRequest]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <AutoComplete
      getOptionLabel={(option) => {
        return option.name;
      }}
      disabled={disable}
      disable={disable}
      size="small"
      label={t("page.schedules.insurance.addInsurance.individual.label")}
      name={KEYS.individual}
      options={options}
    />
  );
};

export default memo(SelectFamilyMember);
