import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { memo, useCallback } from "react";
import { confirm } from "material-ui-confirm";
import { useTranslation } from "react-i18next";

import { ScheduleInsuranceService } from "../insurance.service";
import useFetch from "../../../../hooks/useFetch";

interface IDeleteInsuranceProps {
  id: string;
  onDelete: (id: string) => void;
}

const DeleteInsurance: React.FC<IDeleteInsuranceProps> = ({ id, onDelete }) => {
  const { sendRequest } = useFetch(ScheduleInsuranceService.delete);
  const { t } = useTranslation();

  const handleClick = useCallback(async () => {
    try {
      await confirm({
        title: t("page.schedules.insurance.delete.title"),
        description: t("page.schedules.insurance.delete.description"),
        confirmationText: t("page.schedules.insurance.delete.confirmationText"),
        cancellationText: t("page.schedules.insurance.delete.cancellationText"),
      });
      const { success } = await sendRequest(id);
      if (success) {
        onDelete(id);
      }
    } catch (error) {}
  }, [sendRequest, onDelete, id, t]);

  return (
    <IconButton size="small" onClick={handleClick}>
      <DeleteIcon fontSize="small" />
    </IconButton>
  );
};

export default memo(DeleteInsurance);
