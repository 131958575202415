import { Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useStep } from "../../../store/contexts/StepContext";
import { useTranslation } from "react-i18next";

const translationPath = "components.stepperHeader.errors.";

const StepperHeader = ({ steps }: { steps: string[] }) => {
  const { activeStep, errors } = useStep();
  const { t } = useTranslation();

  return (
    <Stepper activeStep={activeStep}> 
      {steps.map((label, index) => {
        const stepProps: { completed?: boolean } = {};
        
        const labelProps: {
          optional?: React.ReactNode;
          error?: boolean;
        } = {};

        if (errors.includes(index)) {
          labelProps.optional = (
            <Typography variant="caption" color="error">
              {t(translationPath + "fields")}
            </Typography>
          );
          labelProps.error = true;
        }

        return (
          <Step key={label} {...stepProps} sx={{ padding: 0.5 }}>
            <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default StepperHeader;
