import { Box, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useMemo } from "react";

import ContentAditionalInformations from "./ContentAditionalInformations";
import ContentTags from "./ContentTags";
import ContentGallery from "./ContentGallery/ContentGallery";
import useDate from "../../hooks/useDate";
import { IContentBase } from "../../models/content";
import ContentVideos from "./ContentVideos";
import ComplementaryMaterial from "./ComplementaryMaterial/ComplementaryMaterial";

interface IContentPageProps {
  content: IContentBase;
  notShowVideoInFront?: boolean;
}

const ContentPage = ({
  content,
  notShowVideoInFront = false,
}: IContentPageProps) => {
  const { validateDate } = useDate();

  const aux = content.contents[0];

  const images = useMemo(
    () => content.contentMediaFiles.filter((i) => i.mimeType.includes("image")),
    [content.contentMediaFiles]
  );

  const files = useMemo(
    () => content.contentMediaFiles.filter((i) => i.mimeType.includes("pdf")),
    [content.contentMediaFiles]
  );

  const hasVideo = aux.contentVideos.length > 0;

  const showVideoInFront = hasVideo && !notShowVideoInFront;

  const title = (
    <Stack gap={1}>
      <Typography variant="h5">{aux.title}</Typography>
      <ContentAditionalInformations
        publicationDate={validateDate(dayjs(content.publicationDate), "L LT")}
        readingTime={content.readingTime}
      />
      <ContentTags tags={aux.contentTags} />
    </Stack>
  );

  let layout = (
    <>
      <ContentVideos videos={aux.contentVideos} vertical />

      {title}

      <ContentGallery images={images} />

      <Box
        dangerouslySetInnerHTML={{
          __html: aux.contentText,
        }}
      />

      <ComplementaryMaterial files={files} />
    </>
  );

  if (!showVideoInFront) {
    layout = (
      <>
        <ContentGallery images={images} />

        {title}

        <Box
          dangerouslySetInnerHTML={{
            __html: aux.contentText,
          }}
        />

        <ContentVideos videos={aux.contentVideos} />

        <ComplementaryMaterial files={files} />
      </>
    );
  }

  return (
    <Stack
      gap={2}
      sx={(t) => ({
        [t.breakpoints.up("xl")]: {
          marginX: 30,
        },
      })}
    >
      {layout}
    </Stack>
  );
};

export default ContentPage;
