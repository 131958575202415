import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Box, Button, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DisplayDate from "../../components/DisplayDate";
import PaperComponent from "../../components/UI/PaperComponent";
import { IAuthorization } from "../../models/authorizations";
import AuthorizationProvider from "./AuthorizationProvider";

const translationPath = "page.authorizations.";

const AuthorizationsCard = ({
  auth,
  initialDate,
  endDate,
}: {
  auth: IAuthorization;
  initialDate: string;
  endDate: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [statusColor, setStatusColor] = useState("");

  useEffect(() => {
    if (auth.documentTypeCurrentSituation === "Autorizado")
      setStatusColor("#28bd45");
    if (auth.documentTypeCurrentSituation === "Negado")
      setStatusColor("#d80202");
    if (auth.documentTypeCurrentSituation === "Em analise")
      setStatusColor("#b1b1b1");
  }, [auth.documentTypeCurrentSituation]);

  return (
    <PaperComponent sx={{ minWidth: 300, maxWidth: 800 }}>
      <Grid container>
        <Grid item container xs={8} sm={10} p={2}>
          <Grid item sm={6}>
            <Typography variant="subtitle1" fontWeight={"bold"} fontSize={18}>
              {auth.authorizationIntegrationId}
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <DisplayDate typography={{ variant: "subtitle1" }}>
              {dayjs(auth.requestDate)}
            </DisplayDate>
          </Grid>
          {auth.beneficiaryName && (
            <AuthorizationProvider
              name={auth.beneficiaryName}
              label="beneficiary"
            />
          )}
          {auth.providerRequesterName && (
            <AuthorizationProvider
              name={auth.providerRequesterName}
              label="provider"
            />
          )}
          {auth.doctorRequesterName && (
            <AuthorizationProvider
              name={auth.doctorRequesterName}
              label="doctor"
            />
          )}
          {auth.providerExecutorName && (
            <AuthorizationProvider
              name={auth.providerExecutorName}
              label="provider"
            />
          )}
        </Grid>
        <Grid
          item
          xs={4}
          sm={2}
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          py={2}
        >
          <Box
            sx={{
              pr: 2,
              textAlign: "center",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ color: statusColor, fontWeight: "bold" }}
            >
              {auth.documentTypeCurrentSituation}
            </Typography>
          </Box>

          <Button
            variant="text"
            size="small"
            startIcon={<AddRoundedIcon />}
            sx={{ mr: 2 }}
            onClick={() =>
              navigate(
                `${initialDate}/${endDate}/${auth.authorizationIntegrationId}`
              )
            }
          >
            {t(translationPath + "see_more")}
          </Button>
        </Grid>
      </Grid>
    </PaperComponent>
  );
};

export default AuthorizationsCard;
