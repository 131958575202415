import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { authService } from "../../../services/auth.service";
import { notificationSliceActions } from "../../../store/slices/notification";
import { useDispatch } from "react-redux";
import FormInput from "../../UI/Inputs/FormInput";
import { TEmailConfirm, emailConfirmSchema } from "./EmailConfirmSchema";
import { useNavigate, useParams } from "react-router-dom";
import useSiteApp from "../../../hooks/useSiteApp";
import ResendEmail from "../LoginForm/ResendEmail";
import ResendEmailForm from "../LoginForm/ResendEmailForm";
import ModalComponent from "../../UI/Modal";
import RegisteredModal from "./RegisteredModal";
import EmailResentModal from "./EmailResentModal";
import EmailErrorCode from "./EmailErrorCodeModal";

const translationPath = "page.confirm_email.";

const EmailConfirmForm = ({ onError }: { onError: () => void }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userName } = useParams();
  const { config } = useSiteApp();
  const navigate = useNavigate();
  const [showResendEmailForm, setShowResendEmailForm] = useState(false);
  const [open, setOpen] = useState(false);
  const [emailRecent, setEmailRecent] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);

  const methods = useForm<TEmailConfirm>({
    resolver: zodResolver(emailConfirmSchema),
  });

  const { handleSubmit, setError } = methods;

  const { loading, sendRequest, error } = useFetch(
    authService.emailConfirmation
  );

  const onSubmitHandler = useCallback(
    async (form: TEmailConfirm) => {
      if (!userName) {
        if (!form.userName || form.userName.length === 0) {
          setError("userName", {
            type: "required",
            message: `${t(translationPath + "error.username", {
              username: `${
                config.isLoginCpf
                  ? `${t(translationPath + "document")}`
                  : `${t(translationPath + "username")}`
              }`,
            })}`,
          });
          return;
        }
      }
      const { success } = await sendRequest({
        code: form.code,
        userName: userName
          ? config.isLoginCpf
            ? userName.replaceAll(".", "").replaceAll("-", "")
            : userName
          : form.userName || "",
      });
      if (success) {
        dispatch(
          notificationSliceActions.showNotification({
            message: `${t(
              "components.forgetPasswordForm.notification_email_confirmed"
            )}`,
            type: "success",
          })
        );
        sessionStorage.setItem("confirmed", "true");
        navigate("/login");
      }
    },
    [dispatch, t, sendRequest, userName, config.isLoginCpf, navigate, setError]
  );

  useEffect(() => {
    if (error) {
      if (error["errors"]["Code"]) {
        setInvalidCode(true);
        setOpen(true);
      }
    }
  }, [error]);

  const showResendEmailFormHandler = () => setShowResendEmailForm(true);
  const hiddenResendEmailFormHandler = () => setShowResendEmailForm(false);

  useEffect(() => {
    const showModal =
      sessionStorage.getItem("registered") || sessionStorage.getItem("resent");

    if (showModal) {
      setOpen(true);
    }
  }, []);

  const closeModalHandler = () => {
    sessionStorage.removeItem("registered");
    sessionStorage.removeItem("resent");
    setOpen(false);
  };

  const openModalHandler = () => {
    setEmailRecent(true);
    setOpen(true);
  };

  const emailRecentHandler = () => {
    setShowResendEmailForm(false);
    setEmailRecent(true);
    setOpen(true);
  };

  return (
    <>
      {showResendEmailForm && (
        <ResendEmailForm
          onEmailRecent={emailRecentHandler}
          onNavigateBack={hiddenResendEmailFormHandler}
        />
      )}
      <ModalComponent open={open} onClose={closeModalHandler}>
        {invalidCode && <EmailErrorCode onCloseModal={closeModalHandler} />}
        {emailRecent && !invalidCode && (
          <EmailResentModal onCloseModal={closeModalHandler} />
        )}
        {!emailRecent && !invalidCode && (
          <RegisteredModal onCloseModal={closeModalHandler} />
        )}
      </ModalComponent>
      {!showResendEmailForm && (
        <FormProvider {...methods}>
          <Box
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmitHandler)}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography variant="h6" align="center" mb={3}>
              {t(translationPath + "confirm_email")}
            </Typography>
            <Typography variant="subtitle2" align="center" mb={3}>
              {t(translationPath + "subtitle")}
            </Typography>
            {!userName && (
              <FormInput
                size="small"
                autoFocus={true}
                name="userName"
                fullWidth
                label={t(translationPath + "username")}
                sx={{ mb: 2 }}
              />
            )}
            <FormInput
              size="small"
              autoFocus={!!userName}
              name="code"
              fullWidth
              label={t(translationPath + "code")}
              sx={{ mb: 2 }}
            />
            <Button disabled={loading} variant="contained" type="submit">
              {loading && (
                <Stack flexDirection={"row"} gap={1}>
                  <CircularProgress size={25} />
                  {t(translationPath + "confirming")}
                </Stack>
              )}
              {!loading && t(translationPath + "confirm_email")}
            </Button>
            <ResendEmail
              userName={userName}
              onShowResendEmailForm={showResendEmailFormHandler}
              onEmailResent={openModalHandler}
            />
          </Box>
        </FormProvider>
      )}
    </>
  );
};

export default EmailConfirmForm;
