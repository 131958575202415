import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import EmergencyCareServiceCard from "../../components/VirtualEmergencyCare/EmergencyCareServiceCard";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CallIcon from "@mui/icons-material/Call";
import { useCallback, useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import ExtensionService from "../../services/extension.service";
import PermissionControl from "../../components/UI/PermissionControl";
import usePermission from "../../hooks/usePermission";
import NoAccessPage from "../NoAccessPage";
import { useInstalledExtensions } from "../../hooks/useInstalledExtensions";
import { Extension } from "../../models/intalled-extensions";
import PagesContainer from "../../components/UI/PagesContainer";

const translationPath = "page.scheduleTeleconsultation.";
const extensionConfig = new ExtensionService();

const ScheduleTeleconsultationPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [phoneNumbers, setPhoneNumbers] = useState<string[]>([]);
  
  const hasTeleconsultationPermission = usePermission({
    domain: "promptservice",
  });
  const hasPhonePermission = usePermission({ domain: "telephonecall" });

  const [telephoneInstalled] = useInstalledExtensions(Extension.TELEPHONE);

  const [promptInstalled] = useInstalledExtensions(Extension.EMERGENCY_CARE);

  const { sendRequest,loading } = useFetch(extensionConfig.getExtensionConfig);

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const getPhoneNumber = useCallback(async () => {
    const { data, success } = await sendRequest({
      Identifier: "PhoneNumber",
      ExtensionCode: "4",
    });
    if (success) {
      setPhoneNumbers(data.map((d) => d.value));
    }
  }, [sendRequest]);

  useEffect(() => {
    getPhoneNumber();
  }, [getPhoneNumber]);

  const hasPermission = hasTeleconsultationPermission || hasPhonePermission;

  if (!hasPermission) {
    return <NoAccessPage />;
  }
  return (
    <PagesContainer title={t(translationPath + "schedule_teleconsultation")} loading={loading}>
      <Grid item sm={12} mb={2} display={"flex"} alignItems={"center"} gap={2}>
        <Typography
          variant="h6"
          sx={(theme) => ({
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[700]
                : theme.palette.grey[400],
          })}
        >
          {t(translationPath + "choose_service")}
        </Typography>
      </Grid>
      <Grid
        item
        sm={12}
        display={"flex"}
        flexDirection={matches ? "row" : "column"}
        flexWrap={"wrap"}
        gap={2}
      >
        <PermissionControl
          domain={"promptservice"}
          actions={["All"]}
          isExtensionInstalled={promptInstalled}
        >
          <EmergencyCareServiceCard
            onClick={() => navigate("appointment-scheduling")}
            icon={<AssignmentTurnedInIcon color="primary" fontSize="large" />}
            title={t(translationPath + "virtual_emergency_care")}
            description={t(translationPath + "service")}
          />
        </PermissionControl>

        <PermissionControl
          domain={"telephonecall"}
          actions={["All"]}
          isExtensionInstalled={telephoneInstalled}
        >
          <>
            {phoneNumbers.length !== 0 &&
              phoneNumbers.map((phone) => (
                <EmergencyCareServiceCard
                  onClick={() => window.open(`tel:${phone}`)}
                  icon={<CallIcon color="primary" fontSize="large" />}
                  title={phone}
                  description={t(translationPath + "service_call")}
                  key={phone}
                />
              ))}
          </>
        </PermissionControl>
      </Grid>
    </PagesContainer>
  );
};

export default ScheduleTeleconsultationPage;
