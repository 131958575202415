import {
  Box,
  Grid,
  Pagination,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ResultItem from "./ResultItem";
import { IMedic, IMedicGuidAddress } from "../../../../../models/medic-guide";
import { useCallback, useEffect, useState } from "react";
import NoMedics from "./NoMedics";
import LoadingMedics from "./LoadingMedics";
import PagesContainer from "../../../../UI/PagesContainer";

const MedicGuideResults = ({
  medics,
  selectedAddress,
  loading,
  getActualPage,
  totalPages,
}: {
  medics: IMedic[];
  loading: boolean;
  selectedAddress?: IMedicGuidAddress;
  getActualPage: (value: number) => void;
  totalPages: number;
}) => {
  const [actualPage, setActualPage] = useState(1);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const loadActualPage = useCallback(() => {
    getActualPage(actualPage);
  }, [getActualPage, actualPage]);

  useEffect(() => {
    loadActualPage();
  }, [loadActualPage]);

  if (loading) {
    return <LoadingMedics />;
  }

  if (medics.length === 0 && !loading) {
    return <NoMedics />;
  }

  return (
    <Grid item sm={12} height={"100%"}>
      <PagesContainer height={totalPages >= 2 ? "calc(100% - 70px)" : "100%"}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          marginTop={1}
          paddingBottom={2}
        >
          {medics.map((medic, index) => (
            <Box key={index}>
              <ResultItem medic={medic} selectedAddress={selectedAddress} />
            </Box>
          ))}
        </Box>
      </PagesContainer>

      <Stack
        alignItems={"center"}
        height={"auto"}
        pt={2}
        width={matches ? "50%" : "100%"}
      >
        {totalPages >= 2 && (
          <Pagination
            count={totalPages}
            variant="outlined"
            color="primary"
            page={actualPage}
            onChange={(e, v) => setActualPage(v)}
          />
        )}
      </Stack>
    </Grid>
  );
};

export default MedicGuideResults;
