import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "dayjs/locale/pt-br";
import {
  Grid,
  SelectChangeEvent,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import CoParticipationService from "../services/coParticipation.service";
import { ICoParticipations } from "../models/co-participation";
import useFetch from "../hooks/useFetch";
import ExtractCoParticipationHeader from "../components/ExtractCoParticipation/ExtractCoParticipationHeader";
import ExtractCoParticipationTable from "../components/ExtractCoParticipation/ExtractCoParticipationTable";
import NoData from "../components/UI/NoData";
import useDownload from "../hooks/useDownload";
import useRole from "../hooks/useRole";
import PagesContainer from "../components/UI/PagesContainer";

const translationPath = "page.extractCoParticipation.";
const coParticipationService = new CoParticipationService();
const DOWNLOAD_URL =
  process.env.REACT_APP_APP +
  "/api/v1/Beneficiary/GetCoparticipationStatementPdf";

const ExtractCoParticipationPage = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { sendRequest } = useFetch(coParticipationService.getAll);
  const [coParticipations, setCoParticipations] = useState<ICoParticipations[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const { i18n, t } = useTranslation();
  const { downloadFileParams, loading: donwloadLoading, error } = useDownload();
  const readPermission = useRole(["Read"]);

  const locale = i18n.language;
  dayjs.locale(locale);

  const actualDate = dayjs(new Date());
  const [date, setDate] = useState(actualDate.format("MM/YYYY - MMMM"));

  const onChangeHandler = (e: SelectChangeEvent) => {
    setDate(e.target.value);
  };

  const loadExtract = useCallback(async () => {
    setLoading(true);
    const { data, success } = await sendRequest({ Date: date.slice(0, 7) });
    setTimeout(() => {
      if (success) {
        setCoParticipations(data);
      }
      setLoading(false);
    }, 500);
  }, [sendRequest, date]);

  const downloadHandler = () => {
    const DATE = date.toString().substring(0, 7);
    downloadFileParams(
      DOWNLOAD_URL,
      {
        Date: DATE,
      },
      DATE
    );
  };

  useEffect(() => {
    if (readPermission) {
      loadExtract();
    }
  }, [loadExtract, readPermission]);

  const allPrices = coParticipations.map((coParticipation) => {
    return coParticipation.coparticipationStatements?.map(
      (item) => item.amountcoparticipation
    );
  });

  const totalSum = allPrices.flat().reduce((accumulator, currentValue) => {
    if (typeof currentValue === "number" && !isNaN(currentValue)) {
      return accumulator! + currentValue;
    }
    return accumulator;
  }, 0);

  return (
    <PagesContainer
      height={matches ? "calc(100vh - 15rem)" : "calc(100vh - 20rem)"}
      sx={{ width: "100%" }}
      loading={loading}
      title={t(translationPath + "extract_coparticipation")}
      headerBottom={
        <ExtractCoParticipationHeader
          actualDate={actualDate}
          coParticipations={coParticipations}
          date={date}
          onChangeHandler={onChangeHandler}
          totalSum={totalSum}
          loading={loading}
          onDownloadClick={downloadHandler}
          donwloadLoading={donwloadLoading}
          error={error}
        />
      }
    >
      <Stack className="print-page" width={"100%"}>
        <ExtractCoParticipationTable coParticipations={coParticipations} />

        {coParticipations.length === 0 && (
          <Grid container mt={5}>
            <Grid item xs={12} sm={12}>
              <NoData />
            </Grid>
          </Grid>
        )}
      </Stack>
    </PagesContainer>
  );
};

export default ExtractCoParticipationPage;
