import { Modal } from "@4uhub/lib4uhub";
import TopicIcon from "@mui/icons-material/Topic";
import { Box, Tooltip } from "@mui/material";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "../../UI/IconButton";
import { IReportsModalProps } from "./models";
import { ReportsModal } from "./ReportsModal/ReportsModal";

const translationPath = "components.reports.";

export const Reports = memo(({ identifier }: IReportsModalProps) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const openModalHandler = () => setOpen(true);

  const closeModalHandler = () => setOpen(false);

  return (
    <>
      <Modal
        open={open}
        onClose={closeModalHandler}
        title={`${t(translationPath + "reports")}`}
        maxDialogWidth={"lg"}
      >
        <ReportsModal identifier={identifier} />
      </Modal>

      <Tooltip title={t(translationPath + "view_reports")} placement="right">
        <Box>
          <IconButton onClick={openModalHandler}>
            <TopicIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    </>
  );
});
