import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { IContentWhatsappNumber } from "./models";

const translationPath = "page.schedules.newSchedule.confirm.";

export const CurrentWhatsappNumber = memo(
  ({ currentWhatsappNumber, onOpenModal }: IContentWhatsappNumber) => {
    const { t } = useTranslation();

    const theme = useTheme();

    const matches = useMediaQuery(theme.breakpoints.down("sm"));

    return (
      <Grid
        item
        xs={12}
        sm={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: matches ? 0 : 1,
        }}
      >
        <Typography
          variant="subtitle1"
          fontSize={15}
          sx={(t) => ({
            color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
          })}
        >
          {t(`${translationPath}whatsapp_number`)}:
        </Typography>
        <Box
          sx={{ height: "fit-content", display: "flex", flexDirection: "row" }}
        >
          <Typography variant="subtitle1" fontSize={15} fontWeight="bold">
            {currentWhatsappNumber ? currentWhatsappNumber : "-"}
          </Typography>
          <Tooltip title={t(`${translationPath}edit`)}>
            <IconButton
              size="small"
              sx={{
                p: 0,
                px: 0.5,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={onOpenModal}
            >
              <EditRoundedIcon fontSize="small" sx={{ fontSize: 15 }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
    );
  }
);
