import { useCallback, useState } from "react";
import useFetch from "./useFetch";
import UploadFileService, { TFileType } from "../services/uploadFile.service";

export interface IUploadFile {
  mediaTypeCode: string;
  type: TFileType;
  parentName: string;
  file: File;
  MediaTypeIdentifier: string;
}

const useUploadFile = ({ service }: { service: UploadFileService }) => {
  const [progress, setProgress] = useState<number | null>(null);
  const [imagePreview, setImagePreview] = useState<string>();
  const [fileId, setFileId] = useState<string>();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const onGetProgress = (uploadProgress: number) => {
    setProgress(uploadProgress);
  };

  const { sendRequest: upload } = useFetch(service.upload);

  const cancelRequest = () => {
    service.controller.abort();
    service.controller = new AbortController();
  };

  const uploadFile = useCallback(
    async ({
      MediaTypeIdentifier,
      file,
      mediaTypeCode,
      parentName,
      type,
    }: IUploadFile) => {
      const { data, success } = await upload({
        data: {
          Type: type,
          ParentName: parentName,
          MediaTypeCode: mediaTypeCode,
          File: file,
          MediaTypeIdentifier: MediaTypeIdentifier,
        },
        progressCallback: onGetProgress,
      });
      if (success) {
        setProgress(0);
        setError(false);
        setSuccess(true);
        setImagePreview(URL.createObjectURL(file));
        setFileId(data.id);
      } else {
        setProgress(0);
        setError(true);
        setSuccess(false);
      }
    },
    [upload]
  );

  return {
    progress,
    imagePreview,
    fileId,
    success,
    error,
    uploadFile,
    cancelRequest,
  };
};

export default useUploadFile;
