import { Box } from "@mui/material";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page } from "react-pdf";
import { SizeMe } from "react-sizeme";

import Loading from "../Loading";
import NoData from "../UI/NoData";
import { IRenderPdfProps } from "./models";
import PagesActions from "./PagesActions/PagesActions";

const translationPath = "components.RenderPdf.";

const RenderPdf = ({ width, file, downloadButtonProps }: IRenderPdfProps) => {
  const { t } = useTranslation();

  const [numPages, setNumPages] = useState<number>(0);

  const [pageNumber, setPageNumber] = useState<number>(1);

  const [scale, setScale] = useState<any>(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const nextPage = () => setPageNumber((oldPage) => (oldPage = oldPage + 1));

  const previousPage = () =>
    setPageNumber((oldPage) => (oldPage = oldPage - 1));

  return (
    <>
      <Box
        sx={(t) => ({
          [t.breakpoints.down("sm")]: {
            width: "90vw",
          },
          width: width ?? "35vw",
          minWidth: 320,
          height: "100%",
          position: "relative",
          overflow: "auto",
        })}
      >
        <SizeMe>
          {({ size }) => (
            <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              error={() => (
                <Box
                  width={"100%"}
                  height={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <NoData message={t(translationPath + "error")} />
                </Box>
              )}
              loading={() => (
                <Box
                  width={"100%"}
                  height={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Loading />
                </Box>
              )}
            >
              <Page
                pageNumber={pageNumber}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                scale={scale}
                width={size.width ? size.width : 1}
              />
            </Document>
          )}
        </SizeMe>
      </Box>
      {numPages > 0 && (
        <PagesActions
          file={file}
          pageNumber={pageNumber}
          numPages={numPages}
          scale={scale}
          previousPage={previousPage}
          nextPage={nextPage}
          setScale={setScale}
          downloadButtonProps={downloadButtonProps}
        />
      )}
    </>
  );
};

export default memo(RenderPdf);
