import GlobalAxios from "../axios/axios-config";

const HISTORY_PAYMENT_ROUTE =
  process.env.REACT_APP_APP + "/api/v1/Beneficiary/GetPaymentHistory/";

export class HistoryPaymentService {
  create = (item: string) => {
    return GlobalAxios.post(HISTORY_PAYMENT_ROUTE, item);
  };

  getAll = (year?: string) => {
    return GlobalAxios.get<any>(HISTORY_PAYMENT_ROUTE + year);
  };
}

export default HistoryPaymentService;
