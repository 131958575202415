import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IInstalledExtension } from "../../models/intalled-extensions";

interface IInstalledExtensionsStore {
  extensions: IInstalledExtension[];
}

const INSTALLED_EXTENSIONS_INITIAL_STATE: IInstalledExtensionsStore = {
  extensions: [],
};

export const slice = createSlice({
  name: "installedExtensions",
  initialState: INSTALLED_EXTENSIONS_INITIAL_STATE,
  reducers: {
    saveInstalledExtension(
      state: IInstalledExtensionsStore,
      action: PayloadAction<IInstalledExtension[]>
    ) {
      return { ...state, extensions: action.payload };
    },
    removeInstalledExtensions(state) {
      return (state = INSTALLED_EXTENSIONS_INITIAL_STATE);
    },
  },
});

export const installedExtensionsSliceActions = slice.actions;

export default slice.reducer;
