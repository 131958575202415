import { Paper, useTheme } from "@mui/material";

import styles from "./AuthCardContainer.module.scss";
import { useAppSelector } from "../../../store/store";
import { Link } from "react-router-dom";
import { memo } from "react";

type TAuthCardContainer = {
  children: any;
  link?: string;
  textLink?: string;
};

const AuthCardContainer: React.FC<TAuthCardContainer> = ({
  children,
  link,
  textLink,
}) => {
  const { logo, logo_dark, mode, name } = useAppSelector(
    (state) => state.theme
  );

  const portalLogo = mode === "1" ? logo : logo_dark ? logo_dark : logo;

  const theme = useTheme();

  return (
    <div
      className={styles.container}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Paper className={styles["card-container"]}>
        <Link
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
            color: theme.palette.primary.main,
          }}
          to="/"
        >
          <img src={portalLogo} className={styles.logo} alt={name} />
        </Link>
        {children}
      </Paper>
      {link && (
        <Link
          to={link}
          style={{ textDecoration: "none", color: theme.palette.primary.main }}
        >
          {textLink}
        </Link>
      )}
    </div>
  );
};

export default memo(AuthCardContainer);
