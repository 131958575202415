import {
  Avatar,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import DisplayDate from "../DisplayDate";
import dayjs from "dayjs";
import { IBeneficiaryData } from "../../models/topmed";
import { useTranslation } from "react-i18next";

const translationPath = "components.schedulingBeneficiary.";

const SchedulingBeneficiary = ({
  beneficiary,
}: {
  beneficiary: IBeneficiaryData;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const { name, mail, gender, cellPhone, birthDate } = beneficiary;

  return (
    <Grid container>
      <Grid
        item
        sm={3}
        xs={2}
        sx={{
          paddingLeft: matches ? 1 : 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <Avatar sx={{ width: matches ? 60 : 50, height: matches ? 60 : 50 }}>
          <PersonRoundedIcon fontSize="large" />
        </Avatar>
      </Grid>
      <Grid
        item
        sm={9}
        xs={10}
        sx={(theme) => ({
          paddingLeft: matches ? 0 : 1,
          transition: "all .2s ease-in-out",
          "&:hover ": {
            color: theme.palette.primary.main,
          },
        })}
      >
        <Typography variant="subtitle1" fontWeight={"bold"}>
          {name}
        </Typography>
        <Stack>
          <Stack flexDirection={"row"} gap={matches ? 1 : 0.5}>
            <Typography
              variant="subtitle2"
              sx={(theme) => ({
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.grey[800]
                    : theme.palette.grey[400],
              })}
            >
              {t(translationPath + "birth_date")}:
            </Typography>
            <DisplayDate
              typography={{
                variant: "subtitle2",
                color: theme.palette.text.primary,
              }}
            >
              {dayjs(`${birthDate}`)}
            </DisplayDate>
          </Stack>
          <Stack flexDirection={"row"} gap={matches ? 1 : 0.5}>
            <Typography
              variant="subtitle2"
              sx={(theme) => ({
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.grey[800]
                    : theme.palette.grey[400],
              })}
            >
              {t(translationPath + "gender")}:
            </Typography>
            <Typography
              variant="subtitle2"
              sx={(theme) => ({
                color: theme.palette.text.primary,
              })}
            >
              {gender}
            </Typography>
          </Stack>
          <Stack flexDirection={"row"} gap={matches ? 1 : 0.5}>
            <Typography
              variant="subtitle2"
              sx={(theme) => ({
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.grey[800]
                    : theme.palette.grey[400],
              })}
            >
              {t(translationPath + "mail")}:
            </Typography>
            <Typography
              variant="subtitle2"
              sx={(theme) => ({
                color: theme.palette.text.primary,
              })}
            >
              {mail || "--"}
            </Typography>
          </Stack>
          <Stack flexDirection={"row"} gap={matches ? 1 : 0.5}>
            <Typography
              variant="subtitle2"
              sx={(theme) => ({
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.grey[800]
                    : theme.palette.grey[400],
              })}
            >
              {t(translationPath + "phone")}:
            </Typography>
            <Typography
              variant="subtitle2"
              sx={(theme) => ({
                color: theme.palette.text.primary,
              })}
            >
              {cellPhone || "--"}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default SchedulingBeneficiary;
