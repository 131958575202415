import { useCallback, useEffect, useState } from "react";

import { EBannerLocale, IPortalBanner } from "../../../models/banners";
import Modal from "../../UI/Modal";
import { tokenService } from "../../../services/token.service";
import Banners from "./Banners";
import { useBanners } from "../../../store/contexts/BannersContext/useBanners";

const HomeBanners = () => {
  const [open, setOpen] = useState(false);

  const token = tokenService.getAccessToken();

  const localeCode = token
    ? EBannerLocale.LOGIN_IN_SYSTEM
    : EBannerLocale.OPEN_SITE_APP;

  const { banners, closeAllBanners, closeBanner } = useBanners({ localeCode });

  const [currentSlide, setCurrentSlide] = useState(0);

  const closeModalHandler = () => setOpen(false);

  useEffect(() => {
    setOpen(banners.length > 0);
  }, [banners.length]);

  const handleCloseBanner = useCallback(
    async (banner: IPortalBanner) => {
      await closeBanner(banner);
      setCurrentSlide((prev) => (prev === 0 ? prev : prev - 1));
    },
    [closeBanner]
  );

  return (
    <Modal open={open} onClose={closeModalHandler}>
      <Banners
        currentSlide={currentSlide}
        setCurrentSlide={setCurrentSlide}
        banners={banners}
        closeBanner={handleCloseBanner}
        closeAllBanners={closeAllBanners}
      />
    </Modal>
  );
};

export default HomeBanners;
