import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { SxProps, Theme } from "@mui/material";

import { EcalendarStatusCode, IScheduleAppointment } from "../../model";
import Row from "../../../../components/UI/Row";

interface IConsultData {
  data: IScheduleAppointment;
  sx?: SxProps<Theme>;
}

export const ConsultData: React.FC<IConsultData> = memo(
  ({
    data: {
      physicianNameExecute,
      pacientName,
      insuranceName,
      calendarClassificationName,
      calendarStatusName,
      calendarStatusCode,
    },
    sx,
  }) => {
    const { t } = useTranslation();

    return (
      <>
        <Row
          label={t("page.schedules.scheduleDetails.professional")}
          value={physicianNameExecute}
          sx={sx}
        />
        <Row label={t("page.schedules.scheduleDetails.type")} value={calendarClassificationName} sx={sx} />
        <Row label={t("page.schedules.scheduleDetails.insurance")} value={insuranceName} sx={sx} />
        <Row label={t("page.schedules.scheduleDetails.patient")} value={pacientName} sx={sx} />
        <Row
          label={t("page.schedules.scheduleDetails.status")}
          value={calendarStatusName}
          sx={[
            (t) => ({
              color:
                calendarStatusCode === EcalendarStatusCode.CANCELED
                  ? t.palette.error.main
                  : t.palette.text.primary,
            }),
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
        />
      </>
    );
  }
);
