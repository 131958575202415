import { useTranslation } from "react-i18next";
import { IMedicGuideAutoComplete } from "../../../models/medic-guide";

export interface IMedicGuideOption {
  title: string;
  id: string | string[];
  name: string;
  code: string;
}

const SearchOptions = (options?: IMedicGuideAutoComplete) => {
  const { t } = useTranslation();

  const specialties =
    options?.specialty &&
    options?.specialty.map((s) => ({
      id: [s.id],
      name: s.name,
      code: "1",
      title: t("components.autoCompleteVoice.specialty"),
    }));
  const practiceArea =
    options?.practiceArea &&
    options?.practiceArea.map((s) => ({
      id: [s.id],
      name: s.name,
      code: "2",
      title: t("components.autoCompleteVoice.practice_area"),
    }));
  const city =
    options?.city &&
    options?.city.map((s) => ({
      id: [s.id],
      name: s.name,
      code: "3",
      title: t("components.autoCompleteVoice.city"),
    }));
  const healthPlan =
    options?.healthPlan &&
    options?.healthPlan.map((s) => ({
      id: [s.id],
      name: s.name,
      code: "4",
      title: t("components.autoCompleteVoice.health_plan"),
    }));
  const professional =
    options?.professional &&
    options?.professional.map((s) => ({
      id: s.id,
      name: s.name,
      code: "5",
      title: t("components.autoCompleteVoice.professional"),
    }));
  const providerType =
    options?.providerType &&
    options?.providerType.map((s) => ({
      id: [s.id],
      name: s.name,
      code: "6",
      title: t("components.autoCompleteVoice.provider_type"),
    }));

  const allOptions: IMedicGuideOption[] | undefined = specialties?.concat(
    practiceArea!,
    city!,
    healthPlan!,
    professional!,
    providerType!
  );

  return allOptions;
};

export default SearchOptions;
