import { Box } from "@mui/material";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useFormStepperContext } from "../../../../../store/contexts/FormStepperContext";
import Button from "../../../Button";
import { IFormStepsActions } from "./models";

const translationPath = "components.formStepsActions.";

export const FormStepsActions = memo(
  ({
    stepsLength,
    stepLoading,
    type = "button",
    stepFunction,
  }: IFormStepsActions) => {
    const { t } = useTranslation();

    const { activeStep, handleNext, handleBack } = useFormStepperContext();

    const nextHandler = useCallback(() => {
      if (stepFunction) {
        stepFunction();
      }
      handleNext();
    }, [stepFunction, handleNext]);

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          pt: 2,
        }}
      >
        <Button
          color="inherit"
          variant="outlined"
          size="small"
          disabled={activeStep <= 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          {t(translationPath + "back")}
        </Button>
        {activeStep <= stepsLength && (
          <Button
            variant="outlined"
            size="small"
            loading={stepLoading}
            type={type}
            onClick={type === "button" ? nextHandler : undefined}
          >
            {activeStep === stepsLength
              ? t(translationPath + "end")
              : t(translationPath + "next")}
          </Button>
        )}
      </Box>
    );
  }
);
