import { memo } from "react";
import { IconButton, Stack } from "@mui/material";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { IContentMediaFile } from "../../../models/content";

interface IMaterialProps {
  file: IContentMediaFile;
}

const Material: React.FC<IMaterialProps> = ({ file }) => {
  const uri = file ? file.cdnDomain + "/" + file.filePath : undefined;

  const openFileLink = () => {
    if (uri) {
      window.open(uri, "_blank");
    }
  };

  return (
    <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
      {file.fileName}
      <IconButton onClick={openFileLink} size="small">
        <FileOpenIcon fontSize="small"/>
      </IconButton>
    </Stack>
  );
};

export default memo(Material);
