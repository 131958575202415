import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AutoComplete, {
  ISelectType,
} from "../../../../components/UI/Inputs/AutoComplete";
import useFetch from "../../../../hooks/useFetch";
import { KEYS } from "./AddFamilyGroup";
import { FamilyGroupService } from "../familyGroup.service";

const SelectKinship = () => {
  const [options, setOptions] = useState<ISelectType[]>([]);

  const { t } = useTranslation();

  const { sendRequest } = useFetch(FamilyGroupService.getKinship);

  const fetch = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (data && success) {
      setOptions(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <AutoComplete
      getOptionLabel={(option) => {
        return option.name;
      }}
      size="small"
      label={t("page.schedules.addFamilyGroup.kinshipDegree.label")}
      name={KEYS.kinship}
      options={options}
    />
  );
};

export default memo(SelectKinship);
