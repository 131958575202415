import { Box } from "@mui/material";
import { useNotificationsUser } from "../../store/contexts/NotificationsUserContext";
import UnreadNotificationItem from "./UnreadNotificationItem";
import { defaultScrollTheme } from "../../utils/utils";

interface IUnreadNotificationsListProps {
  onClose: () => void;
}

const UnreadNotificationsList = ({
  onClose,
}: IUnreadNotificationsListProps) => {
  const { unreadNotifications } = useNotificationsUser();

  return (
    <Box
      my={0.5}
      maxHeight={260}
      sx={(t) => ({
        overflowY: "auto",
        ...defaultScrollTheme(t)
      })}
    >
      {unreadNotifications.length !== 0 &&
        unreadNotifications.map((notification) => (
          <UnreadNotificationItem
            notification={notification.notificationBatchUserContent}
            notificationId={notification.id}
            key={notification.id}
            onClose={onClose}
          />
        ))}
    </Box>
  );
};

export default UnreadNotificationsList;
