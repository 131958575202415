import { memo } from "react";
import { Grid } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import dayjs from "dayjs";

import { useSchedule } from "./useSchedule";
import { TAppointment } from "../../schema";
import { SchedulesList } from "./SchedulesList";
import ScheduleCalendar from "./ScheduleCalendar";

const Schedule = () => {
  const { control } = useFormContext<TAppointment>();

  const dateForm = useWatch({ name: "search.date", control });

  const {
    date,
    dates,
    datesLoading,
    onDateChange,
    onVisibleMonthsChange,
    schedules,
    schedulesLoading,
  } = useSchedule(dateForm.toDateString());

  return (
    <Grid container spacing={1} >
      <Grid item xs={12} md={"auto"}>
        <ScheduleCalendar
          date={date}
          defaultValue={dayjs(dateForm)}
          dates={dates}
          datesLoading={datesLoading}
          onDateChange={onDateChange}
          onMonthChange={onVisibleMonthsChange}
        />
      </Grid>
      <Grid item xs={12} md={true}>
        <SchedulesList
          schedules={schedules}
          name="schedule"
          loading={schedulesLoading}
        />
      </Grid>
    </Grid>
  );
};

export default memo(Schedule);
