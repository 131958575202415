import { CircularProgress, Grid, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

import { INews } from "../../models/news";
import { getRecentNews } from "../../services/news.service";
import useFetch from "../../hooks/useFetch";
import NewsCard from "./NewsCard";
import NoNews from "./NoNews";
import Button from "../UI/Button";
import { useAppSelector } from "../../store/store";

const translationPath = "components.recentNews.";

interface IRecentNewsProps {
  onHasRecentNews: (hasRecentNews: boolean) => void;
}

const RecentNews = ({ onHasRecentNews }: IRecentNewsProps) => {
  const { t } = useTranslation();

  const actualLang = useAppSelector((state) => state.language.languageId);

  const navigate = useNavigate();

  const [recentNews, setRecentNews] = useState<INews[]>([]);

  const { sendRequest, loading } = useFetch(getRecentNews);

  const loadNewsHandler = useCallback(async () => {
    if (!actualLang) return;
    const { data, success } = await sendRequest({
      LanguageId: actualLang,
      QtyRecords: 4,
    });
    if (data && success) {
      if (data.length === 0) {
        onHasRecentNews(true);
      }
      setRecentNews(data);
    }
  }, [actualLang, sendRequest, onHasRecentNews]);

  useEffect(() => {
    loadNewsHandler();
  }, [loadNewsHandler]);

  const viewAllNewsHandler = () => {
    navigate("news");
  };

  const hasRecentNews = recentNews && recentNews.length !== 0;

  return (
    <>
      {hasRecentNews && (
        <Stack mt={1}>
          {loading && (
            <Stack
              mt={1}
              width={"100%"}
              flexDirection={"row"}
              justifyContent={"center"}
            >
              <CircularProgress />
            </Stack>
          )}
          {!loading && !hasRecentNews && (
            <NoNews message={t(translationPath + "no_news")} />
          )}
          {!loading && hasRecentNews && (
            <Grid container spacing={1}>
              {recentNews.map((news) => (
                <Grid item xs={12} sm={6} key={news.id}>
                  <NewsCard news={news} />
                </Grid>
              ))}
            </Grid>
          )}
          {recentNews.length === 4 && (
            <Stack width={"100%"} flexDirection={"row"} justifyContent={"end"}>
              <Button
                onClick={viewAllNewsHandler}
                startIcon={<AddIcon />}
                sx={{ textTransform: "initial" }}
              >
                {t("components.newsList.view_more")}
              </Button>
            </Stack>
          )}
        </Stack>
      )}
    </>
  );
};

export default RecentNews;
