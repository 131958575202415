import { ISelectType } from "../components/UI/Inputs/AutoComplete";

export interface IInstalledExtension {
  id: string;
  software: ISelectType;
  code: string;
  name: string;
  title: string;
  description: string;
}

export enum Extension {
  EMERGENCY_CARE = "1",
  REFUND = "2",
  TELEPHONE = "4",
  FAQ = "6",
  CARDS = "10",
  MEDIC_GUIDE = "11",
  PAYMENTSLIP = "12",
  COPARTICIPATION = "13",
  IRPF = "14",
  AUTHORIZATIONS = "15",
  WHATSAPP = "19",
  NEWS = "8",
  NOTIFICATIONS = "9",
  HEALTH_PROGRAM = "18",
  EXAMS = "23",
  SCHEDULE = "25"
}
