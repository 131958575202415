import { useCallback, useState } from "react";
import { AxiosResponse, isAxiosError } from "axios";
import { notificationSliceActions } from "../store/slices/notification";
import { useAppDispatch } from "../store/store";

type Trequest<T, X> = (item: T) => Promise<AxiosResponse<X, any>>;

type Treturn<X> =
  | {
      data: X;
      success: true;
    }
  | {
      data: null;
      success: false;
    };

const useFetch = <T, X>(request?: Trequest<T, X>) => {
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const sendRequest: (requestBody: T) => Promise<Treturn<X>> = useCallback(
    async (requestBody: T) => {
      if (!request)
        return {
          success: false,
          data: null,
        };
      try {
        setLoading(true);
        const response = await request(requestBody);
        return {
          success: true,
          data: response.data,
        };
      } catch (err: any) {
        if (isAxiosError(err)) {
          setError(err.response?.data);
          if (err.response?.data.errors) {
            let errorMessage = "";

            Object.keys(err.response?.data.errors).forEach((key) => {
              errorMessage += err.response?.data.errors[key].join(" | ") + " ";
            });

            dispatch(
              notificationSliceActions.showNotification({
                message: errorMessage,
                type: "error",
              })
            );
          } else if (err.response?.data.detail) {
            dispatch(
              notificationSliceActions.showNotification({
                message: err.response?.data.detail || err.message,
                type: "error",
              })
            );
          } else {
            dispatch(
              notificationSliceActions.showNotification({
                message: err.message,
                type: "error",
              })
            );
          }
        } else {
          dispatch(
            notificationSliceActions.showNotification({
              message: err.message,
              type: "error",
            })
          );
        }
        return {
          success: false,
          data: null,
        };
      } finally {
        setLoading(false);
      }
    },
    [request, dispatch]
  );

  return { error, loading, sendRequest };
};

export default useFetch;
