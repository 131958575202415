import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Cards from "../UI/Cards";
import { useTranslation } from "react-i18next";
import DownloadIcon from "@mui/icons-material/Download";
import useDownload from "../../hooks/useDownload";
import IrpfModal from "./IrpfModal";
import { useEffect, useState } from "react";

const translationPath = "page.irpf.";

interface IIrpfCards {
  year: number;
  payerIntegrationId: string | null;
  individualIntegrationId: string | null;
}

const DOWNLOAD_URL =
  process.env.REACT_APP_APP + "/api/v1/Beneficiary/GetIncomeTaxStatement/";

const IrpfCards = ({
  year,
  payerIntegrationId,
  individualIntegrationId,
}: IIrpfCards) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const { downloadFileParams, loading, error } = useDownload();

  const downloadHandler = () => {
    const YEAR = year.toString();
    downloadFileParams(
      DOWNLOAD_URL,
      {
        Year: YEAR,
        PayerIntegrationId: payerIntegrationId ?? undefined,
        IndividualIntegrationId: individualIntegrationId ?? undefined,
      },
      YEAR
    );
  };

  const onCloseHandler = () => setOpen(false);

  useEffect(() => {
    if (error) setOpen(true);
  }, [error]);

  return (
    <>
      <IrpfModal selectedYear={year} open={open} closeModal={onCloseHandler} />
      <Cards>
        <Grid item xl={9} xs={6} display={"flex"} alignItems={"center"} gap={1}>
          <Typography
            variant="subtitle1"
            sx={(theme) => ({
              color:
                theme.palette.mode === "light"
                  ? theme.palette.grey[600]
                  : theme.palette.grey[400],
            })}
          >
            {t(translationPath + "year")}:
          </Typography>
          <Typography variant="h6">{year}</Typography>
        </Grid>
        <Grid item xs={6} xl={3} textAlign={"end"}>
          <Button
            variant="contained"
            onClick={downloadHandler}
            startIcon={!loading ? <DownloadIcon /> : undefined}
            size="small"
            disabled={loading ? true : false}
          >
            {loading && (
              <Stack
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
                px={0.75}
              >
                <CircularProgress size={15} />
                <Typography variant="button" fontSize={12} pt={0.5}>
                  {t(translationPath + "downloading")}
                </Typography>
              </Stack>
            )}
            {!loading && t(translationPath + "download_irpf")}
          </Button>
        </Grid>
      </Cards>
    </>
  );
};

export default IrpfCards;
