import {
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IDocumentAcceptance } from "../models/document-acceptance";
import DocAcceptanceService from "../services/docAcceptance.service";
import useFetch from "../hooks/useFetch";

import Document from "../components/Docs/Document";
import ModalComponent from "../components/UI/Modal";
import DocumentAcceptanceModal from "../components/Docs/DocumentAcceptanceModal";
import NoData from "../components/UI/NoData";
import { useAppSelector } from "../store/store";

const documentsService = new DocAcceptanceService();

const DocumentsPage = () => {
  const [open, setOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] =
    useState<IDocumentAcceptance>();
  const [docs, setDocs] = useState<IDocumentAcceptance[]>([]);

  const actualLang = useAppSelector((state) => state.language.languageId);

  const { t } = useTranslation();

  const { loading, sendRequest } = useFetch(
    documentsService.getDocumentsAccepted
  );

  const getDocuments = useCallback(async () => {
    if (!actualLang) return;
    const { data, success } = await sendRequest(actualLang);
    if (success) {
      setDocs(data);
    }
  }, [sendRequest, actualLang]);

  useEffect(() => {
    getDocuments();
  }, [getDocuments]);

  const onOpenDocument = useCallback((document: IDocumentAcceptance) => {
    setSelectedDocument(document);
    setOpen(true);
  }, []);

  const onCloseHandler = () => setOpen(false);

  return (
    <>
      <ModalComponent
        open={open}
        onClose={onCloseHandler}
        dialogMaxWidth={'xl'}
      >
        {selectedDocument && <DocumentAcceptanceModal {...selectedDocument} />}
      </ModalComponent>

      <Stack direction={"column"} width={"100%"}>
        <Typography variant="h5" mb={2}>
          {t("page.documents.title")}
        </Typography>
        {!loading && docs.length > 0 && (
          <Typography variant="subtitle1" mb={2}>
            {t("page.documents.subtitle")}
          </Typography>
        )}

        {loading && <CircularProgress />}
        {!loading && (
          <Stack>
            {docs?.map((d, i) => {
              return (
                <Document
                  document={d}
                  index={i}
                  key={i}
                  onClick={onOpenDocument}
                />
              );
            })}
          </Stack>
        )}
        {!loading && docs.length === 0 && (
          <NoData message={t("page.documents.no_docs")} />
        )}
      </Stack>
    </>
  );
};

export default DocumentsPage;
