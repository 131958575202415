import { useMediaQuery, useTheme } from "@mui/material";
import { DataGridProps, GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import DisplayDate from "../../components/DisplayDate";
import ExamsDownload from "../../components/Exams/ExamsDownload/ExamsDownload";
import ExamsRedirectFile from "../../components/Exams/ExamsRedirectFile/ExamsRedirectFile";
import { Reports } from "../../components/Exams/Reports/Reports";
import BaseTable from "../../components/UI/Table/BaseTable";
import useSiteApp from "../../hooks/useSiteApp";
import { IExams } from "../../models/exams";

const translationPath = "page.exams.";

interface IExamsTableProps {
  data: IExams[];
  isDoctor: boolean;
  individualId?: string;
  tableProps?: Omit<DataGridProps, "columns" | "rows" | "sx">;
}

const ExamsTable: React.FC<IExamsTableProps> = ({
  data,
  isDoctor,
  tableProps,
  individualId,
}) => {
  const { t } = useTranslation();

  const {
    config: { sideMenu },
  } = useSiteApp();

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const columns: GridColDef<IExams>[] = [
    {
      field: "type",
      headerName: `${t(translationPath + "type")}`,
      flex: 1,
    },
    {
      field: "medicalCertificateName",
      headerName: `${t(translationPath + "procedure")}`,
      flex: 1,
    },
    {
      field: "physicianName",
      headerName: `${t(translationPath + "doctor")}`,
      flex: 1,
    },
    {
      field: "establishmentName",
      headerName: `${t(translationPath + "unit")}`,
      flex: 1,
    },
    {
      field: "medicalCertificateDate",
      headerName: `${t(translationPath + "date")}`,
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => (
        <DisplayDate typography={{ variant: "body2" }}>
          {dayjs(params.value)}
        </DisplayDate>
      ),
    },
    {
      field: "sequencialImageControls",
      headerName: `${t(translationPath + "image")}`,
      flex: 1,
      maxWidth: 100,
      renderCell: (params) =>
        params.value.length === 0 ? (
          "-"
        ) : (
          <ExamsRedirectFile
            sequencialControl={params.row.sequencialControl}
            sequencialImageControls={params.row.sequencialImageControls}
          />
        ),
    },
    {
      field: "download",
      headerName: `${t(translationPath + "report")}`,
      flex: 1,
      maxWidth: 100,
      renderCell: (params) =>
        !params.row.medicalCertificateContent ||
        params.row.medicalCertificateContent?.length === 0 ? (
          "-"
        ) : params.row.formatTypeCode === "3" ? (
          <Reports
            identifier={params.row.id}
            id={params.row.id.toString()}
            examType={params.row.type}
            date={params.row.medicalCertificateDate}
            individualId={individualId}
          />
        ) : (
          <ExamsDownload
            id={params.row.id.toString()}
            examType={params.row.type}
            date={params.row.medicalCertificateDate}
            individualId={individualId}
          />
        ),
    },
  ];

  return (
    <BaseTable<IExams>
      {...tableProps}
      columns={columns}
      rows={data}
      paginationMode="server"
      sx={{
        height: matches
          ? matches && isDoctor
            ? "calc(100vh - 340px)"
            : "calc(100vh - 320px)"
          : sideMenu
          ? isDoctor && sideMenu
            ? "calc(100vh - 240px)"
            : "calc(100vh - 220px)"
          : "calc(100vh - 240px)",
      }}
    />
  );
};

export default memo(ExamsTable);
