import { z } from "zod";
import { CPF_VALIDATOR } from "../../../../utils/Validators/regexValidators";

const genderSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  {
    invalid_type_error: "page.schedules.addFamilyGroup.gender.error",
    required_error: "page.schedules.addFamilyGroup.gender.error",
  }
);

const kinshipSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  {
    invalid_type_error: "page.schedules.addFamilyGroup.kinshipDegree.error",
    required_error: "page.schedules.addFamilyGroup.kinshipDegree.error",
  }
);

export const familyMemberSchema = z.object({
  name: z.string().min(1, "page.schedules.addFamilyGroup.name.error"),
  kinship: kinshipSchema,
  document: z
    .string()
    .regex(CPF_VALIDATOR, "page.schedules.addFamilyGroup.document.error"),
  birthDate: z.date({
    invalid_type_error: "page.schedules.addFamilyGroup.birthDate.error",
    required_error: "page.schedules.addFamilyGroup.birthDate.error",
  }),
  gender: genderSchema,
  nationality: z
    .string()
    .min(1, "page.schedules.addFamilyGroup.nationality.error"),
});

export type TFamilyMember = z.infer<typeof familyMemberSchema>;
