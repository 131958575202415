import usePermission from "../../hooks/usePermission";
import { Permission } from "../../models/permission";

interface IPermissonControlProps {
  domain?: string;
  actions?: Permission[];
  children: JSX.Element;
  isExtensionInstalled?: boolean;
}

const PermissionControl: React.FC<IPermissonControlProps> = ({
  domain,
  children,
  actions,
  isExtensionInstalled,
}) => {
  const hasPermission = usePermission({ domain, actions });

  if (isExtensionInstalled) {
    if (domain && hasPermission) {
      return children;
    }
    if (!domain) {
      return children;
    }
    return null;
  }

  return null;
};

export default PermissionControl;
