import { Stack } from "@mui/material";
import { IContact } from "../../../../../models/medic-guide";
import MedicGuideContact from "./MedicGuideContact";
import { useTranslation } from "react-i18next";

const translationPath = "components.medicGuideFilter.";

const ResultItemContacts = ({ contact }: { contact: IContact }) => {
  const { t } = useTranslation();

  return (
    <Stack flexDirection={"column"} mt={0.5}>
      {contact.phone && (
        <MedicGuideContact
          label={`${t(translationPath + "phone")}:`}
          contact={contact.phone}
        />
      )}

      {contact.cellPhone && (
        <MedicGuideContact
          label={`${t(translationPath + "cellphone")}:`}
          contact={contact.cellPhone}
        />
      )}

      {contact.whatsapp && (
        <MedicGuideContact
          label={`${t(translationPath + "whatsapp")}:`}
          contact={contact.whatsapp}
        />
      )}

      {contact.website && (
        <MedicGuideContact
          label={`${t(translationPath + "website")}:`}
          contact={contact.website}
        />
      )}

      {contact.mail && contact.mail.length > 1 && (
        <MedicGuideContact
          label={`${t(translationPath + "mail")}:`}
          contact={contact.mail}
        />
      )}
    </Stack>
  );
};

export default ResultItemContacts;
