import {
  Box,
  Grid,
  Link,
  Pagination,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";

import useFetch from "../../hooks/useFetch";
import { getAllNotifications } from "../../services/notifications.service";
import NotificationCard from "../../components/Notifications/NotificationCard";
import NoNotifications from "../../components/Notifications/NoNotifications";
import { useNotificationsUser } from "../../store/contexts/NotificationsUserContext";
import PagesContainer from "../../components/UI/PagesContainer";
import useRole from "../../hooks/useRole";
import { useAppSelector } from "../../store/store";

const translatioPath = "page.notifications.";

const Notifications = () => {
  const { t } = useTranslation();

  const hasUpdatePermission = useRole(["Update"]);

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const [pagination, setPagination] = useState({ PageNumber: 1, PageSize: 10 });

  const [paginationData, setPaginationData] = useState({
    pageNumber: 1,
    totalPages: 1,
  });

  const {
    loadAllNotifications,
    allNotifications,
    markAllNotificationAsReadHandler,
  } = useNotificationsUser();

  const actualLang = useAppSelector((state) => state.language.languageId);

  const { sendRequest, loading } = useFetch(getAllNotifications);

  const loadNotifications = useCallback(async () => {
    if (!actualLang) return;
    const { data, success } = await sendRequest({
      LanguageId: actualLang,
      ...pagination,
    });
    if (data && success) {
      loadAllNotifications(data.items);
      setPaginationData({
        pageNumber: data.pageNumber,
        totalPages: data.totalPages,
      });
    }
  }, [sendRequest, actualLang, loadAllNotifications, pagination]);

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  const nextPage = (PageNumber: number) => {
    setPagination((oldData) => ({ ...oldData, PageNumber }));
  };

  return (
    <PagesContainer
      paddinRight={matches ? "80px" : 0}
      loading={loading}
      title={t(translatioPath + "title")}
    >
      {allNotifications.length === 0 && <NoNotifications />}
      {allNotifications.length !== 0 && (
        <Grid container spacing={2}>
          {allNotifications.find((not) => !not.isRead) &&
            hasUpdatePermission && (
              <Grid item sm={12}>
                <Link
                  variant="subtitle2"
                  onClick={markAllNotificationAsReadHandler}
                  sx={{ cursor: "pointer" }}
                >
                  {t(translatioPath + "mark_all_as_read")}
                </Link>
              </Grid>
            )}
          {allNotifications.length !== 0 &&
            allNotifications.map((notification) => (
              <Grid item xs={12} sm={6} key={notification.id}>
                <NotificationCard notification={notification} />
              </Grid>
            ))}
        </Grid>
      )}
      <Box flexGrow={1}></Box>
      {paginationData.totalPages >= 2 && (
        <Box
          sx={(t) => ({
            mt: 2,
            width: "100%",
            height: 50,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: t.palette.background.default,
          })}
        >
          <Pagination
            page={paginationData.pageNumber}
            count={paginationData.totalPages}
            onChange={(event, page) => nextPage(page)}
          />
        </Box>
      )}
    </PagesContainer>
  );
};

export default Notifications;
