import { Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import RefundsBeneficiaryForm from "../../../../components/Refunds/RefundsBeneficiaryForm";
import { ISelectType } from "../../../../components/UI/Inputs/AutoComplete";
import DateInput from "../../../../components/UI/Inputs/DateInput";
import FormInput from "../../../../components/UI/Inputs/FormInput";
import RadioGroupInput from "../../../../components/UI/Inputs/RadioGroupInput";
import useFetch from "../../../../hooks/useFetch";
import { IInputFile } from "../../../../models/input-file";
import MainApiService from "../../../../services/generic.service";
import { useStep } from "../../../../store/contexts/StepContext";
import RefundStepContainer from "../RefundStepContainer";
import RefundFiles from "./RefundFiles";

const translationPath = "page.refundsPage.refund.";

const TYPE_GUIDE_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const typeGuideService = new MainApiService<ISelectType>(TYPE_GUIDE_ROUTE);

const RefundFirst = ({
  name,
  onDeleteFilesHandler,
}: {
  name: string;
  onDeleteFilesHandler: (value: string) => void;
}) => {
  const { t } = useTranslation();
  const { formState, getValues } = useFormContext();
  const { onError, onRemoveError, defineFields } = useStep();
  const [typeGuideList, setTypeGuideList] = useState<ISelectType[]>([]);
  const [fields] = useState([
    "typeGuideId",
    "serviceDate",
    "serviceValue",
    "serviceLocation",
    "serviceReason",
    "files",
  ]);

  const { sendRequest } = useFetch(typeGuideService.list);

  const listTypeGuide = useCallback(async () => {
    const { data, success } = await sendRequest({ Identifier: "TypeGuide" });
    if (success) {
      setTypeGuideList(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    if (fields.some((field) => formState.errors[field])) {
      onError(1);
    } else {
      onRemoveError(1);
    }
    listTypeGuide();
  }, [
    formState.errors,
    onError,
    onRemoveError,
    getValues,
    listTypeGuide,
    fields,
  ]);

  const onDeleFileHandler = (file: IInputFile) => {
    const selectedFile = file;
    if (selectedFile.id.length !== 0) {
      onDeleteFilesHandler(selectedFile.id);
    }
  };

  const onValidation = useCallback(() => {
    if (fields.some((field) => formState.errors[field])) return;
    defineFields(fields);
  }, [defineFields, formState.errors, fields]);

  useEffect(() => {
    onValidation();
  }, [onValidation]);

  return (
    <RefundStepContainer>
      <Grid container columnSpacing={2}>
        <RefundsBeneficiaryForm name={name} />

        <Grid item sm={12} mb={2}>
          <RadioGroupInput
            name="typeGuideCode"
            groupLabel={t(translationPath + "type_guide")}
            radios={typeGuideList.map((type) => ({
              label: type.name,
              value: {
                id: type.id,
                code: type.code,
              },
            }))}
            valueField="code"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DateInput
            hasDefaultDate={false}
            label={t(translationPath + "service_date")}
            name="serviceDate"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormInput
            size="small"
            name="serviceValue"
            fullWidth
            label={t(translationPath + "service_value")}
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <Typography
                  mr={1}
                  sx={(theme) => ({
                    color:
                      theme.palette.mode === "light"
                        ? theme.palette.grey[800]
                        : theme.palette.grey[400],
                  })}
                  variant="subtitle2"
                >
                  R$
                </Typography>
              ),
            }}
            mask={[
              {
                mask: "num",
                blocks: {
                  num: {
                    mask: Number,
                    thousandsSeparator: ".",
                    decimalSymbol: ",",
                  },
                },
              },
            ]}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormInput
            size="small"
            name="serviceLocation"
            fullWidth
            label={t(translationPath + "service_location")}
            sx={{ mb: 2 }}
          />
        </Grid>

        <Grid item xs={12} sm={12} mb={5}>
          <FormInput
            size="small"
            name="serviceReason"
            fullWidth
            label={t(translationPath + "service_reason")}
            multiline
            minRows={4}
          />
        </Grid>

        <RefundFiles onDeleteFileHandler={onDeleFileHandler} />
      </Grid>
    </RefundStepContainer>
  );
};

export default RefundFirst;
