import { Box, Button, Stack, Typography } from "@mui/material";
import { useAppDispatch } from "../../../store/store";
import { notificationSliceActions } from "../../../store/slices/notification";
import { useTranslation } from "react-i18next";

const translationPath = "components.queueModal.";

const QueueModal = ({
  url,
  message,
  onClose,
}: {
  url: string;
  onClose: () => void;
  message: string;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onCopyToClipboardHandler = () => {
    navigator.clipboard.writeText(url);
    dispatch(
      notificationSliceActions.showNotification({
        message: `${t(translationPath + "link_copied")}`,
        type: "success",
      })
    );
  };

  return (
    <>
      {url.length === 0 && (
        <Box maxWidth={450}>
          <Typography mb={2}>{message}</Typography>
          <Button variant="contained" size="small" fullWidth onClick={onClose}>
            Fechar
          </Button>
        </Box>
      )}
      {url.length !== 0 && (
        <Box maxWidth={400}>
          <Stack
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <img
              src={"/actions/virtual-emergency-care.png"}
              style={{ marginBottom: 15, maxWidth: 150 }}
              alt={`${t(translationPath + "image_alt")}`}
            />
            <Typography variant="h6" textAlign={"center"}>
              {t(translationPath + "title")}
            </Typography>
            <Typography
              variant="subtitle1"
              textAlign={"center"}
              lineHeight={1.2}
            >
              {t(translationPath + "subtitle")}
            </Typography>
          </Stack>
          <Stack
            mt={2}
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            gap={1}
          >
            <Button
              size="small"
              variant="outlined"
              fullWidth
              onClick={onCopyToClipboardHandler}
            >
              {t(translationPath + "copy_link")}
            </Button>
            <Button
              size="small"
              variant="contained"
              fullWidth
              onClick={() => window.open(url)}
            >
              {t(translationPath + "go_queue")}
            </Button>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default QueueModal;
