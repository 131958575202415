import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AutoComplete, {
  ISelectType,
} from "../../../../components/UI/Inputs/AutoComplete";
import { addInsuranceSchema } from "./schema";
import useFetch from "../../../../hooks/useFetch";
import { ScheduleInsuranceService } from "../insurance.service";

const KEYS = addInsuranceSchema.keyof().Enum;

const SelectInsurance = () => {
  const [options, setOptions] = useState<ISelectType[]>([]);

  const { t } = useTranslation();

  const { sendRequest } = useFetch(ScheduleInsuranceService.all);

  const fetch = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (data && success) {
      setOptions(
        data.map((i) => ({
          id: i.id || String(i.identifier),
          name: i.insuranceName,
        }))
      );
    }
  }, [sendRequest]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <AutoComplete
      getOptionLabel={(option) => {
        return option.name;
      }}
      size="small"
      label={t("page.schedules.insurance.addInsurance.insurance.label")}
      name={KEYS.insurance}
      options={options}
    />
  );
};

export default memo(SelectInsurance);
