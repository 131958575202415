import { createContext } from "react";
import { Navigate } from "react-router-dom";

import { Permission } from "../models/permission";
import usePermission from "../hooks/usePermission";
import { tokenService } from "../services/token.service";
import { tokenTemporaryService } from "../services/tokenTemorary.service";

export const ALL_APP_DOMAIN = "all";

interface IDomain {
  domainName: string;
}

export const DomainContext = createContext({
  domainName: "",
});

const DomainContextProvider = ({
  children,
  value,
  actions,
  redirect = true,
  redirectUrlNotLogged,
  visitUserCanAccess = false,
  isTemporaryAccessPage = false,
}: {
  children: any;
  value: IDomain;
  actions?: Permission[];
  redirect?: boolean;
  redirectUrlNotLogged?: string;
  visitUserCanAccess?: boolean;
  isTemporaryAccessPage?: boolean;
}) => {
  const hasPermission = usePermission({ domain: value.domainName, actions });

  const isLogged = tokenService.hasAccessTokens();

  const isTemporaryAccess = tokenTemporaryService.hasTemporaryAccessToken();

  if (visitUserCanAccess && !isLogged) {
    return children;
  }

  if (!isLogged) {
    if (isTemporaryAccess && isTemporaryAccessPage) {
      return children;
    }

    if (!isTemporaryAccess) {
      if (!redirectUrlNotLogged) return <Navigate to={`/no-access`} />;
      return <Navigate to={redirectUrlNotLogged} />;
    }
  }

  if (hasPermission) {
    return (
      <DomainContext.Provider value={value}>{children}</DomainContext.Provider>
    );
  }

  if (!hasPermission && redirect) {
    return <Navigate to={`/no-access`} />;
  }

  return <>{!hasPermission && redirect && <Navigate to={`/no-access`} />}</>;
};

export default DomainContextProvider;
