import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Grid } from "@mui/material";

import useFetch from "../hooks/useFetch";
import { IIrpfList } from "../models/irpf";
import IrpfService from "../services/irpf.service";

import IrpfCards from "../components/Irpf/IrpfCards";
import IrpfHeader from "../components/Irpf/IrpfHeader";
import NoData from "../components/UI/NoData";
import useRole from "../hooks/useRole";
import PagesContainer from "../components/UI/PagesContainer";

const translationPath = "page.irpf.";

const listService = new IrpfService();

const IrpfPage = () => {
  const { t } = useTranslation();
  const { sendRequest, loading } = useFetch(listService.getAll);
  const [listPlans, setListPlans] = useState<IIrpfList[]>([]);
  const readPermission = useRole(["Read"]);

  const loadHistory = useCallback(async () => {
    const { data, success } = await sendRequest(undefined);
    if (success) {
      setListPlans(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    if (readPermission) {
      loadHistory();
    }
  }, [loadHistory, readPermission]);

  const listYears: any[] = [];

  const actualYear = dayjs(new Date()).get("year");
  for (let ano = actualYear - 5; ano <= actualYear - 1; ano++) {
    listYears.push(ano);
  }

  const plansSorted = listPlans.sort((a, b) => {
    return a.planName < b.planName ? -1 : a.planName > b.planName ? 1 : 0;
  });

  listYears.sort((a, b) => b - a);

  return (
    <PagesContainer title={t(translationPath + "irpf")} loading={loading}>
      {plansSorted.length === 0 && (
        <Grid container mt={5} justifyContent={"center"}>
          <Grid item xs={10} sm={6}>
            <NoData message={t("page.irpf.no_data")} />
          </Grid>
        </Grid>
      )}

      {plansSorted.map((plan: IIrpfList) => (
        <IrpfHeader planName={plan.planName} key={plan.planName}>
          {listYears.map((year) => (
            <IrpfCards
              key={year}
              year={year}
              payerIntegrationId={plan.payerIntegrationId}
              individualIntegrationId={plan.individualIntegrationId}
            />
          ))}
        </IrpfHeader>
      ))}
    </PagesContainer>
  );
};

export default IrpfPage;
