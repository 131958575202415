import { Stack, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";

import { TabPanel } from "../../components/TabPanel";
import { IFaq } from "../../models/faq";
import FaqService from "../../services/faq.service";
import useFetch from "../../hooks/useFetch";
import QuestionAnswerList from "../../components/Faq/QuestionAnswerList";
import NoData from "../../components/UI/NoData";
import { OverflowTooltip } from "../../components/UI/TooltipOverflow";
import SearchInput from "../../components/UI/Inputs/SearchInput";
import PagesContainer from "../../components/UI/PagesContainer";
import { useAppSelector } from "../../store/store";

const faqService = new FaqService();

const translationPath = "page.faq.";

const FaqPage = () => {
  const { t } = useTranslation();
  const actualLang = useAppSelector((state) => state.language.languageId);

  const [search, setSearch] = useState("");

  const [value, setValue] = useState(0);
  const [faqs, setFaqs] = useState<IFaq[]>([]);

  const { sendRequest } = useFetch(faqService.list);

  const loadFaqs = useCallback(async () => {
    if (actualLang) {
      const { data, success } = await sendRequest(actualLang);
      if (data && success) {
        setFaqs(data);
      }
    }
  }, [sendRequest, actualLang]);

  useEffect(() => {
    loadFaqs();
  }, [loadFaqs, actualLang]);

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleSearch = (text: string) => {
    if (text && text.length >= 3) {
      setSearch(text);
    } else {
      setSearch("");
    }
  };

  const faqsFiltered = useMemo(() => {
    if (search && search.length >= 3) {
      setValue(0);

      const searchedFaqs = faqs.filter(
        (faq) =>
          faq.name.toLowerCase().includes(search.toLowerCase()) ||
          faq.faqQuestions.some((item) =>
            item.question.toLowerCase().includes(search.toLowerCase())
          ) ||
          faq.faqQuestions.some((item) =>
            item.answer.toLowerCase().includes(search.toLowerCase())
          )
      );

      return searchedFaqs.map((item) => ({
        ...item,
        faqQuestions:
          item.faqQuestions.filter(
            (item) =>
              item.question.toLowerCase().includes(search.toLowerCase()) ||
              item.answer.toLowerCase().includes(search.toLowerCase())
          ).length !== 0
            ? item.faqQuestions.filter(
                (item) =>
                  item.question.toLowerCase().includes(search.toLowerCase()) ||
                  item.answer.toLowerCase().includes(search.toLowerCase())
              )
            : item.faqQuestions,
      }));
    }
    return faqs;
  }, [faqs, search]);

  return (
    <PagesContainer
      title={t(translationPath + "faq")}
      headerBottom={
        <Stack width={"100%"}>
          <SearchInput
            label={`${t(translationPath + "search")}`}
            placeholder={`${t(translationPath + "search_placeholder")}`}
            onSearch={handleSearch}
            focus
            sx={{ maxWidth: 300 }}
          />
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
          >
            {faqsFiltered.map((faq) => (
              <Tab
                label={<OverflowTooltip tooltip={faq.name} text={faq.name} />}
                {...a11yProps(+faq.id)}
                key={faq.id}
              />
            ))}
          </Tabs>
        </Stack>
      }
    >
      <Stack>
        {faqsFiltered.length === 0 && <NoData />}

        {faqsFiltered.map((faq, index) => (
          <TabPanel index={index} value={value} key={faq.id}>
            <QuestionAnswerList faq={faq} />
          </TabPanel>
        ))}
      </Stack>
    </PagesContainer>
  );
};

export default FaqPage;
