import { useCallback, useLayoutEffect, useState } from "react";

import { Permission, Permissions } from "../models/permission";
import { useAppSelector } from "../store/store";
import { tokenService } from "../services/token.service";
import { IPrivilege } from "../models/user";

interface IusePermissionProps {
  domain?: string;
  actions?: Permission[];
}

const usePermission = ({ domain, actions }: IusePermissionProps) => {
  const privileges = useAppSelector((state) => state.privilege.privileges);

  //dominios que o usuario tem acesso
  const domains = privileges.map((p) => p.domainName);
  const [hasPermission, setHasPermission] = useState(true);
  const isLogged = tokenService.hasAccessTokens();

  const checkPermission = useCallback(() => {
    if (!domain) {
      setHasPermission(true);
      return;
    }

    if (!isLogged) {
      setHasPermission(false);
      return;
    }

    //SEM actions
    if (!actions) {
      if (domains.includes(domain)) {
        setHasPermission(true);
        return;
      } else {
        setHasPermission(false);
        return;
      }
    }

    //COM ações
    if (privileges.length) {
      const permissionsToDomain = privileges.filter(
        (p: IPrivilege) => p.domainName === domain
      );

      const permissionsToAccess = permissionsToDomain
        .map((p: IPrivilege) => p.actions)
        .flat();

      const userHasPermission =
        actions?.every((item) => permissionsToAccess.includes(item)) ||
        permissionsToAccess?.includes(Permissions.ALL);

      setHasPermission(!!userHasPermission);
      //
    }
  }, [privileges, domains, isLogged, actions, domain]);

  useLayoutEffect(() => {
    checkPermission();
  }, [checkPermission]);

  return hasPermission;
};

export default usePermission;
