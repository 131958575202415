import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { authService } from "../../../services/auth.service";
import { userService } from "../../../services/user.service";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { TLoginFormData, loginFormSchema } from "./loginSchema";
import FormInput from "../../UI/Inputs/FormInput";
import { tokenService } from "../../../services/token.service";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { permissionSliceActions } from "../../../store/slices/permission";
import Button from "../../UI/Button";
import ModalComponent from "../../UI/Modal";
import ResendEmail from "./ResendEmail";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EmailConfirmedModal from "./EmailConfirmedModal";
import { tokenTemporaryService } from "../../../services/tokenTemorary.service";

const LoginForm = () => {
  const navigate = useNavigate();

  const methods = useForm<TLoginFormData>({
    resolver: zodResolver(loginFormSchema),
  });

  const { sendRequest, loading, error } = useFetch(authService.login);

  const [open, setOpen] = useState(false);

  const [userName, setUserName] = useState<string>("");

  const { t } = useTranslation();

  const theme = useTheme();

  const dispatch = useAppDispatch();

  const isLoginCpf = useAppSelector((state) => state.theme.isLoginCpf);

  const [showPassword, setShowPassword] = useState(false);

  const recentlyConfirmed = sessionStorage.getItem("confirmed");

  const { handleSubmit } = methods;

  useEffect(() => {
    if (error) {
      if (!error["errors"]) return;
      if (error["errors"]["Email"]) {
        navigate(`/email-confirmation/${userName}`);
      }
    }
  }, [error, navigate, userName]);

  const onSubmitHandler = useCallback(
    async (values: TLoginFormData) => {
      const cpf = values.username.replaceAll(".", "").replaceAll("-", "");
      const { data, success } = await sendRequest({
        ...values,
        username: isLoginCpf ? cpf : values.username,
      });
      setUserName(values.username);
      
      if (success) {
        userService.saveUserName(values.username);
        tokenService.saveTokens(data.access_token, data.refresh_token);
        localStorage.removeItem("privacy_policy_term");
        dispatch(
          permissionSliceActions.savePermission({ hasPermission: true })
        );
        tokenTemporaryService.removeTemporaryAccessName();
        tokenTemporaryService.removeTemporaryAccessToken();
        navigate("/");
      }
    },
    [sendRequest, navigate, dispatch, isLoginCpf]
  );

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => (prevState = !prevState));
  };
  const onCloseHandler = () => {
    if (recentlyConfirmed) {
      sessionStorage.removeItem("confirmed");
    }
    setOpen(false);
  };

  useEffect(() => {
    const recentlyConfirmed = sessionStorage.getItem("confirmed");
    if (recentlyConfirmed) {
      setOpen(true);
    }
  }, []);

  return (
    <>
      <ModalComponent open={open} onClose={onCloseHandler}>
        {recentlyConfirmed && (
          <EmailConfirmedModal onCloseModal={onCloseHandler} />
        )}
        {!recentlyConfirmed && <ResendEmail userName={userName} />}
      </ModalComponent>
      <FormProvider {...methods}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitHandler)}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography variant="h6" align="center" mb={3}>
            {t("page.login.welcome")}
          </Typography>

          {!isLoginCpf && (
            <FormInput
              size="small"
              autoFocus={true}
              name="username"
              fullWidth
              label={t("page.login.username")}
              sx={{ mb: 2 }}
            />
          )}

          {isLoginCpf && (
            <FormInput
              size="small"
              autoFocus={true}
              name="username"
              fullWidth
              label={t("page.login.cpf")}
              mask={"000.000.000-00"}
              sx={{ mb: 2 }}
            />
          )}

          <FormInput
            size="small"
            name="password"
            fullWidth
            type={!showPassword ? "password" : "text"}
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={togglePasswordVisibility}
                >
                  {!showPassword && <VisibilityIcon color="action" />}
                  {showPassword && <VisibilityOffIcon color="action" />}
                </IconButton>
              ),
            }}
            label={t("page.login.password")}
            sx={{ mb: 2 }}
          />
          <Stack
            justifyContent={"space-between"}
            direction={"row"}
            mb={2}
            alignItems={"center"}
          >
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label={
                <Typography variant="body2">
                  {" "}
                  {t("page.login.rememberme")}
                </Typography>
              }
            />
            <Link
              type="button"
              to="/forget-password"
              style={{
                textDecoration: "none",
                color: theme.palette.primary.main,
              }}
            >
              <Typography variant="body2">
                {t("page.login.forget_password")}
              </Typography>
            </Link>
          </Stack>
          <Button loading={loading} variant="contained" type="submit">
            {t("page.login.login")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate("/register")}
            sx={{ mt: 1, fontSize: 13 }}
          >
            {t("page.login.dont_account")}
          </Button>
          <Link
            type="button"
            to="/email-confirmation"
            style={{
              marginTop: 10,
              marginBottom: 0,
              textAlign: "center",
              textDecoration: "none",
              color: theme.palette.primary.main,
            }}
          >
            <Typography variant="body2">
              {t("page.login.confirm_email")}
            </Typography>
          </Link>
        </Box>
      </FormProvider>
    </>
  );
};

export default LoginForm;
