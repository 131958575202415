import GlobalAxios from "../axios/axios-config";
import { IHasActiveRule } from "../models/automation";

const BASIC_URL = process.env.REACT_APP_APP + "/api/v1/Automation/";

export const hasActiveRule = (automationCode: string) => {
  return GlobalAxios.get<IHasActiveRule>(
    BASIC_URL + `HasActiveRule/${automationCode}`
  );
};
