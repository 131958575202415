import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
} from "@mui/material";

interface ISelectInputProps {
  label: string;
  onChangeHandler: (e: SelectChangeEvent) => void;
  value: number;
  initialYear: number;
  lastYear: number;
  sx?: SxProps<Theme>;
}

const SelectYears = ({
  label,
  onChangeHandler,
  value,
  initialYear,
  sx,
  lastYear,
}: ISelectInputProps) => {
  const listYears = [];

  for (let ano = initialYear; ano <= lastYear; ano++) {
    listYears.push(ano);
  }

  return (
    <FormControl fullWidth sx={sx}>
      <InputLabel id="select-input">{label}</InputLabel>
      <Select
        id="select-input"
        label={label}
        onChange={onChangeHandler}
        value={value.toString()}
        size="small"
        fullWidth
      >
        {listYears
          .sort((a, b) => b - a)
          .map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectYears;
