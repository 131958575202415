import { useCallback, useLayoutEffect, useState } from "react";
import useFetch from "../hooks/useFetch";
import { getInstalledExtensions } from "../services/installedExtensions.service";
import { useAppDispatch } from "../store/store";
import { installedExtensionsSliceActions } from "../store/slices/installedExtensions";
import { IInstalledExtension } from "../models/intalled-extensions";

const InstalledExtensionContainer = ({ children }: { children: any }) => {
  const dispatch = useAppDispatch();
  const [extensions, setExtensions] = useState<IInstalledExtension[]>();

  const { sendRequest: getAllInstalledExtensions } = useFetch(
    getInstalledExtensions
  );

  const loadInstalledExtensionsHandler = useCallback(async () => {
    const { data, success } = await getAllInstalledExtensions(undefined);
    if (data && success) {
      setExtensions(data);
      dispatch(installedExtensionsSliceActions.saveInstalledExtension(data));
    }
  }, [getAllInstalledExtensions, dispatch]);

  useLayoutEffect(() => {
    loadInstalledExtensionsHandler();
  }, [loadInstalledExtensionsHandler]);

  if (extensions) {
    return children;
  }
  return null;
};

export default InstalledExtensionContainer;
