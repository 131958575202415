import { useTranslation } from "react-i18next";
import AuthCardContainer from "../components/Auth/AuthCardContainer/AuthCardContainer";
import useFetch from "../hooks/useFetch";
import { authService } from "../services/auth.service";
import { useCallback, useState } from "react";
import { Stack, Typography } from "@mui/material";

import Button from "../components/UI/Button";
import EmailConfirmForm from "../components/Auth/EmailConfirmForm/EmailConfirmForm";

const ConfirmEmailPage: React.FC = () => {
  const [error, setError] = useState(false);

  const token = new URL(window.location.href).search.substring(1);
  const params = atob(token);
  const userName = new URLSearchParams(params).get("userName");

  const { loading: resendLoading, sendRequest: resend } = useFetch(
    authService.resendEmailConfirmation
  );

  const { t } = useTranslation();

  const resendEmail = useCallback(async () => {
    if (userName) {
      const { success } = await resend({
        userName: userName,
        softwareCode: "3",
      });
      if (success) {
        setError(false);
      }
    }
  }, [resend, userName]);

  const onError = () => setError(true);

  let content = <EmailConfirmForm onError={onError} />;

  if (error) {
    content = (
      <Stack alignItems={"center"} spacing={1}>
        <Typography
          variant="h6"
          sx={(theme) => ({ color: theme.palette.error.main })}
        >
          {t("page.confirm_email.error.title")}
        </Typography>
        <Typography>{t("page.confirm_email.error.description")}</Typography>

        <Button
          onClick={resendEmail}
          loading={resendLoading}
          variant="contained"
        >
          {t("page.confirm_email.error.action")}
        </Button>
      </Stack>
    );
  }

  return (
    <AuthCardContainer
      link="/login"
      textLink={
        t("components.forgetPasswordForm.back_login") || "Back to login"
      }
    >
      {content}
    </AuthCardContainer>
  );
};

export default ConfirmEmailPage;
