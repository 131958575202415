import { Stack, Typography } from "@mui/material";
import { memo } from "react";

import { IHealthProgram } from "../../../../models/health-programs";
import HealthProgramHeaderButton from "./HealthProgramHeaderButton";

interface IHealthProgramItemHeaderProps {
  program: IHealthProgram;
}

const HealthProgramItemHeader: React.FC<IHealthProgramItemHeaderProps> = ({
  program,
}) => {
  return (
    <Stack flexDirection={"row"} alignItems={"center"}>
      <Typography
        fontWeight={"bold"}
        width={"100%"}
        textOverflow={"clip"}
        overflow={"hidden"}
        flexGrow={1}
        variant="h5"
      >
        {program.name}
      </Typography>
      <HealthProgramHeaderButton data={program} />
    </Stack>
  );
};

export default memo(HealthProgramItemHeader);
