import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { memo } from "react";
import { useFormStepperContext } from "../../../../store/contexts/FormStepperContext";
import { FormStepContainer } from "./FormStepContainer/FormStepContainer";
import { IFormSteps } from "./models";

export const FormSteps = memo(({ endComponent, steps }: IFormSteps) => {
  const { activeStep } = useFormStepperContext();

  if (activeStep >= steps.length && endComponent) {
    console.log("Oi");
    return <FormStepContainer>{endComponent}</FormStepContainer>;
  }

  return (
    <Box sx={{ margin: "0 auto", mt: 5, width: "80%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step) => {
          const stepProps: { completed?: boolean } = {};
          return (
            <Step key={step.step} {...stepProps}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {steps.map((step) => {
        if (step.step === activeStep) {
          return (
            <FormStepContainer key={step.step}>
              <>{step.component}</>
            </FormStepContainer>
          );
        }
        return null;
      })}
    </Box>
  );
});
