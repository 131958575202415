import { Box, Divider, Typography } from "@mui/material";
import { useNotificationsUser } from "../../store/contexts/NotificationsUserContext";
import UnreadNotificationsActions from "./UnreadNotificationsActions";
import { useTranslation } from "react-i18next";
import NoNotifications from "./NoNotifications";
import UnreadNotificationsList from "./UnreadNotificationsList";

const translationPath = "components.unreadNotifications.";

interface IUnreadNotificationsProps {
  onClose: () => void;
}

const UnreadNotifications = ({ onClose }: IUnreadNotificationsProps) => {
  const { t } = useTranslation();

  const { unreadNotifications } = useNotificationsUser();

  return (
    <Box p={1} width={300} maxHeight={350}>
      <Typography pb={0.5}>{t(translationPath + "notifications")}</Typography>
      <Divider />
      {unreadNotifications.length === 0 && <NoNotifications isRecent />}
      <UnreadNotificationsList onClose={onClose} />
      <Divider />
      <UnreadNotificationsActions onClose={onClose} />
    </Box>
  );
};

export default UnreadNotifications;
