import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUser } from "../../models/user";

const USER_INITIAL_STATE: IUser = {
  id: "",
  userName: "",
  email: "",
  fullName: "",
  cpf: "",
  gender: "",
  birthAt: "",
  avatar:
    "https://images.unsplash.com/photo-1548142813-c348350df52b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=689&q=80",
  establishmentStandard: null,
  establishments: [],
  enabel: false,
  establishmentId: "",
  individual: {
    id: "",
    name: "",
    profiles: [],
  },
  roles: [],
};

export const slice = createSlice({
  name: "user",
  initialState: USER_INITIAL_STATE,
  reducers: {
    saveUser(state: IUser, action: PayloadAction<IUser>) {
      return { ...state, ...action.payload };
    },
    removeUser(state) {
      return (state = USER_INITIAL_STATE);
    },
  },
});

export const userSliceActions = slice.actions;

export default slice.reducer;
