import { Stack } from "@mui/material";
import { IFaq } from "../../models/faq";
import QuestionAnswerAccordion from "./QuestionAnswerAccordion";
import NoData from "../UI/NoData";
import { useTranslation } from "react-i18next";

const translationPath = "page.faq.";

const QuestionAnswerList = ({ faq }: { faq: IFaq }) => {
  const { t } = useTranslation();

  return (
    <Stack pt={1} width={"100%"} height={"100%"}>
      <Stack
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Stack
          mt={1}
          height={"100%"}
          width={"100%"}
          flexDirection={"column"}
          gap={1}
        >
          {faq.faqQuestions.length === 0 && (
            <Stack mt={2}>
              <NoData
                message={`${t(translationPath + "no_questions_registered")}`}
              />
            </Stack>
          )}
          {faq.faqQuestions.length !== 0 &&
            faq.faqQuestions.map((faq) => (
              <QuestionAnswerAccordion qa={faq} key={faq.id} />
            ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default QuestionAnswerList;
