import { Box, Button, Tab, Tabs } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import Schedules from "./Schedules";
import { TabPanel } from "../../../components/TabPanel";
import { defaultScrollTheme } from "../../../utils/utils";
import PagesContainer from "../../../components/UI/PagesContainer";

const a11yProps = (index: number) => {
  return {
    id: `schedule-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const MySchedules = () => {
  const [index, setIndex] = useState(0);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setIndex(newValue);
    },
    []
  );

  const onClickHandler = () => {
    navigate("/schedule/newSchedule");
  };

  return (
    <PagesContainer
      title={t("page.schedules.mySchedules.title")}
      subtitle={t("page.schedules.mySchedules.subtitle")!}
      rowActions={
        <Button variant="contained" onClick={onClickHandler}>
          {t("page.schedules.mySchedules.add")}
        </Button>
      }
    >
      <Tabs value={index} onChange={handleChange}>
        <Tab label={t("page.schedules.mySchedules.next")} {...a11yProps(0)} />
        <Tab
          label={t("page.schedules.mySchedules.historic")}
          {...a11yProps(1)}
        />
      </Tabs>
      <Box
        height={"calc(100% - 48px)"}
        overflow={"auto"}
        sx={defaultScrollTheme}
      >
        <TabPanel index={0} value={index} sx={{ padding: 0, paddingRight: 1 }}>
          <Schedules />
        </TabPanel>
        <TabPanel index={1} value={index} sx={{ padding: 0, paddingRight: 1 }}>
          <Schedules historic />
        </TabPanel>
      </Box>
    </PagesContainer>
  );
};

export default MySchedules;
