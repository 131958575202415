import { Box, IconButton, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "../../UI/Inputs/FormInput";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { TRegisterForm, registerSchema } from "./registerSchema";
import { useCallback, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import DateInput from "../../UI/Inputs/DateInput";
import { useNavigate } from "react-router-dom";
import { authService } from "../../../services/auth.service";
import Button from "../../UI/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RegisterError, { TRegisterError } from "./RegisterError";
import ModalComponent from "../../UI/Modal";

const translationPath = "page.register.";

const RegisterForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const methods = useForm<TRegisterForm>({
    resolver: zodResolver(registerSchema),
  });
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorType, setErrorType] = useState<TRegisterError[]>();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { sendRequest: save, loading, error } = useFetch(authService.register);

  const { handleSubmit } = methods;

  const onSubmitHandler = useCallback(
    async (values: TRegisterForm) => {
      const date = new Date(values.birthDate);
      date.setUTCHours(0, 0, 0, 0);

      const { success } = await save({
        ...values,
        birthDate: date,
      });

      if (success) {
        sessionStorage.setItem("registered", "true");
        navigate(`/email-confirmation/${values.document}`);
      }
    },
    [save, navigate]
  );

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => (prevState = !prevState));
  };

  useEffect(() => {
    if (error) {
      if (error["errors"]) {
        if (error["errors"]["Document"]) {
          methods.setValue("document", "");
          methods.setError("document", {
            type: "required",
            message: error["errors"]["Document"],
          });
          setErrorType(["beneficiary"]);
          setErrorMessage(error["errors"]["Document"]);
          setOpen(true);
        }
        if (error["errors"]["Email"]) {
          methods.setValue("email", "");
          methods.setError("email", {
            type: "required",
            message: error["errors"]["Email"],
          });
          setErrorType(["email"]);
          setErrorMessage(error["errors"]["Email"]);
          setOpen(true);
        }
        if (error["errors"]["BirthDate"]) {
          methods.resetField("birthDate");
          methods.setError("birthDate", {
            type: "required",
            message: error["errors"]["BirthDate"],
          });
          setErrorType(["birthDate"]);
          setErrorMessage(error["errors"]["BirthDate"]);
          setOpen(true);
        }
        if (error["errors"]["BirthDate"] && error["errors"]["Email"]) {
          methods.resetField("birthDate");
          methods.setError("birthDate", {
            type: "required",
            message: error["errors"]["BirthDate"],
          });
          methods.setValue("email", "");
          methods.setError("email", {
            type: "required",
            message: error["errors"]["Email"],
          });
          setOpen(true);
          setErrorType(["birthDate", "email"]);
          setErrorMessage(
            `${error["errors"]["BirthDate"] + " " + error["errors"]["Email"]}`
          );
        }
        if (error["errors"]["Password"]) {
          methods.resetField("password");
          methods.resetField("confirmPassword");
          methods.setError("password", {
            type: "required",
            message: error["errors"]["Password"],
          });
          setOpen(true);
          setErrorType(["password"]);
          setErrorMessage(error["errors"]["Password"]);
        }
      }
    }
  }, [error, methods, t]);

  const closeModalHandler = () => {
    setOpen(false);
  };

  return (
    <FormProvider {...methods}>
      <ModalComponent open={open} onClose={closeModalHandler} dialogMaxWidth={"md"}>
        {errorType && (
          <RegisterError
            errorType={errorType}
            errorMessage={errorMessage}
            onCloseModal={closeModalHandler}
          />
        )}
      </ModalComponent>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitHandler)}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography variant="h6" align="center" mb={3}>
          {t(translationPath + "register")}
        </Typography>
        <FormInput
          size="small"
          autoFocus
          name="document"
          fullWidth
          label={t(translationPath + "document")}
          sx={{ mb: 2 }}
          mask="000.000.000-00"
          unmask
        />
        <DateInput
          hasDefaultDate={false}
          label={t(translationPath + "birth_date")}
          name="birthDate"
          sx={{ mb: 2 }}
        />
        <FormInput
          size="small"
          name="email"
          fullWidth
          label={t(translationPath + "email")}
          sx={{ mb: 2 }}
        />
        <FormInput
          size="small"
          name="password"
          fullWidth
          type={!showPassword ? "password" : "text"}
          InputProps={{
            endAdornment: (
              <IconButton
                sx={{ padding: 0 }}
                onClick={togglePasswordVisibility}
              >
                {!showPassword && <VisibilityIcon color="action" />}
                {showPassword && <VisibilityOffIcon color="action" />}
              </IconButton>
            ),
          }}
          label={t(translationPath + "password")}
          sx={{ mb: 2 }}
        />
        <FormInput
          size="small"
          name="confirmPassword"
          fullWidth
          type={!showPassword ? "password" : "text"}
          label={t(translationPath + "confirm_password")}
          sx={{ mb: 2 }}
        />
        <Button loading={loading} variant="contained" type="submit">
          {t(translationPath + "register")}
        </Button>
      </Box>
    </FormProvider>
  );
};

export default RegisterForm;
