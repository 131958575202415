import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import FolderOff from "@mui/icons-material/FolderOff";
import { useTranslation } from "react-i18next";
import useRole from "../../../hooks/useRole";
import { tokenTemporaryService } from "../../../services/tokenTemorary.service";

const translationPath = "components.noRowsMessage.";

export const NoRowsMessage = ({ inCenter = true }: { inCenter?: boolean }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>();
  const readPermission = useRole(["Read"]);

  const isTemporaryAccess = tokenTemporaryService.hasTemporaryAccessToken();

  useEffect(() => {
    if (!readPermission && !isTemporaryAccess) {
      setMessage(`${t(translationPath + "no_permission")}`);
    } else {
      setMessage(`${t(translationPath + "no_data")}`);
    }
  }, [readPermission, isTemporaryAccess, t]);

  return (
    <Stack
      alignItems={"center"}
      justifyContent={inCenter ? "center" : "start"}
      height={"100%"}
      mt={inCenter ? 0 : 5}
    >
      {!readPermission && !isTemporaryAccess && (
        <DoDisturbIcon
          sx={(theme) => ({
            fontSize: 40,
            mb: 1,
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[600]
                : theme.palette.grey[400],
          })}
        />
      )}
      {(readPermission || isTemporaryAccess) && (
        <FolderOff
          sx={(theme) => ({
            fontSize: 40,
            mb: 1,
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[600]
                : theme.palette.grey[400],
          })}
        />
      )}
      <Typography>{message}</Typography>
    </Stack>
  );
};
