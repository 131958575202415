import { Button, CircularProgress } from "@mui/material";
import { IReceivableAccounts } from "../models/history-payment";
import DescriptionIcon from "@mui/icons-material/Description";
import { useTranslation } from "react-i18next";
import useDownload from "../hooks/useDownload";

const DOWNLOAD_URL =
  process.env.REACT_APP_APP + "/api/v1/Beneficiary/GetPaymentDetail/";

const translationPath = "page.historyPayment.";

const DetailDownloadButton = ({ ticket }: { ticket: IReceivableAccounts }) => {
  const { t } = useTranslation();
  const { downloadFile, loading } = useDownload();

  const onDownloadHandler = () => {
    downloadFile(
      DOWNLOAD_URL,
      ticket.numberAccountIntegrationId,
      ticket.numberAccountIntegrationId
    );
  };

  return (
    <Button
      variant="text"
      onClick={onDownloadHandler}
      startIcon={loading ? <CircularProgress size={20} /> : <DescriptionIcon />}
      disabled={loading}
    >
      {t(translationPath + "detail")}
    </Button>
  );
};

export default DetailDownloadButton;
