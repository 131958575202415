import { z } from "zod";

const translationPath = "page.loginTemporary.errors.";

export const loginTemporarySchema = z.object({
  login: z.string().nonempty(translationPath + "login"),
  password: z.string().min(1, translationPath + "password"),
});

export type TLoginTemporaryForm = z.infer<typeof loginTemporarySchema>;
