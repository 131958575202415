import { IconButton } from "@mui/material";
import { memo } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
interface IEditInsuranceProps {
  id: string;
}

const EditInsurance: React.FC<IEditInsuranceProps> = ({ id }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("edit/" + id);
  };

  return (
    <IconButton onClick={handleClick} size="small">
      <EditIcon fontSize="small"/>
    </IconButton>
  );
};

export default memo(EditInsurance);
