import { ILoginTemporaryUser } from "../models/user";
import { jsonToFormData } from "../utils/utils";
import i18n from "../i18n";
import { authApi } from "./auth.service";
import {
  ILoginTemporaryParams,
  ILoginTemporary,
} from "../models/authorizations";

const BASE_URL = process.env.REACT_APP_IDENTIY;

const loginTemporary = (user: ILoginTemporaryUser) => {
  const request: ILoginTemporaryParams = {
    Password: user.Password,
    Login: user.Login,
    LogintType: user.LoginType,
  };

  return authApi.post<ILoginTemporary>(
    BASE_URL + "/api/v1/Login/TokenLogin",
    jsonToFormData(request)
  );
};

authApi.interceptors.request.use((config) => {
  config.headers["Accept-Language"] = i18n.language;

  return config;
});

export const authTemporaryService = {
  loginTemporary,
};
