import { Loading, useFetch } from "@4uhub/lib4uhub";
import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getNonLaboratorialExamsPdfByIdList,
  getNonLaboratorialExamsPdfByIdListTasy,
} from "../../../../services/nonLaboratorialExams.service";
import { tokenTemporaryService } from "../../../../services/tokenTemorary.service";
import NoData from "../../../UI/NoData";
import { INonLaboratorialExamsPdfResponse } from "../models";
import { FileItem } from "./FileItem/FileItem";
import { FileView } from "./FileView/FileView";
import { IReportsModalProps } from "./models";

const translationPath = "components.reportsModal.";

export const ReportsModal = memo(({ identifier }: IReportsModalProps) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [origins, setOrigins] = useState<INonLaboratorialExamsPdfResponse[]>(
    []
  );

  const [selectedFile, setSelectedFile] = useState<string | null>(null);

  const [selectedFileIndex, setSelectedFileIndex] = useState<number>(1);

  const [fileLoading, setFileLoading] = useState(false);

  const isTemporaryAccess = !!tokenTemporaryService.getTemporaryAccessToken();

  const { sendRequest, loading } = useFetch(
    isTemporaryAccess
      ? getNonLaboratorialExamsPdfByIdListTasy
      : getNonLaboratorialExamsPdfByIdList
  );

  const fetchOrigin = useCallback(async () => {
    const { data, success } = await sendRequest(identifier);
    if (data && success) {
      setOrigins(data);
      setSelectedFileIndex(0);
    }
  }, [identifier, sendRequest]);

  useEffect(() => {
    fetchOrigin();
  }, [fetchOrigin]);

  if (loading) {
    return (
      <Box
        sx={{
          height: matches ? "calc(100vh - 190px)" : "calc(100vh - 150px)",
        }}
      >
        <Loading />
      </Box>
    );
  }

  if (origins.length === 0 && !loading) {
    return (
      <Box
        sx={{
          height: matches ? "calc(100vh - 190px)" : "calc(100vh - 150px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NoData />
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Box
        sx={(t) => ({
          width: "25%",
          display: "flex",
          flexDirection: "column",
          gap: 0.5,
          borderRight: 1,
          borderRightColor: t.palette.divider,
        })}
      >
        {origins.map((origin, index) => (
          <FileItem
            index={index}
            selectedFile={selectedFile}
            origin={origin}
            identifier={identifier}
            isActive={selectedFileIndex === index}
            onSelectFile={setSelectedFile}
            onFileLoading={setFileLoading}
            onGetFileIndex={setSelectedFileIndex}
            key={origin.sequencialControl + index}
          />
        ))}
      </Box>
      {fileLoading && (
        <Box
          sx={{
            height: matches ? "calc(100vh - 190px)" : "calc(100vh - 150px)",
            width: "75%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <CircularProgress size={60} />
          <Typography>{t(`${translationPath}loading`)}</Typography>
        </Box>
      )}
      {selectedFile && !fileLoading && <FileView file={selectedFile} />}
      {!selectedFile && !fileLoading && (
        <Box
          sx={{
            height: matches ? "calc(100vh - 190px)" : "calc(100vh - 150px)",
            width: "75%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <NoData message={t(`${translationPath}not_found`)} />
        </Box>
      )}
    </Box>
  );
});
