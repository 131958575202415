import {
  Dialog,
  IconButton,
  Breakpoint,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface IModal {
  onClose: () => any;
  open: boolean;
  children: any;
  dialogMaxWidth?: false | Breakpoint | undefined;
}

const ModalComponent: React.FC<IModal> = ({
  onClose,
  open,
  children,
  dialogMaxWidth,
}) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth={dialogMaxWidth}
    >
      <IconButton
        size="small"
        onClick={onClose}
        sx={{ alignSelf: "flex-end", padding: 1 }}
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default ModalComponent;
