import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import { Carousel } from "react-responsive-carousel";
import { motion } from "framer-motion";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useAppSelector } from "../../store/store";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const Carousel2: FC<{
  slides: any[];
  onChange: (index: number) => void;
  selected: number;
  viewType?: "mobile" | "browser";
}> = ({ slides, onChange, selected, viewType = "mobile" }) => {
  const theme = useTheme();

  const displayCount = (): number => {
    if (slides.length >= 3) {
      return 3;
    } else return slides.length;
  };

  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { frontBackgroundColor, frontSecondBackgroundColor } = useAppSelector(
    (theme) => theme.theme
  );

  return (
    <Box
      maxWidth={"80%"}
      minWidth={"530px"}
      sx={{
        transform:
          viewType === "mobile"
            ? "rotate(90deg) scale(1.5)"
            : viewType === "browser"
            ? "rotate(0) scale(1.5)"
            : "rotate(0)",
      }}
    >
      <Carousel
        width={"100%"}
        centerMode={true}
        autoFocus
        selectedItem={selected}
        centerSlidePercentage={!matches ? 200 / displayCount() : undefined}
        showArrows={true}
        onClickItem={(i) => {
          onChange(i);
        }}
        showIndicators={true}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          return (
            <span
              style={{
                margin: 2,
                color: theme.palette.getContrastText(frontBackgroundColor),
                opacity: isSelected ? 1 : 0.5,
                cursor: "pointer",
              }}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              key={index}
              role="button"
              tabIndex={0}
              aria-label={`${label} ${index + 1}`}
            >
              <FiberManualRecordIcon sx={{ fontSize: 10 }} />
            </span>
          );
        }}
        axis={matches ? "vertical" : "horizontal"}
        onChange={(i) => {
          onChange(i);
        }}
        showStatus={false}
        preventMovementUntilSwipeScrollTolerance
        emulateTouch
        showThumbs={false}
        renderArrowPrev={(clickHandler, hasPrev) => {
          return (
            hasPrev && (
              <IconButton
                sx={{
                  position: "absolute",
                  zIndex: 10,
                  top: matches ? "40%" : "44%",
                  left: !matches ? "16%" : "14%",
                }}
                onClick={clickHandler}
              >
                <ChevronLeftIcon
                  sx={(theme) => ({
                    color: theme.palette.getContrastText(frontBackgroundColor),
                  })}
                />
              </IconButton>
            )
          );
        }}
        renderArrowNext={(clickHandler, hasNext) => {
          return (
            hasNext && (
              <IconButton
                sx={{
                  position: "absolute",
                  zIndex: 10,
                  top: matches ? "40%" : "44%",
                  left: !matches ? "77%" : "78%",
                }}
                onClick={clickHandler}
              >
                <ChevronRightIcon
                  sx={(theme) => ({
                    color: theme.palette.getContrastText(
                      frontSecondBackgroundColor || frontBackgroundColor
                    ),
                  })}
                />
              </IconButton>
            )
          );
        }}
        renderItem={(item, props) => (
          <motion.div
            animate={{
              opacity: !props?.isSelected ? 0.7 : 1,
              transform: !props?.isSelected ? "scale(0.8)" : "scale(1)",
            }}
            transition={{ type: "tween", duration: 0.4, ease: "easeOut" }}
          >
            {item}
          </motion.div>
        )}
      >
        {slides.map((s) => (
          <div key={s}>{s}</div>
        ))}
      </Carousel>
    </Box>
  );
};

export default Carousel2;
