import { Button, Grid, Stack, Typography } from "@mui/material";
import { userService } from "../../../../services/user.service";
import { tokenService } from "../../../../services/token.service";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { userSliceActions } from "../../../../store/slices/user";
import { privilegeSliceActions } from "../../../../store/slices/privilege";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { permissionSliceActions } from "../../../../store/slices/permission";

interface IMenuDrawerLogoutProps {
  toggleMenu: (open: boolean) => void;
}

const MenuDrawerLogout = ({ toggleMenu }: IMenuDrawerLogoutProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { removeUserName } = userService;
  const { removeAllTokens } = tokenService;

  const logout = useCallback(() => {
    removeAllTokens();
    removeUserName();
    dispatch(userSliceActions.removeUser());
    dispatch(privilegeSliceActions.removePrivilege());
    dispatch(permissionSliceActions.removePermission());
    navigate("/", { replace: true });
    toggleMenu(false);
  }, [navigate, dispatch, removeAllTokens, removeUserName, toggleMenu]);

  return (
    <Grid container width={"100%"} display={"flex"} justifyContent={"center"}>
      <Stack direction={"row"} spacing={2} mb={2} position={"fixed"} bottom={0}>
        <Button onClick={logout}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            p={2}
            sx={(theme) => ({
              backgroundColor:
                theme.palette.mode === "light"
                  ? theme.palette.grey[300]
                  : theme.palette.grey[700],
              color:
                theme.palette.mode === "light"
                  ? theme.palette.grey[800]
                  : theme.palette.grey[300],
              height: 50,
              borderRadius: 2,
              width: "100%",
              textTransform: "capitalize",
            })}
          >
            <Stack
              direction={"row"}
              width={150}
              gap={2}
              justifyContent={"center"}
            >
              <Typography>{t("components.menu.logout")}</Typography>
            </Stack>
          </Stack>
        </Button>
      </Stack>
    </Grid>
  );
};

export default MenuDrawerLogout;
