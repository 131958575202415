import { Grid } from "@mui/material";
import { ACBA, ADICQ, AIQG, AONA, APALC, E, N, P, Q, R } from "../Legends";
import { useTranslation } from "react-i18next";
import LegendsModalItem from "./LegendsModalItem";

const translationPath = "components.legends.";

const LegendsModalList = () => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <LegendsModalItem
          component={ACBA}
          subtitle={t(translationPath + "international")}
        />
        <LegendsModalItem
          component={ADICQ}
          subtitle={t(translationPath + "national")}
        />
        <LegendsModalItem
          component={AIQG}
          subtitle={t(translationPath + "international")}
        />
        <LegendsModalItem
          component={N}
          subtitle={t(translationPath + "events_comunication")}
          fontSize="small"
          marginTop={0.8}
        />
        <LegendsModalItem
          component={Q}
          subtitle={t(translationPath + "monitored_quality")}
          fontSize="small"
          marginTop={0.8}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <LegendsModalItem
          component={AONA}
          subtitle={t(translationPath + "national")}
        />
        <LegendsModalItem
          component={APALC}
          subtitle={t(translationPath + "national")}
        />
        <LegendsModalItem
          component={R}
          subtitle={t(translationPath + "residence_professional")}
          fontSize="small"
          marginTop={0.8}
        />
        <LegendsModalItem
          component={P}
          subtitle={t(translationPath + "specialty_professional")}
          fontSize="small"
          marginTop={0.8}
        />
        <LegendsModalItem
          component={E}
          subtitle={t(translationPath + "specialist_title")}
          fontSize="small"
          marginTop={0.8}
        />
      </Grid>
    </Grid>
  );
};

export default LegendsModalList;
