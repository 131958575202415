import { DataGrid, DataGridProps, GridValidRowModel } from "@mui/x-data-grid";
import { genericMemo } from "../../../utils/utils";

const DEFAULT_PAGE_SIZE_OPTS = [5, 10, 20, 50];

const BaseTable = <R extends GridValidRowModel = any>(
  props: DataGridProps<R> & React.RefAttributes<HTMLDivElement>
) => {
  return (
    <DataGrid
      {...props}
      pageSizeOptions={DEFAULT_PAGE_SIZE_OPTS}
      disableColumnFilter
      disableColumnMenu
      sx={[
        (theme) => ({
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.grey[900]
                : theme.palette.grey[200],
            textTransform: "capitalize",
          },
          marginTop: 2,
          WebkitScrollbar: {
            width: "0.4em",
          },
          WebkitScrollbarTrack: {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            backgroundColor:
              theme.palette.mode === "light"
                ? theme.palette.grey[400]
                : theme.palette.grey[900],
            borderRadius: 5,
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          WebkitScrollbarThumb: {
            backgroundColor:
              theme.palette.mode === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[700],
            borderRadius: 5,
          },
        }),
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
};

export default genericMemo(BaseTable);
