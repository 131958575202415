import GlobalAxios from "../axios/axios-config";
import { IGetWhatsappNumber, IWhatsappNumber } from "../models/whatsapp-number";

const WHATSAPP_NUMBER_ROUTE = process.env.REACT_APP_APP + "/api/v1/Whatsapp";

export const updateWhatsappNumber = (item: IWhatsappNumber) => {
  return GlobalAxios.put(WHATSAPP_NUMBER_ROUTE + "/" + item.individualId, item);
};

export const getWhatsappNumber = (params?: any) => {
  return GlobalAxios.get<IGetWhatsappNumber>(WHATSAPP_NUMBER_ROUTE, {
    params: params,
  });
};
