import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AutoComplete, {
  ISelectType,
} from "../../../../components/UI/Inputs/AutoComplete";
import useFetch from "../../../../hooks/useFetch";
import { FamilyGroupService } from "../familyGroup.service";
import { KEYS } from "./AddFamilyGroup";

const SelectGender = ({ disabled }: { disabled: boolean }) => {
  const [options, setOptions] = useState<ISelectType[]>([]);

  const { t } = useTranslation();

  const { sendRequest } = useFetch(FamilyGroupService.getGenderOpts);

  const fetch = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (data && success) {
      setOptions(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <AutoComplete
      getOptionLabel={(option) => {
        return option.name;
      }}
      size="small"
      label={t("page.schedules.addFamilyGroup.gender.label")}
      name={KEYS.gender}
      disable={disabled}
      disabled={disabled}
      options={options}
    />
  );
};

export default memo(SelectGender);
