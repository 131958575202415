import { Stack } from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Dayjs } from "dayjs";

import { getEstablishmentParam } from "../../services/establishmentParam.service";
import ProcedureType from "../../components/Exams/ProcedureType/ProcedureType";
import { DateRange } from "../../components/UI/DateRange/DateRange";
import useFetch from "../../hooks/useFetch";
import { IFilter } from "./ExamsPage";
import { AddIdField, IIndividualList, QPFQuickRegister } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

interface IFilterProps {
  filter: IFilter;
  isDoctor: boolean;
  onFilterChange: (v: IFilter) => void;
}

const translationPath = "page.exams.";

const Filter: React.FC<IFilterProps> = ({
  filter,
  isDoctor,
  onFilterChange,
}) => {
  const { t } = useTranslation();

  const [showProcedureTypeFilter, setShowProcedureTypeFilter] = useState(false);

  const [individual, setIndividual] =
    useState<AddIdField<IIndividualList> | null>(null);

  const { sendRequest } = useFetch(getEstablishmentParam);

  const fetchEstablishmentParam = useCallback(async () => {
    const { data, success } = await sendRequest("29");
    if (data && success) {
      setShowProcedureTypeFilter(data.length > 0);
    }
  }, [sendRequest]);

  const onChangeProcedureTypeHandler = useCallback(
    (value: string) => {
      onFilterChange({
        ...filter,
        ExamType: value,
      });
    },
    [filter, onFilterChange]
  );

  const onChangeEndDateHandler = useCallback(
    (endDate: Dayjs | null) => {
      onFilterChange({ ...filter, EndDate: endDate });
    },
    [onFilterChange, filter]
  );

  const onStartDateChangeHandler = useCallback(
    (startDate: Dayjs | null) => {
      const { EndDate } = filter;

      if (startDate && EndDate) {
        const year = startDate.year().toString();

        if (startDate.isValid() && year.length === 4 && EndDate.isValid()) {
          const monthsDiff = EndDate.diff(startDate, "month", true);

          const needToAdd = monthsDiff > 12 || monthsDiff < 0;

          onFilterChange({
            ...filter,
            StartDate: startDate,
            EndDate: needToAdd ? startDate?.add(12, "month") : EndDate,
          });
        }
      } else {
        onFilterChange({ ...filter, StartDate: startDate });
      }
    },
    [filter, onFilterChange]
  );

  const onSelectedIndividual = useCallback(
    (value: AddIdField<IIndividualList> | null) => {
      setIndividual(value);
      onFilterChange({
        ...filter,
        IndividualId: value ? value.id : undefined,
      });
    },
    [filter, onFilterChange]
  );

  const maxEndDate = useMemo(
    () => filter.StartDate?.add(12, "month"),
    [filter.StartDate]
  );

  useEffect(() => {
    fetchEstablishmentParam();
  }, [fetchEstablishmentParam]);

  return (
    <Stack
      pt={1}
      flexDirection={"row"}
      alignItems={isDoctor ? "start" : "center"}
      flexWrap={"wrap"}
      gap={1}
    >
      {showProcedureTypeFilter && (
        <ProcedureType
          procedureType={filter.ExamType}
          changeProcedureType={onChangeProcedureTypeHandler}
        />
      )}
      {isDoctor && (
        <Stack sx={{ minWidth: 350 }}>
          <QPFQuickRegister
            label={t(translationPath + "patient")}
            value={individual}
            disableRegister
            onChange={onSelectedIndividual}
          />
        </Stack>
      )}
      <DateRange
        startDateValue={filter.StartDate}
        endDateValue={filter.EndDate}
        maxEndDate={maxEndDate}
        onChangeStartDate={onStartDateChangeHandler}
        onChangeEndDate={onChangeEndDateHandler}
        disableFuture
        clearable={false}
      />
    </Stack>
  );
};

export default memo(Filter);
