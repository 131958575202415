import { Grid } from "@mui/material";
import FormInput from "../../../../components/UI/Inputs/FormInput";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../../services/generic.service";
import AutoComplete, {
  ISelectType,
} from "../../../../components/UI/Inputs/AutoComplete";
import DateInput from "../../../../components/UI/Inputs/DateInput";
import { useFormContext } from "react-hook-form";
import { TRefundForm } from "../RefundSchema";

const translationPath = "page.refundsPage.refund.";

const BANK_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Bank/";
const bankService = new MainApiService<ISelectType>(BANK_ROUTE);

const RefundSecondBankData = ({ isChecked }: { isChecked: boolean }) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext<TRefundForm>();

  return (
    <>
      <Grid item xs={12} sm={4}>
        <FormInput
          size="small"
          name="holderBankAccountName"
          fullWidth
          label={t(translationPath + "holder_name")}
          disabled={
            !isChecked && getValues("bankData")?.holderBankAccountName
              ? true
              : !isChecked && !getValues("bankData")
              ? true
              : false
          }
          sx={{ mb: 2 }}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormInput
          size="small"
          name="holderDocument"
          fullWidth
          label={t(translationPath + "holder_document")}
          disabled={
            !isChecked && getValues("bankData")?.holderDocument
              ? true
              : !isChecked && !getValues("bankData")
              ? true
              : false
          }
          mask={"000.000.000-00"}
          sx={{ mb: 2 }}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <DateInput
          name="holderBirthDate"
          label={t(translationPath + "holder_birth_date")}
          disabled={
            !isChecked && getValues("bankData")?.holderBirthDate
              ? true
              : !isChecked && !getValues("bankData")
              ? true
              : false
          }
          hasDefaultDate={false}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <AutoComplete
          getOptionLabel={(option) => {
            return option.name;
          }}
          size="small"
          label={t(translationPath + "bank")}
          disable={
            !isChecked && getValues("bankData")?.bank
              ? true
              : !isChecked && !getValues("bankData")
              ? true
              : false
          }
          name="bank"
          request={bankService.list}
        />
      </Grid>

      <Grid item xs={12} sm={2}>
        <FormInput
          size="small"
          name="bankBranch"
          type="number"
          fullWidth
          label={t(translationPath + "bank_branch")}
          disabled={
            !isChecked && getValues("bankData")?.bankBranch
              ? true
              : !isChecked && !getValues("bankData")
              ? true
              : false
          }
          sx={{ mb: 2 }}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormInput
          size="small"
          name="bankAccount"
          type="number"
          fullWidth
          label={t(translationPath + "bank_account")}
          disabled={
            !isChecked && getValues("bankData")?.bankAccount
              ? true
              : !isChecked && !getValues("bankData")
              ? true
              : false
          }
          sx={{ mb: 2 }}
        />
      </Grid>

      <Grid item xs={12} sm={2}>
        <FormInput
          size="small"
          name="bankAccountDigit"
          type="number"
          fullWidth
          label={t(translationPath + "bank_account_digit")}
          disabled={
            !isChecked && getValues("bankData")?.bankAccountDigit
              ? true
              : !isChecked && !getValues("bankData")
              ? true
              : false
          }
        />
      </Grid>
    </>
  );
};

export default RefundSecondBankData;
