import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ContentPage from "../../components/Content/ContentPage";
import Loading from "../../components/Loading";
import NoData from "../../components/UI/NoData";
import PagesContainer from "../../components/UI/PagesContainer";
import useFetch from "../../hooks/useFetch";
import { IContentBase } from "../../models/content";
import { getContentById } from "../../services/healthPrograms.service";

const HealthProgramContentPage: React.FC = () => {
  const { contentId } = useParams();

  const [content, setContent] = useState<IContentBase>();

  const { sendRequest, loading } = useFetch(getContentById);

  const fetch = useCallback(async () => {
    if (!contentId) return;
    const { data, success } = await sendRequest(contentId);

    if (success && data) {
      setContent(data);
    }
  }, [contentId, sendRequest]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (loading) return <Loading />;

  if (!content) return <NoData />;

  return (
    <PagesContainer>
      <ContentPage content={content} />
    </PagesContainer>
  );
};

export default HealthProgramContentPage;
