import { ISelectType } from "@4uhub/lib4uhub";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IStartLanguage {
  languageId: string;
  languages: ISelectType[];
}

const LANGUAGE_INITIAL_STATE: IStartLanguage = {
  languageId: "",
  languages: [],
};

export const slice = createSlice({
  name: "language",
  initialState: LANGUAGE_INITIAL_STATE,
  reducers: {
    saveLanguage(state: IStartLanguage, action: PayloadAction<string>) {
      const languageOption = state.languages.find(
        (l) => l.code === action.payload
      );
      if (languageOption) {
        state.languageId = languageOption.id;
      }
      return state;
    },
    saveLanguageId(state: IStartLanguage, action: PayloadAction<string>) {
      state.languageId = action.payload;
      return state;
    },
    saveLanguageOptions(
      state: IStartLanguage,
      action: PayloadAction<ISelectType[]>
    ) {
      state.languages = action.payload;
      return state;
    },
  },
});

export const languageSliceActions = slice.actions;

export default slice.reducer;
