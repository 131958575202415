import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import ErrorMessage from "./ErrorMessage";

import EmailIcon from "@mui/icons-material/Email";
import EventIcon from "@mui/icons-material/Event";
import PersonIcon from "@mui/icons-material/Person";
import HttpsIcon from "@mui/icons-material/Https";

export type TRegisterError = "email" | "birthDate" | "beneficiary" | "password";

const translationPath = "components.registerError.";

const RegisterError = ({
  errorType,
  errorMessage,
  onCloseModal,
}: {
  errorType: TRegisterError[];
  errorMessage: string;
  onCloseModal: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Stack flexDirection={"column"} alignItems={"center"} gap={2}>
      {errorType[0] === "email" && errorType.length === 1 && (
        <ErrorMessage errorTitle={errorMessage}>
          <EmailIcon
            color="error"
            sx={(t) => ({ fontSize: 48, color: t.palette.error.light })}
          />
        </ErrorMessage>
      )}
      {errorType[0] === "birthDate" && errorType.length === 1 && (
        <ErrorMessage errorTitle={errorMessage}>
          <EventIcon
            color="error"
            sx={(t) => ({ fontSize: 48, color: t.palette.error.light })}
          />
        </ErrorMessage>
      )}
      {errorType.includes("birthDate") && errorType.includes("email") && (
        <ErrorMessage errorTitle={errorMessage}>
          <Stack flexDirection={"row"}>
            <EventIcon
              color="error"
              sx={(t) => ({ fontSize: 48, color: t.palette.error.light })}
            />
            <EmailIcon
              color="error"
              sx={(t) => ({ fontSize: 48, color: t.palette.error.light })}
            />
          </Stack>
        </ErrorMessage>
      )}
      {errorType[0] === "beneficiary" && (
        <ErrorMessage errorTitle={errorMessage}>
          <PersonIcon
            sx={(t) => ({ fontSize: 48, color: t.palette.error.light })}
          />
        </ErrorMessage>
      )}
      {errorType[0] === "password" && (
        <ErrorMessage errorTitle={errorMessage}>
          <HttpsIcon
            sx={(t) => ({ fontSize: 48, color: t.palette.error.light })}
          />
        </ErrorMessage>
      )}
      <Button
        variant="contained"
        onClick={onCloseModal}
        fullWidth
        sx={(t) => ({
          backgroundColor: t.palette.error.light,
          "&:hover": { backgroundColor: t.palette.error.dark },
        })}
      >
        {t(translationPath + "button")}
      </Button>
    </Stack>
  );
};

export default RegisterError;
