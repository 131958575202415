import { Box, Tooltip, Typography } from "@mui/material";
import IconButton from "../../UI/IconButton";
import { useTranslation } from "react-i18next";
import { IPagesControlProps } from "./models";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { memo, useState } from "react";

const translationPath = "components.pagesActions.";

const PagesControl = ({
  pageNumber,
  numPages,
  onNextPage,
  onPreviousPage,
}: IPagesControlProps) => {
  const { t } = useTranslation();

  const [previousTooltip, setPreviousTooltip] = useState(false);

  const [nextTooltip, setNextTooltip] = useState(false);

  return (
    <>
      <Tooltip
        title={t(translationPath + "previous_page")}
        open={previousTooltip}
        onMouseEnter={() => {
          if (pageNumber !== 1) {
            setPreviousTooltip(true);
          }
        }}
        onMouseLeave={() => setPreviousTooltip(false)}
      >
        <Box>
          <IconButton
            size="small"
            onClick={onPreviousPage}
            disabled={pageNumber === 1}
          >
            <ChevronLeftIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
      <Typography>
        {t(translationPath + "pages", {
          pageNumber: pageNumber,
          numPages: numPages,
        })}
      </Typography>
      <Tooltip
        title={t(translationPath + "next_page")}
        open={nextTooltip}
        onMouseEnter={() => {
          if (pageNumber !== numPages) {
            setNextTooltip(true);
          }
        }}
        onMouseLeave={() => setNextTooltip(false)}
      >
        <Box>
          <IconButton
            size="small"
            onClick={onNextPage}
            disabled={pageNumber === numPages}
          >
            <ChevronRightIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    </>
  );
};

export default memo(PagesControl);
