import { Dispatch, SetStateAction, createContext } from "react";

import { EBannerLocale, IPortalBanner } from "../../../models/banners";

interface IBannersContext {
  banners: IPortalBanner[];
  setBanners: Dispatch<SetStateAction<IPortalBanner[]>>;
  closeBanner: (banner: IPortalBanner) => Promise<void>;
  closeAllBanners: (banners: IPortalBanner[]) => Promise<void>;
  haveBanners: (locale: EBannerLocale) => boolean;
}

export const BannersContext = createContext<IBannersContext | undefined>(
  undefined
);
