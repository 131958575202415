import { MuiFileInput } from "mui-file-input";
import { useCallback, useEffect, useState } from "react";
import { Stack, TextFieldPropsSizeOverrides } from "@mui/material";
import InputFileProgress from "./InputFileProgress";
import InputFileStatus from "./InputFileStatus";
import InputFileValidations from "./InputFileValidations";
import { OverridableStringUnion } from "@mui/types";
import { IInputFile } from "../../../../models/input-file";
import useUploadFile, { IUploadFile } from "../../../../hooks/useUploadFile";
import UploadFileService from "../../../../services/uploadFile.service";

interface IInputFileProps {
  value?: IInputFile;
  onChange?: (value?: IInputFile) => void;
  onFileSelected?: (file: IInputFile) => void;
  oldValue?: any;
  label: string;
  accept: string;
  maxSize: number;
  size?: OverridableStringUnion<
    "small" | "medium",
    TextFieldPropsSizeOverrides
  >;
  uploadFileProps: Omit<IUploadFile, "file">;
  name: string;
}

const service = new UploadFileService();

const InputFile: React.FC<IInputFileProps> = ({
  value,
  onChange,
  label,
  accept,
  maxSize,
  uploadFileProps,
  oldValue,
  onFileSelected,
  size = "small",
  name,
}) => {
  const [file, setFile] = useState<File | null>();
  const [fileConverted, setFileConverted] = useState<File | null>();
  const fileType = file?.type.split("/").splice(1).toString();
  const invalidType = !accept.includes(fileType!);
  const sizeInBytes = maxSize * 1000000;
  const isFileSizeAllowed = file && file.size < sizeInBytes;
  const { type } = uploadFileProps;
  const {
    fileId,
    progress,
    uploadFile,
    cancelRequest,
    imagePreview,
    success,
    error,
  } = useUploadFile({
    service: service,
  });

  useEffect(() => {
    if (onChange && file && fileId) {
      onChange({
        id: fileId,
        isPublic: type === "Public",
        fileName: file.name,
        filePath: file.webkitRelativePath,
        cdnDomain: "",
        mimeType: file.type,
        fileSize: file.size,
        imagePreview: imagePreview,
      });
    }
  }, [onChange, file, fileId, type, imagePreview]);

  const onClear = () => {
    setFile(null);
    setFileConverted(null);
    cancelRequest();
    if (onChange) {
      onChange(undefined);
    }
  };

  const onChangeHandler = (file: File | null) => {
    if (onFileSelected && oldValue) {
      onFileSelected(oldValue);
    }
    if (file) {
      setFile(file);
      updloadFileFunction(file);
    }
    if (!file) {
      setFile(null);
      onClear();
    }
  };

  const updloadFileFunction = useCallback(
    (file: File) => {
      const fileType = file.type.split("/").splice(1).toString();
      if (file.size < sizeInBytes && accept.includes(fileType)) {
        uploadFile({ ...uploadFileProps, file });
      }
    },
    [sizeInBytes, uploadFile, uploadFileProps, accept]
  );

  const onUploadHandler = (file: File) => {
    updloadFileFunction(file);
  };

  useEffect(() => {
    if (value?.id.length !== 0) {
      setFileConverted(
        new File([JSON.stringify(value)], value?.fileName || "", {
          type: value?.mimeType,
        })
      );
    }
  }, [value]);

  return (
    <Stack>
      <Stack flexDirection={"row"}>
        <MuiFileInput
          value={fileConverted || file}
          onChange={onChangeHandler}
          label={label}
          size={size}
          fullWidth
          inputProps={{
            accept: accept,
          }}
        />
        <InputFileProgress progress={progress} />
        {!progress && value && (
          <InputFileStatus
            file={file}
            value={value}
            imagePreview={imagePreview || ""}
            success={success}
            error={error}
            onUpload={onUploadHandler}
            name={name}
          />
        )}
      </Stack>
      {file && (
        <InputFileValidations
          maxFileSize={maxSize}
          invalidType={invalidType}
          fileSizeAllowed={isFileSizeAllowed}
        />
      )}
    </Stack>
  );
};

export default InputFile;
