import { Box, useMediaQuery, useTheme } from "@mui/material";
import { IPreViewProps } from "./models";
import RenderPDF from "../../../RenderPDF/RenderPDF";
import Loading from "../../../Loading";
import NoData from "../../../UI/NoData";
import { NoContentContainer } from "./NoContentContainer/NoContentContainer";
import useDownload from "../../../../hooks/useDownload";
import { useCallback } from "react";
import { tokenTemporaryService } from "../../../../services/tokenTemorary.service";

const DOWNLOADFILE_TEMPORARY_ROUTE =
  process.env.REACT_APP_APP + "/api/v1/LaboratorialExams/file/Tasy";
const DOWNLOADFILE_ROUTE =
  process.env.REACT_APP_APP + "/api/v1/LaboratorialExams/file";

const PreView = ({
  file,
  currentImageIdentifier,
  previewLoading,
}: IPreViewProps) => {
  const imageFormats = ["jpg", "jpeg", "png"];

  const { downloadFileParams, loading } = useDownload();

  const isTemporary = tokenTemporaryService.hasTemporaryAccessToken();

  const handleDownloadFile = useCallback(() => {
    if (!file) return;

    const url = isTemporary ? DOWNLOADFILE_TEMPORARY_ROUTE : DOWNLOADFILE_ROUTE;
    downloadFileParams(url, {
      Identifier: file.identifier,
      ImageIdentifier: file.imageIdentifier,
    });
  }, [downloadFileParams, isTemporary, file]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  if (previewLoading) {
    return (
      <NoContentContainer>
        <Loading />
      </NoContentContainer>
    );
  }

  if (!file && !currentImageIdentifier) {
    return (
      <NoContentContainer>
        <NoData message={"Nenhum arquivo selecionado"} />
      </NoContentContainer>
    );
  }

  if (file && currentImageIdentifier) {
    if (!imageFormats.includes(file.format) && file.format !== "pdf") {
      <NoContentContainer>
        <NoData
          message={
            "O arquivo não possui um formato suportado para ser pré-visualizado"
          }
        />
      </NoContentContainer>;
    }
  }

  if (!file) return null;

  return (
    <Box
      sx={{
        height: file.format === "pdf" ? "80vh" : undefined,
        width: matches ? "100%" : undefined,
        justifyContent: "center",
        display: "flex",
      }}
    >
      {file.format === "pdf" && (
        <RenderPDF
          file={`data:application/pdf;base64,${file.content}`}
          downloadButtonProps={{
            loading: loading,
            onDownload: handleDownloadFile,
          }}
        />
      )}
      {imageFormats.includes(file.format) && (
        <img
          src={`data:image/${file.format};base64,${file.content}`}
          alt="preview"
          style={{
            maxWidth: matches ? 250 : 600,
            borderRadius: 10,
          }}
        />
      )}
    </Box>
  );
};

export default PreView;
