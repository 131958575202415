import { Grid, Stack, Typography } from "@mui/material";
import { IHealthcareProfessional } from "../../../../../models/medic-guide";
import { useTranslation } from "react-i18next";

const translationPath = "components.medicGuideFilter.";

const ResultItemProfessional = ({
  healthcareProfessional,
}: {
  healthcareProfessional: IHealthcareProfessional;
}) => {
  const { t } = useTranslation();
  return (
    <Grid item sm={12} mb={1} mt={1}>
      <Stack flexDirection={"column"} flexWrap={"wrap"} columnGap={1}>
        <Typography fontWeight={700}>{healthcareProfessional.name}</Typography>
        <Stack flexDirection={"row"} gap={1}>
          <Typography
            sx={(theme) => ({
              color:
                theme.palette.mode === "light"
                  ? theme.palette.grey[800]
                  : theme.palette.grey[400],
            })}
          >
            {t(translationPath + "provider_type")}:
          </Typography>
          <Typography>
            {healthcareProfessional.professionalType.name} &#40;
            {healthcareProfessional.council.code}{" "}
            {healthcareProfessional.council.councilNumber}&#41;
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default ResultItemProfessional;
