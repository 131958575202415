import { Typography } from "@mui/material";
import { memo } from "react";

import { IContentMediaFile } from "../../../models/content";
import Material from "./Material";
import Card from "../../UI/Card";
import { useTranslation } from "react-i18next";

interface IContentGalleryProps {
  files: IContentMediaFile[];
}

const ComplementaryMaterial: React.FC<IContentGalleryProps> = ({ files }) => {
  const { t } = useTranslation();

  if (files.length === 0) {
    return null;
  }

  return (
    <Card sx={{ mb: 1 }}>
      <Typography variant="h6">
        {t("page.news.complementaryMaterial")}
      </Typography>
      {files.map((file) => (
        <Material file={file} key={file.id} />
      ))}
    </Card>
  );
};

export default memo(ComplementaryMaterial);
