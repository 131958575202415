import GlobalAxios from "../axios/axios-config";
import {
  IAllNotificationsParams,
  IMarkAsRead,
  INotification,
} from "../models/notifications-user";
import { ISearchReturn } from "../models/requests";

const NOTIFICATION_ROUTE =
  process.env.REACT_APP_APP + "/api/v1/NotificationBatchUser/";

export const getUnreadNotifications = (LanguageId: string) => {
  return GlobalAxios.get<INotification[]>(
    NOTIFICATION_ROUTE + "UnreadNotifications",
    { params: { LanguageId } }
  );
};

export const getAllNotifications = (params: IAllNotificationsParams) => {
  return GlobalAxios.get<ISearchReturn<INotification>>(NOTIFICATION_ROUTE, {
    params,
  });
};

export const getNotification = ({
  languageId,
  id,
}: {
  languageId: string;
  id: string;
}) => {
  return GlobalAxios.get<INotification>(NOTIFICATION_ROUTE + id, {
    params: { languageId },
  });
};

export const markNotificationAsRead = (notificationsIds: IMarkAsRead) => {
  return GlobalAxios.put<INotification[]>(
    NOTIFICATION_ROUTE + "UpdateReadStatus",
    notificationsIds
  );
};
