import { useContext } from "react";
import { DomainContext } from "../store/DomainContext";

const useDomain = () => {
  const domain = useContext(DomainContext);

  return domain;
};

export default useDomain;
