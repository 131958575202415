import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IColumnsHeader } from "./models";
import { memo } from "react";

const translationPath = "components.columnsSelect.";

const ColumnsHeader = ({
  columnVisibilityModel,
  handleSelectAll,
}: IColumnsHeader) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={(t) => ({
        px: 2,
        pt: 1,
        width: "100%",
        height: "fit-content",
        position: "sticky",
        top: 0,
        backgroundColor: t.palette.background.default,
        zIndex: 10,
      })}
    >
      <Typography>{t(translationPath + "visible_columns")}</Typography>
      <Typography
        variant="subtitle2"
        sx={(t) => ({
          color:
            t.palette.mode === "light"
              ? t.palette.grey[600]
              : t.palette.grey[400],
        })}
      >
        {t(translationPath + "subtitle")}
      </Typography>
      <FormControlLabel
        label={t(translationPath + "select_all")}
        control={
          <Checkbox
            size="small"
            checked={Object.keys(columnVisibilityModel).every(
              (key) => columnVisibilityModel[key] === true
            )}
            onChange={handleSelectAll}
          />
        }
      />
    </Box>
  );
};

export default memo(ColumnsHeader);
