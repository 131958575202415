import { Stack, Typography } from "@mui/material";
import ModalComponent from "../../../../components/UI/Modal";
import Button from "../../../../components/UI/Button";
import { useTranslation } from "react-i18next";

interface INotAvaiableModalProps {
  open: boolean;
  onClose: () => void;
}

const NotAvaiableModal: React.FC<INotAvaiableModalProps> = ({
  onClose,
  open,
}) => {
  const { t } = useTranslation();

  return (
    <ModalComponent onClose={onClose} open={open}>
      <Stack gap={1}>
        <Typography>
          {t("page.schedules.newSchedule.notAvaiableModal.description")}
        </Typography>
        <Button variant="contained" onClick={onClose}>
          {t("page.schedules.newSchedule.notAvaiableModal.button")}
        </Button>
      </Stack>
    </ModalComponent>
  );
};

export default NotAvaiableModal;
