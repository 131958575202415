import React, { memo } from "react";
import Row from "../../../../components/UI/Row";
import { IExamProcedure } from "../../model";

interface IProcedure {
  data: IExamProcedure;
}

export const Procedure: React.FC<IProcedure> = memo(
  ({ data: { procedureName } }) => {
    return (
      <>
        <Row label={"Procedimento"} value={procedureName} />
      </>
    );
  }
);
