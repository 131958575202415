import { useTranslation } from "react-i18next";
import AuthCardContainer from "../../components/Auth/AuthCardContainer/AuthCardContainer";
import ForgetPasswordForm from "../../components/Auth/ForgetPasswordForm/ForgetPasswordForm";

const ForgetPasswordPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <AuthCardContainer
      link="/login"
      textLink={
        t("components.forgetPasswordForm.back_login") || "Back to login"
      }
    >
      <ForgetPasswordForm />
    </AuthCardContainer>
  );
};

export default ForgetPasswordPage;
