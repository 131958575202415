import { Box, Grid, Typography } from "@mui/material";
import { IDocumentAcceptance } from "../../models/document-acceptance";
import { useTranslation } from "react-i18next";

const translationPath = "components.documentAcceptance.";

const DocumentAcceptanceModal: React.FC<IDocumentAcceptance> = ({
  content,
  docAcceptanceId,
  name,
  language,
}) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      display={"flex"}
      flexDirection={"column"}
      width={"100%"}
      minWidth={200}
    >
      <Grid item sm={12} mb={1}>
        <Typography variant="h6">{name}</Typography>
      </Grid>
      {language && (
        <Grid item sm={12} display={"flex"} flexDirection={"column"}>
          <Typography
            variant="subtitle2"
            sx={(theme) => ({
              color:
                theme.palette.mode === "light"
                  ? theme.palette.grey[500]
                  : theme.palette.grey[400],
            })}
          >
            {t(translationPath + "language")}:
          </Typography>
          <Typography variant="subtitle1">{language.name}</Typography>
        </Grid>
      )}
      <Grid item sm={12} xs={12} display={"flex"} flexDirection={"column"}>
        <Typography
          variant="subtitle2"
          sx={(theme) => ({
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[500]
                : theme.palette.grey[400],
          })}
        >
          {t(translationPath + "content")}:
        </Typography>
        <Box
          sx={{ maxWidth: "100%", overflowX: "auto" }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Grid>
    </Grid>
  );
};

export default DocumentAcceptanceModal;
