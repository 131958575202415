import { useTranslation } from "react-i18next";
import { Box, Grid, Stack, Typography } from "@mui/material";
import AutoCompleteVoice from "../MedicGuide/AutoCompleteVoice/AutoCompleteVoice";

const MedicGuide = () => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        width: "100%",
        height: "130px",
      }}
      justifyContent={"center"}
    >
      <Stack direction={"row"} spacing={2} alignItems={"center"}>
        <Grid item>
          <Typography variant="h6" fontWeight={"bold"}>
            {t("components.medicGuide.medic_guide")}
          </Typography>
          <Typography variant="subtitle2">
            {t("components.medicGuide.subtitle")}
          </Typography>
        </Grid>
      </Stack>
      <Stack direction={"row"} mt={2}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <AutoCompleteVoice
            label={t("components.medicGuide.search")}
            SearchAsYouTypeParams={(v) => ({ Search: v })}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default MedicGuide;
