import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ISelectType } from "../../../../UI/Inputs/AutoComplete";

const translationPath = "components.medicGuideFilter.";

const ResultItemPracticeArea = ({
  practiceArea,
}: {
  practiceArea: ISelectType[];
}) => {
  const { t } = useTranslation();

  return (
    <Stack flexDirection={"row"} flexWrap={"wrap"} columnGap={1} mt={1}>
      <Typography
        variant="subtitle2"
        sx={(theme) => ({
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[800]
              : theme.palette.grey[400],
        })}
      >
        {t(translationPath + "practice_area")}:
      </Typography>
      {practiceArea.map((s) => (
        <Typography variant="subtitle2" key={s.id}>
          {s.name}
        </Typography>
      ))}
    </Stack>
  );
};

export default ResultItemPracticeArea;
