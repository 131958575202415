import { memo } from "react";
import IconButton from "../../UI/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Tooltip } from "@mui/material";
import { IDownloadPdfProps } from "./models";
import { useTranslation } from "react-i18next";

const translationPath = "components.pagesActions.";

export const DownloadPdf = memo(
  ({ onDownload, loading }: IDownloadPdfProps) => {
    const { t } = useTranslation();

    return (
      <Tooltip title={t(translationPath + "download_pdf")}>
        <Box>
          <IconButton size="small" loading={loading} onClick={onDownload}>
            <DownloadIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    );
  }
);
