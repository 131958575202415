const TEMPORARY_TOKEN = "temporary_access_token";
const TEMPORARY_NAME = "temporary_access_name";

const getTemporaryAccessToken = (): string | null => {
  return localStorage.getItem(TEMPORARY_TOKEN);
};

const saveTemporaryAccessToken = (temporaryAcess: string) => {
  localStorage.setItem(TEMPORARY_TOKEN, temporaryAcess);
};

const removeTemporaryAccessToken = () => {
  localStorage.removeItem(TEMPORARY_TOKEN);
};

const getTemporaryAccessName = (): string | null => {
  return localStorage.getItem(TEMPORARY_NAME);
};

const saveTemporaryAccessName = (temporaryName: string) => {
  localStorage.setItem(TEMPORARY_NAME, temporaryName);
};

const removeTemporaryAccessName = () => {
  localStorage.removeItem(TEMPORARY_NAME);
};

const hasTemporaryAccessToken = () => !!getTemporaryAccessToken();

export const tokenTemporaryService = {
  getTemporaryAccessToken,
  saveTemporaryAccessToken,
  removeTemporaryAccessToken,
  getTemporaryAccessName,
  saveTemporaryAccessName,
  removeTemporaryAccessName,
  hasTemporaryAccessToken,
};
