import {
  IFormSatisfaction,
  IRequestFormSatisfaction,
  IRequestUpdateFormSatisfaction,
} from "../models/form-satisfaction";
import { authApi } from "./auth.service";

const EVALUATION_FORM_ROUTE =
  process.env.REACT_APP_APP + "/api/v1/EvalMovEncSatisfaction/";

const EVALUATION_FORM_POST_ROUTE =
  process.env.REACT_APP_APP + "/api/v1/EvalMovAnswerEncSatisfaction";

export const evaluationFormQuestions = (id: string) => {
  return authApi.get<IFormSatisfaction>(EVALUATION_FORM_ROUTE + id);
};

export const answerEvaluationFormQuestion = (
  params: IRequestFormSatisfaction
) => {
  return authApi.post(EVALUATION_FORM_POST_ROUTE, params);
};

export const updateAnswerEvaluationFormQuestion = (
  params: IRequestUpdateFormSatisfaction
) => {
  return authApi.put(EVALUATION_FORM_POST_ROUTE + `/${params.id}`, params);
};
