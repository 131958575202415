import { Stack } from "@mui/material";
import { memo } from "react";

import AvatarDropdown from "../AvatarDropdown";
import ThemeSwitch from "../../ThemeSwitch";
import LanguageSwitch from "../LanguageSwitch";
import Notifications from "../../../Notifications/Notifications";


const HeaderRight = () => {
  const token = localStorage.getItem("access_tokens");

  return (
    <Stack direction={"row"} justifyContent={"end"} alignItems={"center"}>
      {token && <Notifications />}

      <ThemeSwitch />

      {!token && <LanguageSwitch />}

      <AvatarDropdown />
    </Stack>
  );
};

export default memo(HeaderRight);
