import { Loading } from "@4uhub/lib4uhub";
import { Box, SxProps, Theme } from "@mui/material";
import { memo } from "react";

import { useAppSelector } from "../../store/store";
import { defaultScrollTheme } from "../../utils/utils";
import PagesHeader, { IPagesHeaderProps } from "./PagesHeader";

interface IPagesContainerProps extends IPagesHeaderProps {
  children: any;
  paddinRight?: string | number;
  height?: string | number;
  sx?: SxProps<Theme>;
  loading?: boolean;
  disablePadding?: boolean;
}

const PagesContainer = ({
  children,
  sx,
  disablePadding = false,
  loading,
  ...headerProps
}: IPagesContainerProps) => {
  const { logoInHeader } = useAppSelector((state) => state.theme);

  return (
    <Box
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      overflow={"auto"}
      sx={[
        defaultScrollTheme,
        !disablePadding &&
          ((t) => ({
            paddingInline: 2,
            [t.breakpoints.up("md")]: {
              paddingInline: logoInHeader ? 15 : 6,
            },
          })),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <PagesHeader {...headerProps} />
      {!loading && children}
      {loading && <Loading />}
    </Box>
  );
};

export default memo(PagesContainer);
