import { createAsyncThunk } from "@reduxjs/toolkit";
import ConfigurationsService from "../../services/configurations.service";
import { RootState } from "../store";
import { IConfigurationState } from "../slices/configurations";

const service = new ConfigurationsService();

export const saveConfig = createAsyncThunk(
  "configurations/saveConfig",
  async (configs: Partial<IConfigurationState>, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    const { configurations } = state;

    let webAppearance = configurations.webAppearance;

    if (configs && configs.webAppearance?.code) {
      webAppearance = configs.webAppearance;
    }

    const newState = {
      language: configs?.language || configurations.language,
      softwareAppearance: webAppearance,
    };

    await service.create({
      parentName: configurations.parentName,
      languageId: newState.language.id,
      softwareAppearanceCode: webAppearance.code,
      mediaFileIds: configurations.mediaFileIds,
      deleteMediaFileIds: configurations.deleteMediaFileIds,
    });

    return newState;
  }
);
