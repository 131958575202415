import { Badge, IconButton, Popover, Tooltip, Typography } from "@mui/material";

import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import { useNotificationsUser } from "../../store/contexts/NotificationsUserContext";
import { useState } from "react";
import UnreadNotifications from "./UnreadNotifications";
import { useTranslation } from "react-i18next";

const translationPath = "components.notificationsButton.";

const NotificationsButton = () => {
  const { t } = useTranslation();

  const { unreadNotifications } = useNotificationsUser();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <UnreadNotifications onClose={handleClose} />
      </Popover>

      <Tooltip title={t(translationPath + "tooltip")} placement="left">
        <IconButton onClick={handleClick} size="small">
          <Badge
            badgeContent={
              unreadNotifications.length !== 0 ? (
                <Typography variant="body2">
                  {unreadNotifications.length <= 9
                    ? unreadNotifications.length
                    : "9+"}
                </Typography>
              ) : null
            }
            color="primary"
          >
            {unreadNotifications.length !== 0 && <NotificationsActiveIcon fontSize="small"/>}
            {unreadNotifications.length === 0 && <NotificationsIcon fontSize="small" />}
          </Badge>
        </IconButton>
      </Tooltip>
    </>
  );
};

export default NotificationsButton;
