import GlobalAxios from "../axios/axios-config";

const CHANGE_PASSWORD_ROUTE =
  process.env.REACT_APP_IDENTIY + "/api/v1/Users/ChangeCurrentUserPassword";

export interface IChangePassword {
  currentPassword: string;
  newPassword: string;
}

export class ChangePasswordService {
  create = (item: IChangePassword) => {
    return GlobalAxios.post(CHANGE_PASSWORD_ROUTE, item);
  };
}

export default ChangePasswordService;
