import GlobalAxios from "../axios/axios-config";
import {
  IBeneficiaries,
  IGetServiceQueue,
  IGetSymptoms,
  IServiceQueue,
} from "../models/topmed";

const BASE_URL = process.env.REACT_APP_APP + "/api/v1/TopMed/";

export class EmergencyCareService {
  getBeneficiaries = () => {
    return GlobalAxios.get<IBeneficiaries>(BASE_URL + "GetBeneficiaryData");
  };

  registerPerson = (beneficiaryId: string) => {
    return GlobalAxios.post(BASE_URL + "RegisterPerson", {
      beneficiaryId: beneficiaryId,
    });
  };

  serviceQueue = (params: IServiceQueue) => {
    return GlobalAxios.post<IGetServiceQueue>(BASE_URL + "ServiceQueue", {
      beneficiaryId: params.beneficiaryId,
      symptoms: params.symptoms,
    });
  };

  getSymptoms = (beneficiaryId: string) => {
    return GlobalAxios.get<IGetSymptoms[]>(
      BASE_URL + "GetBeneficiarySymptons/" + beneficiaryId
    );
  };
}

export default EmergencyCareService;
