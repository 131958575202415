import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useFormContext } from "react-hook-form";
import AutoComplete from "../../../../../components/UI/Inputs/AutoComplete";
import useFetch from "../../../../../hooks/useFetch";
import { ScheduleAppointmentService } from "../../../appointment.service";
import { ISpeciality } from "../../../model";
import { TAppointment } from "../../schema";

const Speciality = () => {
  const [specialitys, setSpecialitys] = useState<ISpeciality[]>([]);

  const { sendRequest } = useFetch(ScheduleAppointmentService.getSpecialitys);

  const { t } = useTranslation();

  const { setValue } = useFormContext<TAppointment>();

  const fetch = useCallback(async () => {
    const { success, data } = await sendRequest(null);

    if (success && data) {
      setSpecialitys(data);
    }
  }, [sendRequest]);

  const valueSelectedHandler = useCallback(() => {
    setValue("search.professional", undefined);
  }, [setValue]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <AutoComplete
      options={specialitys.map((s) => ({ id: s.integrationId, name: s.name }))}
      getOptionLabel={(option) => {
        return option.name;
      }}
      onValueSelected={valueSelectedHandler}
      size="small"
      label={t("page.schedules.newSchedule.search.speciality")}
      name="search.speciality"
      sx={{ mb: 2 }}
    />
  );
};

export default memo(Speciality);
