import GlobalAxios from "../axios/axios-config";
import {
  IGetNews,
  IGetNewsById,
  INews,
  INewsParams,
  IRecentNewsParams,
} from "../models/news";
import { ISearchReturn } from "../models/requests";

const NEWS_URL = process.env.REACT_APP_APP + "/api/v1/Content";

export const getAllNews = (params: INewsParams) => {
  return GlobalAxios.get<ISearchReturn<INews>>(NEWS_URL, { params });
};

export const getRecentNews = (params: IRecentNewsParams) => {
  return GlobalAxios.get<INews[]>(NEWS_URL + "/list", { params });
};

export const getNews = (params: IGetNews) => {
  return GlobalAxios.get<IGetNewsById>(NEWS_URL + "/ById", { params });
};
