import { Controller, useFormContext } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Box, FormHelperText, SxProps, Theme } from "@mui/material";
import { useTranslation } from "react-i18next";

import useDate from "../../../hooks/useDate";
import { Dayjs } from "dayjs";

interface IDateTime {
  name: string;
  label: string;
  hasDefaultDate?: boolean;
  disableFuture?: boolean;
  disabled?: boolean;
  sx?: SxProps<Theme> | undefined;
}

const DateInput = ({
  name,
  label,
  hasDefaultDate = true,
  disabled = false,
  disableFuture,
  sx,
}: IDateTime) => {
  const { control } = useFormContext();

  const { t } = useTranslation();

  const { dayjs } = useDate();

  const adapterLocale = dayjs.locale();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={hasDefaultDate ? new Date() : null}
      render={(field) => {
        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={adapterLocale}
          >
            <Box sx={sx}>
              <DatePicker
                {...field}
                slotProps={{
                  textField: {
                    size: "small",
                    error: !!field.fieldState.error,
                    fullWidth: true,
                  },
                }}
                dayOfWeekFormatter={(_,date: Dayjs) => date.format('dddd').charAt(0).toUpperCase()}
                label={label}
                disableFuture={disableFuture}
                value={!field.field.value ? null : dayjs(field.field.value)}
                disabled={disabled}
                onChange={(v: any) => {
                  field.field.onChange(new Date(v));
                }}
              />
              {field.fieldState.error?.message && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.palette.error.main,
                    marginLeft: theme.spacing(2),
                  })}
                >
                  {t(field.fieldState.error.message)}
                </FormHelperText>
              )}
            </Box>
          </LocalizationProvider>
        );
      }}
    />
  );
};

export default DateInput;
