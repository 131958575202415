import { Link, Stack, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DescriptionIcon from "@mui/icons-material/Description";
import { IDocumentAcceptance } from "../../models/document-acceptance";
import useDate from "../../hooks/useDate";
import { useTranslation } from "react-i18next";

interface IDocumentProps {
  onClick: (document: IDocumentAcceptance) => any;
  document: IDocumentAcceptance;
  index: number;
  style?: any;
}

const Document: React.FC<IDocumentProps> = ({
  document,
  index,
  onClick,
  style,
}) => {
  const { validateDate } = useDate();
  const { t } = useTranslation();

  return (
    <Link
      onClick={() => onClick(document)}
      underline="none"
      key={index}
      style={style}
      sx={{ cursor: "pointer" }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={(theme) => ({
          paddingBlock: 2,
          borderBottom:
            theme.palette.mode === "light"
              ? `1px solid ${theme.palette.grey[500]}`
              : `1px solid ${theme.palette.grey[400]}`,
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[800]
              : theme.palette.grey[400],
        })}
        alignItems={"center"}
      >
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <DescriptionIcon />
          <Typography variant="subtitle1">{document.name}</Typography>
        </Stack>
        <Stack direction={"row"} gap={2}>
          {document.acceptanceDate && (
            <Typography variant="subtitle2">
              {t("components.document.acceptIn", {
                interpolation: {
                  escapeValue: false
                },
                date: validateDate(document.acceptanceDate, "L")
              })}
            </Typography>
          )}
          <ArrowForwardIosIcon fontSize="small" />
        </Stack>
      </Stack>
    </Link>
  );
};

export default Document;
