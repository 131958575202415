import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useState } from "react";

import PagesContainer from "../../components/UI/PagesContainer";
import { HealthProgramsProvider } from "../../store/contexts/HelthProgramContext";
import HealthProgramsList from "../../components/HealthPrograms/HealthProgramsList/HealthProgramsList";
import usePermission from "../../hooks/usePermission";
import { tokenService } from "../../services/token.service";
import useDebounce from "../../hooks/useDebounce";
import SearchInput from "../../components/UI/Inputs/SearchInput";

const translationPath = "page.health_programs.";

const HealthPrograms = () => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState("");

  const searched = useDebounce(searchText, 500);

  const token = !!tokenService.getAccessToken();

  const hasPermission = usePermission({
    domain: "healthprogramportalapp",
    actions: ["Read"],
  });

  if (!hasPermission && token) {
    return <Navigate to={`/no-access`} />;
  }

  return (
    <PagesContainer
      sx={(t) => ({
        [t.breakpoints.up("md")]: {
          paddingRight: "80px",
        },
      })}
      title={t(translationPath + "title")}
      headerBottom={
        <Box
          mt={1}
          sx={(t) => ({
            [t.breakpoints.up("md")]: {
              width: "40%",
            },
            width: "100%",
          })}
        >
          <SearchInput
            onSearch={setSearchText}
            label={t(translationPath + "search") + ""}
          />
        </Box>
      }
    >
      <HealthProgramsProvider>
        <HealthProgramsList search={searched} />
      </HealthProgramsProvider>
    </PagesContainer>
  );
};

export default HealthPrograms;
