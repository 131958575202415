import { Divider, Stack, Typography } from "@mui/material";
import RecentNews from "./RecentNews";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const translationPath = "components.newsList.";

const NewsList = () => {
  const { t } = useTranslation();
  const [hasRecentNews, setHasRecentNews] = useState<boolean>(false);

  return (
    <Stack>
      {hasRecentNews && (
        <>
          <Typography variant={"subtitle1"} mb={1}>
            {t(translationPath + "news")}
          </Typography>
          <Divider />
        </>
      )}
      <RecentNews onHasRecentNews={setHasRecentNews} />
    </Stack>
  );
};

export default NewsList;
