import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ModalComponent from "../UI/Modal";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import DocumentAcceptanceModal from "./DocumentAcceptanceModal";
import { IDocumentAcceptance } from "../../models/document-acceptance";
import useFetch from "../../hooks/useFetch";
import DocAcceptanceService from "../../services/docAcceptance.service";
import { notificationSliceActions } from "../../store/slices/notification";
import { tokenService } from "../../services/token.service";
import Document from "./Document";

const docAcceptanceService = new DocAcceptanceService();

interface IDocumentAcceptanceProps {
  documents?: IDocumentAcceptance[];
  onAcceptDocuments: () => void;
}

const translationPath = "components.documentAcceptance.";

const DocumentAcceptance = ({
  documents,
  onAcceptDocuments,
}: IDocumentAcceptanceProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [selectedDocument, setSelectedDocument] =
    useState<IDocumentAcceptance>();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { sendRequest: accept } = useFetch(docAcceptanceService.create);
  const token = tokenService.hasAccessTokens();

  const onAcceptDocumentsHandler = useCallback(async () => {
    if (documents) {
      const allDocumentsId = documents.map((doc) => doc.docAcceptanceId);
      const data = await accept({ docAcceptanceIds: allDocumentsId });
      if (data) {
        onAcceptDocuments();
        dispatch(
          notificationSliceActions.showNotification({
            message: `${t("components.documentAcceptance.document_accepted")}`,
            type: "success",
          })
        );
      }
    }
  }, [accept, documents, dispatch, t, onAcceptDocuments]);

  const onOpenDocument = useCallback((document: IDocumentAcceptance) => {
    setSelectedDocument(document);
    setOpen(true);
  }, []);

  const onCloseHandler = () => setOpen(false);

  return (
    <>
      <ModalComponent
        open={open}
        onClose={onCloseHandler}
        dialogMaxWidth={"lg"}
      >
        {selectedDocument && <DocumentAcceptanceModal {...selectedDocument} />}
      </ModalComponent>
      <Grid
        container
        width={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid
          item
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          mb={2}
        >
          <img
            src={"/actions/ir.png"}
            alt={t(translationPath + "image_alt") || "Paper"}
            style={{ maxWidth: 100 }}
          />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          mb={1}
        >
          <Typography
            variant="h6"
            textAlign={"center"}
            sx={{ lineHeight: 1.3, maxWidth: "90%" }}
          >
            {t(translationPath + "document_acceptance")}
          </Typography>
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          mb={2}
        >
          <Typography variant="subtitle1" lineHeight={1.2}>
            {t(translationPath + "document_message")}
          </Typography>
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          display={"flex"}
          flexDirection={"column"}
          maxHeight={!matches ? 210 : 300}
          overflow={"auto"}
        >
          {documents?.map((document, index) => (
            <Document
              key={index}
              document={document}
              index={index}
              onClick={onOpenDocument}
            />
          ))}
        </Grid>
        <Grid item sm={12} xs={12} sx={{ marginBlock: 2 }}>
          <Typography variant="subtitle2" lineHeight={1.2}>
            {t(translationPath + "document_accept")}
          </Typography>
        </Grid>
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            if (token) {
              onAcceptDocumentsHandler();
            }
            onAcceptDocuments();
          }}
        >
          {t(translationPath + "accept")}
        </Button>
      </Grid>
    </>
  );
};

export default DocumentAcceptance;
