import AuthCardContainer from "../../components/Auth/AuthCardContainer/AuthCardContainer";
import ResetPasswordForm from "../../components/Auth/ResetPasswordForm/ResetPasswordForm";

const ResetPasswordPage: React.FC = () => {
  return (
    <AuthCardContainer>
      <ResetPasswordForm />
    </AuthCardContainer>
  );
};

export default ResetPasswordPage;
