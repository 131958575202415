import { Modal, useFetch } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { memo, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormCheckBox from "../../../../../../components/UI/Inputs/FormCheckBox";
import { hasActiveRule } from "../../../../../../services/automation.service";
import { getWhatsappNumber } from "../../../../../../services/whatsappNumber.service";
import { TAppointment } from "../../../schema";
import { CurrentWhatsappNumber } from "./CurrentWhatsappNumber/CurrentWhatsappNumber";
import { WhatsappNumberModal } from "./WhatsappNumberModal/WhatsappNumberModal";

const translationPath = "page.schedules.newSchedule.confirm.";

export const Antecipate = memo(() => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const [activeRule, setActiveRule] = useState(false);

  const { control, setValue } = useFormContext<TAppointment>();

  const antecipate = useWatch({ name: "antecipate", control });

  const scheduleType = useWatch({ name: "search.type", control });

  const [currentWhatsappNumber, setCurrentWhatsappNumber] = useState<
    string | null
  >(null);

  const { sendRequest } = useFetch(getWhatsappNumber);

  const { sendRequest: getHasActiveRule } = useFetch(hasActiveRule);

  const openHandler = useCallback(() => {
    setOpen(true);
  }, []);

  const closeHandler = useCallback(() => {
    setOpen(false);
  }, []);

  const getNewWhatsappNumber = useCallback(
    (newWhatsappNumber: string | null) => {
      setCurrentWhatsappNumber(newWhatsappNumber);
    },
    []
  );

  const fetchWhatsappNumber = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (data && success) {
      setValue("whatsappNumber", data.value);
      setCurrentWhatsappNumber(data.value);
    }
  }, [sendRequest, setValue]);

  const fetchHasActiveRule = useCallback(async () => {
    const { data, success } = await getHasActiveRule(
      scheduleType === "3" ? "9" : "10"
    );
    if (data && success) {
      setActiveRule(data.hasActiveRule);
    }
  }, [getHasActiveRule, scheduleType]);

  useLayoutEffect(() => {
    if (activeRule) {
      fetchWhatsappNumber();
    }
  }, [fetchWhatsappNumber, activeRule]);

  useEffect(() => {
    fetchHasActiveRule();
  }, [fetchHasActiveRule]);

  useEffect(() => {
    if (antecipate) {
      openHandler();
    }
  }, [antecipate, openHandler, closeHandler]);

  if (!activeRule) return null;

  return (
    <Grid item container xs={12} sm={12}>
      <Modal
        open={open}
        onClose={closeHandler}
        title={`${t(`${translationPath}whatsapp_number`)}`}
      >
        <WhatsappNumberModal
          onSaveWhatsappNumber={getNewWhatsappNumber}
          onCloseModal={closeHandler}
        />
      </Modal>
      <Grid
        item
        xs={12}
        sm={6}
        sx={(t) => ({
          padding: 2,
          border: 1,
          borderColor: t.palette.divider,
          borderRadius: t.shape.borderRadius,
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            backgroundColor:
              t.palette.grey[t.palette.mode === "light" ? 100 : 900],
          },
        })}
      >
        <FormCheckBox
          name="antecipate"
          label={`${t(`${translationPath}antecipate`)}`}
        />
        <AnimatePresence>
          {antecipate && (
            <motion.div
              key="whatsappNumber"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: [0.6, -0.05, 0.01, 0.99] }}
            >
              <CurrentWhatsappNumber
                currentWhatsappNumber={currentWhatsappNumber}
                onOpenModal={openHandler}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </Grid>
    </Grid>
  );
});
