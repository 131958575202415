import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IFamilyMember } from "./model";
import useFetch from "../../../hooks/useFetch";
import { FamilyGroupService } from "./familyGroup.service";
import FamilyGroupTable from "./FamilyGroupTable/FamilyGroupTable";
import PagesContainer from "../../../components/UI/PagesContainer";

const FamilyGroup = () => {
  const [familyGroupMembers, setFamilyGroupMembers] = useState<IFamilyMember[]>(
    []
  );

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { sendRequest, loading } = useFetch(FamilyGroupService.get);

  const fetch = useCallback(async () => {
    const { data, success } = await sendRequest(null);

    if (data && success) {
      setFamilyGroupMembers(data);
    }
  }, [sendRequest]);

  const handleDelete = useCallback((id: string) => {
    setFamilyGroupMembers((data) => data.filter((i) => i.id !== id));
  }, []);

  const handleClick = () => {
    navigate("add");
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <PagesContainer
      title={t("page.schedules.addFamilyGroup.title")}
      subtitle={t("page.schedules.addFamilyGroup.subtitle")!}
      rowActions={
        <Button variant="contained" onClick={handleClick}>
          {t("page.schedules.addFamilyGroup.add")}
        </Button>
      }
    >
      <FamilyGroupTable
        loading={loading}
        familyGroupMembers={familyGroupMembers}
        onDelete={handleDelete}
      />
    </PagesContainer>
  );
};

export default FamilyGroup;
