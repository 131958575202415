import { Box } from "@mui/material";
import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { IFormStepperActionsProps } from "./models";
import Button from "../../../../components/UI/Button";

const FormStepperActions: React.FC<IFormStepperActionsProps> = ({
  activeStep,
  isLastStep,
  canGoBack = true,
  finishLabel = "page.schedules.newSchedule.sched"!,
  onBack,
  onNext,
  onFinish,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onClickHandler = useCallback(async () => {
    if (isLastStep) {
      setLoading(true);
      await onFinish();
      setLoading(false);
    } else {
      onNext();
    }
  }, [onFinish, isLastStep, onNext]);

  return (
    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
      <Button
        color="inherit"
        disabled={activeStep === 0 || !canGoBack}
        onClick={onBack}
        sx={{ mr: 1 }}
      >
        {t("page.schedules.newSchedule.back")}
      </Button>

      <Box sx={{ flex: "1 1 auto" }} />

      <Button onClick={onClickHandler} variant="contained" loading={loading}>
        {t(isLastStep ? finishLabel : "page.schedules.newSchedule.next")}
      </Button>
    </Box>
  );
};

export default memo(FormStepperActions);
