import { Box, IconButton, Typography } from "@mui/material";
import FormInput from "../../UI/Inputs/FormInput";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Button from "../../UI/Button";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { authTemporaryService } from "../../../services/authTemporary.service";
import {
  TLoginTemporaryForm,
  loginTemporarySchema,
} from "./LoginTemporarySchema";
import { tokenService } from "../../../services/token.service";
import { useTemporaryAccess } from "../../../store/contexts/TemporaryAccessContext";
import { useAppDispatch } from "../../../store/store";
import { userSliceActions } from "../../../store/slices/user";
import { privilegeSliceActions } from "../../../store/slices/privilege";
import { permissionSliceActions } from "../../../store/slices/permission";
import { configurationsSliceActions } from "../../../store/slices/configurations";
import { tokenTemporaryService } from "../../../services/tokenTemorary.service";

const translationPath = "page.loginTemporary.";

const LoginTemporary = () => {
  const { t } = useTranslation();

  const { type } = useParams();

  const isLogged = tokenService.hasAccessTokens();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const methods = useForm<TLoginTemporaryForm>({
    resolver: zodResolver(loginTemporarySchema),
  });

  const { handleSubmit } = methods;

  const { sendRequest, loading } = useFetch(
    authTemporaryService.loginTemporary
  );

  const { fetchTemporaryUser } = useTemporaryAccess();

  const onSubmitHandler = useCallback(
    async (form: TLoginTemporaryForm) => {
      if (!type) return;
      const { data, success } = await sendRequest({
        Login: form.login,
        Password: form.password,
        LoginType: type ?? "",
      });
      if (data && success) {
        tokenTemporaryService.saveTemporaryAccessToken(data.token);
        if (data.name) tokenTemporaryService.saveTemporaryAccessName(data.name);
        if (isLogged) {
          fetchTemporaryUser(data);
          removeAllTokens();
          removeUserName();
          dispatch(userSliceActions.removeUser());
          dispatch(privilegeSliceActions.removePrivilege());
          dispatch(permissionSliceActions.removePermission());
          dispatch(configurationsSliceActions.removeConfigs());
        }
        if (type) {
          navigate(`/${type}`);
        } else {
          navigate("/");
        }
      }
    },
    [sendRequest, navigate, fetchTemporaryUser, dispatch, type, isLogged]
  );

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => (prevState = !prevState));
  };

  const loginType =
    type === "exams" ? t(translationPath + "access_exam") : "Acesso temporário";

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitHandler)}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography variant="h6" align="center" fontWeight={"bold"}>
          {loginType}
        </Typography>

        <Typography variant="subtitle1" align="center" mb={3}>
          {t(translationPath + "welcome")}
        </Typography>

        <FormInput
          size="small"
          autoFocus={true}
          name="login"
          fullWidth
          label={t(translationPath + "login")}
          sx={{ mb: 2 }}
        />

        <FormInput
          size="small"
          name="password"
          fullWidth
          type={!showPassword ? "password" : "text"}
          InputProps={{
            endAdornment: (
              <IconButton
                sx={{ padding: 0 }}
                onClick={togglePasswordVisibility}
              >
                {!showPassword && <VisibilityIcon color="action" />}
                {showPassword && <VisibilityOffIcon color="action" />}
              </IconButton>
            ),
          }}
          label={t(translationPath + "password")}
          sx={{ mb: 2 }}
        />
        <Button loading={loading} variant="contained" type="submit">
          {t(translationPath + "access_button")}
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigate("/register")}
          sx={{ mt: 1, fontSize: 13 }}
        >
          {t(translationPath + "dont_account")}
        </Button>
      </Box>
    </FormProvider>
  );
};

export default memo(LoginTemporary);
function removeAllTokens() {
  throw new Error("Function not implemented.");
}

function removeUserName() {
  throw new Error("Function not implemented.");
}
