import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const translationPath = "components.inputFileValidations.";

const InputFileValidations = ({
  invalidType,
  fileSizeAllowed,
  maxFileSize,
}: {
  invalidType: boolean;
  fileSizeAllowed: boolean | null | undefined;
  maxFileSize: number;
}) => {
  const { t } = useTranslation();

  return (
    <Stack display={"flex"} flexDirection={"row"} gap={2}>
      <Typography color="error" fontSize={14} mt={1}>
        {invalidType && `${t(translationPath + "invalid_type")}.`}
      </Typography>
      <Typography color="error" fontSize={14} mt={1}>
        {!fileSizeAllowed &&
          `${t(translationPath + "invalid_size", {
            maxFileSize: maxFileSize,
          })}`}
      </Typography>
    </Stack>
  );
};

export default InputFileValidations;
