import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useFetch from "../../hooks/useFetch";
import { INotification } from "../../models/notifications-user";
import {
  getUnreadNotifications,
  markNotificationAsRead,
} from "../../services/notifications.service";
import { useAppDispatch, useAppSelector } from "../store";
import { notificationSliceActions } from "../slices/notification";

interface ILanguageContext {
  unreadNotifications: INotification[];
  allNotifications: INotification[];
  markUnreadNotificationAsReadHandler: (
    id: string,
    hideNotification?: boolean
  ) => void;
  markAllUnreadNotificationAsReadHandler: () => void;
  loadAllNotifications: (notifications: INotification[]) => void;
  markNotificationAsReadHandler: (notificationId: string) => void;
  markAllNotificationAsReadHandler: () => void;
}

interface INotificationsUserContextProps {
  children: ReactNode;
}

const NotificationsUserContext = createContext<ILanguageContext>({
  unreadNotifications: [],
  allNotifications: [],
  markUnreadNotificationAsReadHandler: () => {},
  markAllUnreadNotificationAsReadHandler: () => {},
  loadAllNotifications: () => {},
  markNotificationAsReadHandler: () => {},
  markAllNotificationAsReadHandler: () => {},
});

export const NotificationsUserProvider: React.FC<
  INotificationsUserContextProps
> = ({ children }) => {
  const actualLang = useAppSelector((state) => state.language.languageId);

  const notificationPrivileges = useAppSelector(
    (state) => state.privilege.privileges
  );
  const filteredPrivileges = useMemo(
    () => notificationPrivileges.filter((p) => p.domainName === "notification"),
    [notificationPrivileges]
  );

  const hasReadPermission = !!filteredPrivileges.find((p) =>
    p.actions.some((a) => a === "Read")
  );

  const dispatch = useAppDispatch();

  const [unreadNotifications, setUnreadNotifications] = useState<
    INotification[]
  >([]);
  const [allNotifications, setAllNotifications] = useState<INotification[]>([]);

  const { sendRequest } = useFetch(getUnreadNotifications);
  const { sendRequest: markAsRead } = useFetch(markNotificationAsRead);

  const loadUnreadNotifications = useCallback(async () => {
    if (!actualLang) return;
    const { data, success } = await sendRequest(actualLang);
    if (data && success) {
      setUnreadNotifications(data);
    }
  }, [sendRequest, actualLang]);

  useEffect(() => {
    if (hasReadPermission) {
      loadUnreadNotifications();
    }
  }, [loadUnreadNotifications, hasReadPermission]);

  const markAllUnreadNotificationAsReadHandler = useCallback(async () => {
    const { success } = await markAsRead({
      notificationBatchUserIds: unreadNotifications.map((not) => not.id),
    });
    if (success) {
      setUnreadNotifications([]);
      dispatch(
        notificationSliceActions.showNotification({
          message: "Notificações marcadas como lida",
          type: "success",
        })
      );
    }
  }, [markAsRead, dispatch, unreadNotifications]);

  const loadAllNotifications = useCallback((notifications: INotification[]) => {
    setAllNotifications(notifications);
  }, []);

  const markNotificationAsReadHandler = useCallback(
    (notificationId: string) => {
      setAllNotifications((oldState) =>
        oldState.map((old) => {
          if (old.id === notificationId) {
            return { ...old, isRead: true, readDate: new Date().toISOString() };
          }
          return old;
        })
      );
    },
    []
  );

  const markAllNotificationAsReadHandler = useCallback(() => {
    markAllUnreadNotificationAsReadHandler();
    setAllNotifications((oldState) =>
      oldState.map((old) => ({
        ...old,
        isRead: true,
        readDate: old.readDate ?? new Date().toISOString(),
      }))
    );
  }, [markAllUnreadNotificationAsReadHandler]);

  const markUnreadNotificationAsReadHandler = useCallback(
    async (notificationId: string, hideNotification?: boolean) => {
      const { success } = await markAsRead({
        notificationBatchUserIds: [notificationId],
      });
      if (success) {
        markNotificationAsReadHandler(notificationId);
        setUnreadNotifications((oldState) =>
          oldState.filter((o) => o.id !== notificationId)
        );
        if (hideNotification) return;
        dispatch(
          notificationSliceActions.showNotification({
            message: "Notificação marcada como lida",
            type: "success",
          })
        );
      }
    },
    [markNotificationAsReadHandler, markAsRead, dispatch]
  );

  const value = useMemo(
    () => ({
      unreadNotifications,
      allNotifications,
      markUnreadNotificationAsReadHandler,
      markAllUnreadNotificationAsReadHandler,
      loadAllNotifications,
      markNotificationAsReadHandler,
      markAllNotificationAsReadHandler,
    }),
    [
      unreadNotifications,
      allNotifications,
      markUnreadNotificationAsReadHandler,
      markAllUnreadNotificationAsReadHandler,
      loadAllNotifications,
      markNotificationAsReadHandler,
      markAllNotificationAsReadHandler,
    ]
  );

  return (
    <NotificationsUserContext.Provider value={value}>
      {children}
    </NotificationsUserContext.Provider>
  );
};

export const useNotificationsUser = () => {
  const context = useContext(NotificationsUserContext);
  return context;
};
