import { CircularProgress, Stack } from "@mui/material";

const Loading = () => {
  return (
    <Stack
      width={"100%"}
      display={"flex"}
      alignItems={"center"}
      overflow={"hidden"}
      mt={5}
    >
      <CircularProgress />
    </Stack>
  );
};

export default Loading;
