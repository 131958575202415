import { Grid, Stack, Typography } from "@mui/material";
import { memo, useState } from "react";
import YouTube from "react-youtube";

import { IContentVideo } from "../../models/content";
import VideoThumb, { getYoutubeVideoId } from "./VideoThumb";

interface IContentVideosVideos {
  videos: IContentVideo[];
  vertical?: boolean;
}

const ContentVideosVideos: React.FC<IContentVideosVideos> = ({
  videos,
  vertical = false,
}) => {
  const [index, setIndex] = useState(0);

  const onlyOneVideo = videos.length === 1;

  const video = videos[index];

  console.log("video", videos);

  return (
    <Grid container flexDirection={"row"} spacing={2}>
      {video && video.link && (
        <Grid item xs={12} lg={onlyOneVideo || vertical ? 12 : 8}>
          <YouTube
            videoId={getYoutubeVideoId(video.link)}
            opts={{
              height: "100%",
              width: "100%",
              playerVars: {
                controls: 1,
              },
            }}
            style={{
              aspectRatio: 16 / 9,
              width: "100%",
            }}
          />
        </Grid>
      )}
      {!onlyOneVideo && videos.length > 0 && (
        <Grid item xs={12} lg={vertical ? 12 : 4}>
          <Stack>
            <Typography mb={1}>
              Playlist - {index + 1}/{videos.length}
            </Typography>
            {videos.map((v, i) => {
              return (
                <VideoThumb
                  video={v}
                  onClick={() => setIndex(i)}
                  selected={i === index}
                  key={i}
                />
              );
            })}
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(ContentVideosVideos);
