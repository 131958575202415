import { IEstablishmentParam } from "../models/establishment-param";
import { authApi } from "./auth.service";

const ESTABLISHMENT_PARAM_ROUTE =
  process.env.REACT_APP_APP + "/api/v1/EstablishmentParam/GetParam/";

export const getEstablishmentParam = (ParamTypeCode: string) => {
  return authApi.get<IEstablishmentParam[]>(
    ESTABLISHMENT_PARAM_ROUTE + ParamTypeCode
  );
};
