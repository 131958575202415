import axios, { isAxiosError } from "axios";
import { getDomain } from "../services/domain.service";
import { useDispatch } from "react-redux";
import { tokenService } from "../services/token.service";
import { useCallback, useEffect, useState } from "react";
import i18n from "../i18n";
import { notificationSliceActions } from "../store/slices/notification";
import { tokenTemporaryService } from "../services/tokenTemorary.service";

interface IUseViewFileProps {
  url: string;
  id: string;
  items?: any;
}

export const useViewFile = ({ url, id, items }: IUseViewFileProps) => {
  const dispatch = useDispatch();

  const loggedToken = tokenService.getAccessToken();

  const temporaryToken = tokenTemporaryService.getTemporaryAccessToken();

  const token = loggedToken || temporaryToken;

  const [loading, setLoading] = useState(false);

  const [fileData, setFileData] = useState<any>(null);

  const [fileSize, setFileSize] = useState<number>(0);

  const blobToBase64 = useCallback((blob: Blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }, []);

  const getUrlFile = useCallback(() => {
    setLoading(true);
    axios
      .get(url + id, {
        responseType: "blob", // Define o tipo de resposta como um blob (objeto binário)
        params: items,
        headers: {
          Authorization: `Bearer ${token}`,
          Domain: getDomain(),
          "Accept-Language": i18n.language,
        },
      })
      .then((response) => {
        setFileSize(response.data.size);
        blobToBase64(response.data).then((res) => {
          setFileData(res);
        });
      })
      .catch((err) => {
        if (isAxiosError(err)) {
          if (err.response?.data.errors) {
            let errorMessage = "";

            Object.keys(err.response?.data.errors).forEach((key) => {
              errorMessage += err.response?.data.errors[key].join(" | ") + " ";
            });

            dispatch(
              notificationSliceActions.showNotification({
                message: errorMessage,
                type: "error",
              })
            );
          }
          if (err.response?.data.detail) {
            dispatch(
              notificationSliceActions.showNotification({
                message: err.response?.data.detail || err.message,
                type: "error",
              })
            );
          }
        } else {
          dispatch(
            notificationSliceActions.showNotification({
              message: err.message,
              type: "error",
            })
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, blobToBase64, token, id, url, items]);

  useEffect(() => {
    getUrlFile();
  }, [getUrlFile]);

  return { fileData, fileSize, loading };
};
