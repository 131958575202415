import { useNotificationContext } from "@4uhub/lib4uhub";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import {
  answerEvaluationFormQuestion,
  updateAnswerEvaluationFormQuestion,
} from "../../../services/evaluationForm.service";
import { useEvaluationFormContext } from "../../../store/contexts/EvaluationFormContext";
import { useFormStepperContext } from "../../../store/contexts/FormStepperContext";
import { FormStepsActions } from "../../UI/FormsStepper/FormSteps/FormStepsActions/FormStepsActions";
import RadioGroupInput from "../../UI/Inputs/RadioGroupInput";
import { JustificationField } from "./JustificationField/JustificationField";
import { IStepProps } from "./models";
import { TStepForm, stepSchema } from "./stepSchema";

const translationPath = "components.step.";

export const Step = memo(({ stepsLength, question }: IStepProps) => {
  const { t } = useTranslation();

  const { handleNext } = useFormStepperContext();

  const { id } = useParams();

  const { setMessage } = useNotificationContext();

  const { answers, addAnswer, updateAnswer } = useEvaluationFormContext();

  const currentAnswer = useMemo(
    () => answers.find((asw) => asw.questionId === question.id),
    [answers, question.id]
  );

  const methods = useForm<TStepForm>({
    resolver: zodResolver(stepSchema),
    defaultValues: {
      questionAlternative: currentAnswer?.questionAlternativeId ?? "",
      justification: currentAnswer?.justification ?? "",
    },
  });

  const { handleSubmit, setError, reset } = methods;

  const { sendRequest, loading } = useFetch(answerEvaluationFormQuestion);

  const { sendRequest: update } = useFetch(updateAnswerEvaluationFormQuestion);

  const onSubmitHandler = useCallback(
    async (form: TStepForm) => {
      const currentAlternative = question.questionAlternatives.find(
        (alt) => alt.id === form.questionAlternative
      );

      if (
        (currentAlternative &&
          currentAlternative.nrOption <
            question.questionOptionType.requiredJustificationLessThan &&
          !form.justification) ||
        (form.justification &&
          form.justification.length <
            question.questionOptionType.requiredJustificationMinChars)
      ) {
        setError("justification", {
          type: "min",
          message: question.questionOptionType.requiredJustificationMessage,
        });
        return;
      }

      if (!id) return;

      if (!currentAnswer) {
        const { data, success } = await sendRequest({
          evalMovEncSatisfactionId: id,
          questionAlternativeId: form.questionAlternative,
          justification: form.justification ?? undefined,
          questionId: question.id,
        });
        if (data && success) {
          addAnswer({
            id: data.id,
            questionId: question.id,
            justification: form.justification ?? "",
            questionAlternativeId: form.questionAlternative,
          });
          setMessage({
            message: `${t(translationPath + "answer_success")}`,
            type: "success",
          });
          handleNext();
        }
      } else {
        if (
          currentAnswer.questionAlternativeId !== form.questionAlternative ||
          currentAnswer.justification !== form.justification
        ) {
          const { success } = await update({
            id: currentAnswer.id,
            questionAlternativeId: form.questionAlternative,
            justification: form.justification ?? "",
            questionId: question.id,
          });
          if (success) {
            updateAnswer({
              id: question.id,
              questionId: question.id,
              justification: form.justification ?? "",
              questionAlternativeId: form.questionAlternative,
            });
            setMessage({
              message: `${t(translationPath + "update_answer_success")}`,
              type: "success",
            });
          }
        }
        handleNext();
      }
    },
    [
      question,
      id,
      currentAnswer,
      setError,
      handleNext,
      sendRequest,
      update,
      addAnswer,
      updateAnswer,
      setMessage,
      t,
    ]
  );

  const getAnswer = useCallback(() => {
    if (currentAnswer) {
      reset({
        questionAlternative: currentAnswer.questionAlternativeId,
        justification: currentAnswer.justification,
      });
    }
  }, [currentAnswer, reset]);

  useEffect(() => {
    getAnswer();
  }, [getAnswer]);

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmitHandler)}
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography fontSize={18} fontWeight="bold">
            {question.title}
          </Typography>
          {question.description && question.description.length > 0 && (
            <Typography
              variant="subtitle2"
              sx={(t) => ({
                color: t.palette.grey[t.palette.mode === "light" ? 600 : 300],
              })}
            >
              {question.description}
            </Typography>
          )}
          <Box
            sx={{
              mt: 2,
              width: "100%",
              maxWidth: 600,
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <RadioGroupInput
              name="questionAlternative"
              groupLabel=""
              disableBorder
              inline
              fullwidth
              radios={question.questionAlternatives
                .sort((a, b) =>
                  a.displayOrder < b.displayOrder
                    ? -1
                    : a.displayOrder > b.displayOrder
                    ? 1
                    : 0
                )
                .map((alternative) => ({
                  label: alternative.nrOption.toString(),
                  value: alternative.id,
                }))}
            />
            <JustificationField
              requiredJustificationLessThan={
                question.questionOptionType.requiredJustificationLessThan
              }
              questionAlternatives={question.questionAlternatives}
            />
          </Box>
        </Box>
        <FormStepsActions
          stepsLength={stepsLength}
          type="submit"
          stepLoading={loading}
        />
      </Box>
    </FormProvider>
  );
});
