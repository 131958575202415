import {
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import CircleIcon from "@mui/icons-material/Circle";
import { IAddress, IMedicGuidAddress } from "../../../../../models/medic-guide";
import { useState } from "react";
import ResultActions from "./ResultActions";
import LegendsIconsList from "./Legends/LegendIconsList";
import MedicGuideSpecialty from "./MedicGuideSpecialty";
import ResultItemContacts from "./ResultItemContacts";
import ResultItemPracticeArea from "./ResultItemPracticeArea";

const ResultItemAddress = ({
  address,
  healthcareProfessional,
}: {
  address: IAddress;
  selectedAddress?: IMedicGuidAddress;
  healthcareProfessional: string | null;
}) => {
  const [isSelected] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const completeAddress = `${address.addressDescription}${
    address.number ? `, ${address.number}` : ""
  }${address.neighborhoodName ? ` - ${address.neighborhoodName}` : ""}${
    address.city.name ? ` - ${address.city.name}` : ""
  }${address.city.provincy.code ? ` - ${address.city.provincy.code}` : ""}`;

  return (
    <>
      <Grid
        item
        container
        alignItems={"center"}
        sm={12}
        mr={matches ? 1 : 0}
        p={matches ? 1 : 0.8}
        pt={0}
      >
        <Grid
          item
          xs={9}
          sm={9}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
        >
          {isSelected && (
            <CircleIcon color="primary" sx={{ mr: 1, fontSize: 12 }} />
          )}

          <Stack flexDirection={"column"}>
            <Stack>
              {!healthcareProfessional && (
                <Typography fontWeight={700}>
                  {address.legalEntity.tradingName}
                </Typography>
              )}
              <Typography>
                {address.workSector
                  ? address.workSector
                  : address.legalEntity.corporateName}
              </Typography>
            </Stack>
            <LegendsIconsList legend={address.legend} />
            <Typography fontSize={15} variant="subtitle1" lineHeight={1.2}>
              {completeAddress}
            </Typography>

            <ResultItemContacts contact={address.contact} />

            {address.specialty.length !== 0 && (
              <MedicGuideSpecialty specialties={address.specialty} />
            )}

            {address.practiceArea.length !== 0 && (
              <ResultItemPracticeArea practiceArea={address.practiceArea} />
            )}
          </Stack>
        </Grid>
        <Grid
          item
          xs={3}
          sm={3}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"end"}
          gap={1}
        >
          <ResultActions
            phone={
              address.contact.phone
                ? address.contact.phone
                : address.contact.cellPhone
                ? address.contact.cellPhone
                : null
            }
            address={completeAddress}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ResultItemAddress;
