import { IInstalledExtension } from "../models/intalled-extensions";
import { authApi } from "./auth.service";

const BASE_URL = process.env.REACT_APP_APP;

export const getInstalledExtensions = () => {
  return authApi.get<IInstalledExtension[]>(
    BASE_URL + "/api/v1/SiteApp/installed-extensions"
  );
};
