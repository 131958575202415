import { useMediaQuery, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

const RefundStepContainer = ({ children }: { children: any }) => {
  const theme = useTheme();
  const { pathname } = useLocation();

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <motion.main
      style={{
        margin: "0px 10px 10px 10px",
        paddingRight: matches ? "80px" : "0px",
        paddingBottom: !matches ? "80px" : "0px",
      }}
      key={pathname}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
    >
      {children}
    </motion.main>
  );
};

export default RefundStepContainer;
