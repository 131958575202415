import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";

import { IMedic, IMedicGuidAddress } from "../../../../../models/medic-guide";
import ResultItemAddress from "./ResultItemAddress";
import ResultItemProfessional from "./ResultItemProfessional";
import PaperComponent from "../../../../UI/PaperComponent";

const ResultItem = ({
  medic,
  selectedAddress,
}: {
  medic: IMedic;
  selectedAddress?: IMedicGuidAddress;
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <PaperComponent sx={{ width: matches ? "50%" : "100%", marginInline: 1 }}>
      <Box
        sx={{
          paddingInline: 1,
        }}
      >
        <Grid
          container
          flexDirection={"row"}
          alignItems={"center"}
          width={"100%"}
        >
          {medic.healthcareProfessional && (
            <ResultItemProfessional
              healthcareProfessional={medic.healthcareProfessional}
            />
          )}
          <Grid
            item
            container
            sm={12}
            flexDirection={"row"}
            alignItems={"center"}
            mt={1}
            rowGap={2}
          >
            {medic.address.map((address) => (
              <ResultItemAddress
                address={address}
                selectedAddress={selectedAddress}
                key={address.id}
                healthcareProfessional={
                  medic.healthcareProfessional
                    ? medic.healthcareProfessional.id
                    : null
                }
              />
            ))}
          </Grid>
        </Grid>
      </Box>
    </PaperComponent>
  );
};

export default ResultItem;
