import { Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import BackButton from "../components/UI/BackButton";
import MedicGuideResults from "../components/MedicGuide/MedicGuidePage/Results/Addressess/MedicGuideResults";
import {
  IMedic,
  IMedicGuidAddress,
  IMedicalGuideParams,
} from "../models/medic-guide";
import { useCallback, useEffect, useState } from "react";
import ModalComponent from "../components/UI/Modal";
import MedicGuideFilter from "../components/MedicGuide/MedicGuidePage/Results/Filters/MedicGuideFilter";
import MedicalGuideService from "../services/medicalGuide.service";
import useFetch from "../hooks/useFetch";
import { TMedicGuideFilterForm } from "../components/MedicGuide/MedicGuidePage/Results/Filters/MedicGuideFilterSchema";
import MedicGuideHeader from "../components/MedicGuide/MedicGuidePage/Header/MedicGuideHeader";
import { notificationSliceActions } from "../store/slices/notification";
import { useAppDispatch } from "../store/store";
import { useTranslation } from "react-i18next";

const MEDICAL_GUIDE_ROUTE = process.env.REACT_APP_APP + "/api/v1/MedicalGuide/";
const medicalGuideService = new MedicalGuideService<IMedic>(
  MEDICAL_GUIDE_ROUTE
);

const translationPath = "components.medicGuideFilter.";

const MedicGuidePage = () => {
  const { search, "*": secondParam } = useParams();
  const [selectedAddress] = useState<IMedicGuidAddress>();
  // const [selectedMarker, setSelectedMarker] = useState<ISelectedMarker>({
  //   lat: +lat!,
  //   lng: +lng!,
  // });
  // const [openSelectedAddress, setOpenSelectedAddress] = useState(false);
  // const [selectedName, setSelectedName] = useState("");
  const [open, setOpen] = useState(false);
  const [showMap] = useState(false);
  const [medics, setMedics] = useState<IMedic[]>([]);
  const [filterData, setFilterData] = useState<TMedicGuideFilterForm>();
  const [actualPage, setActualPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const { sendRequest, loading } = useFetch(medicalGuideService.getAllMedics);

  const loadSearch = useCallback(async () => {
    const getSearched = JSON.parse(localStorage.getItem("searched")!);
    if (search && !filterData) {
      const { data, success } = await sendRequest({
        Search: !getSearched
          ? secondParam
            ? `"${search + `/${secondParam}`}"`
            : search
          : undefined,
        IsUrgentEmergencyIntegration: undefined,
        MedicalSpecialtyId:
          getSearched && getSearched["code"] === "1"
            ? getSearched["id"]
            : undefined,
        MedicalPracticeAreaId:
          getSearched && getSearched["code"] === "2"
            ? getSearched["id"]
            : undefined,
        CityId:
          getSearched && getSearched["code"] === "3"
            ? getSearched["id"]
            : undefined,
        HealthPlanId:
          getSearched && getSearched["code"] === "4"
            ? getSearched["id"]
            : undefined,
        NameIds:
          getSearched && getSearched["code"] === "5"
            ? getSearched["id"]
            : undefined,
        ProviderTypeId:
          getSearched && getSearched["code"] === "6"
            ? getSearched["id"]
            : undefined,
        PageNumber: actualPage,
        PageSize: 50,
      });
      if (data && success) {
        setMedics(data.items);
        setTotalPages(data.totalPages);
        setOpen(false);
      }
    }
  }, [search, secondParam, filterData, sendRequest, actualPage]);

  useEffect(() => {
    loadSearch();
  }, [loadSearch]);

  const loadMedics = useCallback(
    async (params: IMedicalGuideParams) => {
      const { data, success } = await sendRequest(params);
      if (data && success) {
        setMedics(data.items);
        setOpen(false);
        dispatch(
          notificationSliceActions.showNotification({
            message: `${t(translationPath + "filters_removed")}`,
            type: "success",
          })
        );
      }
    },
    [sendRequest, dispatch, t]
  );

  // const defaultProps = {
  //   center: {
  //     lat: selectedMarker.lat,
  //     lng: selectedMarker.lng,
  //   },
  //   zoom: 15,
  // };

  const onCloseHandler = () => setOpen(false);

  // const onShowMapHandler = () => setShowMap(true);
  // const onHiddenMapHandler = () => setShowMap(false);

  const renderResults = !showMap || matches;
  // const renderMap = showMap || matches;
  // const showMarkerInfo = openSelectedAddress && selectedAddress && !matches;

  return (
    <>
      <Grid container overflow={"hidden"} height={"100%"}>
        <Grid item xs={12}>
          <BackButton
            iconButton
            onClick={() => {
              localStorage.removeItem("searched");
              localStorage.removeItem("coords");
            }}
          />
        </Grid>
        <MedicGuideHeader filterData={filterData} openModal={setOpen} />
        {/* {!matches && (
        <MedicGuideActions
          onHidden={onHiddenMapHandler}
          onShow={onShowMapHandler}
          show={showMap}
        />
        )} */}

        <Grid item xs={12} lg={12} height={"80%"}>
          <Stack height={"100%"}>
            {renderResults && (
              <MedicGuideResults
                medics={medics}
                selectedAddress={selectedAddress}
                loading={loading}
                totalPages={totalPages}
                getActualPage={setActualPage}
              />
            )}
          </Stack>
        </Grid>

        {/* <Grid item xs={12} lg={6}>
          {renderMap && (
          <Grid item xs={12} sm={8} position={"relative"}>
            <Maps defaultProps={defaultProps}>
              {showMarkerInfo && (
                <SelectedMarker
                  address={selectedAddress}
                  onClose={() => setOpenSelectedAddress(false)}
                  name={selectedName}
                />
              )}
              <Marker
                options={{
                  label: {
                    text: `${t(translationPath + "you")}`,
                    className: "marker-label",
                  },
                }}
                position={{
                  lat: +lat!,
                  lng: +lng!,
                }}
                onClick={() => {
                  setSelectedMarker({
                    lat: +lat!,
                    lng: +lng!,
                  });
                }}
              />
              {testeData.map((data) =>
                data.addresses.map((add) => (
                  <Marker
                    options={{
                      label: { text: data.name, className: "marker-label" },
                    }}
                    position={{
                      lat: add.coordinates.lat,
                      lng: add.coordinates.lng,
                    }}
                    onClick={(e) => {
                      e.domEvent.preventDefault();
                      if (!matches) {
                        setOpenSelectedAddress(true);
                        setSelectedName(data.name);
                      }
                      setSelectedAddress(add);
                      setSelectedMarker({
                        lat: add.coordinates.lat,
                        lng: add.coordinates.lng,
                      });
                    }}
                    key={add.address}
                  />
                ))
              )}
            </Maps>
          </Grid>
        )}
        </Grid> */}
      </Grid>
      <ModalComponent open={open} onClose={onCloseHandler}>
        <MedicGuideFilter
          loadMedics={loadMedics}
          loadFilterData={setFilterData}
          filterData={filterData}
          loading={loading}
        />
      </ModalComponent>
    </>
  );
};

export default MedicGuidePage;
