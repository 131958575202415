import { Stack, Typography } from "@mui/material";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import { useTranslation } from "react-i18next";

interface INoData {
  message?: string | null;
}

const NoData: React.FC<INoData> = ({ message }) => {
  const { t } = useTranslation();

  return (
    <Stack alignItems={"center"} spacing={2}>
      <FolderOffIcon fontSize="large" />
      <Typography variant="subtitle1">
        {message || t("components.no_data")}
      </Typography>
    </Stack>
  );
};

export default NoData;
