import { useMediaQuery, useTheme } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Loading from "../../../components/Loading";
import NoData from "../../../components/UI/NoData";
import useFetch from "../../../hooks/useFetch";
import { ScheduleAppointmentService } from "../appointment.service";
import { ISchedule, IScheduleCheckInRule } from "../model";
import MySchedulesTable from "./MySchedulesTable";
import ScheduleList from "./ScheduleList";

interface ISchdulesProps {
  historic?: boolean;
}

const Schedules: React.FC<ISchdulesProps> = ({ historic = false }) => {
  const [schedules, setSchedule] = useState<ISchedule[]>([]);

  const [checkInRule, setCheckInRule] = useState<IScheduleCheckInRule | null>(
    null
  );

  const { t } = useTranslation();

  const { sendRequest, loading } = useFetch(ScheduleAppointmentService.getList);

  const { breakpoints } = useTheme();

  const mobile = useMediaQuery(breakpoints.down("md"));

  const fetch = useCallback(async () => {
    const { data, success } = await sendRequest(historic);

    if (data && success) {
      setSchedule(data.calendarConsultationExamScheduleAppointment);
      setCheckInRule(data.tasySchedCheckInRule);
    }
  }, [sendRequest, historic]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (loading) {
    return <Loading />;
  }

  if (schedules.length === 0) {
    return <NoData message={t("page.schedules.home.noFound")} />;
  }

  if (mobile) {
    return <ScheduleList data={schedules} checkInRule={checkInRule!} />;
  }

  return <MySchedulesTable data={schedules} checkInRule={checkInRule!} />;
};

export default memo(Schedules);
