import { Stack, Typography } from "@mui/material";
import AvaliationBtn from "./AvaliationBtn";
import { useTranslation } from "react-i18next";
import FaqService from "../../../services/faq.service";
import useFetch from "../../../hooks/useFetch";
import { useCallback } from "react";

const faqService = new FaqService();

const translationPath = "components.avaliationsButtons.";

interface IAvaliationsButtonsProps {
  onDislike: (value: boolean) => void;
  onRated: (value: boolean) => void;
  faqQuestionId: string;
}

const AvaliationsButtons = ({
  onDislike,
  onRated,
  faqQuestionId,
}: IAvaliationsButtonsProps) => {
  const { t } = useTranslation();
  const { sendRequest, loading } = useFetch(faqService.updateItemById);

  const onLike = useCallback(async () => {
    const { success } = await sendRequest({ id: faqQuestionId });
    if (success) {
      onRated(true);
    }
  }, [sendRequest, onRated, faqQuestionId]);

  return (
    <Stack alignItems={"center"} width={"100%"}>
      <Typography variant="subtitle2">
        {t(translationPath + "title")}
      </Typography>
      <Stack flexDirection={"row"} gap={1} mt={1}>
        <AvaliationBtn action="like" onClick={onLike} loading={loading} />
        <AvaliationBtn
          action="dislike"
          onClick={() => onDislike(true)}
          loading={false}
        />
      </Stack>
    </Stack>
  );
};

export default AvaliationsButtons;
