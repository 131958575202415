import { Grid } from "@mui/material";
import AutoCompleteBankData, {
  IBankData,
} from "../UI/Inputs/AutoCompleteBankData";
import RefundsService from "../../services/refunds.service";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { useFormContext } from "react-hook-form";
import { TRefundForm } from "../../pages/refunds/RefundsForm/RefundSchema";

const bankData = new RefundsService();

const translationPath = "page.refundsPage.refund.";

const RefundsBankData = ({
  isChecked,
  beneficiaryId,
}: {
  isChecked: boolean;
  beneficiaryId: string;
}) => {
  const { t } = useTranslation();
  const [bankOptions, setBankOptions] = useState<IBankData[]>([]);
  const { setValue } = useFormContext<TRefundForm>();

  const { sendRequest } = useFetch(bankData.bankDataList);

  const loadBankData = useCallback(async () => {
    const { data, success } = await sendRequest({
      Integrationid: beneficiaryId,
    });
    if (success) {
      setBankOptions(data);
      if (data.length === 0) {
        setValue("otherBankData", true);
      }
    }
  }, [sendRequest, setValue, beneficiaryId]);

  useEffect(() => {
    loadBankData();
  }, [loadBankData]);

  return (
    <Grid item xs={12} sm={12} mb={2}>
      <AutoCompleteBankData
        autoFocus
        size="small"
        label={t(translationPath + "bank_data")}
        name="bankData"
        disable={isChecked}
        options={bankOptions}
        onValueSelected={(e) => {
          setValue(
            "holderBankAccountName",
            e.holderBankAccountName ? e.holderBankAccountName : null
          );
          setValue(
            "holderDocument",
            e.holderDocument ? e.holderDocument : null
          );
          setValue(
            "holderBirthDate",
            e.holderBirthDate ? new Date(e.holderBirthDate) : null
          );
          setValue(
            "bank",
            e.bank ? { id: e.bank.id, name: e.bank.name } : null
          );
          setValue("bankBranch", e.bankBranch ? e.bankBranch : null);
          setValue("bankAccount", e.bankAccount ? e.bankAccount : null);
          setValue(
            "bankAccountDigit",
            e.bankAccountDigit ? e.bankAccountDigit : null
          );
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.integrationId}>
              {`${t("components.autoCompleteBankData.short_name")}: ` +
                option.shortName +
                ` ${t("components.autoCompleteBankData.bank_branch")}: ` +
                option.bankBranch +
                ` ${t("components.autoCompleteBankData.bank_account")}: ` +
                option.bankAccount +
                "-" +
                option.bankAccountDigit +
                ` ${t("components.autoCompleteBankData.holder_account")}: ` +
                option.holderBankAccountName}
            </li>
          );
        }}
      />
    </Grid>
  );
};

export default RefundsBankData;
