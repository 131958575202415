import { Loading, useFetch } from "@4uhub/lib4uhub";
import { Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import PagesContainer from "../../../components/UI/PagesContainer";
import { ScheduleAppointmentService } from "../appointment.service";
import { ETasyScheduleTypeCode } from "../model";
import { Message } from "./Message/Message";

const CheckedInPage = () => {
  const [roomName, setRoomName] = useState<string | null>(null);

  const [loading, setLoading] = useState(true);

  const { identifier, tasySchedulingTypeCode } = useParams();

  const { sendRequest } = useFetch(
    ScheduleAppointmentService.getConsulationScheduleAppointment
  );

  const { sendRequest: examRequest } = useFetch(
    ScheduleAppointmentService.getExamScheduleAppointment
  );

  const isExam = useMemo(
    () => tasySchedulingTypeCode === ETasyScheduleTypeCode.EXAM,
    [tasySchedulingTypeCode]
  );

  const fetchConsult = useCallback(async () => {
    if (!identifier) return;
    const { data, success } = await sendRequest(Number(identifier));
    if (success && data) {
      if (!data.scheduleAppointment) return;
      setRoomName(data.scheduleAppointment.roomName);
    }
  }, [sendRequest, identifier]);

  const fetchExam = useCallback(async () => {
    if (!identifier) return;
    const { data, success } = await examRequest(Number(identifier));
    if (success && data) {
      if (!data.scheduleAppointment) return;
      setRoomName(data.scheduleAppointment.roomName);
    }
  }, [examRequest, identifier]);

  const getData = useCallback(async () => {
    setLoading(true);
    if (isExam) {
      await fetchExam();
    } else {
      await fetchConsult();
    }
    setLoading(false);
  }, [isExam, fetchConsult, fetchExam]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <PagesContainer>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        {loading && <Loading />}
        {!loading && <Message roomName={roomName} />}
      </Box>
    </PagesContainer>
  );
};

export default CheckedInPage;
