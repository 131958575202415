import GlobalAxios from "../axios/axios-config";
import { ICoParticipations } from "../models/co-participation";

const HISTORY_PAYMENT_ROUTE =
  process.env.REACT_APP_APP +
  "/api/v1/Beneficiary/GetCoparticipationStatementData/";

export class CoParticipationService {
  getAll = (searchParams?: any) => {
    return GlobalAxios.get<ICoParticipations[]>(HISTORY_PAYMENT_ROUTE, {
      params: searchParams,
    });
  };
}

export default CoParticipationService;
