import { Button, SelectChangeEvent, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import { useCallback, useEffect, useRef, useState } from "react";

import useFetch from "../../hooks/useFetch";
import SelectMonthYear from "../../components/UI/Inputs/SelectMonthYear";
import useRole from "../../hooks/useRole";
import RefundsTable from "./RefundsTable";
import { IGetAllRefunds } from "../../models/refunds";
import RefundsService from "../../services/refunds.service";
import PagesContainer from "../../components/UI/PagesContainer";

const translationPath = "page.refundsPage.";

const refundsService = new RefundsService();

const RefundsPage = () => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const actualDate = dayjs(new Date()).locale(locale);
  const [date, setDate] = useState(actualDate.format("MM/YYYY - MMMM"));
  const componentRef = useRef<HTMLDivElement>(null);
  const [beneficiaryRefunds, setBeneficiaryRefunds] = useState<
    IGetAllRefunds[]
  >([]);
  const readPermission = useRole(["Read"]);
  const { sendRequest, loading } = useFetch(refundsService.getAll);
  const navigate = useNavigate();

  const handleChange = (event: SelectChangeEvent) => {
    setDate(event.target.value);
  };

  const loadBeneficiaryRefunds = useCallback(async () => {
    const { data, success } = await sendRequest(date.slice(0, 7));
    if (success) {
      setBeneficiaryRefunds(data);
    }
  }, [sendRequest, date]);

  useEffect(() => {
    if (readPermission) {
      loadBeneficiaryRefunds();
    }
  }, [loadBeneficiaryRefunds, readPermission]);

  return (
    <PagesContainer
      title={t(translationPath + "refunds")}
      loading={loading}
      rowActions={
        <Button
          startIcon={<AddIcon />}
          variant="outlined"
          size="small"
          onClick={() => navigate("new")}
        >
          {t(translationPath + "new_refund")}
        </Button>
      }
      headerBottom={
        <SelectMonthYear
          label={t(translationPath + "select_date")}
          actualDate={actualDate}
          startDate={"2020-01-01"}
          value={date}
          sx={{ maxWidth: 300, mt: 1 }}
          onChangeHandler={(e) => handleChange(e)}
        />
      }
    >
      <Stack ref={componentRef} className="print-page" width={"100%"}>
        <RefundsTable refunds={beneficiaryRefunds} />
      </Stack>
    </PagesContainer>
  );
};

export default RefundsPage;
