import { Stack } from "@mui/material";
import HomeBannersCarousel from "../BannersCarousel";
import CloseAllBanners from "./CloseAllBanners";
import { Dispatch, SetStateAction, memo } from "react";
import { IPortalBanner } from "../../../models/banners";
import HomeBannersImage from "./HomeBannersImage";

interface IBannersProps {
  banners: IPortalBanner[];
  currentSlide: number;
  setCurrentSlide: Dispatch<SetStateAction<number>>;
  closeBanner: (banner: IPortalBanner) => Promise<void>;
  closeAllBanners: (ids: IPortalBanner[]) => Promise<void>;
}

const Banners = ({
  banners,
  closeBanner,
  currentSlide,
  setCurrentSlide,
  closeAllBanners,
}: IBannersProps) => {
  return (
    <Stack flexDirection={"column"} gap={2}>
      <HomeBannersCarousel
        currentSlide={currentSlide}
        changeSlide={(index) => setCurrentSlide(index)}
      >
        {banners.map((banner) => (
          <HomeBannersImage
            closeBanner={() => closeBanner(banner)}
            bannerAlternativeText={banner.alternativeText}
            bannerId={banner.id}
            bannerPath={banner.path}
            key={banner.id}
          />
        ))}
      </HomeBannersCarousel>
      <CloseAllBanners banners={banners} closeAllBanners={closeAllBanners} />
    </Stack>
  );
};

export default memo(Banners);
