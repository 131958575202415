import { useCallback, useEffect, useState } from "react";
import Button from "../../UI/Button";
import useFetch from "../../../hooks/useFetch";
import { authService } from "../../../services/auth.service";
import { useDispatch } from "react-redux";
import { notificationSliceActions } from "../../../store/slices/notification";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";

const ResendResetEmail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userName } = useParams();
  const { sendRequest: resendEmail, loading } = useFetch(authService.sendEmail);
  const [counter, setCounter] = useState(60);

  const onResendEmail = useCallback(async () => {
    const { success } = await resendEmail({
      userName: userName || "",
      softwareCode: "3",
    });
    if (success) {
      dispatch(
        notificationSliceActions.showNotification({
          message: `${t(
            "components.forgetPasswordForm.notification_email_sent"
          )}`,
          type: "success",
        })
      );
      setCounter(60);
    }
  }, [resendEmail, dispatch, t, userName]);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <>
      <Button
        sx={{ mt: 1 }}
        loading={loading}
        variant="outlined"
        onClick={onResendEmail}
        disabled={counter !== 0}
      >
        Reenviar email
      </Button>
      {counter !== 0 && (
        <Typography variant="subtitle2" mt={1}>
          {t("components.resetPasswordForm.counter", { counter: counter })}
        </Typography>
      )}
    </>
  );
};

export default ResendResetEmail;
