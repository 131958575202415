import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import PagesContainer from "../../../components/UI/PagesContainer";
import { useAppSelector } from "../../../store/store";
import { TAppointment, appointmentSchema } from "./schema";
import FormStepper from "./Stepper/FormStepper";
import { IStep } from "./Stepper/models";
import Confirm from "./Steps/Confirm/Confirm";
import { useConfirm } from "./Steps/Confirm/useConfirm";
import Insurance from "./Steps/Insurance";
import Patient from "./Steps/Patient";
import Schedule from "./Steps/Schedule/Schedule";
import SearchAvailability from "./Steps/SearchAvailability/SearchAvailability";

const STEPS: IStep<TAppointment>[] = [
  {
    label: "page.schedules.newSchedule.steps.patient.title",
    element: <Patient />,
    fields: ["patient"],
  },
  {
    label: "page.schedules.newSchedule.steps.insurance.title",
    element: <Insurance />,
    fields: ["insurance"],
  },
  {
    label: "page.schedules.newSchedule.steps.search.title",
    element: <SearchAvailability />,
    fields: ["search"],
  },
  {
    label: "page.schedules.newSchedule.steps.schedule.title",
    element: <Schedule />,
    fields: ["schedule"],
  },
  {
    label: "page.schedules.newSchedule.steps.confirm",
    element: <Confirm />,
    fields: [],
  },
];

const NewSchedule = () => {
  const { individual } = useAppSelector((state) => state.user);

  const methods = useForm<TAppointment>({
    resolver: zodResolver(appointmentSchema),
    mode: "onChange",
    defaultValues: {
      search: {
        type: "3",
        date: new Date(),
      },
      patient: {
        id: individual.id,
        individualId: individual.id,
        name: individual.name,
      },
      antecipate: false,
    },
  });

  const { t } = useTranslation();

  const { scheduleConsultation, error } = useConfirm();

  const onFinish = async () => {
    const v = methods.getValues();
    await scheduleConsultation({
      ...v,
      whatsappNumber:
        v.antecipate && v.whatsappNumber
          ? v.whatsappNumber
              .replaceAll("+", "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll("-", "")
          : null,
    });
  };

  return (
    <PagesContainer>
      <FormProvider<TAppointment> {...methods}>
        <FormStepper<TAppointment>
          steps={STEPS}
          error={error}
          finishLabel={t("page.schedules.newSchedule.sched")!}
          onFinish={onFinish}
        />
      </FormProvider>
    </PagesContainer>
  );
};

export default NewSchedule;
