import {
  Autocomplete,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import MicIcon from "@mui/icons-material/Mic";
import useFetch from "../../../hooks/useFetch";
import { useTranslation } from "react-i18next";
import useDebounce from "../../../hooks/useDebounce";
import SearchIcon from "@mui/icons-material/Search";
import { Form, useNavigate } from "react-router-dom";
import MedicalGuideService from "../../../services/medicalGuide.service";
import { IMedicGuideAutoComplete } from "../../../models/medic-guide";
import SearchOptions, { IMedicGuideOption } from "./SearchOptions";

const MEDIC_AUTOCOMPLETE_ROUTE = "GetAutoComplete";
const medicAutoCompleteService = new MedicalGuideService(
  MEDIC_AUTOCOMPLETE_ROUTE
);

interface IAutoCompleteVoiceProps {
  label: string;
  SearchAsYouTypeParams?: (typedValue: string) => any;
  params?: any;
}

const AutoCompleteVoice = ({
  label,
  SearchAsYouTypeParams,
  params,
}: IAutoCompleteVoiceProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    listening,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
    resetTranscript,
    finalTranscript,
  } = useSpeechRecognition();

  const { sendRequest } = useFetch(medicAutoCompleteService.autoComplete);

  const [value, setValue] = useState<string>("");
  const [options, setOptions] = useState<IMedicGuideAutoComplete>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] =
    useState<IMedicGuideOption | null>(null);

  const searchQuery = useDebounce(value, 500);

  const handleChange = (search: string) => {
    setValue(search);
    resetTranscript();
  };

  const handleOptions = useCallback(
    async (parameters?: any) => {
      const { data, success } = await sendRequest(parameters);
      if (success) {
        setOptions(data);
      }
    },
    [sendRequest]
  );

  useEffect(() => {
    if (SearchAsYouTypeParams) {
      if (searchQuery !== "" && searchQuery.length > 2) {
        handleOptions(SearchAsYouTypeParams(searchQuery));
      }
    }
  }, [searchQuery, SearchAsYouTypeParams, handleOptions]);

  useEffect(() => {
    if (!SearchAsYouTypeParams) {
      handleOptions(params);
    }
  }, [handleOptions, SearchAsYouTypeParams, params]);

  useEffect(() => {
    const onSearch = (position: GeolocationPosition) => {
      const coords = position.coords;
      localStorage.setItem(
        "coords",
        JSON.stringify({ lat: coords.latitude, lng: coords.longitude })
      );
      navigate(`/medic-guide/${finalTranscript}`);
    };
    setTimeout(() => {
      if (finalTranscript) navigator.geolocation.getCurrentPosition(onSearch);
      SpeechRecognition.stopListening();
    }, 500);
  }, [finalTranscript, navigate]);

  const LIGHT_COLOR = alpha(theme.palette.primary.light, 0.5);
  const DARK_COLOR = alpha(theme.palette.primary.dark, 0.5);

  const onSearch = useCallback(
    (position: GeolocationPosition) => {
      setIsLoading(true);
      const coords = position.coords;
      if (!selectedOption) {
        localStorage.removeItem("searched");
      }
      if (selectedOption) {
        localStorage.setItem(
          "searched",
          JSON.stringify({
            id:
              selectedOption.code === "5"
                ? selectedOption.id
                : selectedOption.id[0],
            code: selectedOption.code,
          })
        );
      }
      localStorage.setItem(
        "coords",
        JSON.stringify({ lat: coords.latitude, lng: coords.longitude })
      );
      navigate(`/medic-guide/${value}`);
      if (coords) {
        setIsLoading(false);
      }
    },
    [navigate, selectedOption, value]
  );

  useEffect(() => {
    if (selectedOption) {
      navigator.geolocation.getCurrentPosition(onSearch);
    }
  }, [selectedOption, onSearch]);

  return (
    <Grid container>
      <Form
        onSubmit={() => {
          if (value.length >= 3) {
            navigator.geolocation.getCurrentPosition(onSearch);
          }
        }}
        style={{ width: "100%" }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          width={"100%"}
          position={"relative"}
        >
          <Autocomplete
            onInputChange={(e, v) => handleChange(v)}
            options={SearchOptions(options) || []}
            groupBy={(option) => option.title}
            clearOnBlur={false}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id[0]}>
                  {option.name}
                </li>
              );
            }}
            onChange={(event, value) => {
              if (value) {
                setSelectedOption(value);
              } else {
                setSelectedOption(null);
              }
            }}
            noOptionsText={t("components.autoCompleteVoice.no_options")}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              />
            )}
            size="small"
            sx={(t) => ({
              width: "97%",
              ".MuiOutlinedInput-root": {
                borderRadius: 2
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderColor:
                  t.palette.mode === "light"
                    ? t.palette.primary.light
                    : t.palette.primary.dark,
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor:
                  t.palette.mode === "light"
                    ? t.palette.primary.dark + " !important"
                    : t.palette.primary.light + " !important",
              },
            })}
          />
          <Stack position={"absolute"} right={0} zIndex={10}>
            {value.length !== 0 && (
              <IconButton
                size="large"
                sx={(theme) => ({
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? theme.palette.primary.light
                      : theme.palette.primary.dark,
                  ":hover": {
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? theme.palette.primary.dark
                        : theme.palette.primary.light,
                  },
                  color: "#fff",
                })}
                onClick={() =>
                  navigator.geolocation.getCurrentPosition(onSearch)
                }
                disabled={isLoading}
              >
                {isLoading && <CircularProgress />}
                {!isLoading && <SearchIcon />}
              </IconButton>
            )}
            {value.length === 0 && (
              <IconButton
                size="large"
                sx={(theme) => ({
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? theme.palette.primary.light
                      : theme.palette.primary.dark,
                  ":hover": {
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? theme.palette.primary.dark
                        : theme.palette.primary.light,
                  },
                  color: listening ? "#ff0000" : "#fff",
                  outline: listening
                    ? `15px solid ${
                        theme.palette.mode === "light"
                          ? LIGHT_COLOR
                          : DARK_COLOR
                      }`
                    : "",
                })}
                onClick={() => {
                  if (!listening)
                    SpeechRecognition.startListening({ continuous: true });
                  if (listening) SpeechRecognition.stopListening();
                  // if (onSearch.length !== 0) {
                  //   navigator.geolocation.getCurrentPosition(onSearch);
                  // }
                }}
                disabled={isLoading}
              >
                {isLoading && <CircularProgress />}
                {!isLoading && <MicIcon />}
              </IconButton>
            )}
          </Stack>
        </Stack>
        {!isMicrophoneAvailable && (
          <Typography
            variant="body2"
            sx={(theme) => ({
              color: theme.palette.error.main,
              fontSize: 12,
            })}
            mt={1}
          >
            {t("components.autoCompleteVoice.permission")}
          </Typography>
        )}
        {!browserSupportsSpeechRecognition && (
          <Typography
            variant="body2"
            sx={(theme) => ({
              color: theme.palette.error.main,
              fontSize: 12,
            })}
            mt={1}
          >
            {t("components.autoCompleteVoice.support")}
          </Typography>
        )}
      </Form>
    </Grid>
  );
};

export default AutoCompleteVoice;
