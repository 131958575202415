import { IconButton, Stack, Tooltip } from "@mui/material";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ErrorIcon from "@mui/icons-material/Error";
import { useTranslation } from "react-i18next";
import { IInputFile } from "../../../../models/input-file";

import { useFormContext } from "react-hook-form";

const translationPath = "components.inputFileStatus.";

interface IInputFilePreviewProps {
  imagePreview: string;
  success: boolean;
  error: boolean;
  value: IInputFile;
  file: File | null | undefined;
  name: string;
  onUpload: (file: File) => void;
}

const InputFileStatus = ({
  imagePreview,
  success,
  error,
  file,
  value,
  name,
  onUpload,
}: IInputFilePreviewProps) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext();

  return (
    <>
      {value && file && success && (
        <Stack
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Tooltip title={t(translationPath + "tooltip_view_file")}>
            <IconButton
              size="small"
              onClick={() => window.open(imagePreview, "_blank")}
            >
              <OpenInNewIcon color="success" />
            </IconButton>
          </Tooltip>
        </Stack>
      )}

      {value.fileName && !success && (
        <Stack
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Tooltip title={t(translationPath + "tooltip_view_file")}>
            <IconButton
              size="small"
              onClick={() => {
                if (value.cdnDomain.length !== 0) {
                  window.open(`${value.cdnDomain}/${value.filePath}`, "_blank");
                } else {
                  const oldFile = getValues(name).imagePreview;
                  window.open(oldFile, "_blank");
                }
              }}
            >
              <OpenInNewIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      )}

      {error && (
        <Stack
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Tooltip title={t(translationPath + "tooltip_try_again")}>
            <IconButton
              size="small"
              onClick={() => (file ? onUpload(file) : undefined)}
            >
              <ErrorIcon color="error" />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
    </>
  );
};

export default InputFileStatus;
