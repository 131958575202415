import { z } from "zod";

const translationPath = "page.register.errors.";

export const registerSchema = z
  .object({
    document: z.string().min(11, translationPath + "document"),
    birthDate: z.date({
      invalid_type_error: translationPath + "birth_date",
    }),
    email: z.string().email(translationPath + "email"),
    password: z.string().nonempty(translationPath + "password"),
    confirmPassword: z.string(),
    id: z.string().optional(),
  })
  .superRefine((value, ctx) => {
    if (value.password !== value.confirmPassword) {
      ctx.addIssue({
        code: "custom",
        message: translationPath + "confirm_password",
        path: ["confirmPassword"],
      });
    }
  });

export type TRegisterForm = z.infer<typeof registerSchema>;
