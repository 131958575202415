import { Box, CircularProgress } from "@mui/material";
import { FC } from "react";
import PaperComponent from "../UI/PaperComponent";

export interface IMedicCardComponent {
  verticalView?: boolean;
  viewType?: "mobile" | "browser";
  loading?: boolean;
  vLoading?: boolean;
  cardImage?: string;
}

const MedicCard: FC<IMedicCardComponent> = ({
  verticalView = false,
  viewType = "mobile",
  cardImage,
  loading,
  vLoading,
}) => {
  const cardLoading = loading || vLoading;

  return (
    <PaperComponent
      sx={{
        width: "350px",
        height: "220px",
        transform: verticalView
          ? "rotate(90deg) scale(1.5)"
          : viewType === "browser"
          ? "rotate(0) scale(1.7)"
          : "rotate(0)",
        borderRadius: "10px",
      }}
    >
      {cardLoading && (
        <Box
          width={"100%"}
          height={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress size={25} />
        </Box>
      )}
      {!cardLoading && (
        <img
          src={cardImage}
          style={{ borderRadius: "10px", width: "100%" }}
          alt={"Frente da Carteirinha do beneficiário"}
        />
      )}
    </PaperComponent>
  );
};

export default MedicCard;
