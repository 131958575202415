import GlobalAxios from "../axios/axios-config";
import TemporaryAxios from "../axios/temprary-axios-config";
import { IHasUser } from "../models/authorizations";
import { IDownloadExamsParams, IExams } from "../models/exams";
import { ISearchParams, ISearchReturn } from "../models/requests";

const EXAMS_ROUTE = process.env.REACT_APP_APP + "/api/v1/LaboratorialExams";

const EXAMS_TASY_ROUTE =
  process.env.REACT_APP_APP + "/api/v1/LaboratorialExams/Tasy";

const BASE_URL = process.env.REACT_APP_IDENTIY;

interface IGetExamsParams extends ISearchParams {
  ExamType?: string;
  StartDate?: string;
  EndDate?: String;
  IndividualId?: string;
}

export const getExams = (params?: IGetExamsParams) => {
  return GlobalAxios.get<ISearchReturn<IExams>>(EXAMS_ROUTE, {
    params,
  });
};

export const getTasyExams = (params?: IGetExamsParams) => {
  return TemporaryAxios.get<ISearchReturn<IExams>>(EXAMS_TASY_ROUTE, {
    params,
  });
};

export const downloadExamTemporary = (params: IDownloadExamsParams) => {
  return TemporaryAxios.get<ISearchReturn<IExams>>(
    EXAMS_ROUTE + `/${params.id}`,
    {
      params: { examType: params.examType },
    }
  );
};

export const downloadExam = (params: IDownloadExamsParams) => {
  return GlobalAxios.get<ISearchReturn<IExams>>(EXAMS_ROUTE + `/${params.id}`, {
    params: { examType: params.examType, individualId: params.individualId },
  });
};

export const hasUserService = () => {
  return TemporaryAxios.get<IHasUser>(BASE_URL + "/api/v1/Login/HasUser");
};
