import { Box } from "@mui/material";
import { IPagesActionsProps } from "./models";

import { memo } from "react";
import PagesControl from "./PagesControl";
import ZoomControl from "./ZoomControl";
import { DownloadPdf } from "./DownloadPdf";
import { PrintPdf } from "./PrintPdf/PrintPdf";

const PagesActions = ({
  file,
  pageNumber,
  numPages,
  scale,
  downloadButtonProps,
  previousPage,
  nextPage,
  setScale,
}: IPagesActionsProps) => {
  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"center"}
      position={"fixed"}
      left={0}
      bottom={5}
      zIndex={100}
    >
      <Box
        boxShadow={2}
        sx={(t) => ({
          backgroundColor: t.palette.background.default,
          borderRadius: 2,
        })}
      >
        <Box
          p={0.5}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          gap={1}
        >
          <PagesControl
            pageNumber={pageNumber}
            numPages={numPages}
            onPreviousPage={previousPage}
            onNextPage={nextPage}
          />
          <ZoomControl scale={scale} setScale={setScale} />
          <DownloadPdf {...downloadButtonProps} />
          <PrintPdf file={file} />
        </Box>
      </Box>
    </Box>
  );
};

export default memo(PagesActions);
