import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { IToggleButtonGroup } from "./models";
import { memo } from "react";

const ToggleButtonGroupUncontrolled = ({
  value,
  options,
  toggleButtonProps,
  onChange,
}: IToggleButtonGroup) => {
  return (
    <ToggleButtonGroup
      {...toggleButtonProps}
      color="primary"
      value={value}
      size="small"
      exclusive
      onChange={(e, v) => {
        if (v) {
          onChange(e, v);
        }
      }}
    >
      {options.map((option) => (
        <ToggleButton value={option.value} key={option.label}>
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default memo(ToggleButtonGroupUncontrolled);
