import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { memo, useCallback } from "react";
import { confirm } from "material-ui-confirm";
import { useTranslation } from "react-i18next";

import useFetch from "../../../../hooks/useFetch";
import { FamilyGroupService } from "../familyGroup.service";

interface IDeleteFamilyGroupProps {
  id: string;
  onDelete: (id: string) => void;
}

const DeleteFamilyGroup: React.FC<IDeleteFamilyGroupProps> = ({
  id,
  onDelete,
}) => {
  const { sendRequest } = useFetch(FamilyGroupService.delete);
  const { t } = useTranslation();

  const handleClick = useCallback(async () => {
    try {
      await confirm({
        title: t("page.schedules.addFamilyGroup.delete.title"),
        description: t("page.schedules.addFamilyGroup.delete.description"),
        confirmationText: t("page.schedules.addFamilyGroup.delete.confirmationText"),
        cancellationText: t("page.schedules.addFamilyGroup.delete.cancellationText"),
      });
      const { success } = await sendRequest(id);
      if (success) {
        onDelete(id);
      }
    } catch (error) {}
  }, [sendRequest, onDelete, id, t]);

  return (
    <IconButton size="small" onClick={handleClick}>
      <DeleteIcon fontSize="small" />
    </IconButton>
  );
};

export default memo(DeleteFamilyGroup);
