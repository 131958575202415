import React from "react";
import { memo } from "react";
import DynamicIcon from "../../../components/UI/Menus/DynamicIcon";
import { Box, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ITypeProps {
  type: string;
}

enum ETypes {
  CONSULT = "3",
  EXAM = "2",
}

export const Type: React.FC<ITypeProps> = ({ type }) => {
  const { t } = useTranslation();

  const isExam = type === ETypes.EXAM;

  return (
    <Tooltip title={t(`page.schedules.mySchedules.type.${type}`)}>
      <Box>
        <DynamicIcon
          icon={isExam ? "FileMedicalIcon" : "UserDoctorIcon"}
          color={"inherit"}
        />
      </Box>
    </Tooltip>
  );
};

export default memo(Type);
