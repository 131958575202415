import { Grid, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { formatValue } from "../../utils/conversions";
import DisplayDate from "../../components/DisplayDate";
import Table from "../../components/UI/Table/Table";
import NoData from "../../components/UI/NoData";
import { IGetAllRefunds, IGetRefund } from "../../models/refunds";
import DisplayPlan from "../../components/UI/DisplayPlan";
import PrettyJson from "../../components/PrettyJson";

const translationPath = "page.refundsPage.";

interface IRefundsTableProps {
  refunds: IGetAllRefunds[];
}

const RefundsTable = ({ refunds }: IRefundsTableProps) => {
  const { t } = useTranslation();

  const columns: GridColDef<IGetRefund>[] = [
    {
      field: "individual",
      headerName: `${t(translationPath + "beneficiary")}`,
      flex: 1,
      renderCell: (params) => params.row.individual.name,
    },
    {
      field: "serviceReason",
      headerName: `${t(translationPath + "service_reason")}`,
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.refundData?.serviceReason || "-"}>
            <Typography
              variant="subtitle2"
              sx={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {params.row.refundData?.serviceReason || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "protocolDate",
      headerName: `${t(translationPath + "protocol_date")}`,
      minWidth: 150,
      maxWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <DisplayDate typography={{ variant: "subtitle2" }}>
            {dayjs(params.row.refundData?.serviceDate)}
          </DisplayDate>
        );
      },
    },
    {
      field: "amount",
      headerName: `${t(translationPath + "amount")}`,
      minWidth: 120,
      renderCell: (params) => {
        return (
          <Tooltip
            title={
              params.row.refundData?.additionalRefundData ? (
                <PrettyJson>
                  {params.row.refundData.additionalRefundData}
                </PrettyJson>
              ) : (
                ""
              )
            }
          >
            <Typography variant="subtitle2">
              {formatValue(
                params.row.refundData ? params.row.refundData.serviceValue : 0
              )}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Grid item container xs={12} sm={12} md={12} xl={12}>
      {refunds.map((item) => (
        <Grid item xs={12} sm={12} md={12} mt={2} key={item.planName}>
          <DisplayPlan planName={item.planName} />
          {item.refunds.length !== 0 && (
            <Table
              columns={columns}
              rows={item.refunds.flatMap((item, index) => ({
                ...item,
                id: item.individual.id + index,
              }))}
              rowHeight={70}
            />
          )}
          
          {item.refunds.length === 0 && (
            <Grid container mt={5}>
              <Grid item xs={12} sm={12}>
                <NoData />
              </Grid>
            </Grid>
          )}
        </Grid>
      ))}
      {refunds.length === 0 && (
        <Grid container mt={5}>
          <Grid item xs={12} sm={12}>
            <NoData />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default RefundsTable;
