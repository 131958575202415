import { Stack, Tooltip } from "@mui/material";
import DisplayDate from "../DisplayDate";
import dayjs from "dayjs";
import { memo } from "react";

interface INotificationDateProps {
  icon: JSX.Element;
  date: string;
  tooltipText: string;
}

const NotificationDate = ({
  date,
  icon,
  tooltipText,
}: INotificationDateProps) => {
  return (
    <Tooltip title={tooltipText}>
      <Stack
        sx={(t) => ({
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 0.5,
          color:
            t.palette.mode === "light"
              ? t.palette.grey[800]
              : t.palette.grey[200],
        })}
      >
        {icon}
        <DisplayDate typography={{ variant: "subtitle2" }}>
          {dayjs(date)}
        </DisplayDate>
      </Stack>
    </Tooltip>
  );
};

export default memo(NotificationDate);
