import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IPermission } from "../../models/permission";

const PERMISSION_INITIAL_STATE: IPermission = {
  hasPermission: false,
};

export const slice = createSlice({
  name: "permission",
  initialState: PERMISSION_INITIAL_STATE,
  reducers: {
    savePermission(state: IPermission, action: PayloadAction<IPermission>) {
      return { ...state, ...action.payload };
    },
    removePermission(state) {
      return (state = PERMISSION_INITIAL_STATE);
    },
  },
});

export const permissionSliceActions = slice.actions;

export default slice.reducer;
