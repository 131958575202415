import { Alert, Snackbar } from "@mui/material";
import { useAppSelector } from "../../store/store";
import { useDispatch } from "react-redux";
import { notificationSliceActions } from "../../store/slices/notification";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export default function Notification() {
  const dispatch = useDispatch();
  const messageInfo = useAppSelector((state) => state.notification.messageInfo);
  const open = useAppSelector((state) => state.notification.open);
  const { t } = useTranslation();

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(notificationSliceActions.closeNotification());
  };

  const handleExited = useCallback(() => {
    dispatch(notificationSliceActions.closeNotification());
  }, [dispatch]);

  let message = "";

  if (messageInfo) {
    if (messageInfo.translate) {
      message = t(messageInfo.message);
    } else {
      message = messageInfo.message;
    }
  }

  return (
    <Snackbar
      key={messageInfo ? messageInfo.key : undefined}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
    >
      <Alert
        severity={messageInfo?.type}
        variant="filled"
        onClose={handleClose}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
