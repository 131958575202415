import { MenuItem, Stack, Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { IWhatsappNumber } from "./models";

export const WhatsappNumber = memo(({ openModalHandler }: IWhatsappNumber) => {
  const { t } = useTranslation();
  return (
    <MenuItem onClick={openModalHandler}>
      <Stack direction={"row"} spacing={1}>
        <WhatsAppIcon color="action" />
        <Typography>{t("whatsapp_number")}</Typography>
      </Stack>
    </MenuItem>
  );
});
