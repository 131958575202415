import { memo, useEffect } from "react";

import useDocumentAcceptence from "../../hooks/useDocumentAcceptence";
import ModalComponent from "../UI/Modal";
import DocumentAcceptance from "./DocumentAcceptance";

const DocumentModal = () => {
  const {
    onCheckDocs,
    open,
    onCloseHandler,
    onAcceptDocuments,
    newDocuments,
    acceptDocuments,
  } = useDocumentAcceptence();

  useEffect(() => {
    onCheckDocs();
  }, [onCheckDocs]);

  return (
    <ModalComponent open={open} onClose={onCloseHandler} dialogMaxWidth={"sm"}>
      <DocumentAcceptance
        onAcceptDocuments={onAcceptDocuments}
        documents={newDocuments.length >= 1 ? newDocuments : acceptDocuments}
      />
    </ModalComponent>
  );
};

export default memo(DocumentModal);
