import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { IGetSymptoms } from "../../../models/topmed";
import Cards from "../../UI/Cards";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import HealingIcon from "@mui/icons-material/Healing";
import DisplayDate from "../../DisplayDate";

const translationPath = "components.lastCalls.";

const LastCallsCard = ({
  symptom,
  onUseSymptom,
}: {
  symptom: IGetSymptoms;
  onUseSymptom: (symptoms: string) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Cards width={"100%"}>
      <Stack
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"start"}
      >
        <Stack
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
        >
          <Stack width={"50%"}>
            <DisplayDate
              typography={{ fontWeight: "bold" }}
              format="DD/MM/YYYY - HH:mm"
            >
              {dayjs(symptom.created)}
            </DisplayDate>
          </Stack>
          <Stack
            width={"50%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"end"}
            alignItems={"center"}
          >
            {symptom.link && (
              <Tooltip title={t(translationPath + "return_tooltip")}>
                <Button onClick={() => window.open(symptom.link!)}>
                  {t(translationPath + "return")}
                </Button>
              </Tooltip>
            )}
            <Tooltip title={t(translationPath + "use_symptoms")}>
              <IconButton onClick={() => onUseSymptom(symptom.symptons)}>
                <HealingIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Stack width={"100%"} display={"flex"} flexDirection={"row"} gap={1}>
          <Typography
            style={{
              width: "100%",
              maxHeight: 100,
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
            }}
          >
            <Typography
              sx={(theme) => ({
                mr: 1,
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.grey[700]
                    : theme.palette.grey[400],
              })}
              component={"span"}
            >
              {t(translationPath + "symptoms")}:
            </Typography>
            {symptom.symptons}
          </Typography>
        </Stack>
      </Stack>
    </Cards>
  );
};

export default LastCallsCard;
