import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

type IFormCheckBoxProps = {
  name: string;
  label?: string;
} & CheckboxProps;

const FormCheckBox: React.FC<IFormCheckBoxProps> = ({
  name,
  label,
  ...otherProps
}) => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={false}
      render={({ fieldState: { error }, field }) => (
        <>
          <FormControlLabel
            control={
              <Checkbox
                {...otherProps}
                {...field}
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            }
            label={<Typography variant="body2">{label}</Typography>}
          />
          <FormHelperText sx={(theme) => ({ color: theme.palette.error.main })}>
            {error ? t(error?.message || "") : ""}
          </FormHelperText>
        </>
      )}
    />
  );
};

export default FormCheckBox;
