import { Box, Grid } from "@mui/material";
import { memo } from "react";

import NavigatorCard from "../../components/UI/HomeNavigation/NavigatorCard";
import { INavigationItem } from "../../models/navigation";

const ITEMS: INavigationItem[] = [
  {
    name: "page.schedules.home.actions.newSchedule",
    link: "newSchedule",
    icon: (
      <img
        style={{ height: 75 }}
        src="/actions/schedule-add.png"
        alt={"FamilyGroup"}
      />
    ),
    isExtensionInstalled: true,
  },
  {
    name: "page.schedules.home.actions.mySchedules",
    link: "mySchedules",
    icon: (
      <img
        style={{ height: 75 }}
        src="/actions/schedule-history.png"
        alt={"Insurance"}
      />
    ),
    isExtensionInstalled: true,
  },
  {
    name: "page.schedules.home.actions.familyGroup",
    link: "familyGroup",
    icon: (
      <img
        style={{ height: 75 }}
        src="/actions/schedule-group.png"
        alt={"FamilyGroup"}
      />
    ),
    isExtensionInstalled: true,
  },
  {
    name: "page.schedules.home.actions.insurance",
    link: "insurance",
    icon: (
      <img
        style={{ height: 75 }}
        src="/actions/schedule-insurance.png"
        alt={"Insurance"}
      />
    ),
    isExtensionInstalled: true,
  },
];

const Actions = () => {
  return (
    <Box>
      <Grid
        flexDirection={"row"}
        container
        sx={({ breakpoints }) => ({
          [breakpoints.down("md")]: {
            flexWrap: "nowrap",
            gap: 2,
          },

          [breakpoints.up("md")]: {
            flexWrap: "wrap",
            gap: 3,
          },
          overflowX: "auto",
        })}
      >
        {ITEMS.map((item) => (
          <Grid item xs={"auto"} key={item.link}>
            <NavigatorCard {...item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default memo(Actions);
