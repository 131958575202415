import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useFormContext } from "react-hook-form";
import EmergencyCareService from "../../../services/emergencyCare.service";
import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { IGetSymptoms } from "../../../models/topmed";
import { useCallback, useEffect, useState } from "react";
import LastCallsCard from "./LastCallsCard";

const queueService = new EmergencyCareService();
const translationPath = "components.lastCalls.";

const LastCalls = ({ open }: { open: boolean }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [symptomsList, setStymptomsList] = useState<IGetSymptoms[]>([]);

  const { setValue } = useFormContext();
  const { sendRequest } = useFetch(queueService.getSymptoms);

  const loadSymptoms = useCallback(async () => {
    if (id) {
      const { data, success } = await sendRequest(id);
      if (success) {
        setStymptomsList(data.reverse());
      }
    }
  }, [id, sendRequest]);

  useEffect(() => {
    loadSymptoms();
  }, [loadSymptoms, open]);

  const onUseSymptom = (symptoms: string) => {
    setValue("symptoms", symptoms);
  };

  return (
    <Stack mb={2}>
      {symptomsList.length !== 0 && (
        <>
          <Typography variant="h6" mb={2}>
            {t(translationPath + "last_calls")}
          </Typography>
          <Stack display={"flex"} gap={2}>
            {symptomsList.map((symptom) => (
              <LastCallsCard
                onUseSymptom={onUseSymptom}
                symptom={symptom}
                key={symptom.id}
              />
            ))}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default LastCalls;
