import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import TodayIcon from "@mui/icons-material/Today";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";

import useFetch from "../../hooks/useFetch";
import { getNotification } from "../../services/notifications.service";
import { INotification } from "../../models/notifications-user";
import NotificationNotFound from "../../components/Notifications/NotificationNotFound";
import { useNotificationsUser } from "../../store/contexts/NotificationsUserContext";
import { useAppSelector } from "../../store/store";
import NotificationDate from "../../components/Notifications/NotificationDate";
import PagesContainer from "../../components/UI/PagesContainer";

const translationPath = "page.notification.";

const Notification = () => {
  const { t } = useTranslation();

  const notificationPrivileges = useAppSelector((state) =>
    state.privilege.privileges.filter((p) => p.domainName === "notification")
  );
  const hasUpdatePermission = !!notificationPrivileges.find((p) =>
    p.actions.some((a) => a === "Update")
  );

  const { id } = useParams();

  const actualLang = useAppSelector((state) => state.language.languageId);

  const [notification, setNotification] = useState<INotification>();

  const { sendRequest, loading } = useFetch(getNotification);

  const { markNotificationAsReadHandler, markUnreadNotificationAsReadHandler } =
    useNotificationsUser();

  const getNotificationHandler = useCallback(async () => {
    if (id && actualLang) {
      const { data, success } = await sendRequest({
        id,
        languageId: actualLang,
      });
      if (data && success) {
        setNotification(data);
      }
    }
  }, [sendRequest, id, actualLang]);

  useEffect(() => {
    getNotificationHandler();
  }, [getNotificationHandler]);

  useEffect(() => {
    if (!id) return;
    if (notification && !notification.isRead && hasUpdatePermission) {
      markNotificationAsReadHandler(id);
      markUnreadNotificationAsReadHandler(id, true);
    }
  }, [
    id,
    notification,
    markNotificationAsReadHandler,
    markUnreadNotificationAsReadHandler,
    hasUpdatePermission,
  ]);

  return (
    <PagesContainer
      title={
        notification
          ? notification.notificationBatchUserContent.title
          : t(translationPath + "title")
      }
      loading={loading}
      redirectTo={"/notifications"}
    >
      <Box height={"calc(100vh - 14rem)"}>
        {!notification && <NotificationNotFound />}
        {notification && (
          <>
            <Box pb={2} display={"flex"} alignItems={"center"} gap={1}>
              {notification.created && (
                <NotificationDate
                  date={notification.created}
                  icon={<TodayIcon fontSize="small" />}
                  tooltipText={t(translationPath + "created")}
                />
              )}
              {notification.readDate && (
                <NotificationDate
                  date={notification.readDate}
                  icon={<DoneAllIcon fontSize="small" />}
                  tooltipText={t(translationPath + "read")}
                />
              )}
            </Box>
            <Typography>
              {notification?.notificationBatchUserContent.content}
            </Typography>
          </>
        )}
      </Box>
    </PagesContainer>
  );
};

export default Notification;
