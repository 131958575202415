import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const translationPath = "components.navigationCardsPage.";

const NavigationCardsPage = () => {
  const navigate = useNavigate();
  
  const { t } = useTranslation();

  const onClickHandler = () => {
    navigate("/cards");
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Stack>
      <Typography variant={"subtitle1"} mb={1}>
        {t(translationPath + "title")}
      </Typography>
      <Stack
        direction={"row"}
        sx={{
          padding: 2,
          width: "100%",
          cursor: "pointer",
          borderRadius: 4,
          color: "#fff",
          background: "linear-gradient(to right, #0A79BE, #0D93E6)",
        }}
        alignItems={"center"}
        onClick={onClickHandler}
        gap={2}
      >
        <img
          src={"/actions/card.png"}
          alt="Ícone de cartão"
          style={{ maxWidth: !matches ? "8%" : "16%", minWidth: 70 }}
        ></img>
        <Typography variant={matches ? "subtitle1" : "body2"}>
          {t(translationPath + "action")}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default NavigationCardsPage;
