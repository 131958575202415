import { Box, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback } from "react";

import { IMedicalGuideParams } from "../../../../../models/medic-guide";
import {
  TMedicGuideFilterForm,
  medicGuideFilterSchema,
} from "./MedicGuideFilterSchema";
import MedicGuideFilterFields from "./MedicGuideFilterFields";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../../store/store";
import { notificationSliceActions } from "../../../../../store/slices/notification";

const translationPath = "components.medicGuideFilter.";

const MedicGuideFilter = ({
  loadMedics,
  loadFilterData,
  filterData,
  loading,
}: {
  loadMedics: (params: IMedicalGuideParams) => Promise<void>;
  loadFilterData: (values: TMedicGuideFilterForm | undefined) => void;
  filterData: TMedicGuideFilterForm | undefined;
  loading: boolean;
}) => {
  const { t } = useTranslation();
  const { search } = useParams();
  const dispatch = useAppDispatch();

  const methods = useForm<TMedicGuideFilterForm>({
    resolver: zodResolver(medicGuideFilterSchema),
    defaultValues: {
      isUrgentEmergencyIntegration: false,
    },
  });

  const { handleSubmit } = methods;

  const onSubmitHandler = useCallback(
    async (v: TMedicGuideFilterForm) => {
      loadFilterData(v);
      await loadMedics({
        IsUrgentEmergencyIntegration: v.isUrgentEmergencyIntegration,
        ProviderTypeId: v.providerType?.id,
        CityId: v.city?.city.id,
        HealthPlanId: v.healthPlan?.id,
        MedicalPracticeAreaId: v.medicalPracticeArea?.id,
        MedicalSpecialtyId: v.medicalSpecialty?.id,
        Name: v.name ? v.name : undefined,
        PageNumber: 1,
        PageSize: 50,
      });
    },
    [loadMedics, loadFilterData]
  );

  const clearFilters = useCallback(async () => {
    if (search) {
      loadFilterData(undefined);
      dispatch(
        notificationSliceActions.showNotification({
          message: `${t(translationPath + "filters_removed")}`,
          type: "success",
        })
      );
    }
  }, [dispatch, loadFilterData, search, t]);

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitHandler)}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography variant="h5" align="center" fontWeight={700} mb={3}>
          {t(translationPath + "detailed_search")}
        </Typography>
        <MedicGuideFilterFields
          filterData={filterData}
          clearFilters={clearFilters}
          loading={loading}
        />
      </Box>
    </FormProvider>
  );
};

export default MedicGuideFilter;
