import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import useFetch from "../../../../../hooks/useFetch";
import { ScheduleAppointmentService } from "../../../appointment.service";
import { ETasyScheduleTypeCode, IAvaiableSchedule } from "../../../model";
import { TAppointment, TSearchConsult, TSearchExam } from "../../schema";

export const useSchedule = (defaultDate: string) => {
  const [month, setMonth] = useState<string>(
    dayjs(defaultDate).format("MM/YYYY")
  );

  const { getValues, resetField } = useFormContext<TAppointment>();

  const [datesLoading, setDatesLoading] = useState(false);

  const [schedulesLoading, setSchedulesLoading] = useState(false);

  const [dates, setDates] = useState<Dayjs[]>([]);

  const [schedules, setSchedules] = useState<IAvaiableSchedule[]>([]);

  const [date, setDate] = useState<Dayjs | null>(null);

  const { sendRequest: getConsultationDates } = useFetch(
    ScheduleAppointmentService.getConsultationAvaiableDate
  );

  const { sendRequest: getConsultationSchedules } = useFetch(
    ScheduleAppointmentService.getConsultationAvaiableSchedules
  );

  const { sendRequest: getExamDates } = useFetch(
    ScheduleAppointmentService.getExamAvaiableDate
  );

  const { sendRequest: getExamSchedules } = useFetch(
    ScheduleAppointmentService.getExamAvaiableSchedules
  );

  const fetchConsulationDates = useCallback(
    async (search: TSearchConsult) => {
      const { data, success } = await getConsultationDates({
        MedicalSpecialtyCode: search.speciality?.id,
        MonthYear: month,
        CalendarIdentifier: search.professional?.id
          ? String(search.professional?.id)
          : undefined,
        ScheduleShiftCodeList: search.period?.map((p) => p.code),
        MinDate: new Date(search.date).toDateString(),
      });
      if (success && data) {
        setDates(data.dates.map((d) => dayjs(d)));
      }
    },
    [getConsultationDates, month]
  );

  const fetchExamDates = useCallback(
    async (search: TSearchExam) => {
      const { data, success } = await getExamDates({
        GroupingIdentifier: Number(search.examGroup.id),
        MonthYear: month,
        ScheduleShiftCodeList: search.period?.map((p) => p.code),
        MinDate: new Date(search.date).toDateString(),
      });
      if (success && data) {
        setDates(data.dates.map((d) => dayjs(d)));
      }
    },
    [getExamDates, month]
  );

  const fetchDates = useCallback(async () => {
    const { search } = getValues();
    setDatesLoading(true);
    if (search.type === ETasyScheduleTypeCode.CONSULTATION) {
      await fetchConsulationDates(search);
    } else {
      await fetchExamDates(search);
    }
    setDatesLoading(false);
  }, [fetchConsulationDates, getValues, fetchExamDates]);

  const fetchConsultationSchedules = useCallback(
    async (search: TSearchConsult, pDate: Dayjs) => {
      const { data, success } = await getConsultationSchedules({
        Date: dayjs(pDate).format("MM/DD/YYYY"),
        MedicalSpecialtyCode: search.speciality?.id,
        MonthYear: month,
        CalendarIdentifier: search.professional?.id
          ? String(search.professional?.id)
          : undefined,
        ScheduleShiftCodeList: search.period?.map((p) => p.code),
      });
      if (success && data) {
        setSchedules(data);
      }
    },
    [month, getConsultationSchedules]
  );

  const fetchExamSchedules = useCallback(
    async (search: TSearchExam, pDate: Dayjs) => {
      const { data, success } = await getExamSchedules({
        Date: dayjs(pDate).format("MM/DD/YYYY"),
        GroupingIdentifier: Number(search.examGroup.id),
        MonthYear: month,
        ScheduleShiftCodeList: search.period?.map((p) => p.code),
      });
      if (success && data) {
        console.log(data);
        setSchedules(data);
      }
    },
    [month, getExamSchedules]
  );

  const fetchSchedules = useCallback(
    async (pDate: Dayjs) => {
      const { search } = getValues();
      setSchedulesLoading(true);
      if (search.type === ETasyScheduleTypeCode.CONSULTATION) {
        await fetchConsultationSchedules(search, pDate);
      } else {
        await fetchExamSchedules(search, pDate);
      }
      setSchedulesLoading(false);
    },
    [getValues, fetchConsultationSchedules, fetchExamSchedules]
  );

  const onVisibleMonthsChange = useCallback((month: dayjs.Dayjs) => {
    setMonth(month.format("MM/YYYY"));
  }, []);

  const onDateChange = useCallback(
    (d: Dayjs) => {
      fetchSchedules(d);
      setDate(d);
      resetField("schedule");
    },
    [fetchSchedules, resetField]
  );

  useEffect(() => {
    fetchDates();
  }, [fetchDates]);

  return {
    onDateChange,
    onVisibleMonthsChange,
    date,
    dates,
    schedules,
    datesLoading,
    schedulesLoading,
  };
};
