import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import PagesContainer from "../../components/UI/PagesContainer";
import Actions from "./Actions";
import Schedules from "./MySchedules/Schedules";

const ScheduleHome = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <PagesContainer
      title={t("page.schedules.home.schedules")}
      sx={mobile ? { height: "calc(100% - 5rem)" } : undefined}
    >
      <Stack gap={3} mr={1}>
        <Actions />
        <Stack>
          <Typography variant="h6">
            {t("page.schedules.home.nextSchedules")}
          </Typography>
          <Schedules />
        </Stack>
      </Stack>
    </PagesContainer>
  );
};

export default ScheduleHome;
