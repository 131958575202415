import { motion } from "framer-motion";
import { memo } from "react";
import { IFormStepContainerProps } from "./models";

export const FormStepContainer = memo(
  ({ children }: IFormStepContainerProps) => {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        style={{ height: "calc(100vh - 15rem)" }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: [0.6, -0.05, 0.01, 0.99] }}
      >
        {children}
      </motion.div>
    );
  }
);
