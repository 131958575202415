import { Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";

interface INewsCardTextProps {
  children: string;
  fontWeight?: "bold" | "normal";
  placement?: "top" | "bottom" | "left" | "right";
}

const NewsCardText = ({
  children,
  fontWeight = "normal",
  placement = "bottom",
}: INewsCardTextProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [hoverStatus, setHover] = useState(false);

  const compareSize = useCallback(() => {
    if (!ref.current) return;
    const isTitleEllipsisActive =
      ref.current.scrollHeight > ref.current.clientHeight;

    setHover(isTitleEllipsisActive);
  }, [ref]);

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);

    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, [compareSize]);

  return (
    <Tooltip
      title={children}
      disableHoverListener={!hoverStatus}
      placement={placement}
    >
      <Typography
        ref={ref}
        lineHeight={1.2}
        fontWeight={fontWeight}
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
        }}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};

export default NewsCardText;
