import GlobalAxios from "../../../axios/axios-config";
import { ISelectType } from "../../../components/UI/Inputs/AutoComplete";
import {
  ICreateFamilyMember,
  IFamilyMember,
  IUpdateFamilyMember,
} from "./model";

const FAMILY_GROUP_ROUTE =
  process.env.REACT_APP_CALENDAR + "/api/v1/FamilyGroup";

const KINSHIP_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/KinshipDegree/list/all";

const GENDER_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/list";

export class FamilyGroupService {
  public static get = () => {
    return GlobalAxios.get<IFamilyMember[]>(FAMILY_GROUP_ROUTE);
  };

  public static getById = (id: string) => {
    return GlobalAxios.get<IFamilyMember>(FAMILY_GROUP_ROUTE + `/${id}`);
  };

  public static delete = (id: string) => {
    return GlobalAxios.delete(FAMILY_GROUP_ROUTE + `/${id}`);
  };

  public static getKinship = () => {
    return GlobalAxios.get<ISelectType[]>(KINSHIP_ROUTE);
  };

  public static create = (props: ICreateFamilyMember) => {
    return GlobalAxios.post<{ id: string }>(FAMILY_GROUP_ROUTE, props);
  };

  public static update = (props: IUpdateFamilyMember) => {
    return GlobalAxios.put<{ id: string }>(
      FAMILY_GROUP_ROUTE + `/${props.id}`,
      props
    );
  };

  public static getGenderOpts = () => {
    return GlobalAxios.get<ISelectType[]>(GENDER_ROUTE, {
      params: {
        Identifier: "gender",
      },
    });
  };
}
