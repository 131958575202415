import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IDisplayPlanProps {
  planName: string;
}

const translationPath = "components.displayPlan.";

const DisplayPlan = ({ planName }: IDisplayPlanProps) => {
  const { t } = useTranslation();

  return (
    <Stack direction={"row"} spacing={1}>
      <Typography
        variant="h6"
        sx={(theme) => ({
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[600]
              : theme.palette.grey[400],
        })}
      >
        {t(translationPath + "plan")}:
      </Typography>
      <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
        {planName}
      </Typography>
    </Stack>
  );
};

export default DisplayPlan;
