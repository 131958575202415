import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import { tokenTemporaryService } from "../../../../services/tokenTemorary.service";

const translationPath = "components.menu.";

interface IMenuUserActions {
  toggleMenu: (open: boolean) => void;
  openModalHandler: () => void;
}

const MenuUserActions = ({
  toggleMenu,
  openModalHandler,
}: IMenuUserActions) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isTemporaryUser = tokenTemporaryService.hasTemporaryAccessToken();

  return (
    <Stack direction={"row"} spacing={2} mx={2} mb={4}>
      {!isTemporaryUser && (
        <Button
          onClick={() => {
            navigate("/change-password");
            toggleMenu(false);
          }}
          sx={(theme) => ({
            backgroundColor:
              theme.palette.mode === "light"
                ? theme.palette.grey[300]
                : theme.palette.grey[700],
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[800]
                : theme.palette.grey[300],
            height: 50,
            borderRadius: 2,
            width: "100%",
            textTransform: "capitalize",
          })}
          startIcon={<LockIcon />}
        >
          <Typography>{t(translationPath + "change_password")}</Typography>
        </Button>
      )}
      <Button
        onClick={() => {
          toggleMenu(false);
          openModalHandler();
        }}
        sx={(theme) => ({
          backgroundColor:
            theme.palette.mode === "light"
              ? theme.palette.grey[300]
              : theme.palette.grey[700],
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[800]
              : theme.palette.grey[300],
          height: 50,
          borderRadius: 2,
          width: "100%",
          textTransform: "capitalize",
        })}
        startIcon={<LanguageIcon />}
      >
        <Typography>{t(translationPath + "change_language")}</Typography>
      </Button>
    </Stack>
  );
};

export default MenuUserActions;
