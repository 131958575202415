import GlobalAxios from "../axios/axios-config";
import { ICheckInParams, ICheckInResponse } from "../models/check-in";

const BASE_URL = process.env.REACT_APP_CALENDAR + "/api/v1/tasy/";

export const checkInExam = (params: ICheckInParams) => {
  return GlobalAxios.put<ICheckInResponse>(
    BASE_URL + "CalendarExamCheckin",
    params
  );
};

export const checkInConsultation = (params: ICheckInParams) => {
  return GlobalAxios.put<ICheckInResponse>(
    BASE_URL + "CalendarConsultationCheckin",
    params
  );
};
