import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Stack, Typography } from "@mui/material";

import ContentAditionalInformations from "../../Content/ContentAditionalInformations";
import ContentTags from "../../Content/ContentTags";
import useDate from "../../../hooks/useDate";
import { IContentBase } from "../../../models/content";
import { useAppSelector } from "../../../store/store";

interface IHealthProgramContentItemProps {
  content: IContentBase;
}

const HealthProgramContentItem: React.FC<IHealthProgramContentItemProps> = ({
  content,
}) => {
  const navigate = useNavigate();

  const { dayjs, validateDate } = useDate();

  const actualLang = useAppSelector((state) => state.language.languageId);

  const aux = content.contents[0];

  const img = content.contentMediaFiles[0];

  const src = img.cdnDomain + "/" + img.filePath;

  const onClickHandler = () => {
    navigate(content.id);
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems={"center"}
      sx={(t) => ({
        cursor: "pointer",
        "&:hover": {
          opacity: 0.8,
        },
      })}
      onClick={onClickHandler}
    >
      <Grid
        item
        xs={12}
        xl={3}
        sx={(t) => ({
          [t.breakpoints.down("xl")]: {
            order: 2,
          },
        })}
      >
        <img
          style={{
            borderRadius: 6,
            width: "100%",
            height: "100%",
          }}
          src={src}
          key={img.id}
          alt={
            img.contentMediaFileCaptions.find(
              (cap) => cap.language.id === actualLang
            )?.caption || ""
          }
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        xl={7}
        sx={(t) => ({
          [t.breakpoints.down("xl")]: {
            order: 1,
          },
        })}
      >
        <Stack gap={1} width={"100%"}>
          <ContentTags tags={aux.contentTags} />
          <Typography variant="h6" fontWeight={"bold"}>
            {aux.title}
          </Typography>
          <ContentAditionalInformations
            publicationDate={validateDate(
              dayjs(content.publicationDate),
              "L LT"
            )}
            readingTime={content.readingTime}
          />
          <Typography
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              textOverflow: "ellipsis",
            }}
            variant="body2"
          >
            {aux.summary}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default memo(HealthProgramContentItem);
