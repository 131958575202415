import { Typography, TypographyProps } from "@mui/material";
import useDate from "../hooks/useDate";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

interface IDisplayDate {
  children: dayjs.Dayjs;
  typography?: TypographyProps;
  format?: string;
}

const DisplayDate = ({ children, typography, format }: IDisplayDate) => {
  const { validateDate } = useDate();
  return (
    <Typography {...typography}>
      {validateDate(children, format ? format : "L")}
    </Typography>
  );
};

export default DisplayDate;
