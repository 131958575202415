import { memo } from "react";
import { IProcedureTypeProps } from "./models";
import { useTranslation } from "react-i18next";
import ToggleButtonGroupUncontrolled from "../../UI/ToggleButtonGroupUncontrolled/ToggleButtonGroupUncontrolled";
import { IToggleButtonGroupOption } from "../../UI/ToggleButtonGroupUncontrolled/models";

const translationPath = "components.procedureType.";

const ProcedureType = ({
  procedureType,
  changeProcedureType,
}: IProcedureTypeProps) => {
  const { t } = useTranslation();

  const procedureTypes: IToggleButtonGroupOption[] = [
    { label: t(translationPath + "all"), value: "all" },
    { label: t(translationPath + "laboratorial"), value: "LABORATORIAL" },
    {
      label: t(translationPath + "no_laboratorial"),
      value: "NAO_LABORATORIAL",
    },
  ];

  return (
    <ToggleButtonGroupUncontrolled
      value={procedureType}
      options={procedureTypes}
      onChange={(_, value) => changeProcedureType(value)}
    />
  );
};

export default memo(ProcedureType);
