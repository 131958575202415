import { Stack, SvgIcon, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface ILegendsModalItemProps {
  component: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  subtitle: string;
  fontSize?: "small" | "inherit" | "large" | "medium";
  marginTop?: number;
}

const LegendsModalItem = ({
  component,
  subtitle,
  fontSize = "large",
  marginTop,
}: ILegendsModalItemProps) => {
  return (
    <Stack
      minWidth={280}
      flexDirection={"row"}
      alignItems={"center"}
      height={40}
    >
      <Stack
        width={50}
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <SvgIcon
          component={component}
          fontSize={fontSize}
          sx={{ mt: marginTop }}
          inheritViewBox
        />
      </Stack>
      <Typography lineHeight={1.2} variant="subtitle2">
        {subtitle}
      </Typography>
    </Stack>
  );
};

export default LegendsModalItem;
