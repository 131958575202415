import { memo } from "react";
import { IContentsHealthProgram } from "../../../models/health-programs";
import { Box, Stack, Typography } from "@mui/material";
import { useAppSelector } from "../../../store/store";

interface IHealthProgramItemContentProps {
  content: IContentsHealthProgram;
  onClick: (id: string) => void;
}

const HealthProgramItemContent: React.FC<IHealthProgramItemContentProps> = ({
  content,
  onClick,
}) => {
  const item = content.contentMediaFiles[0];

  const { favicon } = useAppSelector((state) => state.theme);

  const uri = item ? item.cdnDomain + "/" + item.filePath : undefined;

  const onClickHandler = () => {
    onClick(content.content.id);
  };
  
  return (
    <Box
      onClick={onClickHandler}
      sx={{
        flexShrink: 0,
        backgroundImage: `url(${uri ? uri : favicon})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: uri ? "cover" : "contain",
        backgroundPosition: "center",
        width: 300,
        height: 200,
        cursor: "pointer",
        "&:hover": {
          opacity: 0.8,
        },
      }}
    >
      <Box
        sx={{
          background:
            "linear-gradient(0deg, rgba(0,0,0,0.4) 60%, rgba(0,0,0,0) 100%)",
        }}
        width={"100%"}
        height={"100%"}
      >
        <Stack
          justifyContent={"flex-end"}
          height={"100%"}
          width={"100%"}
          padding={1}
        >
          <Typography
            variant="h6"
            fontWeight={"bold"}
            color={"white"}
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
            }}
          >
            {content.content.title}
          </Typography>
          <Typography
            color={"white"}
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
            }}
          >
            {content.content.summary}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default memo(HealthProgramItemContent);
