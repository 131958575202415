import { IconButton, Stack, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useCallback, useEffect, useState } from "react";
import MicIcon from "@mui/icons-material/Mic";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Form } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ISearchVoiceProps } from "./models";

const SearchVoice = ({ onSearch, label, placeholder }: ISearchVoiceProps) => {
  const [search, setSearch] = useState("");
  const { i18n } = useTranslation();

  const {
    listening,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
    finalTranscript,
    resetTranscript,
  } = useSpeechRecognition();

  const searchHandler = useCallback(() => {
    if (finalTranscript) {
      return onSearch(finalTranscript);
    }
    onSearch(search);
  }, [onSearch, search, finalTranscript]);

  const canUseMic = browserSupportsSpeechRecognition && isMicrophoneAvailable;

  useEffect(() => {
    if (finalTranscript) {
      setTimeout(() => {
        searchHandler();
      }, 500);
    }
  }, [searchHandler, finalTranscript]);

  return (
    <Form onSubmit={searchHandler}>
      <Stack
        position={"relative"}
        height={50}
        flexDirection={"row"}
        alignItems={"center"}
      >
        <TextField
          value={search ? search : finalTranscript ? finalTranscript : ""}
          size="small"
          label={label}
          placeholder={placeholder}
          fullWidth
          onChange={(e) => {
            resetTranscript();
            if (e.target.value.length === 0) {
              onSearch("");
            }
            setSearch(e.target.value);
          }}
        />
        <Stack position={"absolute"} right={-10} zIndex={10}>
          <IconButton
            type="submit"
            size="large"
            sx={(t) => ({
              backgroundColor:
                t.palette.mode === "light"
                  ? t.palette.primary.light
                  : t.palette.primary.dark,
              color: listening ? t.palette.error.light : "white",
              "&:hover": {
                backgroundColor:
                  t.palette.mode === "light"
                    ? t.palette.primary.dark
                    : t.palette.primary.light,
              },
            })}
            onClick={() =>
              search.length === 0 && !listening && canUseMic
                ? SpeechRecognition.startListening({
                    continuous: true,
                    language: i18n.language,
                  })
                : listening
                ? SpeechRecognition.stopListening()
                : search.length !== 0
                ? searchHandler
                : undefined
            }
          >
            {search.length === 0 && canUseMic ? <MicIcon /> : <SearchIcon />}
          </IconButton>
        </Stack>
      </Stack>
    </Form>
  );
};

export default SearchVoice;
