import {
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCallback, useState } from "react";
import MedicCardShareForm from "./MedicCardShareForm";
import { IMedicCard } from "../../models/medic-card";
import ModalComponent from "../UI/Modal";
import { TMedicShareForm } from "./MedicCardShareSchema";
import { useDispatch } from "react-redux";
import useFetch from "../../hooks/useFetch";
import CardService from "../../services/card.service";
import { notificationSliceActions } from "../../store/slices/notification";
import BeneficiaryCardModal from "./BeneficiaryCardModal";
import PaperComponent from "../UI/PaperComponent";

interface IBeneficiaryCardProps {
  card: IMedicCard;
  onSubmit: () => void;
  canShare?: boolean;
}

const translationPath = "page.cards.";

const cardService = new CardService();

const BeneficiaryCard = ({
  card,
  onSubmit,
  canShare = true,
}: IBeneficiaryCardProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [openCard, setOpenCard] = useState(false);

  const { sendRequest: share, loading } = useFetch(cardService.create);
  const { sendRequest: remove, error: deleteError } = useFetch(
    cardService.deleteItemById
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseCard = () => {
    setOpenCard(false);
  };

  const handleOpenCard = () => {
    setOpenCard(true);
  };

  const onSubmitHandler = useCallback(
    async (values: TMedicShareForm) => {
      const { success } = await share(values);
      if (success) {
        dispatch(
          notificationSliceActions.showNotification({
            message: t("components.medicCardShare.success"),
            type: "success",
          })
        );
        handleClose();
        onSubmit();
      }
    },
    [dispatch, share, t, onSubmit]
  );

  const onDeleteHandler = useCallback(
    async (sharedId: string, name: string) => {
      await remove(sharedId);
      if (!deleteError) {
        dispatch(
          notificationSliceActions.showNotification({
            message: t("components.medicCardShare.deleted", { name: name }),
            type: "success",
          })
        );
        onSubmit();
      }
    },
    [dispatch, remove, deleteError, onSubmit, t]
  );

  return (
    <>
      <ModalComponent open={open} onClose={handleClose} dialogMaxWidth={"md"}>
        <MedicCardShareForm
          card={card}
          onClose={handleClose}
          loading={loading}
          onSubmit={onSubmitHandler}
        />
      </ModalComponent>

      <BeneficiaryCardModal
        card={card}
        handleCloseCard={handleCloseCard}
        openCard={openCard}
      />

      <PaperComponent sx={{ minWidth: 300, maxWidth: 800 }}>
        <Grid container alignItems={"center"} sx={{ p: 2 }}>
          <Grid item xs={6} sm={9} md={9}>
            <Typography variant="button" sx={{ fontWeight: 700, fontSize: 16 }}>
              {card.individual.name}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: 16 }}>
              {card.cardCode}
            </Typography>
          </Grid>
          <Grid item container xs={6} sm={3} md={3}>
            <Stack
              direction={"column"}
              spacing={1}
              alignItems={"end"}
              width={"100%"}
            >
              <Button variant="contained" size="small" onClick={handleOpenCard}>
                {t(translationPath + "view_card")}
              </Button>
              {canShare && (
                <Button variant="text" size="small" onClick={handleOpen}>
                  {t(translationPath + "share_card")}
                </Button>
              )}
            </Stack>
          </Grid>

          {card.individualBeneficiaryShareCards &&
            card.individualBeneficiaryShareCards.length >= 1 && (
              <>
                <Divider sx={{ width: "100%", my: 2 }} />
                <Typography variant="subtitle1" mb={2}>
                  {t("components.beneficiaryCard.share")}
                </Typography>
                <Grid container direction={"column"} spacing={2}>
                  {card.individualBeneficiaryShareCards.map((shared) => (
                    <Grid key={shared.document} item sm={12}>
                      <Grid container alignItems={"center"}>
                        <Grid item xs={2} sm={1}>
                          <Avatar>{shared.name.substring(0, 1)}</Avatar>
                        </Grid>
                        <Grid item xs={5} sm={8}>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: 700 }}
                          >
                            {shared.name}
                          </Typography>
                          <Typography variant="subtitle1">
                            {shared.document}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={5}
                          sm={3}
                          display={"flex"}
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"end"}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: 700, marginRight: 1 }}
                          >
                            {shared.shareCode}
                          </Typography>
                          <IconButton
                            aria-label="delete"
                            onClick={() =>
                              onDeleteHandler(shared.id, shared.name)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
        </Grid>
      </PaperComponent>
    </>
  );
};

export default BeneficiaryCard;
