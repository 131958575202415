import { Button, IconButton } from "@mui/material";
import { To, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { memo } from "react";
interface IBackButtonProps {
  iconButton?: boolean;
  redirectTo?: To;
  onClick?: () => void;
}

const BackButton = ({
  iconButton = false,
  redirectTo,
  onClick,
}: IBackButtonProps) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const onClickHandler = () => {
    if (onClick) {
      return onClick();
    }

    if (redirectTo) {
      return navigate(redirectTo);
    }

    navigate(-1);
  };

  if (!iconButton) {
    return (
      <Button
        variant="text"
        size="small"
        color="inherit"
        startIcon={<ArrowBackIcon />}
        onClick={onClickHandler}
      >
        {t("components.backButton")}
      </Button>
    );
  }

  return (
    <IconButton size="small" onClick={onClickHandler} sx={{ padding: 0 }}>
      <ArrowBackIcon
        sx={(theme) => ({
          color: theme.palette.text.secondary,
        })}
        fontSize="small"
      />
    </IconButton>
  );
};

export default memo(BackButton);
