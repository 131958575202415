import React, { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Stack } from "@mui/material";

import { IAvaiableSchedule } from "../../../model";
import FormErrorMessage from "../../../../../components/UI/FormErrorMessage";
import ScheduleItem from "./ScheduleItem";
import Loading from "../../../../../components/Loading";

export interface ISelectHour {
  id: string;
  hour: string;
}

interface ISchedulesListProps {
  schedules: IAvaiableSchedule[];
  name: string;
  loading?: boolean;
}

export const SchedulesList: React.FC<ISchedulesListProps> = memo(
  ({ schedules, name, loading }) => {
    const { control } = useFormContext();

    if (loading) {
      return (
        <Stack alignItems={"center"} justifyContent={"center"} height={"100%"}>
          <Loading />
        </Stack>
      );
    }

    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <Stack>
              <Stack gap={1}>
                {schedules.map((s) => (
                  <ScheduleItem
                    key={s.identifier}
                    data={s}
                    selectedHour={value}
                    onSelectedHour={onChange}
                  />
                ))}
              </Stack>
              {error && error.message && (
                <FormErrorMessage message={error.message} />
              )}
            </Stack>
          );
        }}
      />
    );
  }
);
