import { Divider, Grid, Stack, Typography } from "@mui/material";
import RefundsService from "../../../services/refunds.service";
import useFetch from "../../../hooks/useFetch";
import { IBeneficiaries } from "../../../models/topmed";
import { useCallback, useEffect, useState } from "react";
import EmergencyCareCard from "../../../components/VirtualEmergencyCare/EmergencyCareCard";
import { useTranslation } from "react-i18next";
import { useStep } from "../../../store/contexts/StepContext";

const refundsService = new RefundsService();

const translationPath = "components.refundSelectBeneficiary.";

const RefundSelectBeneficiary = ({
  onSelectBeneficiary,
}: {
  onSelectBeneficiary: (
    name: string,
    individualBeneficiaryId: string,
    individualId: string
  ) => void;
}) => {
  const { t } = useTranslation();
  const [beneficiaries, setBeneficiaries] = useState<IBeneficiaries>();
  const { nextStep } = useStep();

  const { sendRequest } = useFetch(refundsService.list);

  const loadBeneficiaries = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (success) {
      setBeneficiaries(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    loadBeneficiaries();
  }, [loadBeneficiaries]);

  const selectBeneficiaryHandler = (
    name: string,
    individualBeneficiaryId: string,
    individualId: string
  ) => {
    onSelectBeneficiary(name, individualBeneficiaryId, individualId);
    nextStep();
  };

  return (
    <>
      {beneficiaries && (
        <Grid container display={"flex"} flexDirection={"column"} gap={2}>
          <Grid item xs={12} sm={8} md={8} xl={8} mt={2}>
            <EmergencyCareCard
              padding={1}
              beneficiary={beneficiaries.currentUser}
              isLoadingRegister={false}
              onClick={() =>
                selectBeneficiaryHandler(
                  beneficiaries.currentUser.name,
                  beneficiaries.currentUser.individualBeneficiaryIntegrationId,
                  beneficiaries.currentUser.beneficiaryId
                )
              }
            />
          </Grid>
          {beneficiaries.dependents.length !== 0 && (
            <>
              <Divider />
              <Grid item sm={12} mt={2}>
                <Typography variant="h6" mb={1}>
                  {t(translationPath + "dependents")}
                </Typography>
                <Stack
                  display={"flex"}
                  flexDirection={"row"}
                  flexWrap={"wrap"}
                  gap={2}
                >
                  {beneficiaries.dependents.map((dependent) => (
                    <EmergencyCareCard
                      beneficiary={dependent}
                      isLoadingRegister={false}
                      onClick={() =>
                        selectBeneficiaryHandler(
                          dependent.name,
                          dependent.individualBeneficiaryIntegrationId,
                          dependent.beneficiaryId
                        )
                      }
                      key={dependent.beneficiaryId}
                    />
                  ))}
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default RefundSelectBeneficiary;
