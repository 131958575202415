import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EventIcon from "@mui/icons-material/Event";

const translationPath = "page.news.";

interface IContentAditionalInformationsProps {
  readingTime?: number;
  publicationDate: string;
}

const ContentAditionalInformations = ({
  publicationDate,
  readingTime,
}: IContentAditionalInformationsProps) => {
  const { t } = useTranslation();

  const theme = useTheme();
  
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      display={"flex"}
      flexDirection={matches ? "column" : "row"}
      alignItems={matches ? "start" : "center"}
      gap={matches ? 0.5 : 2}
      sx={(t) => ({
        color:
          t.palette.mode === "light"
            ? t.palette.grey[700]
            : t.palette.grey[500],
      })}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        gap={0.5}
      >
        <AccessTimeIcon fontSize="small" />
        <Typography variant="caption">
          {t(translationPath + "reading_time", {
            readingTime: readingTime,
          })}
        </Typography>
      </Box>

      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        gap={0.5}
      >
        <EventIcon fontSize="small" />
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          gap={0.5}
        >
          <Typography variant="caption">
            {t(translationPath + "publication_date")}
          </Typography>
          <Typography variant="caption">{publicationDate}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ContentAditionalInformations;
