import { AlertColor } from "@mui/material";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { INotification, SnackbarMessage } from "../../models/notification";

const NOTIFICATION_INITIAL_STATE: INotification = {
  open: false,
};

export const slice = createSlice({
  name: "notification",
  initialState: NOTIFICATION_INITIAL_STATE,
  reducers: {
    showNotification(
      state: INotification,
      action: PayloadAction<{
        message: string;
        type: AlertColor;
        translate?: boolean;
      }>
    ) {
      const message: SnackbarMessage = {
        message: action.payload.message,
        type: action.payload.type,
        translate: action.payload.translate,
        key: new Date().getTime(),
      };

      state = {
        open: true,
        messageInfo: message,
      };

      return state;
    },
    closeNotification(state: INotification) {
      return NOTIFICATION_INITIAL_STATE;
    },
  },
});

export const notificationSliceActions = slice.actions;

export default slice.reducer;
