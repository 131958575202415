import { Grid, Typography } from "@mui/material";
import FormInput from "../../../../components/UI/Inputs/FormInput";
import { useTranslation } from "react-i18next";
import FormCheckBox from "../../../../components/UI/Inputs/FormCheckBox";
import { useFormContext, useWatch } from "react-hook-form";
import { useCallback, useEffect } from "react";
import { useStep } from "../../../../store/contexts/StepContext";
import RefundsBankData from "../../../../components/Refunds/RefundsBankData";
import RefundStepContainer from "../RefundStepContainer";
import RefundSecondBankData from "./RefundSecondBankData";

const translationPath = "page.refundsPage.refund.";

const RefundSecond = ({ beneficiaryId }: { beneficiaryId: string }) => {
  const { t } = useTranslation();
  const { control, setValue, formState, getValues } = useFormContext();
  const { onError, onRemoveError } = useStep();

  const isChecked = useWatch({
    name: "otherBankData",
    control,
  });

  const bankData = useWatch({
    name: "bankData",
    control,
  });

  useEffect(() => {
    if (!isChecked) return;
    if (isChecked) {
      setValue("bankData", null);
    }
  }, [isChecked, setValue]);

  useEffect(() => {
    const values = getValues();
    const fields = Object.keys(values).splice(8);
    if (fields.some((field) => formState.errors[field])) {
      onError(2);
    } else {
      onRemoveError(2);
    }
  }, [formState.errors, onError, onRemoveError, getValues]);

  const cleanData = useCallback(() => {
    setValue("holderBankAccountName", null);
    setValue("holderDocument", null);
    setValue("holderBirthDate", null);
    setValue("bank", null);
    setValue("bankBranch", null);
    setValue("bankAccount", null);
    setValue("bankAccountDigit", null);
  }, [setValue]);

  useEffect(() => {
    if (isChecked) {
      cleanData();
    }
    if (!isChecked && !bankData) {
      cleanData();
    }
  }, [cleanData, isChecked, bankData]);

  return (
    <RefundStepContainer>
      <Grid container columnSpacing={2} mt={2}>
        <RefundsBankData isChecked={isChecked} beneficiaryId={beneficiaryId} />

        <Grid item xs={12} sm={12} mb={2}>
          <FormCheckBox
            name="otherBankData"
            label={`${t(translationPath + "bank_data_no_found")}`}
          />
        </Grid>

        <RefundSecondBankData isChecked={isChecked} />

        <Grid item xs={12} sm={12} mb={1}>
          <Typography variant="h6">
            {t(translationPath + "contacts")}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormInput
            size="small"
            name="phoneContact"
            fullWidth
            label={t(translationPath + "phone_contact")}
            sx={{ mb: 2 }}
            mask={"(00)000000000"}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormInput
            size="small"
            name="mailContact"
            fullWidth
            label={t(translationPath + "mail_contact")}
            sx={{ mb: 2 }}
          />
        </Grid>
      </Grid>
    </RefundStepContainer>
  );
};

export default RefundSecond;
