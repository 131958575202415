import { Box, Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStep } from "../../../store/contexts/StepContext";

const translationPath = "components.stepperActions.";

export interface ILastButtonProps {
  label: string;
  type: "button" | "reset" | "submit";
  function?: () => void;
}

const StepperActions = ({
  stepsLength,
  lastButton,
  showFirstButton = true,
  loading,
}: {
  stepsLength: number;
  lastButton?: ILastButtonProps;
  showFirstButton?: boolean;
  loading?: boolean;
}) => {
  const { activeStep, nextStep, backStep } = useStep();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        paddingBlock: 2,
      }}
    >
      {activeStep >= 1 && (
        <Button
          color="primary"
          onClick={backStep}
          variant="outlined"
          size="small"
        >
          {t(translationPath + "back")}
        </Button>
      )}
      <Box sx={{ flex: "1 1 auto" }} />
      {showFirstButton && (
        <Button onClick={nextStep} variant="contained" size="small">
          {t(translationPath + "next")}
        </Button>
      )}
      {activeStep !== stepsLength - 1 && activeStep !== 0 && (
        <Button
          onClick={nextStep}
          variant="contained"
          size="small"
          disabled={loading}
        >
          {t(translationPath + "next")}
        </Button>
      )}
      {activeStep === stepsLength - 1 && lastButton && (
        <Button
          variant="contained"
          size="small"
          type={lastButton.type}
          onClick={lastButton.function}
          disabled={loading}
        >
          {loading && <CircularProgress size={15} />}
          {!loading && lastButton.label}
        </Button>
      )}
    </Box>
  );
};

export default StepperActions;
