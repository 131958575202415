import { SxProps } from "@mui/material";
import { FC } from "react";
import { Theme } from "@emotion/react";

import PaperComponent from "./PaperComponent";

const Card: FC<{ children: React.ReactNode; sx?: SxProps<Theme> }> = ({
  children,
  sx,
}) => {
  return (
    <PaperComponent
      sx={[
        (theme) => ({
          backgroundColor:
            theme.palette.mode === "dark" ? "#1E1E1E" : "#f4f4f4",
          padding: 2,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
        {children}
    </PaperComponent>
  );
};

export default Card;
