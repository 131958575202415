import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IDocumentAcceptance } from "../models/document-acceptance";
import { useAppDispatch, useAppSelector } from "../store/store";
import useFetch from "./useFetch";
import DocAcceptanceService from "../services/docAcceptance.service";
import { tokenService } from "../services/token.service";
import { notificationSliceActions } from "../store/slices/notification";
import { userService } from "../services/user.service";
import { userSliceActions } from "../store/slices/user";
import { privilegeSliceActions } from "../store/slices/privilege";

const docAcceptanceService = new DocAcceptanceService();

const useDocumentAcceptence = () => {
  const [open, setOpen] = useState(false);
  const [acceptDocuments, setAcceptDocuments] = useState<IDocumentAcceptance[]>(
    []
  );
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [newDocuments, setNewDocuments] = useState<IDocumentAcceptance[]>([]);
  const getAccepted = localStorage.getItem("document_accepted");
  const { sendRequest: documentsAll } = useFetch(docAcceptanceService.getAll);
  const { sendRequest: documentsList } = useFetch(docAcceptanceService.list);
  const { removeUserName } = userService;
  const { removeAllTokens } = tokenService;
  const navigate = useNavigate();

  const actualLang = useAppSelector((state) => state.language.languageId);

  const loadDocAcceptances = useCallback(async () => {
    if (!actualLang) return;
    const data = await documentsAll({ LanguageId: actualLang });
    if (data.data) {
      setAcceptDocuments(data.data);
    }
  }, [documentsAll, actualLang]);

  const onCheckDocs = useCallback(() => {
    const documentAccepted = JSON.parse(
      localStorage.getItem("document_accepted")!
    );

    if (!tokenService.hasAccessTokens() && !documentAccepted && acceptDocuments.length >= 1) {
      setOpen(true);
    }

    if (!tokenService.hasAccessTokens() && documentAccepted) {
      if (documentAccepted.length >= 1) {
        if (documentAccepted.length !== acceptDocuments.length) {
          const newDocuments = acceptDocuments.filter(
            (objeto2: IDocumentAcceptance) => {
              return !documentAccepted.some(
                (objeto1: IDocumentAcceptance) =>
                  objeto1.docAcceptanceId === objeto2.docAcceptanceId
              );
            }
          );
          setNewDocuments(newDocuments);
        }
        if (documentAccepted.length === acceptDocuments.length) {
          setOpen(false);
        }
      }
    }
  }, [acceptDocuments]);

  const onAcceptDocuments = () => {
    if (!tokenService.hasAccessTokens()) {
      localStorage.setItem(
        "document_accepted",
        JSON.stringify(acceptDocuments)
      );
      dispatch(
        notificationSliceActions.showNotification({
          message: `${t("components.documentAcceptance.document_accepted")}`,
          type: "success",
        })
      );
    }
    setOpen(false);
  };

  const logout = useCallback(() => {
    removeAllTokens();
    removeUserName();
    dispatch(userSliceActions.removeUser());
    dispatch(privilegeSliceActions.removePrivilege());
    navigate("/login", { replace: true });
    dispatch(
      notificationSliceActions.showNotification({
        message: `${t("components.documentAcceptance.need_accept")}`,
        type: "error",
      })
    );
  }, [navigate, dispatch, removeAllTokens, removeUserName, t]);

  const onCloseHandler = () => {
    if (tokenService.hasAccessTokens()) {
      logout();
      setOpen(false);
    }
  };

  const visitDocAcceptances = useCallback(async () => {
    if (!actualLang) return;
    const data = await documentsList({
      LanguageId: actualLang,
    });
    if (data.data) {
      setAcceptDocuments(data.data);
    }
  }, [documentsList, actualLang]);

  const newDocumentsLength = newDocuments.length;
  const aceeptDocumentsLength = acceptDocuments.length;

  useEffect(() => {
    if (tokenService.hasAccessTokens()) {
      console.log('epa',tokenService.hasAccessTokens())
      loadDocAcceptances();
      if (aceeptDocumentsLength >= 1) {
        setOpen(true);
      }
    } else {
      visitDocAcceptances();
      if (!getAccepted) {
        visitDocAcceptances();
      }
      if (newDocumentsLength >= 1) {
        setOpen(true);
      }
    }
  }, [
    getAccepted,
    visitDocAcceptances,
    loadDocAcceptances,
    newDocumentsLength,
    aceeptDocumentsLength,
  ]);

  return {
    onCheckDocs,
    onCloseHandler,
    onAcceptDocuments,
    open,
    newDocuments,
    acceptDocuments,
  };
};

export default useDocumentAcceptence;
