import "./App.css";
import { ConfirmProvider } from "material-ui-confirm";
import { pdfjs } from "react-pdf";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import {
  AuthContextProvider,
  useNotificationContext,
} from "@4uhub/lib4uhub";
import { useCallback } from "react";

import GlobalAxios, { InterceptorRefresh } from "./axios/axios-config";
import EnvironmentType from "./components/EnvironmentType";
import InstalledExtensionContainer from "./components/InstalledExtensionsContainer";
import SiteAppTheme from "./components/SiteAppProvider";
import Notification from "./components/UI/Notification";
import routes from "./routes";
import { BannersProvider } from "./store/contexts/BannersContext/BannersProvider";
import { NotificationsUserProvider } from "./store/contexts/NotificationsUserContext";
import store from "./store/store";

export const router = createBrowserRouter(routes);

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function App() {
  const { setMessage } = useNotificationContext();

  const logoutUser = useCallback(() => {
    // removeUserData();
    router.navigate("/login");
    setMessage({
      message: "Login expirado",
      type: "error",
    });
  }, [setMessage]);

  return (
    <Provider store={store}>
      <AuthContextProvider
        onLogoutUser={logoutUser}
        refreshToken={InterceptorRefresh}
        axios={GlobalAxios}
        identityApiPath={process.env.REACT_APP_IDENTIY!}
        mainApiPath={process.env.REACT_APP_MAIN!}
      >
        <SiteAppTheme>
          <InstalledExtensionContainer>
            <NotificationsUserProvider>
              <ConfirmProvider>
                <EnvironmentType />
                <BannersProvider>
                  <RouterProvider router={router} />
                </BannersProvider>
                <Notification />
              </ConfirmProvider>
            </NotificationsUserProvider>
          </InstalledExtensionContainer>
        </SiteAppTheme>
      </AuthContextProvider>
    </Provider>
  );
}

/*
const siteItamed: TTheme & ThemeOptions = {
  logo: "/assets/ITAMED_LOGO_1.png",
  frontBackgroundColor: "#DCE8D0",
  frontSecondBackgroundColor: "#5995A1",
  backBackgroundColor: "#ffffff",
  backTargeColor: "#4B607D",
  favicon: "/assets/ITAMED_LOGO_2.png",
  name: "Itamed",
  mode: "1",
  menuOpen: true,
  palette: {
    primary: {
      main: "#0A79BE",
    },
  },
  isLoginCpf: true,
  description: "Teste drescrição itamed",
};

const siteSpotify: TTheme & ThemeOptions = {
  mode: "1",
  backTargeColor: "#115606",
  backBackgroundColor: "#4baa36",
  frontBackgroundColor: "#4baa36",
  menuOpen: false,
  name: "Spotify",
  logo: "https://logosmarcas.net/wp-content/uploads/2020/09/Spotify-Logo.png",
  favicon:
    "https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Spotify_logo_without_text.svg/2048px-Spotify_logo_without_text.svg.png",
  palette: {
    primary: {
      main: "#1ed760",
    },
  },
  shape: {
    borderRadius: 0,
  },
  description: "Teste drescrição itamed",
};
*/

export default App;
