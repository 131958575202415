import { Box } from "@mui/material";
import { memo } from "react";
import { OverflowTooltip } from "../../UI/TooltipOverflow";

interface IContentGallerySubtitleProps {
  alt: string;
}

const ContentGallerySubtitle:React.FC<IContentGallerySubtitleProps> = ({alt}) => {
  return (
    <Box
      sx={{
        px: 1,
        pb: 0.5,
        width: "100%",
        bottom: 0,
        position: "absolute",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        textAlign: "start",
        color: "white",
        borderRadius: "0 0 6px 6px",
      }}
    >
      <OverflowTooltip text={alt} tooltip={alt} />
    </Box>
  );
};

export default memo(ContentGallerySubtitle);
