import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { ReactNode } from "react";
import PaperComponent from "./PaperComponent";

interface ICardsProps {
  width?: number | string;
  children: ReactNode;
}

const Cards = ({ children, width }: ICardsProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <PaperComponent
      sx={!matches ? { width: "100%" } : { width: width ? width : 800 }}
    >
      <Grid container alignItems={"center"} sx={{ p: 2 }}>
        {children}
      </Grid>
    </PaperComponent>
  );
};

export default Cards;
