import { Box, Dialog, IconButton } from "@mui/material";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { Carousel } from "react-responsive-carousel";
import { memo } from "react";

import { IContentMediaFile } from "../../../models/content";
import ContentGallerySubtitle from "./ContentGallerySubtitle";
import { useAppSelector } from "../../../store/store";

export interface IContentImgFullScreenProps {
  newsImages: IContentMediaFile[];
  index: number;
  open: boolean;
  onChange: (index: number) => void;
  onClose?: () => void;
}

const ContentImgFullScreen = ({
  onClose,
  onChange,
  newsImages,
  open,
  index,
}: IContentImgFullScreenProps) => {
  const actualLang = useAppSelector((state) => state.language.languageId);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"xl"}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <Box
          height={"100%"}
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          position={"relative"}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 10,
              boxShadow: 1,
            }}
            onClick={onClose}
          >
            <FullscreenExitIcon sx={{ color: "white" }} />
          </IconButton>
          <Carousel
            showThumbs={false}
            showStatus={false}
            selectedItem={index}
            onChange={onChange}
            dynamicHeight
          >
            {newsImages.map((image) => {
              const src = image.cdnDomain + "/" + image.filePath;

              const alt = image.contentMediaFileCaptions.find(
                (cap) => cap.language.id === actualLang
              )?.caption;

              return (
                <Box key={image.id}>
                  <img
                    src={src}
                    alt={alt}
                    style={{
                      width: "100%",
                    }}
                  />
                  {alt && <ContentGallerySubtitle alt={alt} />}
                </Box>
              );
            })}
          </Carousel>
        </Box>
      </div>
    </Dialog>
  );
};

export default memo(ContentImgFullScreen);
