import GlobalAxios from "../axios/axios-config";
import { IGetUserConfig, IUpdateUserConfig } from "../models/configurations";

const CREATE_ROUTE =
  process.env.REACT_APP_IDENTIY +
  "/api/v1/UserConfigurations/CreateUpdateUserConfigurationPortal";

const GET_ROUTE =
  process.env.REACT_APP_IDENTIY +
  "/api/v1/UserConfigurations/GetUserConfigurationPortal";

export class ConfigurationsService {
  create = (item: IUpdateUserConfig) => {
    return GlobalAxios.post(CREATE_ROUTE, item);
  };

  getAll = () => {
    return GlobalAxios.get<IGetUserConfig>(GET_ROUTE);
  };
}

export default ConfigurationsService;
