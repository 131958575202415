import {
  Button,
  CircularProgress,
  Grid,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import DownloadIcon from "@mui/icons-material/Download";
import { useTranslation } from "react-i18next";

import useFetch from "../hooks/useFetch";
import HistoryPaymentService from "../services/historyPayment.service";
import { IHistory, IReceivableAccounts } from "../models/history-payment";
import Table from "../components/UI/Table/Table";
import SelectYears from "../components/UI/Inputs/SelectYears";
import NoData from "../components/UI/NoData";
import ModalComponent from "../components/UI/Modal";
import DownloadTicket from "../components/DownloadTicket";
import DisplayDate from "../components/DisplayDate";
import { formatValue } from "../utils/conversions";
import useRole from "../hooks/useRole";
import DetailDownloadButton from "../components/DetailDownloadButton";
import PagesContainer from "../components/UI/PagesContainer";

const historyListService = new HistoryPaymentService();

const translationPath = "page.historyPayment.";

const HistoryPaymentPage = () => {
  const { sendRequest } = useFetch(historyListService.getAll);
  const [history, setHistory] = useState<IHistory[]>([]);
  const { t } = useTranslation();
  const actualYear = dayjs(new Date()).get("year");
  const [year, setYear] = useState<number>(actualYear);
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const readPermission = useRole(["Read"]);

  const loadHistory = useCallback(async () => {
    setLoading(true);
    const { data, success } = await sendRequest(year.toString());
    if (success) {
      setTimeout(() => {
        setLoading(false);
        setHistory(data);
      }, 500);
    }
  }, [sendRequest, year]);

  useEffect(() => {
    if (readPermission) {
      loadHistory();
    }
  }, [loadHistory, readPermission]);

  const handleChange = (event: SelectChangeEvent) => {
    setYear(Number(event.target.value));
  };

  const openHandler = (e: IReceivableAccounts) => {
    setModalData(e);
    setOpen(true);
  };

  const componentRef = useRef<HTMLDivElement>(null);

  const closeHandler = () => setOpen(false);

  const columns: GridColDef<IReceivableAccounts>[] = [
    {
      field: "numberAccountIntegrationId",
      headerName: `${t(translationPath + "title_number")}`,
      minWidth: 100,
      maxWidth: 150,
      flex: 1,
    },
    {
      field: "referenceDate",
      headerName: `${t(translationPath + "reference_date")}`,
      width: 180,
      renderCell: (params) => {
        dayjs.locale("pt-BR");
        return (
          <DisplayDate typography={{ variant: "body2" }}>
            {dayjs(params.value)}
          </DisplayDate>
        );
      },
    },
    {
      field: "dueDate",
      headerName: `${t(translationPath + "due_date")}`,
      width: 180,
      renderCell: (params) => {
        return (
          <DisplayDate typography={{ variant: "body2" }}>
            {dayjs(params.value)}
          </DisplayDate>
        );
      },
    },
    {
      field: "paymentDate",
      headerName: `${t(translationPath + "payment_date")}`,
      width: 180,
      renderCell: (params) => {
        return (
          <DisplayDate typography={{ variant: "body2" }}>
            {dayjs(params.value)}
          </DisplayDate>
        );
      },
    },
    {
      field: "amountFine",
      headerName: `${t(translationPath + "amount_fine")}`,
      minWidth: 150,
      renderCell: (params) => {
        return formatValue(params.value);
      },
    },
    {
      field: "amountInterest",
      headerName: `${t(translationPath + "amount_interest")}`,
      minWidth: 150,
      renderCell: (params) => {
        return formatValue(params.value);
      },
    },
    {
      field: "amountPenality",
      headerName: `${t(translationPath + "amount_penality")}`,
      minWidth: 150,
      renderCell: (params) => {
        return formatValue(params.value);
      },
    },
    {
      field: "amountMonthly",
      headerName: `${t(translationPath + "amount_monthly")}`,
      minWidth: 150,
      renderCell: (params) => {
        return formatValue(params.value);
      },
    },
    {
      field: "showBankBill",
      headerName: `${t(translationPath + "donwloads")}`,
      width: 220,
      renderCell: (params) => {
        return (
          <Stack flexDirection={"row"} justifyContent={"start"}>
            {params.value && (
              <Button
                variant="text"
                onClick={() => openHandler(params.row)}
                startIcon={<DownloadIcon />}
              >
                {t(translationPath + "download_ticket")}
              </Button>
            )}
            {!params.value && <DetailDownloadButton ticket={params.row} />}
          </Stack>
        );
      },
    },
  ];

  if (loading) {
    return (
      <Stack
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        overflow={"hidden"}
        mt={5}
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      <ModalComponent open={open} onClose={closeHandler}>
        <DownloadTicket ticket={modalData!} />
      </ModalComponent>

      <PagesContainer
        title={t(translationPath + "history_payment")}
        headerBottom={
          <SelectYears
            label={t(translationPath + "select_year")}
            value={year}
            sx={{ maxWidth: 300 }}
            initialYear={2020}
            lastYear={actualYear}
            onChangeHandler={(e) => handleChange(e)}
          />
        }
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          xl={12}
          ref={componentRef}
          className="print-page"
          sx={{
            paddingBottom: 4,
          }}
        >
          {history.length >= 1 &&
            history.map((item) => (
              <Grid item xs={12} sm={12} md={12} mt={2} key={item.planName}>
                <Typography
                  variant="h6"
                  sx={{ textTransform: "capitalize", lineHeight: 1.2 }}
                >
                  {t(translationPath + "plan")}: {item.planName}
                </Typography>
                {item.receivableAccounts.length >= 1 && (
                  <Table
                    columns={columns}
                    sortModel={[{ field: "referenceDate", sort: "desc" }]}
                    rows={item.receivableAccounts.map((row) => ({
                      id: row.uniqueId,
                      numberAccountIntegrationId:
                        row.numberAccountIntegrationId,
                      referenceDate: row.referenceDate,
                      dueDate: row.dueDate,
                      paymentDate: row.paymentDate,
                      amountFine: row.amountFine,
                      amountInterest: row.amountInterest,
                      amountPenality: row.amountPenality,
                      amountMonthly: row.amountMonthly,
                      showBankBill: row.showBankBill,
                    }))}
                  />
                )}
                {item.receivableAccounts.length === 0 && (
                  <Grid container mt={5}>
                    <Grid item xs={12} sm={12}>
                      <NoData />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            ))}
          {history.length === 0 && (
            <Grid container mt={5} justifyContent={"center"}>
              <Grid item xs={10} sm={6}>
                <NoData message={t(translationPath + "no_data")} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </PagesContainer>
    </>
  );
};

export default HistoryPaymentPage;
