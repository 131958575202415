import { Stack } from "@mui/material";
import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../Button";
import LoginIcon from "@mui/icons-material/Login";
import { useTranslation } from "react-i18next";

const translationPath = "components.accessPortalBtn.";

export const AccessPortalBtn = memo(() => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const accessPortalHandler = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <Stack
      height={60}
      direction={"row"}
      justifyContent={"end"}
      alignItems={"center"}
    >
      <Button
        variant="outlined"
        startIcon={<LoginIcon />}
        onClick={accessPortalHandler}
      >
        {t(translationPath + "button")}
      </Button>
    </Stack>
  );
});
