import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import userReducer from "./slices/user";
import privilegeReducer from "./slices/privilege";
import notificationReducer from "./slices/notification";
import themeReducer from "./slices/theme";
import permissionReducer from "./slices/permission";
import cardsReducer from "./slices/cards";
import configurationsReducer from "./slices/configurations";
import languageReducer from "./slices/language";
import installedExtensionsReducer from "./slices/installedExtensions";

const combinedReducer = combineReducers({
  user: userReducer,
  privilege: privilegeReducer,
  notification: notificationReducer,
  theme: themeReducer,
  permission: permissionReducer,
  cards: cardsReducer,
  configurations: configurationsReducer,
  language: languageReducer,
  installedExtensions: installedExtensionsReducer,
});

const store = configureStore({
  reducer: combinedReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
