import DescriptionIcon from "@mui/icons-material/Description";
import { Box, Tooltip } from "@mui/material";
import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useDownload from "../../../hooks/useDownload";
import useFetch from "../../../hooks/useFetch";
import { getEstablishmentParam } from "../../../services/establishmentParam.service";
import { tokenTemporaryService } from "../../../services/tokenTemorary.service";
import IconButton from "../../UI/IconButton";
import ModalComponent from "../../UI/Modal";
import ExamsPdf from "./ExamsPdf";

const EXAMS_ROUTE = process.env.REACT_APP_APP + "/api/v1/LaboratorialExams/";

const EXAMS_TASY_ROUTE =
  process.env.REACT_APP_APP + "/api/v1/LaboratorialExams/Tasy/";

const translationPath = "components.examsDownload.";

interface IExamsProps {
  id: string;
  examType: string;
  date: string;
  individualId?: string;
}

const ExamsDownload = ({ id, examType, date, individualId }: IExamsProps) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const isLogginTemporary = tokenTemporaryService.hasTemporaryAccessToken();

  const { sendRequest, loading } = useFetch(getEstablishmentParam);

  const { downloadFile, loading: dLoading } = useDownload();

  const closeHandler = () => setOpen(false);
  const openHandler = () => setOpen(true);

  const downloadHandler = useCallback(async () => {
    const { data, success } = await sendRequest("22");

    if (data && success) {
      if (data.length !== 0) {
        downloadFile(
          isLogginTemporary ? EXAMS_TASY_ROUTE : EXAMS_ROUTE,
          id,
          `${t(translationPath + "report")}-${date.substring(0, 10)}`,
          {
            examType:
              examType === "LABORATORIAL"
                ? "LaboratorialExam"
                : "NonLaboratorialExam",
            individualId,
          }
        );
      } else {
        openHandler();
      }
    }
  }, [
    sendRequest,
    downloadFile,
    t,
    isLogginTemporary,
    individualId,
    date,
    examType,
    id,
  ]);

  const isLoading = loading || dLoading;

  return (
    <>
      <ModalComponent open={open} onClose={closeHandler} dialogMaxWidth={"xl"}>
        <ExamsPdf
          id={id}
          examType={examType}
          date={date}
          individualId={individualId}
        />
      </ModalComponent>

      <Tooltip title={t(translationPath + "open_report")} placement="right">
        <Box>
          <IconButton
            size="small"
            loading={isLoading}
            onClick={downloadHandler}
          >
            <DescriptionIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    </>
  );
};

export default memo(ExamsDownload);
