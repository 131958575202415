import { useCallback } from "react";

type TDistance = "km" | "meters";

export const useDistance = () => {
  const degreesToRadians = (degrees: number): number => {
    return degrees * (Math.PI / 180);
  };

  const distanceBetweenCoordinates = useCallback(
    (
      lat1: number,
      lon1: number,
      lat2: number,
      lon2: number,
      unit: TDistance = "km"
    ): number => {
      const R = 6371; // Raio da Terra em km
      const dLat = degreesToRadians(lat2 - lat1);
      const dLon = degreesToRadians(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(degreesToRadians(lat1)) *
          Math.cos(degreesToRadians(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      let distance = R * c; // Distância em km

      if (unit === "meters") {
        distance *= 1000; // Converter para metros
      }

      return Number(distance.toFixed(2));
    },
    []
  );

  return { distanceBetweenCoordinates };
};
