import { Box, Grid, Typography } from "@mui/material";
import { IProcedure } from "../../models/authorizations";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import PaperComponent from "../../components/UI/PaperComponent";
import { ProcedureQuantity } from "./ProcedureQuantity/ProcedureQuantity";

const translationPath = "components.procedures.";

const Procedures = ({ procedure }: { procedure: IProcedure }) => {
  const { t } = useTranslation();

  const [statusColor, setStatusColor] = useState("");
  useEffect(() => {
    if (procedure.procedureCurrentSituation.includes("Liberado")) {
      setStatusColor("#28bd45");
      return;
    }
    if (
      procedure.procedureCurrentSituation.includes("Negado") ||
      procedure.procedureCurrentSituation.includes("Cancelado")
    ) {
      setStatusColor("#d80202");
      return;
    }
    if (procedure.procedureCurrentSituation.includes("Aguardando")) {
      setStatusColor("#e8ec00");
      return;
    }
    setStatusColor("#b1b1b1");
  }, [procedure.procedureCurrentSituation]);

  return (
    <PaperComponent sx={{ minWidth: 300, maxWidth: 800, position: "relative" }}>
      <Grid container sx={{ position: "relative" }}>
        <Grid item container sm={12} px={2} py={2} spacing={1}>
          <Grid item sm={12} display={"flex"} gap={1}>
            <Typography
              sx={(theme) => ({
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.grey[600]
                    : theme.palette.grey[400],
              })}
              variant="subtitle1"
            >
              {t(translationPath + "code")}:
            </Typography>
            <Typography variant="subtitle1">
              {procedure.procedureCode}
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <Typography variant="subtitle1">
              {procedure.procedureName}
            </Typography>
          </Grid>
          <ProcedureQuantity
            label={t(translationPath + "requested_quantity")}
            quantity={procedure.qtyRequested}
          />
          <ProcedureQuantity
            label={t(translationPath + "authorized_quantity")}
            quantity={procedure.qtyApproved}
          />
          <ProcedureQuantity
            label={t(translationPath + "pending_execution_quantity")}
            quantity={procedure.qtyPendingExecution}
          />
          <ProcedureQuantity
            label={t(translationPath + "executed_quantity")}
            quantity={procedure.qtyExecuted}
          />
        </Grid>
      </Grid>
      <Box sx={{ position: "absolute", top: 15, right: 15 }}>
        <Typography
          variant="subtitle1"
          sx={{ color: statusColor, fontWeight: "bold", textAlign: "end" }}
        >
          {procedure.procedureCurrentSituation}
        </Typography>
      </Box>
    </PaperComponent>
  );
};

export default Procedures;
