import {
  Card,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { INotification } from "../../models/notifications-user";
import { OverflowTooltip } from "../UI/TooltipOverflow";
import useFetch from "../../hooks/useFetch";
import { markNotificationAsRead } from "../../services/notifications.service";
import { useCallback } from "react";
import { useNotificationsUser } from "../../store/contexts/NotificationsUserContext";
import useRole from "../../hooks/useRole";
import { useTranslation } from "react-i18next";
import TodayIcon from "@mui/icons-material/Today";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import NotificationDate from "./NotificationDate";

const translationPath = "components.notificationCard.";

interface INotificationCardProps {
  notification: INotification;
}

const NotificationCard = ({ notification }: INotificationCardProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const hasUpdatePermission = useRole(["Update"]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const { markUnreadNotificationAsReadHandler, markNotificationAsReadHandler } =
    useNotificationsUser();

  const { sendRequest } = useFetch(markNotificationAsRead);

  const markAsReadHandler = useCallback(async () => {
    const { success } = await sendRequest({
      notificationBatchUserIds: [notification.id],
    });
    if (success) {
      markUnreadNotificationAsReadHandler(notification.id);
      markNotificationAsReadHandler(notification.id);
    }
  }, [
    sendRequest,
    notification.id,
    markUnreadNotificationAsReadHandler,
    markNotificationAsReadHandler,
  ]);

  return (
    <Card
      sx={(theme) => ({
        p: 2,
        width: "100%",
        height: 135,
        display: "flex",
        cursor: "pointer",
        backgroundColor:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        border:
          theme.palette.mode === "light"
            ? `1px solid ${theme.palette.grey[100]}`
            : `1px solid ${theme.palette.grey[900]}`,
      })}
      elevation={0}
      onClick={() => navigate(`/notifications/${notification.id}`)}
    >
      <Stack
        flexDirection={"column"}
        justifyContent={"space-between"}
        style={{ minWidth: 0, width: "100%" }}
      >
        <Grid container flexDirection={"row"}>
          <Grid item xs={10} sm={11}>
            <OverflowTooltip
              text={notification.notificationBatchUserContent.title}
              tooltip={notification.notificationBatchUserContent.title}
              fontWeight="bold"
              variant="subtitle1"
              width={matches ? "100%" : undefined}
            />
          </Grid>
          <Grid item xs={2} sm={1} textAlign={"end"}>
            {!notification.isRead && (
              <Typography variant="subtitle1" color={"#28bd45"} minWidth={40}>
                {t(translationPath + "new")}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Typography
          variant="subtitle2"
          sx={{
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            LineClamp: 2,
            WebkitBoxOrient: "vertical",
          }}
        >
          {notification.notificationBatchUserContent.content}
        </Typography>
        <Stack
          mt={1}
          width={"100%"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            {notification.created && (
              <NotificationDate
                tooltipText={t(translationPath + "created")}
                icon={<TodayIcon fontSize="small" />}
                date={notification.created}
              />
            )}
            {notification.readDate && (
              <NotificationDate
                tooltipText={t(translationPath + "read")}
                icon={<DoneAllIcon fontSize="small" />}
                date={notification.readDate}
              />
            )}
          </Stack>
          {!notification.isRead && hasUpdatePermission && (
            <Link
              variant="subtitle2"
              onClick={(e) => {
                e.stopPropagation();
                markAsReadHandler();
              }}
            >
              {t(translationPath + "mark_as_read")}
            </Link>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

export default NotificationCard;
