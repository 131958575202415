import { IconButton, Stack, Tooltip } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import { memo } from "react";

interface IHomeBannersImageProps {
  bannerId: string;
  bannerPath: string;
  bannerAlternativeText: string;
  closeBanner: (bannerId: string, index?: number) => Promise<void>;
}

const translationPath = "components.homeBannersImage.";

const HomeBannersImage = ({
  bannerId,
  bannerAlternativeText,
  bannerPath,
  closeBanner,
}: IHomeBannersImageProps) => {
  const { t } = useTranslation();

  return (
    <Stack position={"relative"}>
      <>
        <Tooltip title={t(translationPath + "tooltip")}>
          <IconButton
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 100,
            }}
            onClick={() => closeBanner(bannerId)}
          >
            <VisibilityOffIcon color="primary" />
          </IconButton>
        </Tooltip>
        <img src={bannerPath} key={bannerId} alt={bannerAlternativeText} />
      </>
    </Stack>
  );
};

export default memo(HomeBannersImage);
