import GlobalAxios from "../axios/axios-config";
import { IBankData } from "../components/UI/Inputs/AutoCompleteBankData";
import { IBeneficiaryRefund, IGetAllRefunds } from "../models/refunds";
import { IBeneficiaries } from "../models/topmed";

const BASIC_ROUTE = process.env.REACT_APP_APP + "/api/v1/TasyRefunds/";

export class RefundsService {
  create = (item: IBeneficiaryRefund) => {
    return GlobalAxios.post(
      BASIC_ROUTE + "CreateIndividualBeneficiaryRefund",
      item
    );
  };

  getAll = (serviceDate: string) => {
    return GlobalAxios.get<IGetAllRefunds[]>(
      BASIC_ROUTE + "GetBeneficiaryRefunds",
      {
        params: {
          ServiceDate: serviceDate,
        },
      }
    );
  };

  list = (params?: any) => {
    return GlobalAxios.get<IBeneficiaries>(BASIC_ROUTE + "GetBeneficiary", {
      params: params,
    });
  };

  bankDataList = (params?: any) => {
    return GlobalAxios.get<IBankData[]>(BASIC_ROUTE + "GetBankData", {
      params: params,
    });
  };
}

export default RefundsService;
