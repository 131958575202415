import GlobalAxios from "../axios/axios-config";
import { IContentBase } from "../models/content";
import {
  IHealthProgram,
  IHealthProgramDetail,
} from "../models/health-programs";
import { authApi } from "./auth.service";

const BASE_URL = process.env.REACT_APP_APP + "/api/v1/HealthProgram/";

export const getHealthProgramsNonAuthenticated = () => {
  return authApi.get<IHealthProgram[]>(
    BASE_URL + "GetHealthProgramsNonAuthenticated"
  );
};

export const getHealthProgramsAuthenticated = () => {
  return GlobalAxios.get<IHealthProgram[]>(
    BASE_URL + "GetHealthProgramsAuthenticated"
  );
};

export const getHealthProgramById = (id: string) => {
  return GlobalAxios.get<IHealthProgramDetail>(BASE_URL + id);
};

export const getContentById = (id: string) => {
  return GlobalAxios.get<IContentBase>(
    process.env.REACT_APP_APP + "/api/v1/Content/ById",
    {
      params: { id },
    }
  );
};

export const requestAccess = (healthProgramId: string) => {
  return GlobalAxios.post(BASE_URL, { healthProgramId });
};

export const leaveProgram = (healthProgramId: string) => {
  return GlobalAxios.put(BASE_URL + "ExitProgram", { healthProgramId });
};
