import {
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const translationPath = "page.authorizations.";

const AuthorizationProvider = ({
  label,
  name,
}: {
  label: string;
  name: string;
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Grid item sm={12} mt={1}>
      <Stack
        display={matches ? "flex" : "block"}
        flexDirection={matches ? "row" : undefined}
        gap={matches ? 1 : 0}
      >
        <Typography
          variant="subtitle1"
          sx={(theme) => ({
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[600]
                : theme.palette.grey[400],
          })}
        >
          {t(translationPath + label)}:
        </Typography>
        <Typography variant="subtitle1">{name}</Typography>
      </Stack>
    </Grid>
  );
};

export default AuthorizationProvider;
