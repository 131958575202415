import { Box, Popover } from "@mui/material";
import { IColumnsPopover } from "./models";
import { memo } from "react";
import { defaultScrollTheme } from "../../../../utils/utils";

const ColumnsPopover = ({
  open,
  anchorEl,
  handleClose,
  children,
}: IColumnsPopover) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box
        position={"relative"}
        sx={[
          {
            maxHeight: 300,
            overflowY: "auto",
          },
          defaultScrollTheme,
        ]}
      >
        {children}
      </Box>
    </Popover>
  );
};

export default memo(ColumnsPopover);
