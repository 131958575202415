import { CssBaseline, ThemeProvider } from "@mui/material";
import { HelmetProvider, Helmet } from "react-helmet-async";
import useSiteApp from "../hooks/useSiteApp";
import { setFavicon } from "../services/theme.service";
import Loading from "./Loading/Loading";

interface ISiteAppProps {
  children: JSX.Element;
}

const SiteAppTheme: React.FC<ISiteAppProps> = ({ children }) => {
  const { config, theme, loading } = useSiteApp();
  setFavicon(config.favicon || config.logo);

  let content = (
    <HelmetProvider>
      <Helmet>
        <title>{config.name}</title>
        <meta name="title" content={config.name} />
        <meta name="description" content={config.description} />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={config.name} />
        <meta property="og:description" content={config.description} />
        <meta property="og:image" content={config.logo} />

        <meta property="twitter:card" content="summary_large_image" />

        <meta property="twitter:title" content={config.name} />
        <meta property="twitter:description" content={config.description} />
        <meta property="twitter:image" content={config.logo} />
      </Helmet>
      <CssBaseline />
      {children}
    </HelmetProvider>
  );

  if (loading) {
    content = <Loading />;
  }

  return <ThemeProvider theme={theme}>{content}</ThemeProvider>;
};

export default SiteAppTheme;
