import { Link, Stack, Typography } from "@mui/material";
import { INotificationBatchUserContent } from "../../models/notifications-user";
import { useNotificationsUser } from "../../store/contexts/NotificationsUserContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { OverflowTooltip } from "../UI/TooltipOverflow";
import { useAppSelector } from "../../store/store";

interface IUnreadNotificationProps {
  notification: INotificationBatchUserContent;
  notificationId: string;
  onClose: () => void;
}

const translationPath = "components.unreadNotificationItem.";

const UnreadNotificationItem = ({
  notification,
  notificationId,
  onClose,
}: IUnreadNotificationProps) => {
  const { t } = useTranslation();

  const notificationPrivileges = useAppSelector((state) =>
    state.privilege.privileges.filter((p) => p.domainName === "notification")
  );
  const hasUpdatePermission = !!notificationPrivileges.find((p) =>
    p.actions.some((a) => a === "Update")
  );

  const navigate = useNavigate();

  const { markUnreadNotificationAsReadHandler } = useNotificationsUser();

  const redirectToNotification = () => {
    onClose();
    navigate(`/notifications/${notificationId}`);
  };

  return (
    <Stack
      p={1}
      width={"100%"}
      sx={(t) => ({
        borderRadius: 1,
        transition: "all 0.1s ease-in-out",
        cursor: "pointer",
        "&:hover": {
          backgroundColor:
            t.palette.mode === "light"
              ? t.palette.grey["100"]
              : t.palette.grey["900"],
        },
      })}
      onClick={redirectToNotification}
    >
      <Stack flexDirection={"row"} justifyContent={"space-between"} gap={2}>
        <OverflowTooltip
          text={notification.title}
          tooltip={notification.title}
          fontWeight="bold"
        />
        <Typography variant="subtitle2" color={"#28bd45"}>
          Nova
        </Typography>
      </Stack>
      <Typography
        variant="subtitle2"
        sx={(t) => ({
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color:
            t.palette.mode === "light"
              ? t.palette.grey["600"]
              : t.palette.grey["400"],
        })}
      >
        {notification.content}
      </Typography>
      {hasUpdatePermission && (
        <Stack flexDirection={"row"} justifyContent={"end"}>
          <Link
            variant="subtitle2"
            onClick={(e) => {
              e.stopPropagation();
              markUnreadNotificationAsReadHandler(notificationId);
            }}
          >
            {t(translationPath + "mark_as_read")}
          </Link>
        </Stack>
      )}
    </Stack>
  );
};

export default UnreadNotificationItem;
