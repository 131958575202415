import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { IconButton, Stack, Typography } from "@mui/material";
import { memo, useState } from "react";

import ModalComponent from "./UI/Modal";

interface IHelpModalProps {
  title: string;
  description: string;
}

const HelpModal: React.FC<IHelpModalProps> = ({ description, title }) => {
  const [open, setOpen] = useState(false);

  const onClickHandler = () => {
    setOpen(true);
  };

  const onCloseHandler = () => {
    setOpen(false);
  };

  return (
    <>
      <ModalComponent onClose={onCloseHandler} open={open}>
        <Stack gap={1}>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body1">{description}</Typography>
        </Stack>
      </ModalComponent>
      <IconButton onClick={onClickHandler}>
        <HelpOutlineIcon color="action" />
      </IconButton>
    </>
  );
};

export default memo(HelpModal);
