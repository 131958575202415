import { z } from "zod";

const translationPath = "page.refundsPage.refund.errors.";

const fileSchema = z.object(
  {
    mediaType: z.string().optional(),
    id: z.string().nonempty(translationPath + "file"),
    mediaTypeId: z.string().optional(),
    isPublic: z.boolean().optional(),
    fileName: z.string().optional(),
    cdnDomain: z.string().optional(),
    filePath: z.string().optional(),
    mimeType: z.string().optional(),
    fileSize: z.number().optional(),
    imagePreview: z.string().optional(),
  },
  { required_error: translationPath + "file" }
);

const mediaFilesSchema = z
  .object({
    file: fileSchema,
  })
  .superRefine((val, ctx) => {
    if (val.file.id.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["file"],
        message: translationPath + "file",
      });
    }
  });

const bankSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const bankDataSchema = z.object({
  integrationId: z.string().nullable(),
  shortName: z.string().nullable(),
  bacenCode: z.number().nullable(),
  bankBranch: z.string().nullable(),
  bankAccount: z.string().nullable(),
  bankAccountDigit: z.string().nullable(),
  holderBankAccountName: z.string().nullable(),
  holderBirthDate: z.string().nullable(),
  holderDocument: z.string().nullable(),
  bank: bankSchema.nullable(),
});

export const refundSchema = z
  .object({
    // Step 1
    typeGuideCode: z.object({
      id: z.string(),
      code: z.string(),
    }),
    serviceDate: z.date({
      invalid_type_error: translationPath + "service_date",
    }),
    serviceValue: z.string().nonempty(translationPath + "service_value"),
    serviceLocation: z.string().nonempty(translationPath + "service_location"),
    serviceReason: z.string().nonempty(translationPath + "service_reason"),
    files: z.array(mediaFilesSchema).nonempty(translationPath + "files"),
    // Step 2
    otherBankData: z.boolean(),
    bankData: bankDataSchema.nullish(),
    holderBankAccountName: z.string().nullable(),
    holderDocument: z.string().nullable(),
    holderBirthDate: z.date().nullable(),
    bank: bankSchema.nullable(),
    bankBranch: z.string().nullable(),
    bankAccount: z.string().nullable(),
    bankAccountDigit: z.string().nullable(),
    phoneContact: z.string().nonempty(translationPath + "phone_contact"),
    mailContact: z
      .string()
      .nonempty(translationPath + "mail_contact")
      .email(translationPath + "mail_contact_val"),
  })
  .superRefine((val, ctx) => {
    if (!val.otherBankData && !val.bankData?.integrationId) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["bankData"],
        message: translationPath + "bank_data",
      });
    }
    if (!val.holderBankAccountName) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["holderBankAccountName"],
        message: translationPath + "holder_name",
      });
    }
    if (!val.holderDocument) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["holderDocument"],
        message: translationPath + "holder_document",
      });
    }
    if (!val.bank) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["bank"],
        message: translationPath + "bank",
      });
    }
    if (!val.holderBirthDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["holderBirthDate"],
        message: translationPath + "holder_birth_date",
      });
    }
    if (!val.bankBranch) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["bankBranch"],
        message: translationPath + "bank_branch",
      });
    }
    if (!val.bankAccount) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["bankAccount"],
        message: translationPath + "bank_account",
      });
    }
    if (!val.bankAccountDigit) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["bankAccountDigit"],
        message: translationPath + "bank_account_digit",
      });
    }
  });

export type TRefundForm = z.infer<typeof refundSchema>;
