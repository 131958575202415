import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import FormInput from "../../UI/Inputs/FormInput";
import { useAppSelector } from "../../../store/store";
import Button from "../../UI/Button";
import { useCallback } from "react";
import useFetch from "../../../hooks/useFetch";
import { authService } from "../../../services/auth.service";
import { useNavigate } from "react-router-dom";

const resendEmailSchema = z.object({
  username: z.string().min(1, "O login é obrigatório"),
});

type TResendEmailForm = z.infer<typeof resendEmailSchema>;

const ResendEmailForm = ({
  onNavigateBack,
  onEmailRecent,
}: {
  onNavigateBack: () => void;
  onEmailRecent: () => void;
}) => {
  const methods = useForm<TResendEmailForm>({
    resolver: zodResolver(resendEmailSchema),
  });
  const { handleSubmit } = methods;
  const { sendRequest, loading } = useFetch(
    authService.resendEmailConfirmation
  );
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoginCpf = useAppSelector((state) => state.theme.isLoginCpf);

  const resendEmailHandler = useCallback(
    async (form: TResendEmailForm) => {
      const { success } = await sendRequest({
        userName: isLoginCpf
          ? form.username.replaceAll(".", "").replaceAll("-", "")
          : form.username,
        softwareCode: "3",
      });
      if (success) {
        sessionStorage.setItem("resent", "true");
        onEmailRecent();
        navigate(`/email-confirmation/${form.username}`);
      }
    },
    [sendRequest, navigate, onEmailRecent, isLoginCpf]
  );

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(resendEmailHandler)}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography variant="h6" textAlign={"center"} mb={1}>
          Reenviar e-mail
        </Typography>
        <FormInput
          name="username"
          size="small"
          label={isLoginCpf ? "CPF" : "Nome de usuário"}
          fullWidth
        />
        <Button
          loading={loading}
          variant="contained"
          type="submit"
          sx={{ mt: 1 }}
          fullWidth
        >
          Reenviar e-mail
        </Button>
        <Button
          variant="outlined"
          onClick={onNavigateBack}
          fullWidth
          sx={{ mt: 1 }}
        >
          Voltar
        </Button>
      </Box>
    </FormProvider>
  );
};

export default ResendEmailForm;
