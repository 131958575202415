import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";

import { useCallback, useEffect } from "react";
import useFetch from "../../../hooks/useFetch";
import { authService } from "../../../services/auth.service";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notificationSliceActions } from "../../../store/slices/notification";
import { zodResolver } from "@hookform/resolvers/zod";
import { TNewPassword, resetPasswordFormSchema } from "./ResetPasswordSchema";
import FormInput from "../../UI/Inputs/FormInput";
import ResendResetEmail from "./ResendResetEmail";

const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const { sendRequest: resetPassword } = useFetch(authService.resetPassword);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userName } = useParams();

  const methods = useForm<TNewPassword>({
    resolver: zodResolver(resetPasswordFormSchema),
  });

  const {
    formState: { isSubmitSuccessful },
    reset,
    handleSubmit,
  } = methods;

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const onSubmitHandler = useCallback(
    async (form: TNewPassword) => {
      if (userName) {
        const { success } = await resetPassword({
          userName: userName,
          confirmationCode: form.confirmationCode,
          newPassword: form.newPassword,
          softwareCode: "3",
        });

        if (success) {
          dispatch(
            notificationSliceActions.showNotification({
              message: `${t(
                "components.resetPasswordForm.notification_password_success"
              )}`,
              type: "success",
            })
          );

          navigate("/login");
        }
      }
    },
    [resetPassword, dispatch, t, navigate, userName]
  );

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitHandler)}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography variant="h6" align="center">
          {t("components.resetPasswordForm.title")}
        </Typography>
        <Typography variant="subtitle2" align="center" lineHeight={1.2} mb={4}>
          {t("components.resetPasswordForm.subtitle")}
        </Typography>
        <FormInput
          size="small"
          autoFocus={true}
          name="confirmationCode"
          fullWidth
          label={
            t("components.resetPasswordForm.confirmation_code") ||
            "Confirmation code"
          }
          sx={{ mb: 2 }}
        />
        <FormInput
          size="small"
          name="newPassword"
          fullWidth
          type="password"
          label={t("components.resetPasswordForm.password") || "Password"}
          sx={{ mb: 2 }}
        />
        <FormInput
          size="small"
          name="confirmNewPassword"
          fullWidth
          type="password"
          label={
            t("components.resetPasswordForm.confirm_password") ||
            "Confirm password"
          }
          sx={{ mb: 2 }}
        />
        <Button variant="contained" type="submit">
          {t("components.forgetPasswordForm.button")}
        </Button>
        <ResendResetEmail />
      </Box>
    </FormProvider>
  );
};

export default ResetPasswordForm;
