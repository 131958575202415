import { z } from "zod";

const medicalSpecialtySchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const medicalPracticeAreaSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const provincySchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const citySchema = z.object({
  id: z.string(),
  name: z.string(),
  provincy: provincySchema,
});

const medicCitySchema = z.object({
  id: z.string(),
  city: citySchema,
});

const healthPlanSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const providerTypeSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

export const medicGuideFilterSchema = z.object({
  search: z.string().optional(),
  medicalSpecialty: medicalSpecialtySchema.optional().nullable(),
  medicalPracticeArea: medicalPracticeAreaSchema.optional().nullable(),
  name: z.string().optional().nullable(),
  city: medicCitySchema.optional().nullable(),
  healthPlan: healthPlanSchema.optional().nullable(),
  isUrgentEmergencyIntegration: z.boolean(),
  providerType: providerTypeSchema.optional().nullable(),
});

export type TMedicGuideFilterForm = z.infer<typeof medicGuideFilterSchema>;
