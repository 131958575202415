import { Grid, Typography } from "@mui/material";
import ModalComponent from "../UI/Modal";
import { useTranslation } from "react-i18next";

interface IIrpfModalProps {
  open: boolean;
  closeModal: () => void;
  selectedYear: number;
}

const translationPath = "page.irpf.";

const IrpfModal = ({ open, closeModal, selectedYear }: IIrpfModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalComponent open={open} onClose={closeModal} dialogMaxWidth={"md"}>
      <Grid container>
        <Grid item sm={12}>
          <Typography variant={"h6"}>
            {t(translationPath + "no_file.title")}
          </Typography>
        </Grid>
        <Grid item sm={12}>
          <Typography variant={"subtitle1"}>
            {t(translationPath + "no_file.description", {
              year: selectedYear,
            })}
          </Typography>
        </Grid>
      </Grid>
    </ModalComponent>
  );
};

export default IrpfModal;
