import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { getDomain } from "../services/domain.service";

const EnvironmentType = () => {
  const environmentType = process.env.REACT_APP_ENVIRONMENT;
  const [tagName, setTagName] = useState<string>();
  const [backgroundColor, setBackgroundColor] = useState<string>();


  const siteDomain = window.location.hostname;

  const isLocalHost = siteDomain === "localhost";

  useEffect(() => {
    if (
      environmentType === "development" ||
      environmentType === "development/local"
    ) {
      setTagName(environmentType);
      setBackgroundColor("#26e077");
    }
    if (environmentType === "homolog") {
      setTagName(environmentType);
      setBackgroundColor("#2A7ABE");
    }
  }, [environmentType]);

  return (
    <Box>
      {tagName && (
        <Box
          position={"fixed"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          zIndex={1000}
          sx={{
            width: "100vw",
            height: ".92rem",
            backgroundColor: backgroundColor,
            color: backgroundColor === "#26e077" ? "#000" : "#fff",
            fontSize: "0.8rem",
          }}
        >
          {tagName} {isLocalHost && `(${getDomain()})`}
        </Box>
      )}
    </Box>
  );
};

export default EnvironmentType;
