import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { memo } from "react";
import { IPeriodSelectProps } from "./models";

export const PeriodSelect = memo(
  ({ value, options, label, onChange, sx }: IPeriodSelectProps) => {
    return (
      <FormControl sx={sx}>
        <InputLabel id="period-select-field">{label}</InputLabel>
        <Select
          value={value}
          id="period-select-field"
          label={label}
          size="small"
          sx={{ minWidth: 320 }}
          onChange={onChange}
        >
          {options.map((option) => (
            <MenuItem
              key={option.name}
              value={`${option.startDate} ${option.endDate}`}
            >
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);
