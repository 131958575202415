import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Stack, Typography } from "@mui/material";
import Button from "../../UI/Button";
import { useTranslation } from "react-i18next";

const translationPath = "components.emailConfirmedModal.";

const EmailConfirmedModal = ({
  onCloseModal,
}: {
  onCloseModal: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Stack flexDirection={"column"} alignItems={"center"} gap={2}>
      <CheckCircleIcon color="success" sx={{ fontSize: 48 }} />
      <Stack textAlign={"center"}>
        <Typography variant="h6" fontWeight={"bold"}>
          {t(translationPath + "title")}
        </Typography>
        <Typography>{t(translationPath + "description")}</Typography>
      </Stack>
      <Button variant="contained" onClick={onCloseModal} fullWidth>
        {t(translationPath + "button")}
      </Button>
    </Stack>
  );
};

export default EmailConfirmedModal;
