import { useCallback, useEffect, useLayoutEffect } from "react";
import { createTheme } from "@mui/material";

import useFetch from "./useFetch";
import { useAppDispatch, useAppSelector } from "../store/store";
import { controlMode, getSiteAppConfig } from "../services/theme.service";
import { themeSliceActions } from "../store/slices/theme";
import { tokenService } from "../services/token.service";

const useSiteApp = () => {
  const token = tokenService.hasAccessTokens();

  const { loading, sendRequest } = useFetch(getSiteAppConfig);

  const dispatch = useAppDispatch();

  const config = useAppSelector((state) => state.theme);

  const fetchSiteAppConfig = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (success) {
      try {
        const config = JSON.parse(data.siteApp.frontConfig);

        dispatch(themeSliceActions.setTheme(config));
      } catch (error) {
        console.warn(
          "The app configuration provided is not valid.Using default configuration"
        );
      }
    }
  }, [dispatch, sendRequest]);

  const visitUserMode = localStorage.getItem("theme");

  useEffect(() => {
    if (!token && visitUserMode) {
      dispatch(
        themeSliceActions.setThemeMode(visitUserMode === "1" ? "1" : "2")
      );
    }
  }, [dispatch, token, visitUserMode]);

  useLayoutEffect(() => {
    fetchSiteAppConfig();
  }, [fetchSiteAppConfig]);

  const theme = createTheme(controlMode(config, config.mode));

  return {
    loading,
    config,
    theme,
  };
};

export default useSiteApp;
