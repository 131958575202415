const pt_BR = {
  translations: {
    home: {
      message: "Olá Mundo",
    },
    page: {
      schedules: {
        home: {
          schedules: "Agendamentos",
          nextSchedules: "Próximos agendamentos",
          noFound: "Nenhum agendamento para os próximos dias",
          actions: {
            newSchedule: "Novo agendamento",
            mySchedules: "Meus agendamentos",
            familyGroup: "Grupo familiar",
            insurance: "Meus Convênios",
          },
        },
        scheduleDetails: {
          title: "Detalhes do agendamento",
          professional: "Profissional",
          type: "Tipo de agendamento",
          insurance: "Convênio",
          reschedule: "Reagendar",
          patient: "Paciente",
          confirmModal: {
            title: "Confirmar agenda",
            description:
              "Você deve confirmar o agendamento com {{min}} minutos de antecedência.",
          },
          status: "Status",
          confirm: {
            button: "Confirmar",
            confirmed: "Agendamento confirmado",
          },
          cancel: {
            button: "Cancelar",
            success: "Agendamento cancelado com sucesso",
            title: "Cancelar Agendamento?",
            title2: "Reagendar Agendamento?",
            description2:
              "Para fazer o reagendamento primeiro é necessário fazer o cancelamento do agendamento, preencha o motivo abaixo e em seguida será possível selecionar um novo agendamento",
            description:
              "Você confirma o cancelamento do seu agendamento dia {{date}} com {{professional}}?",
            justification: {
              label: "Justificativa",
              placeholder: "Digite o motivo para cancelar a agenda",
              error: "A justificava é obrigatória",
            },
            reason: {
              label: "Motivo do cancelamento",
              placeholder: "Selecione o motivo para cancelar a agenda",
              error: "O motivo é obrigatório",
            },
            cancel: "Não",
            confirm: "Sim, Cancelar",
          },
        },
        insurance: {
          title: "Meus Convênios",
          subtitle: "Gerencie os seus convênios e os do grupo familiar",
          add: "Adicionar",
          insuranceName: "Convênio",
          individual: "Indivíduo",
          cardNumber: "Número do cartão",
          cardValidity: "Validade",
          delete: {
            title: "Deletar convênio?",
            description: "Essa ação é permanente!",
            confirmationText: "Sim, deletar",
            cancellationText: "Cancelar",
          },
          addInsurance: {
            edit: "Editar convênio",
            add: "Adicionar convênio",
            buttonSave: "Salvar",
            buttonEdit: "Editar",
            number: {
              label: "Número da carteirinha (opcional)",
              error: "O número é obrigatório",
            },
            validity: {
              label: "Validade da carteirinha (opcional)",
              error: "A validade é obrigatória",
            },
            individual: {
              label: "Paciente",
              error: "O paciente é obrigatório",
            },
            insurance: {
              label: "Convênio",
              error: "O convênio é obrigatório",
            },
          },
        },
        addFamilyGroup: {
          title: "Grupo Familiar",
          subtitle: "Gerencie os membros do seu grupo familiar",
          add: "Adicionar",
          individual: "Indivíduo",
          delete: {
            title: "Deletar familiar?",
            description: "Essa ação é permanente!",
            confirmationText: "Sim, deletar",
            cancellationText: "Cancelar",
          },
          titleAdd: "Adicionar Familiar",
          titleEdit: "Editar Familiar",
          birthDate: {
            label: "Data de nascimento",
            error: "A data de nascimento é obrigatória",
          },
          kinshipDegree: {
            label: "Parentesco",
            error: "O parentesco é obrigatório",
          },
          document: {
            label: "CPF",
            error: "O CPF é obrigatório",
          },
          name: {
            label: "Nome",
            error: "O nome é obrigatório",
          },
          nationality: {
            label: "Nacionalidade",
            error: "A nacionalidade é obrigatória",
          },
          gender: {
            label: "Gênero",
            error: "O Gênero é obrigatório",
          },
          edit: "Editar",
          save: "Salvar",
        },
        mySchedules: {
          title: "Meus agendamentos",
          subtitle:
            "Visualize suas agendas futuras e o histórico de agendas passadas",
          next: "Próximos agendamentos",
          historic: "Histórico",
          add: "Adicionar",
          status: "Status",
          date: "Data",
          pacientName: "Paciente",
          type: {
            3: "Consulta",
            2: "Exame",
          },
          descriptionSite: "Descrição",
          tasySchedulingType: "Tipo",
          checkin: "Check in",
        },
        newSchedule: {
          success: "Agendamento cadastrado com sucesso",
          sched: "Agendar",
          next: "Próximo",
          back: "Voltar",
          finish: "Finalizar",
          notAvaiableModal: {
            button: "Ok",
            description:
              "O horário escolhido não está mais disponível, por favor selecione outro horário.",
          },
          steps: {
            patient: {
              title: "Selecione o paciente",
              error: "O paciente é obrigatório",
            },
            insurance: {
              title: "Selecione o convênio",
              error: "O convênio é obrigatório",
            },
            search: {
              title: "Procure por uma agenda",
              specialityError: "A especialidade é obrigatória",
              examError: "O exame é obrigatório",
            },
            schedule: {
              title: "Selecione uma data e um horário",
              error: "O horário é obrigatório",
            },
            confirm: "Confirmar dados",
          },
          insurance: "Convênio",
          addInsurance: "Adicionar convênio",
          addFamilyGroup: "Adicionar familiar",
          search: {
            from: "A partir de",
            examGroup: "Exame",
            professional: "Profissional (opcional)",
            speciality: "Especialidade",
            type: "Tipo de agendamento",
            period: "Período (opcional)",
          },
          schedule: {
            freeDays: "Dias com datas disponíveis",
          },
          confirm: {
            descriptionSite: "Profissional",
            patient: "Paciente",
            insurance: "Convênio",
            schedule: "Agendamento",
            antecipate:
              "Gostaria de ser avisado caso exista alguma agenda livre antes desta data. Neste caso se alguém cancelar uma agenda antes deste dia, iremos lhe avisar desta oportunidade de encaixe.",
            whatsapp_number: "Número do WhatsApp",
            edit: "Editar",
            cancel: "Cancelar",
            confirm: "Confirmar",
            errors: {
              whatsapp_number: "O número do WhatsApp é obrigatório",
            },
          },
        },
      },
      login: {
        welcome: "Bem-vindo de volta",
        username: "Nome de usuário",
        cpf: "CPF",
        password: "Senha",
        rememberme: "Lembre de mim",
        forget_password: "Esqueceu a senha?",
        dont_account: "Não tem uma conta? Registre-se",
        login: "Acessar",
        confirm_email: "Confirmar e-mail",
        errors: {
          login: "O login é obrigatório",
          password: "A senha precisa de no mínimo 6 caracteres",
        },
      },
      loginTemporary: {
        welcome: "Seja bem-vindo(a)",
        login: "Login",
        password: "Senha",
        dont_account: "Não tem uma conta? Registre-se",
        access_button: "Acessar",
        access_exam: "Acesso Exames",
        errors: {
          login: "O login é obrigatório",
          password: "A senha é obrigatória",
        },
      },
      not_found: {
        page_not_found: "Ooops! Página não encontrada",
        back_to_begin: "Voltar ao início",
        server_not_responding: "O servidor não está respondendo",
        try_again: "Tente novamante",
        back_to_login: "Voltar ao login",
        something_went_wrong: "Algo deu errado :(",
      },
      home: {
        ticket: "2ª via de boleto",
        coParticipation: "Extrato de coparticipação",
        paymentHistory: "2ª via de boleto",
        authorization: "Autorizações",
        dependent: "Dependente",
        irAndReimbursement: "IR",
        refunds: "Solicitações de reembolso",
        navigation_card_list: "Ações",
        faq: "FAQ",
        schedule: "Agendas",
        health_program: "Programas de saúde",
        exams: "Resultado exames",
      },
      documents: {
        title: "Termos e Políticas",
        subtitle: "Estes são os documentos que você aceitou",
        no_docs:
          "Você ainda não aceitou nenhum documento. Após aceitar qualquer documento ele aparecerá aqui",
      },
      cards: {
        card: "Cartão Virtual",
        plan: "Plano",
        no_cards: "Nenhum cartão cadastrado",
        view_card: "Ver cartão",
        show_shared_card: "Adicionar cartão compartilhado",
        share_card: "Compartilhar",
        name: "Nome",
        number: "Número",
        birth_date: "Data de nascimento",
        plan_name: "Produto",
        accommodation: "Acomodação",
        coverage: "Abrangência",
        accession: "Data de adesão",
        contract_name: "Contratante",
        card_validity: "Validade",
        card_lack_description: "Carências",
        card_lack_unlock_date: "Liberado a partir de",
        card_lack_usage: "Cartão de uso pessoal e intransferível.",
        card_lack_presentation:
          "Deve ser apresentado junto com um documento de identificação.",
      },
      register: {
        register: "Cadastre-se",
        document: "CPF",
        has_register: "Já tem cadastro? Acesse sua conta",
        birth_date: "Data de Nascimento",
        email: "E-mail",
        confirm_email: "Confirmar e-mail",
        password: "Senha",
        confirm_password: "Confirmar senha",
        notification: "Cadastrado com sucesso",
        errors: {
          document: "O CPF é obrigatório",
          password: "A senha é obrigatória",
          email: "O e-mail é obrigatório",
          confirm_email: "Os e-mails precisam ser iguais",
          confirm_password: "As senhas precisam ser iguais",
          birth_date: "A data de nascimento é obrigatória",
        },
        emailSendMessage:
          "Enviamos um email para confirmar a criação da conta. Por favor verifique a aba de spam :)",
        backToLogin: " Voltar para o login",
      },
      irpf: {
        irpf: "Extrato de IRPF",
        year: "Ano",
        download_irpf: "Baixar IRPF",
        downloading: "Baixando",
        select_year: "Selecione o ano",
        plan: "Plano",
        no_data:
          "Não foram listadas informações de IRPF porque você não é um pagador de contratos de Planos de Saúde. Para obter este relatório, por favor procure o Pagador de seu contrato com a Operadora de Plano de Saúde.",
        no_file: {
          title: "Arquivo não disponível",
          description:
            "Você não possui IRPF do ano {{year}} disponível para download.",
        },
      },
      extractCoParticipation: {
        extract_coparticipation: "Extrato de Coparticipação",
        select_date: "Selecione a data",
        beneficiary: "Beneficiário",
        procedure: "Procedimento",
        procedure_date: "Data",
        quantity: "Quantidade",
        provider: "Prestador",
        value: "Valor",
        total: "Total",
        total_plan: "Total do plano",
      },
      historyPayment: {
        history_payment: "2ª via de boleto",
        title_number: "Nr. título",
        plan_name: "Nome do plano",
        plan: "Plano",
        reference_date: "Data de referência",
        due_date: "Data de vencimento",
        payment_date: "Data de pagamento",
        amount_fine: "Valor pago",
        amount_interest: "Valor juros",
        amount_penality: "Valor multa",
        amount_monthly: "Valor mensalidade",
        download_ticket: "Baixar boleto",
        donwloads: "Baixar documentos",
        detail: "Baixar detalhamento",
        select_year: "Selecione o ano",
        no_data:
          "Não foram listadas informações de pagamento porque você não é um pagador de contratos de Planos de Saúde. Para obter este relatório, por favor procure o Pagador de seu contrato com a Operadora de Plano de Saúde.",
      },
      changePassword: {
        change_password: "Alterar senha",
        actual_password: "Senha atual",
        new_password: "Nova senha",
        save: "Salvar",
        confirm_new_password: "Confirmar nova senha",
        success_message: "Senha alterada com sucesso",
        errors: {
          actual_password: "A senha atual é obrigatória",
          new_password: "A nova senha é obrigatória",
          confirm_new_password: "O confirmar nova senha é obrigatório",
          passwords_match: "As senhas não coincidem",
        },
      },
      confirm_email: {
        confirm_email: "Confirmar e-mail",
        confirming: "Confirmando",
        subtitle:
          'Insira abaixo o código recebido por e-mail e então clique em "Confirmar e-mail".',
        username: "Nome de usuário",
        document: "CPF",
        code: "Código",
        resent: "E-mail reenviado com sucesso",
        success: {
          title: "Email Confirmado",
          description:
            "O seu email foi confirmado com sucesso, volte para o login para acessar sua conta",
        },
        error: {
          username: "O {{username}} é obrigatório",
          title: "Houve um erro",
          description: "Ocorreu um erro ao confirmar o email, tente novamente",
          action: "Reenviar email",
          code: "O Código é obrigatório",
        },
      },
      medicGuide: {
        title: "Resultados",
        show_result: "Sua pesquisa foi:",
        you: "Você",
      },
      authorizations: {
        authorizations: "Autorizações",
        plan_name: "Plano",
        select_period: "Selecione um período",
        see_more: "Ver mais",
        provider: "Prestador",
        doctor: "Médico",
        beneficiary: "Beneficiário",
      },
      authorization: {
        authorization: "Autorização",
        authorization_code: "Número da requisição",
        issue_date: "Data de emissão",
        password_code: "Senha de requisição",
        password_expiration: "Validade da senha",
        situation: "Situação",
        provider: "Prestador",
        doctor: "Médico",
        procedures: "Procedimentos",
        history: "Histórico",
        service_protocol: "Protocolo de atendimento",
        doc_type: "Tipo",
        beneficiary: "Beneficiário",
      },
      noAccess: {
        message:
          "Ops! Parece que você não possui permissão para acessar essa página!",
        button: "Voltar para o início",
      },
      whatsapp: {
        redirect: "Redirecionando...",
      },
      refundsPage: {
        refunds: "Solicitações de reembolso",
        new_refund: "Nova solicitação",
        select_date: "Selecione a data",
        beneficiary: "Beneficiário",
        protocol_date: "Data do protocolo",
        situation: "Situação",
        due_date: "Data de vencimento",
        amount: "Valor",
        service_reason: "Motivo do reembolso",
        refund: {
          refund: "Novo reembolso",
          select_beneficiary: "Selecione um beneficiário",
          basic_data: "Dados básicos",
          others_data: "Informações bancárias e contatos",
          name: "Nome",
          type_guide: "Tipo de Atendimento",
          service_date: "Data de atendimento",
          service_value: "Valor do serviço",
          service_location: "Local do atendimento",
          service_reason: "Motivo do reembolso",
          holder_name: "Nome do titular",
          holder_document: "CPF do titular",
          holder_birth_date: "Data de nascimento",
          bank_data: "Dados bancários",
          bank_data_no_found:
            "Não encontrei os dados bancários na lista acima e quero informar manualmente",
          bank: "Banco",
          bank_branch: "Agência",
          bank_account: "Conta",
          bank_account_digit: "Dígito",
          files: "Fotos do comprovante para reembolso",
          file: "Arquivo",
          add_file: "Adicionar arquivo",
          contacts: "Contatos",
          phone_contact: "Fone",
          mail_contact: "E-mail",
          save: "Solicitar",
          refund_requested: "Reembolso solicitado com sucesso",
          errors: {
            // Step 1
            type_guide: "O tipo de atendimento é obrigatório",
            service_date: "A data de atendimento é obrigatória",
            service_value: "O valor do serviço é obrigatório",
            service_location: "O local do atendimento é obrigatório",
            service_reason: "O motivo do reembolso é obrigatório",
            files: "Ao menos um arquivo é obrigatório",
            file: "O arquivo é obrigatório",
            // Step 2
            bank_data: "Os dados bancários são obrigatórios",
            holder_name: "O nome do titular é obrigatório",
            holder_document: "O CPF do titular é obrigatório",
            bank: "O banco é obrigatório",
            holder_birth_date: "A data de nascimento é obrigatória",
            bank_branch: "A agência é obrigatória",
            bank_account: "A conta é obrigatória",
            bank_account_digit: "O dígito é obrigatório",
            phone_contact: "O telefone é obrigatório",
            mail_contact: "O e-mail é obrigatório",
            mail_contact_val: "Insira um e-mail válido",
          },
        },
      },
      scheduleTeleconsultation: {
        schedule_teleconsultation: "Agendar teleconsulta",
        choose_service: "Qual atendimento você deseja?",
        virtual_emergency_care: "Pronto Atendimento Virtual",
        service: "Atendimento por vídeo com médico, por ordem de acionamento.",
        service_call:
          "Atendimento por telefone com médico, por ordem de acionamento.",
      },
      emergencyCareScheduling: {
        appointment_scheduling: "Agendamento de consulta",
        choose_patient: "Para quem você deseja agendar uma consulta?",
        dependents: "Dependente(s)",
      },
      virtualEmergencyCare: {
        virtual_emergency_care: "Pronto Atendimento Virtual",
        message:
          'Olá, esse é o nosso serviço de Pronto Atendimento Virtual. Aqui você terá acesso a um médico de onde estiver e quando precisar. Informe os sintomas, clique em "Solicitar Atendimento" e aguarde que você já será atendido.',
      },
      faq: {
        faq: "FAQ",
        search: "Pesquisar",
        search_placeholder: "Pesquise por uma pergunta ou resposta",
        no_data: "Nenhum resultado encontrado",
        no_questions_registered: "Nenhuma pergunta cadastrada",
      },
      allNews: {
        title: "Notícias",
        no_news: "Sem notícias para exibir",
        search_label: "Pesquisar",
        search_placeholder: "Pesquisar por notícias e tags",
      },
      news: {
        reading_time: "Tempo estimado de leitura: {{readingTime}} minutos",
        publication_date: "Data de publicação:",
        complementaryMaterial: "Material complementar",
        no_video: "O video não foi encontrado",
      },
      notifications: {
        title: "Notificações",
        mark_all_as_read: "Marcar todas como lida",
      },
      notification: {
        title: "Notificação",
        created: "Data de publicação",
        read: "Data de leitura",
      },
      health_programs: {
        title: "Programas de Saúde",
        search: "Pesquise por um programa de saúde",
        not_participate: "Você ainda não participa de nenhum programa de saúde",
        no_found: "Nenhum programa encontrado",
        myPrograms: "Meus programas",
        otherPrograms: "Outros programas",
        seeMore: "Ver mais",
        exit: "Sair",
        requestToEnter: {
          title: "Não foi possível visualizar",
          description:
            "Você não faz parte deste programa. Deseja solicitar para entrar?",
          button1: "Cancelar",
          button2: "Solicitar",
        },
        refused: {
          title: "Solicitação recusada",
          button1: "Cancelar",
          button2: "Reenviar solicitação",
        },
        hasRequested: {
          title: "Solicitação em andamento",
          description: "Aguarde a resposta dos responsáveis",
          button1: "Ok",
        },
        exitProgram: {
          title: "Sair do programa?",
          description:
            "Tem certeza que deseja sair do programa? Será necessário solicitar permissão para entrar novamente.",
          button1: "Sair do programa",
          button2: "Cancelar",
        },
        dateInfo: "{{date}} - Tempo de de leitura {{readingTime}} min",
      },

      exams: {
        exams: "Resultado exames",
        procedure: "Procedimento",
        type: "Tipo de Procedimento",
        doctor: "Médico",
        unit: "Unidade",
        date: "Data",
        image: "Imagens",
        report: "Laudo",
        search: "Pesquisar",
        date_range_info: "O limite máximo para consulta é de 1 ano",
        patient: "Paciente",
      },
    },
    components: {
      menu: {
        home: "Início",
        cards: "Cartão Virtual",
        whats: "Whatsapp",
        faq: "FAQ",
        virtualEmergencyCare: "Telemedicina",
        schedule: "Agendamentos",
        ticket: "2ª via de boleto",
        co_participation: "Extrato de coparticipação",
        irpf: "IRPF",
        authorizations: "Autorizações",
        plan: "Informações de plano",
        config: "Configurações",
        change_password: "Alterar senha",
        change_language: "Alterar idioma",
        logout: "Sair da conta",
        settings: "Configurações",
        whatsapp: {
          title: "Em desenvolvimento",
          description:
            "Algo incrível está em construção. Em breve estará pronto!",
        },
      },
      backButton: "Voltar",
      private: {
        permission: "Ooops! Você não tem permissão!",
      },
      forgetPasswordForm: {
        title: "Esqueceu a senha?",
        subtitle:
          "Insira seu CPF abaixo e enviaremos um código no seu e-mail para a recuperação de senha.",
        button: "Enviar",
        login: "CPF",
        login_required: "O campo CPF é obrigatório!",
        notification_email_sent: "Email enviado com sucesso!",
        back_login: "Voltar para o login",
        notification_email_confirmed:
          "E-mail confirmado com sucesso, por favor informe seus dados de acesso novamente.",
      },
      resetPasswordForm: {
        title: "Nova senha",
        subtitle:
          "Digite  o código enviado para seu email. Se não encontrá-lo verifique a área de spam.",
        password: "Senha",
        confirm_password: "Confirmar senha",
        confirmation_code: "Código de confirmação",
        new_password_required: "O campo senha é obrigatório.",
        confirm_new_password_required:
          "O campo de confirmar senha é obrigatório.",
        passwords_match: "As senhas não coincidem.",
        confirmation_code_required: "O código de confirmação é obrigatório",
        notification_password_success: "Senha alterada com sucesso!",
        counter: "Reenviar e-mail disponível em {{counter}} segundo(s)",
      },
      themeSwitch: {
        change_theme: "Mudar tema",
      },
      languageSwitch: {
        change_language: "Mudar idioma",
      },
      recents: {
        status: {
          analyze: "Em análise",
          expiresToday: "Vence hoje",
        },
      },
      changeLanguageForm: {
        title: "Alterar idioma",
        language: "Idioma",
        button: "Alterar",
      },
      beneficiaryCard: {
        share: "Com quem estou compartilhando:",
      },
      medicCardShare: {
        share_with:
          "Informe o CPF com o qual deseja compartilhar o cartão virtual {{cardCode}} de {{individualName}}",
        responsibility_message:
          "É de responsabilidade do TITULAR do plano de saúde o compartilhamento e disseminação das informações pessoais contidas nesta carteirinha virtual. O TITULAR dá anuência ao compartilhamento sob sua responsabilidade e conhece a LEI Nº 13.709 que dispõe sobre a proteção de dados pessoais.",
        read: "Li e concordo com os termos acima",
        document: "CPF",
        name: "Nome da pessoa",
        cancel: "Cancelar",
        share: "Compartilhar",
        errors: {
          document: "O CPF é obrigatório",
          name: "O nome é obrigatório",
          terms: "Você precisa concordar com os termos para compartilhar",
        },
        success: "Cartão virtual compartilhado com sucesso",
        deleted: "Você deixou de compartilhar com {{name}}",
      },
      medicCardShared: {
        message:
          "Por favor, digite seu CPF e o código para exibir o cartão compartilhado com você.",
        see_card: "Adicionar cartão",
        document: "CPF",
        code: "Código",
        errors: {
          document: "O CPF é obrigatório",
          code: "O código é obrigatório",
          card_added: "Cartão virtual já adicionado",
        },
      },
      autocomplete: {
        noOptions: "Sem opções",
        hint: "Insira ao menos 3 caracteres para pesquisar",
      },
      navigationCardsPage: {
        title: "Cartão virtual",
        action:
          "Visualize seus cartões virtuais ou cartões compartilhados com você",
      },
      notifications: "Notificações",
      medicGuide: {
        medic_guide: "Guia Médico",
        subtitle: "Pesquise por médico, especialidade, clínica...",
        search: "O que você procura?",
      },
      logout: "Sair",
      no_data: "Sem dados para exibir",
      downloadTicket: {
        ticket_value: "Valor do boleto",
        ticket_payment_date: "Vencimento",
        payment_code: "Código para pagamento",
        copy_code: "Copiar código",
        download_ticket: "Baixar boleto",
        downloading: "Baixando",
        code_copied: "Código copiado",
      },
      documentAcceptance: {
        document_acceptance: "Documentos",
        document_message:
          "Ao aceitar você concorda e aceita os documentos listados abaixo",
        document_accept:
          "Você precisa aceitar os documentos para continuar usando o site",
        document_accepted: "Documentos aceitos com sucesso",
        need_accept: "Você precisa aceitar os termos para continuar",
        accept: "Aceitar",
        image_alt: "Papel",
        language: "Idioma",
        content: "Conteúdo",
      },
      document: {
        acceptIn: "Aceito em {{ date }}",
      },
      autoCompleteVoice: {
        permission: "Você precisa permitir o microfone",
        support: "O navegador não oferece suporte ao reconhecimento de fala",
        no_options: "Sem opções",
        specialty: "Especialidade",
        practice_area: "Área de atuação",
        city: "Cidade",
        health_plan: "Plano",
        professional: "Nome",
        provider_type: "Tipo de prestador",
      },
      avatarDropdown: {
        tooltip_user: "Configurações de conta",
        tooltip_visit: "Acessar",
      },
      resendEmail: {
        title: "Parece que você ainda não confirmou seu e-mail",
        description:
          "Clique no botão abaixo para reenviar o email de confirmação.",
        button: "Reenviar e-mail",
        success: "E-mail reenviado com sucesso!",
      },
      downloadPageButton: {
        error: "O Download falhou",
      },
      procedures: {
        code: "Código",
        authorized_quantity: "Qtde autorizada",
        requested_quantity: "Qtde solicitada",
        pending_execution_quantity: "Qtde Pendente de execução",
        executed_quantity: "Qtde executada",
      },
      stepperActions: {
        back: "Voltar",
        next: "Avançar",
      },
      stepperHeader: {
        errors: {
          fields: "Campo obrigatório em branco ou com erro",
        },
      },
      inputFileStatus: {
        tooltip_view_file: "Visualizar arquivo",
        tooltip_try_again: "Tentar novamente",
      },
      inputFileValidations: {
        invalid_type: "Tipo de arquivo não suportado",
        invalid_size:
          "Tamanho de arquivo não suportado. (Max {{maxFileSize}} MB)",
      },
      refundsModal: {
        title: "Selecione um beneficiário",
        select: "Selecionar",
        beneficiary: "Beneficiário",
        errors: {
          beneficiary: "O beneficiário é obrigatório",
        },
      },
      schedulingBeneficiary: {
        birth_date: "Data de nascimento",
        gender: "Sexo",
        mail: "E-mail",
        phone: "Telefone",
      },
      emergencyCareSymptoms: {
        symptoms: "Quais são seus sintomas?",
        request_service: "Solicitar atendimento",
        requesting: "Solicitando",
        beneficiary_symptoms: "Febre, dor de cabeça...",
        cancel: "Cancelar",
      },
      medicGuideFilter: {
        detailed_search: "Busca detalhada",
        legends: "Legendas",
        plan: "Plano",
        province: "Estado",
        city: "Cidade",
        neighborhood: "Bairro",
        specialty: "Especialidade",
        character_service: "Caráter do atendimento",
        occupation_area: "Área de atuação",
        provider_type: "Tipo de prestador",
        doctor: "Médico",
        search: "Pesquisar",
        name: "Nome",
        emergency: "Apenas atendimentos de urgência e emergência",
        clean: "Limpar filtros",
        no_medic_found: "Nenhum médico encontrado!",
        specialties: "Especialidades",
        practice_area: "Área de atuação",
        phone: "Telefone",
        cellphone: "Celular",
        whatsapp: "Whatsapp",
        website: "Site",
        mail: "E-mail",
        filters_added: "Filtros adicionados com sucesso.",
        filters_removed: "Filtros removidos com sucesso.",
        view_more: "Ver mais",
        view_less: "Ver menos",
      },
      medicGuideActions: {
        list: "Lista",
        map: "Mapa",
      },
      queueModal: {
        go_queue: "Ir para a fila",
        copy_link: "Copiar link",
        link_copied: "Link copiado com sucesso",
        image_alt:
          "Celular com um estetoscópio, símbolo do serviço de pronto atendimento virtual",
        title: "Falta pouco para você ser atendido!",
        subtitle:
          'Você já está na fila, basta clicar no botão "Ir para a fila" e aguardar ser atendido.',
        close: "Fechar",
      },
      lastCalls: {
        last_calls: "Últimos atendimentos",
        return: "Retomar",
        return_tooltip: "Retornar para o atendimento",
        use_symptoms: "Usar sintomas",
        symptoms: "Sintomas",
      },
      displayPlan: {
        plan: "Plano",
      },
      refundSelectBeneficiary: {
        dependents: "Dependente(s)",
      },
      autoCompleteBankData: {
        short_name: "Banco",
        bank_branch: "Agência",
        bank_account: "Conta",
        holder_account: "Titular",
      },
      legends: {
        national: "Padrão nacional de qualidade",
        international: "Padrão internacional de qualidade",
        events_comunication: "Comunicação de eventos adversos",
        monitored_quality: "Qualidade monitorada",
        residence_professional: "Profissional com residência",
        specialty_professional: "Profissional com especialização",
        specialist_title: "Título de especialista",
      },
      avaliationsButtons: {
        title: "Este artigo foi útil?",
      },
      avaliationInput: {
        placeholder:
          "Agradecemos seu retorno, adicione um comentário aqui para ajudar a melhorar o artigo.",
        button: "Enviar comentário",
        sending: "Enviando",
        back: "Voltar",
      },
      rated: {
        message: "Agradecemos seu retorno!",
      },
      registeredModal: {
        title: "Cadastro realizado com sucesso!",
        description:
          "Por favor, clique no botão abaixo e, após, siga os passos para realizar a confirmação do seu e-mail.",
        button: "Ok",
      },
      emailConfirmedModal: {
        title: "E-mail confirmado com sucesso!",
        description:
          "Agora você já pode acessar sua conta, basta clicar no botão abaixo e seguir os passos para acessar sua conta.",
        button: "Ok",
      },
      emailResent: {
        title: "E-mail reenviado com sucesso!",
        description:
          "Reenviamos o código para o e-mail cadastrado, cheque seu e-mail, copie o código de confirmação, clique no botão abaixo e siga os passos para confirmar seu e-mail.",
        button: "Ok",
      },
      registerError: {
        button: "Ok",
      },
      emailErrorCode: {
        title: "Código inválido",
        description:
          "Por favor verifique o código, ou tente reenviar o e-mail.",
        button: "ok",
      },
      homeBannersImage: {
        tooltip: "Não exibir mais esse banner",
      },
      closeAllBanners: {
        button: "Não exibir mais esses banners",
      },
      homeBanners: {
        notification: "O Banner não será mais exibido",
        all_banners_notification: "Os Banners não serão mais exibidos",
      },
      newsList: {
        news: "Notícias",
        view_more: "Ver mais",
      },
      recentNews: {
        no_news: "Sem notícias recentes para exibir",
      },
      newsCard: {
        reading_time: "Tempo estimado de leitura",
      },
      notificationsButton: {
        tooltip: "Notificações",
      },
      noNotifications: {
        recent_message: "Sem notificações recentes",
        message: "Sem notificações",
      },
      unreadNotificationItem: {
        mark_as_read: "Marcar como lida",
      },
      unreadNotifications: {
        notifications: "Notificações",
      },
      unreadNotificationsActions: {
        view_all: "Ver todas",
        mark_all_as_read: "Marcar todas como lida",
      },
      notificationCard: {
        new: "Nova",
        mark_as_read: "Marcar como lida",
        created: "Data de publicação",
        read: "Data de leitura",
      },
      notificationNotFound: {
        message: "Notificação não encontrada",
      },
      noRowsMessage: {
        no_permission:
          "Você não possui permissão para acessar essas informações",
        no_data: "Sem dados para exibir",
      },
      examsDownload: {
        open_report: "Abrir laudo",
        report: "Laudo",
      },
      examsPdf: {
        no_data: "Arquivo não encontrado ou vazio",
      },
      examsRedirectFile: {
        download_image: "Baixar imagem(ns)",
      },
      pagesActions: {
        previous_page: "Página anterior",
        next_page: "Próxima página",
        zoom_out: "Diminuir zoom",
        zoom_in: "Aumentar zoom",
        pages: "Página {{pageNumber}} de {{numPages}}",
        download_pdf: "Baixar PDF",
        print: "Imprimir",
      },
      accessTemporaryMessage: {
        access_temporary: "Acesso temporário",
        register:
          "Cadastre-se para ter acesso a todos os recursos do nosso portal",
        login:
          "Acesse sua conta para ter acesso a todos os recursos do nosso portal",
        register_btn: "Cadastre-se",
        login_btn: "Acessar",
      },
      columnsSelect: {
        visible_columns: "Colunas visíveis",
        subtitle: "Selecione as colunas que deseja visualizar",
        select_all: "Selecionar todas",
      },
      procedureType: {
        all: "Todos",
        laboratorial: "Laboratorial",
        no_laboratorial: "Não Laboratorial",
      },
      dateRange: {
        start_date: "Data de início",
        end_date: "Data de fim",
      },
      renderPDF: {
        error: "Erro ao carregar o arquivo",
      },
      examsRedirectModal: {
        title: "Imagem(ns)",
      },
      fileItem: {
        download_file: "Download",
        preview: "Visualizar",
        image: "Imagem",
        open_new_tab: "Abrir arquivo em nova guia",
      },
      accessPortalBtn: {
        button: "Acesse nosso portal",
      },
      formSucceeded: {
        title: "Agradecemos sua participação!",
        subtitle: "Sua resposta ao nosso formulário é muito valiosa para nós.",
        button: "Ir para o portal",
      },
      formNotFound: {
        message: "Formulário não encontrado",
        subtitle: "Por favor, verifique a URL e tente novamente",
        button: "Ir para o portal",
      },
      formStepsActions: {
        back: "Voltar",
        next: "Próximo",
        end: "Finalizar",
      },
      step: {
        answer_success: "Resposta enviada com sucesso",
        update_answer_success: "Resposta atualizada com sucesso",
        errors: {
          alternative: "A alternativa é obrigatória",
        },
      },
      whatsappNumber: {
        title: "Número do WhatsApp",
        whatsapp_number: "Número do WhatsApp",
        success: "Número do WhatsApp alterado com sucesso",
        cancel: "Cancelar",
        save: "Salvar",
        errors: {
          whatsapp_number: "O número do WhatsApp é obrigatório",
        },
      },
      reports: {
        reports: "Laudos",
        view_reports: "Ver laudos",
        back: "Voltar",
        file: "Arquivo",
        view: "Visualizar",
        download: "Baixar",
      },
      checkInButton: {
        check_in: "Realizar check in",
        checked_in: "Check in já realizado, favor dirigir-se ao local:",
        location:
          "Para realizar o check in é preciso permitir o acesso a localização",
        is_late: "A data limite para Check In já passou",
        is_in_time: "O Check in poderá ser feito apenas {{time}} minutos antes",
        is_near:
          "Você precisa estar a menos de {{meters}}m para fazer o Check in",
      },
      checkedIn: {
        message: "Check in realizado com sucesso, favor dirigir-se ao local:",
      },
      reportsModal: {
        loading: "Carregando...",
        not_found: "Arquivo não encontrado",
        file: "Arquivo",
        actions: {
          open: "Abrir",
          view: "Visualizar",
          download: "Baixar",
        },
      },
    },
    logout: "Sair",
    whatsapp_number: "Número do WhatsApp",
    change_password: "Alterar senha",
    change_language: "Alterar idioma",
    access_denied: "Você precisa estar conectado para acessar esta pagina",
    login_expired: "Sua sessão expirou.Logue Novamente",
  },
};

export default pt_BR;
