import { memo } from "react";
import { Stack, Typography } from "@mui/material";

import { IHealthProgram } from "../../../models/health-programs";
import HealthProgramItemHeader from "./HealthProgramItemHeader/HealthProgramItemHeader";
import HealthProgramItemContent from "./HealthProgramItemContent";
import useHealthProgram from "./HealthProgramItemHeader/useHealthProgram";
import { defaultScrollTheme } from "../../../utils/utils";

interface IHealthProgramProps {
  program: IHealthProgram;
}

const HealthProgram = ({ program }: IHealthProgramProps) => {
  const hasImage = program.contentsHealthProgram.length > 0;

  const { requestToEnter } = useHealthProgram(program);

  return (
    <Stack gap={2}>
      <HealthProgramItemHeader program={program} />
      {hasImage && (
        <Stack
          flexDirection={"row"}
          flexWrap={"nowrap"}
          gap={2}
          sx={defaultScrollTheme}
          padding={1}
          overflow={"auto"}
          width={"100%"}
        >
          {program.contentsHealthProgram.map((content) => (
            <HealthProgramItemContent content={content} onClick={()=> requestToEnter(content.id)} key={content.content.id} />
          ))}
        </Stack>
      )}
      {!hasImage && (
        <Typography
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
          }}
        >
          {program.description}
        </Typography>
      )}
    </Stack>
  );
};

export default memo(HealthProgram);
