import { Stack } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import { memo, useCallback, useState } from "react";

import { IPortalBanner } from "../../../models/banners";
import Button from "../../UI/Button";

interface ICloseAllBannersProps {
  closeAllBanners: (banners: IPortalBanner[]) => Promise<void>;
  banners: IPortalBanner[];
}

const translationPath = "components.closeAllBanners.";

const CloseAllBanners = ({
  closeAllBanners,
  banners,
}: ICloseAllBannersProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const onClickHandler = useCallback(async () => {
    setLoading(true);
    await closeAllBanners(banners);
    setLoading(false);
  }, [closeAllBanners, banners]);

  return (
    <Stack flexDirection={"row"} justifyContent={"start"}>
      <Button
        size="small"
        variant="outlined"
        loading={loading}
        onClick={onClickHandler}
        startIcon={<VisibilityOffIcon />}
      >
        {t(translationPath + "button")}
      </Button>
    </Stack>
  );
};

export default memo(CloseAllBanners);
