import {
  Box,
  IconButton,
  Modal,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import Carousel from "../UI/Carousel";
import { useCallback, useEffect, useState } from "react";
import MedicCard from "./MedicCard";
import MedicCardLacks from "./MedicCardLacks";
import { IMedicCard } from "../../models/medic-card";
import useCards from "../../hooks/useCards";
import { tokenService } from "../../services/token.service";

interface IBeneficiaryCardModal {
  openCard: boolean;
  handleCloseCard: () => void;
  card: IMedicCard;
}

const BeneficiaryCardModal = ({
  openCard,
  handleCloseCard,
  card,
}: IBeneficiaryCardModal) => {
  const [selected, setSelected] = useState(0);
  const token = tokenService.getAccessToken();

  const { cardImage, cardBackImage, loadCardImage, vLoading, loading } =
    useCards();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const loadVisitCardsData = useCallback(() => {
    loadCardImage({
      visitUserData: {
        Document: card.visitCard?.document!,
        ShareCode: card.visitCard?.code!,
      },
    });
  }, [loadCardImage, card.visitCard?.document, card.visitCard?.code]);

  const loadUserCardsData = useCallback(() => {
    loadCardImage({
      card: card,
    });
  }, [card, loadCardImage]);

  useEffect(() => {
    if (token && openCard) {
      loadUserCardsData();
    }
    if (!token && openCard) {
      loadVisitCardsData();
    }
  }, [loadVisitCardsData, loadUserCardsData, token, openCard]);

  return (
    <Modal open={openCard} onClose={handleCloseCard}>
      <Stack>
        {matches && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              marginLeft: "80px",
              bottom: 25,
            }}
            position={"relative"}
          >
            <IconButton
              sx={{
                position: "relative",
                top: -140,
                left: 590,
                color: "#fff",
                zIndex: 10,
              }}
              onClick={handleCloseCard}
            >
              <CloseIcon />
            </IconButton>
            <Box>
              <Carousel
                onChange={(i) => setSelected(i)}
                viewType="browser"
                selected={selected}
                slides={[
                  <Stack mt={2} alignItems={"center"}>
                    <MedicCard
                      cardImage={cardImage}
                      loading={loading}
                      vLoading={vLoading}
                    />
                  </Stack>,
                  <Stack mt={2} alignItems={"center"}>
                    <MedicCardLacks
                      cardImage={cardBackImage}
                      loading={loading}
                      vLoading={vLoading}
                    />
                  </Stack>,
                ]}
              />
            </Box>
          </Box>
        )}
        {!matches && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              bottom: -220,
            }}
            position={"relative"}
          >
            <IconButton
              sx={{
                position: "relative",
                top: 400,
                left: 150,
                color: "#fff",
                zIndex: 10,
              }}
              onClick={handleCloseCard}
            >
              <CloseIcon />
            </IconButton>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Carousel
                onChange={(i) => setSelected(i)}
                selected={selected}
                slides={[
                  <Stack alignItems={"center"}>
                    <MedicCard
                      cardImage={cardImage}
                      loading={loading}
                      vLoading={vLoading}
                    />
                  </Stack>,
                  <Stack alignItems={"center"}>
                    <MedicCardLacks
                      cardImage={cardBackImage}
                      loading={loading}
                      vLoading={vLoading}
                    />
                  </Stack>,
                ]}
              />
            </Box>
          </Box>
        )}
      </Stack>
    </Modal>
  );
};

export default BeneficiaryCardModal;
