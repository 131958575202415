import {
  Box,
  Button,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { forgetPasswordFormSchema } from "./Auth/ForgetPasswordForm/ForgetPasswordSchema";
import SelectLanguage from "./i18n/SelectLanguage";
import i18n from "../i18n";
import { useAppDispatch, useAppSelector } from "../store/store";
import { tokenService } from "../services/token.service";
import { saveConfig } from "../store/thunks/ConfigurationsThunk";
import { languageSliceActions } from "../store/slices/language";

const ChangeLanguageForm = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();

  const [language, setLanguage] = useState<string>(i18n.language);

  const token = tokenService.getAccessToken();

  const {
    configurations: userConfig,
    language: { languages },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const methods = useForm<any>({
    resolver: zodResolver(forgetPasswordFormSchema),
  });

  const handleChangeLanguage = (event: SelectChangeEvent) => {
    const language = event.target.value;
    setLanguage(language);
  };

  const changeLanguage = async () => {
    const actualLang = languages.find((lang) => lang.code === language);

    if (actualLang) {
      if (token) {
        await dispatch(
          saveConfig({
            language: { ...userConfig.language, id: actualLang.id },
          })
        );
      }
      dispatch(languageSliceActions.saveLanguageId(actualLang?.id));
      localStorage.setItem("lang", actualLang.id);
      i18n.changeLanguage(language);
    }

    onClose();
  };

  return (
    <Box width={matches ? 300 : 240}>
      <FormProvider {...methods}>
        <Typography variant="h6" align="center" mb={3}>
          {t("components.changeLanguageForm.title")}
        </Typography>
        <Stack mb={2}>
          {languages && (
            <SelectLanguage
              languages={languages}
              onSelect={handleChangeLanguage}
              value={language}
            />
          )}
        </Stack>
        <Button fullWidth variant="contained" onClick={changeLanguage}>
          {t("components.changeLanguageForm.button")}
        </Button>
      </FormProvider>
    </Box>
  );
};

export default ChangeLanguageForm;
