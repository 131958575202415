import { Stack } from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import HelpModal from "../../../../components/HelpModal";
import PagesContainer from "../../../../components/UI/PagesContainer";
import useFetch from "../../../../hooks/useFetch";
import { ScheduleAppointmentService } from "../../appointment.service";
import {
  ETasyScheduleTypeCode,
  EcalendarStatusCode,
  IExamProcedure,
  IScheduleAppointment,
  ITasySchedCheckInRule,
  ITasySchedConfirmationRule,
} from "../../model";
import ConfirmDate from "../../NewSchedule/Stepper/ConfirmDate";
import CancelScheduleButton from "./CancelSchedule/CancelScheduleButton";
import { Confirmation } from "./Confirmation";
import { ConsultData } from "./ConsultData";
import { Procedure } from "./Procedure";
import RescheduleButton from "./RescheduleButton";

const ScheduleDetails = () => {
  const [appointment, setAppointment] = useState<IScheduleAppointment>();

  const [checkInRule, setCheckInRule] = useState<ITasySchedCheckInRule | null>(
    null
  );

  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  const [confirmationRule, setConfirmationRule] =
    useState<ITasySchedConfirmationRule>();

  const [procedure, setProcedure] = useState<IExamProcedure>();

  const { identifier, tasySchedulingTypeCode } = useParams();

  const { sendRequest } = useFetch(
    ScheduleAppointmentService.getConsulationScheduleAppointment
  );

  const { sendRequest: examRequest } = useFetch(
    ScheduleAppointmentService.getExamScheduleAppointment
  );

  const isExam = useMemo(
    () => tasySchedulingTypeCode === ETasyScheduleTypeCode.EXAM,
    [tasySchedulingTypeCode]
  );

  const fetchConsult = useCallback(async () => {
    if (!identifier) return;
    const { data, success } = await sendRequest(Number(identifier));
    if (success && data) {
      setAppointment(data.scheduleAppointment);
      setConfirmationRule(data.tasySchedConfirmationRule);
      setCheckInRule(data.tasySchedCheckInRule);
    }
  }, [sendRequest, identifier]);

  const fetchExam = useCallback(async () => {
    if (!identifier) return;
    const { data, success } = await examRequest(Number(identifier));
    if (success && data) {
      setProcedure(data.scheduleAppointment);
      setAppointment(data.scheduleAppointment);
      setConfirmationRule(data.tasySchedConfirmationRule);
    }
  }, [examRequest, identifier]);

  const getData = useCallback(async () => {
    setLoading(true);
    if (isExam) {
      await fetchExam();
    } else {
      await fetchConsult();
    }
    setLoading(false);
  }, [isExam, fetchConsult, fetchExam]);

  const handleConfirm = useCallback(() => {
    setAppointment((old) => ({
      ...old!,
      calendarStatusCode: EcalendarStatusCode.CONFIMED,
      calendarStatusName: "Confirmado",
    }));
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const canCancel = useMemo(
    () =>
      appointment &&
      dayjs(appointment?.calendarDate).isAfter(new Date()) &&
      appointment.calendarStatusCode !== EcalendarStatusCode.CANCELED,
    [appointment]
  );

  const canConfirm = useMemo(
    () =>
      appointment &&
      dayjs(appointment.calendarDate).isAfter(new Date()) &&
      appointment.calendarStatusCode === EcalendarStatusCode.NORMAL &&
      tasySchedulingTypeCode &&
      identifier,
    [appointment, tasySchedulingTypeCode, identifier]
  );

  return (
    <PagesContainer
      title={t("page.schedules.scheduleDetails.title")}
      loading={loading}
      rowActions={
        confirmationRule &&
        canConfirm && (
          <HelpModal
            title={t("page.schedules.scheduleDetails.confirmModal.title")}
            description={t(
              "page.schedules.scheduleDetails.confirmModal.description",
              { min: confirmationRule.time }
            )}
          />
        )
      }
    >
      <Stack height={"100%"} overflow={"auto"} justifyContent={"space-between"}>
        <Stack gap={1}>
          {appointment && <ConsultData data={appointment} />}
          {procedure && <Procedure data={procedure} />}
          {appointment && (
            <ConfirmDate
              startHour={dayjs(appointment.calendarStartDate)}
              duration={appointment.minutesDuration}
              scheduleType={String(appointment.tasyScheduleTypeCode)}
              scheduleDate={appointment.calendarStartDate!}
              roomName={appointment.roomName}
              checkInRule={checkInRule ?? undefined}
              encounterIdentifier={appointment.encounterIdentifier}
              identifier={Number(identifier)}
            />
          )}
        </Stack>
        <Stack flexDirection={"row"} gap={2} alignSelf={"flex-end"}>
          {canCancel && (
            <RescheduleButton
              appointment={appointment!}
              tasySchedulingTypeCode={tasySchedulingTypeCode!}
            />
          )}

          {canCancel && (
            <CancelScheduleButton
              appointment={appointment!}
              onCancelled={getData}
              tasySchedulingTypeCode={tasySchedulingTypeCode!}
            />
          )}

          {canConfirm && (
            <Confirmation
              onConfirmed={handleConfirm}
              identifier={Number(identifier)}
              identifierCalendar={appointment!.calendarIdentifier}
              type={tasySchedulingTypeCode!}
              rule={confirmationRule}
              date={appointment!.calendarDate}
            />
          )}
        </Stack>
      </Stack>
    </PagesContainer>
  );
};

export default ScheduleDetails;
