import { Box, Tooltip } from "@mui/material";
import IconButton from "../../IconButton";
import TuneIcon from "@mui/icons-material/Tune";
import { IColumsSelect } from "./models";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import ColumnList from "./ColumnList";
import ColumnsHeader from "./ColumnsHeader";
import ColumnsPopover from "./ColumnsPopover";

const translationPath = "components.columnsSelect.";

const ColumnsSelect = ({
  columns,
  columnVisibilityModel,
  VisibilityChanges,
}: IColumsSelect) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleChange = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    VisibilityChanges({
      ...columnVisibilityModel,
      [field]: event.target.checked,
    });
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newColumnVisibilityModel = { ...columnVisibilityModel };
    Object.keys(newColumnVisibilityModel).forEach((column) => {
      newColumnVisibilityModel[column] = event.target.checked;
    });
    VisibilityChanges(newColumnVisibilityModel);
  };

  const getColumnName = (field: string) => {
    const columnFind = columns.find((c) => c.field === field);
    if (columnFind) {
      return columnFind.headerName || columnFind.field;
    }
    return field;
  };

  return (
    <>
      <Tooltip title={t(translationPath + "visible_columns")}>
        <Box>
          <IconButton onClick={handleClick}>
            <TuneIcon />
          </IconButton>
        </Box>
      </Tooltip>
      <ColumnsPopover open={open} anchorEl={anchorEl} handleClose={handleClose}>
        <>
          <ColumnsHeader
            columnVisibilityModel={columnVisibilityModel}
            handleSelectAll={handleSelectAll}
          />
          <ColumnList
            columnVisibilityModel={columnVisibilityModel}
            handleChange={handleChange}
            getColumnName={getColumnName}
          />
        </>
      </ColumnsPopover>
    </>
  );
};

export default memo(ColumnsSelect);
