import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Avatar, Grid, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useAppSelector } from "../../../../store/store";
import MenuUserActions from "./MenuUserActions";
import MenuDrawerLogout from "./MenuDrawerLogout";
import MenuItems from "./MenuItems";
import { INavigationLink } from "../../../../models/navigation";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { tokenTemporaryService } from "../../../../services/tokenTemorary.service";

const translationPath = "components.menu.";
interface RightDrawerProps {
  open: boolean;
  toggleMenu: (open: boolean) => void;
  openModalHandler: () => void;
}

const MenuDrawer: React.FC<RightDrawerProps> = ({
  open,
  toggleMenu,
  openModalHandler,
}) => {
  const { t } = useTranslation();

  const { fullName, email } = useAppSelector((state) => state.user);

  const userTemporary = tokenTemporaryService.getTemporaryAccessName();

  const items: INavigationLink[] = useMemo(
    () => [
      {
        name: t(translationPath + "settings"),
        iconClass: "SettingsIcon",
        link: "/settings",
      },
      {
        name: t("page.documents.title"),
        iconClass: "HistoryEduIcon",
        link: "/docs",
      },
    ],
    [t]
  );

  const name = fullName || userTemporary;

  return (
    <SwipeableDrawer
      anchor={"right"}
      open={open}
      onClose={() => toggleMenu(false)}
      onOpen={() => toggleMenu(true)}
    >
      <Box
        sx={{ width: "100vw" }}
        role="presentation"
        onKeyDown={() => toggleMenu(false)}
      >
        <Grid
          container
          sx={(theme) => ({
            backgroundColor:
              theme.palette.mode === "light"
                ? theme.palette.grey[300]
                : theme.palette.grey[600],
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[600]
                : theme.palette.grey[200],
          })}
          p={2}
          spacing={2}
          mb={2}
        >
          <Grid item xs={12} sm={12} md={12}>
            <IconButton onClick={() => toggleMenu(false)} sx={{ padding: 0 }}>
              <CloseIcon
                sx={(theme) => ({
                  color:
                    theme.palette.mode === "light"
                      ? theme.palette.grey[600]
                      : theme.palette.grey[200],
                })}
              />
            </IconButton>
          </Grid>
          <Grid item xs={12} sm={12} md={12} justifyContent={"start"}>
            {name && (
              <Stack direction={"row"} alignItems={"center"} gap={2}>
                <Avatar
                  sx={(theme) => ({
                    width: 45,
                    height: 45,
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? theme.palette.grey[600]
                        : theme.palette.grey[400],
                  })}
                >
                  {name.charAt(0).toUpperCase()}
                </Avatar>
                <Grid container direction={"column"}>
                  <Grid item height={0} mb={3}>
                    <Typography variant="h6" fontWeight={700}>
                      {name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      sx={(theme) => ({
                        color:
                          theme.palette.mode === "light"
                            ? theme.palette.grey[600]
                            : theme.palette.grey[400],
                      })}
                    >
                      {email}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
            )}
          </Grid>
        </Grid>

        <MenuUserActions
          toggleMenu={toggleMenu}
          openModalHandler={openModalHandler}
        />

        {fullName && <MenuItems toggleMenu={toggleMenu} items={items} />}

        {fullName ||
          (userTemporary && <MenuDrawerLogout toggleMenu={toggleMenu} />)}
      </Box>
    </SwipeableDrawer>
  );
};

export default MenuDrawer;
