import { Extension } from "../../models/intalled-extensions";
import DomainContextProvider from "../../store/DomainContext";
import ExtensionsContextProvider from "../../store/ExtensionsContext";
import NotificationsButton from "./NotificationsButton";

const Notifications = () => {
  return (
    <ExtensionsContextProvider
      value={{ extensionCode: Extension.NOTIFICATIONS }}
      redirect={false}
    >
      <DomainContextProvider
        value={{ domainName: "notification" }}
        redirect={false}
      >
        <NotificationsButton />
      </DomainContextProvider>
    </ExtensionsContextProvider>
  );
};

export default Notifications;
