import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { saveConfig } from "../thunks/ConfigurationsThunk";
import { ISelectType } from "../../components/UI/Inputs/AutoComplete";

export type TAppreanceCode = "1" | "2";
export interface IConfigurationState {
  parentName: string;
  language: ISelectType;
  webAppearance: {
    code: TAppreanceCode;
  };
  mediaFileIds: string[];
  deleteMediaFileIds: string[];
}

const USER_CONFIGS_INITIAL_STATE: IConfigurationState = {
  parentName: "ApplicationUser",
  language: {
    id: "",
    code: "",
    name: "",
  },
  webAppearance: {
    code: "1",
  },
  mediaFileIds: [],
  deleteMediaFileIds: [],
};

export const slice = createSlice({
  name: "configurations",
  initialState: USER_CONFIGS_INITIAL_STATE,
  reducers: {
    saveConfigs(
      state: IConfigurationState,
      action: PayloadAction<IConfigurationState>
    ) {
      return { ...state, ...action.payload };
    },
    removeConfigs(state) {
      return (state = USER_CONFIGS_INITIAL_STATE);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(saveConfig.fulfilled, (state, action) => {
      state.language = action.payload.language;
      state.webAppearance = action.payload.softwareAppearance;
    });
  },
});

export const configurationsSliceActions = slice.actions;

export default slice.reducer;
