import { Navigate } from "react-router-dom";
import { IPrivateRoutes } from "../models/private-routes";
import { useDispatch } from "react-redux";
import { notificationSliceActions } from "../store/slices/notification";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { tokenService } from "../services/token.service";

const PrivateRoutes: React.FC<IPrivateRoutes> = ({
  canAccess,
  children,
  redirectUrl,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = tokenService.hasAccessTokens();

  useEffect(() => {
    if (!canAccess()) {
      dispatch(
        notificationSliceActions.showNotification({
          message: `${t("access_denied")}`,
          type: "error",
        })
      );
    }
    if (token) {
      dispatch(notificationSliceActions.closeNotification());
    }
  }, [canAccess, dispatch, t, token]);

  if (canAccess()) return children;
  return <Navigate to={redirectUrl} />;
};

export default PrivateRoutes;
