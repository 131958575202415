import { Box, Typography } from "@mui/material";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import { useTranslation } from "react-i18next";

const translationPath = "components.noNotifications.";

interface INoNotificationsProps {
  isRecent?: boolean;
}

const NoNotifications = ({ isRecent = false }: INoNotificationsProps) => {
  const { t } = useTranslation();

  return (
    <Box
      my={4}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={0.5}
    >
      <NotificationsOffIcon fontSize={isRecent ? "medium" : "large"} />
      <Typography variant="subtitle1">
        {isRecent
          ? t(translationPath + "recent_message")
          : t(translationPath + "message")}
      </Typography>
    </Box>
  );
};

export default NoNotifications;
