import { useCallback, useEffect } from "react";
import ReactGA from "react-ga4";

import useFetch from "./useFetch";
import { getParam } from "../services/params.service";

const GA_KEY_PARAM_CODE = 6;

export const useGaPageView = (pathname: string) => {
  const { sendRequest } = useFetch(getParam);

  const getGAKey = useCallback(async () => {
    const { data, success } = await sendRequest(GA_KEY_PARAM_CODE);
    if (success && data?.length > 0) {
      ReactGA.initialize(data[0].value);
    }
  }, [sendRequest]);

  useEffect(() => {
    getGAKey();
  }, [getGAKey]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: pathname,
    });
  }, [pathname]);
};
