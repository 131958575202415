import { z } from "zod";
import { CPF_VALIDATOR } from "../../utils/Validators/regexValidators";

const translationPath = "components.medicCardShared.errors.";

export const medicCardSharedSchema = z.object({
  Document: z.string().regex(CPF_VALIDATOR, translationPath + "document"),
  ShareCode: z.string().nonempty(translationPath + "code"),
});

export type TMedicSharedForm = z.infer<typeof medicCardSharedSchema>;
