import { Stack, Typography } from "@mui/material";
import NewspaperIcon from "@mui/icons-material/Newspaper";

interface INoNewsProps {
  message: string;
}

const NoNews = ({ message }: INoNewsProps) => {
  return (
    <Stack
      mt={1}
      width={"100%"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <NewspaperIcon fontSize="large" />
      <Typography variant="subtitle2">{message}</Typography>
    </Stack>
  );
};

export default NoNews;
