import { FormControlLabel, Switch } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { ISwitchInput } from "./models";

export const SwitchInput = ({ label, name }: ISwitchInput) => {
  const { control } = useFormContext();
  return (
    <Controller
      render={({ field }) => {
        const { onChange, value } = field;
        return (
          <FormControlLabel
            control={
              <Switch
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
              />
            }
            label={label}
            {...field}
          />
        );
      }}
      defaultValue={true}
      name={name}
      control={control}
    />
  );
};
