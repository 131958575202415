import { z } from "zod";

const reasonSchema = z.object(
  {
    id: z.string(),
    code: z.string(),
  },
  {
    required_error: "page.schedules.scheduleDetails.cancel.reason.error",
    invalid_type_error: "page.schedules.scheduleDetails.cancel.reason.error",
  }
);

export const cancelSchema = z.object({
  reason: reasonSchema,
  justification: z.string({
    required_error: "page.schedules.scheduleDetails.cancel.justification.error",
    invalid_type_error: "page.schedules.scheduleDetails.cancel.justification.error",
  }).min(1,"page.schedules.scheduleDetails.cancel.justification.error"),
});

export type TCancel = z.infer<typeof cancelSchema>;
