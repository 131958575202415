import { Stack, useTheme } from "@mui/material";
import { IFaqQuestions } from "../../models/faq";
import AccordionComponent from "../UI/Accordion/Accordion";
import AvaliationsButtons from "./Avaliations/AvaliationsButtons";
import AvaliationInput from "./Avaliations/AvaliationInput";
import { useCallback, useState } from "react";
import Rated from "./Avaliations/Rated";

const QuestionAnswerAccordion = ({ qa }: { qa: IFaqQuestions }) => {
  const [dislike, setDislike] = useState(false);
  const [rated, setRated] = useState(false);
  const theme = useTheme();

  const onRated = useCallback((value: boolean) => {
    setRated(value);
  }, []);

  const backHandler = () => setDislike(false);

  return (
    <AccordionComponent title={qa.question}>
      <Stack mb={1}>
        <div
          dangerouslySetInnerHTML={{
            __html: qa.answer
              .split("rgb(0, 0, 0)")
              .join(
                theme.palette.mode === "dark" ? "rgb(255, 255, 255)" : undefined
              ),
          }}
        />
      </Stack>
      {rated && <Rated />}
      {!rated && (
        <Stack
          width={"100%"}
          flexDirection={"column"}
          gap={1}
          sx={(t) => ({
            p: 2,
          })}
        >
          {!dislike && (
            <AvaliationsButtons
              onDislike={setDislike}
              faqQuestionId={qa.id}
              onRated={onRated}
            />
          )}
          {dislike && (
            <AvaliationInput
              faqQuestionId={qa.id}
              onRated={onRated}
              onBack={backHandler}
            />
          )}
        </Stack>
      )}
    </AccordionComponent>
  );
};

export default QuestionAnswerAccordion;
