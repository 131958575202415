import { Grid, Stack, Tooltip, Typography } from "@mui/material";
import Table from "../UI/Table/Table";
import NoData from "../UI/NoData";
import { useTranslation } from "react-i18next";
import {
  ICoParticipation,
  ICoParticipations,
} from "../../models/co-participation";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import DisplayDate from "../DisplayDate";
import { formatValue } from "../../utils/conversions";
import DisplayPlan from "../UI/DisplayPlan";

const translationPath = "page.extractCoParticipation.";

interface IExtractCoParticipationTable {
  coParticipations: ICoParticipations[];
}

const ExtractCoParticipationTable = ({
  coParticipations,
}: IExtractCoParticipationTable) => {
  const { t } = useTranslation();

  const columns: GridColDef<any>[] = [
    {
      field: "beneficiaryname",
      headerName: `${t(translationPath + "beneficiary")}`,
      minWidth: 200,
    },
    {
      field: "procedurename",
      headerName: `${t(translationPath + "procedure")}`,
      minWidth: 450,
      flex: 1,
    },
    {
      field: "proceduredate",
      headerName: `${t(translationPath + "procedure_date")}`,
      maxWidth: 100,
      renderCell: (params) => {
        return <DisplayDate>{dayjs(params.value)}</DisplayDate>;
      },
    },
    {
      field: "approvedquantity",
      headerName: `${t(translationPath + "quantity")}`,
      maxWidth: 100,
    },
    {
      field: "providername",
      headerName: `${t(translationPath + "provider")}`,
      minWidth: 280,
      flex: 1,
      renderCell: (params) => {
        const row: ICoParticipation = params.row;
        if (row.providername && row.doctorname)
          return (
            <Tooltip title={row.providername + " - " + row.doctorname}>
              <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {row.providername + " - " + row.doctorname}
              </span>
            </Tooltip>
          );
        if (row.doctorname) return row.doctorname;
        return params.value;
      },
    },
    {
      field: "amountcoparticipation",
      headerName: `${t(translationPath + "value")}`,
      width: 120,
      renderCell: (params) => {
        return formatValue(params.value);
      },
    },
  ];

  return (
    <Grid item container xs={12} sm={12} md={12} xl={12}>
      {coParticipations.map((item) => (
        <Grid item xs={12} sm={12} md={12} mt={2} key={item.planName}>
          <DisplayPlan planName={item.planName} />
          {item.coparticipationStatements && (
            <>
              <Stack
                key={item.planName}
                direction={"row"}
                alignItems={"end"}
                justifyContent={"end"}
                gap={1}
                mt={1}
              >
                <Typography
                  variant="subtitle1"
                  sx={(theme) => ({
                    color:
                      theme.palette.mode === "light"
                        ? theme.palette.grey[600]
                        : theme.palette.grey[400],
                  })}
                >
                  {t(translationPath + "total_plan")}:
                </Typography>
                <Typography variant="h6">
                  {formatValue(
                    item.coparticipationStatements
                      .map((item) => item.amountcoparticipation)
                      .reduce((partialSum, a) => partialSum + a, 0)
                  )}
                </Typography>
              </Stack>
              <Table
                columns={columns}
                rows={item.coparticipationStatements.map((row) => ({
                  ...row,
                  id: row.uniqueId,
                }))}
                rowHeight={70}
              />
            </>
          )}
          {!item.coparticipationStatements && (
            <Grid container mt={5}>
              <Grid item xs={12} sm={12}>
                <NoData />
              </Grid>
            </Grid>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default ExtractCoParticipationTable;
