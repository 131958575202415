import { memo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { TFamilyMember, familyMemberSchema } from "./schema";
import { useFamilyGroup } from "./useFamilyGroup";
import AddFamilyGroupForm from "./AddFamilyGroupForm";
import PagesContainer from "../../../../components/UI/PagesContainer";

export const KEYS = familyMemberSchema.keyof().Enum;

const AddFamilyGroup = () => {
  const methods = useForm<TFamilyMember>({
    resolver: zodResolver(familyMemberSchema),
  });

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { isEditMode } = useFamilyGroup(methods);

  const handleSuccess = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <PagesContainer
      title={t(
        `page.schedules.addFamilyGroup.${isEditMode ? "titleEdit" : "titleAdd"}`
      )}
    >
      <AddFamilyGroupForm
        onSuccess={handleSuccess}
        sx={(t) => ({
          [t.breakpoints.up("md")]: {
            width: "60%",
            mt: 1,
          },
        })}
      />
    </PagesContainer>
  );
};

export default memo(AddFamilyGroup);
