import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import useFetch from "../../../../hooks/useFetch";
import { useAppDispatch } from "../../../../store/store";
import { notificationSliceActions } from "../../../../store/slices/notification";
import { ScheduleAppointmentService } from "../../appointment.service";
import { ETasyScheduleTypeCode } from "../../model";

interface IUseConfirmationProps {
  type: string;
  date: string;
  identifier: number;
  identifierCalendar: number;
  onConfirmed: () => void;
}

export const useConfirmation = ({
  identifierCalendar,
  date,
  identifier,
  type,
  onConfirmed,
}: IUseConfirmationProps) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { sendRequest: confirmConsultationRequest } = useFetch(
    ScheduleAppointmentService.calendarConsultationScheduleConfirmation
  );

  const { sendRequest: confirmExamRequest } = useFetch(
    ScheduleAppointmentService.calendarExamScheduleConfirmation
  );

  const confirmConsultation = useCallback(async () => {
    const { success } = await confirmConsultationRequest({
      identifier,
      identifierCalendar,
    });
    if (success) {
      onConfirmed();
      dispatch(
        notificationSliceActions.showNotification({
          message: t("page.schedules.scheduleDetails.confirm.confirmed"),
          type: "success",
        })
      );
    }
  }, [
    confirmConsultationRequest,
    identifier,
    identifierCalendar,
    dispatch,
    onConfirmed,
    t,
  ]);

  const confirmExam = useCallback(async () => {
    const { success } = await confirmExamRequest({
      identifier,
      identifierCalendar,
      calendarDate: date,
    });
    if (success) {
      onConfirmed();
      dispatch(
        notificationSliceActions.showNotification({
          message: t("page.schedules.scheduleDetails.confirm.confirmed"),
          type: "success",
        })
      );
    }
  }, [
    identifier,
    identifierCalendar,
    date,
    confirmExamRequest,
    dispatch,
    onConfirmed,
    t,
  ]);

  const confirm = useCallback(async () => {
    setLoading(true);
    if (type === ETasyScheduleTypeCode.CONSULTATION) {
      await confirmConsultation();
    } else {
      await confirmExam();
    }
    setLoading(false);
  }, [confirmConsultation, confirmExam, type]);

  return { confirm, loading };
};
