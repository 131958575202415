import {
  IMedicGuideAutoComplete,
  IMedicalGuideParams,
} from "../models/medic-guide";
import { IRequestItem, ISearchReturn } from "../models/requests";
import { authApi } from "./auth.service";

const BASIC_ROUTE = process.env.REACT_APP_APP + "/api/v1/MedicalGuide/";

export class MedicalGuideService<T extends IRequestItem> {
  route_path: string;

  constructor(rp: string) {
    this.route_path = rp;
  }

  getAllMedics = (serchParams?: IMedicalGuideParams) => {
    return authApi.get<ISearchReturn<T>>(this.route_path, {
      params: serchParams,
      paramsSerializer: { indexes: null },
    });
  };

  list = (params?: any) => {
    return authApi.get<T[]>(BASIC_ROUTE + this.route_path);
  };

  autoComplete = (params?: any) => {
    return authApi.get<IMedicGuideAutoComplete>(BASIC_ROUTE + this.route_path, {
      params: params,
    });
  };
}

export default MedicalGuideService;
