import { Tooltip, Typography } from "@mui/material";
import { useRef, useEffect, useState } from "react";
import { IToolTipOverFlowProps } from "./models";

/**
 * An componment to show tooltip with the inner text is overflowed
 */
export const OverflowTooltip = (props: IToolTipOverFlowProps) => {
  const textElementRef = useRef<HTMLInputElement | null>(null);

  const compareSize = () => {
    if (!textElementRef.current) return;
    const compare =
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth;

    setHover(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
  }, []);

  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize);
    },
    []
  );

  const [hoverStatus, setHover] = useState(false);

  return (
    <Tooltip title={props.tooltip} disableHoverListener={!hoverStatus}>
      <Typography
        ref={textElementRef}
        variant={props.variant ?? "subtitle2"}
        color={props.color}
        sx={{
          maxWidth: !props.width ? "300px" : undefined,
          width: props.width ?? undefined,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textTransform: props.textTransform ?? "capitalize",
          fontWeight: props.fontWeight ?? "normal",
        }}
      >
        {props.text}
      </Typography>
    </Tooltip>
  );
};
