import { CircularProgress, IconButton } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

interface IAvaliationBtnProps {
  action: "like" | "dislike";
  onClick: () => void;
  size?: "small" | "medium" | "large";
  loading: boolean;
}

const AvaliationBtn = ({
  action,
  onClick,
  loading,
  size = "small",
}: IAvaliationBtnProps) => {
  return (
    <IconButton
      sx={(theme) => ({
        backgroundColor:
          action === "like"
            ? theme.palette.success.main
            : theme.palette.error.main,
        color: "white",
        "&:hover": {
          backgroundColor:
            action === "like"
              ? theme.palette.success.dark
              : theme.palette.error.dark,
        },
      })}
      size={size}
      onClick={onClick}
    >
      {!loading && action === "like" ? (
        <ThumbUpIcon fontSize={size} />
      ) : !loading && action === "dislike" ? (
        <ThumbDownIcon fontSize={size} />
      ) : (
        <CircularProgress size={20} sx={{ color: "white" }} />
      )}
    </IconButton>
  );
};

export default AvaliationBtn;
