import { Theme } from "@emotion/react";
import { Box, SxProps } from "@mui/material";
import React from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  paddingInline?: number | string;
  sx?: SxProps<Theme>;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, paddingInline, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={[
            {
              paddingInline: paddingInline,
              height: "100%",
            },
            ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
          ]}
        >
          {children}
        </Box>
      )}
    </div>
  );
}
