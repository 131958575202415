import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import EmergencyCareSymptoms from "../../components/VirtualEmergencyCare/EmergencyCareSymptoms/EmergencyCareSymptoms";
import PagesContainer from "../../components/UI/PagesContainer";

const translationPath = "page.virtualEmergencyCare.";

const VirtualEmergencyCarePage = () => {
  const { t } = useTranslation();

  return (
    <PagesContainer
      title={t(translationPath + "virtual_emergency_care")}
      hideBackButton
    >
      <Stack
        sx={{
          width: "100%",
          maxWidth: 700,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          sx={(theme) => ({
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[700]
                : theme.palette.grey[400],
          })}
        >
          {t(translationPath + "message")}
        </Typography>
        <EmergencyCareSymptoms />
      </Stack>
    </PagesContainer>
  );
};

export default VirtualEmergencyCarePage;
