import { Grid, Typography } from "@mui/material";

const RefundsBeneficiaryForm = ({ name }: { name: string }) => {
  return (
    <>
      <Grid
        mt={1}
        mb={1}
        item
        xs={12}
        sm={6}
        display={"flex"}
        flexDirection={"row"}
        gap={0.5}
      >
        <Typography
          variant="subtitle1"
          sx={(theme) => ({
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[800]
                : theme.palette.grey[400],
          })}
        >
          Nome:
        </Typography>
        <Typography variant="subtitle1">{name}</Typography>
      </Grid>
    </>
  );
};

export default RefundsBeneficiaryForm;
