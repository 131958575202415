import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { memo, useMemo } from "react";

import { useHealthProgramsContext } from "../../../store/contexts/HelthProgramContext";
import Loading from "../../Loading";
import HealthProgramSection from "./HealthProgramSection";
import { IHealthProgram } from "../../../models/health-programs";

const translationPath = "page.health_programs.";

interface IHealthProgamsListProps {
  search: string;
}

const healthProgramFilter = (program: IHealthProgram, search: string) => {
  const loweredSearch = search.toLowerCase();

  if (program.name.toLowerCase().includes(loweredSearch)) {
    return true;
  }

  if (program.description?.toLowerCase().includes(loweredSearch)) {
    return true;
  }

  const findInContent = program.contentsHealthProgram.some((content) => {
    if (content.content.title.toLowerCase().includes(search)) {
      return true;
    }

    if (content.content.summary.toLowerCase().includes(search)) {
      return true;
    }

    return false;
  });

  if (findInContent) {
    return true;
  }

  return false;
};

const HealthProgramsList: React.FC<IHealthProgamsListProps> = ({ search }) => {
  const { t } = useTranslation();

  const { healthPrograms, loading } = useHealthProgramsContext();

  const filteredHealthPrograms = useMemo(
    () =>
      healthPrograms.filter((program) => healthProgramFilter(program, search)),
    [search, healthPrograms]
  );

  const otherPrograms = useMemo(
    () => filteredHealthPrograms.filter((h) => !h.isInProgram),
    [filteredHealthPrograms]
  );

  const inPrograms = useMemo(
    () => filteredHealthPrograms.filter((h) => h.isInProgram),
    [filteredHealthPrograms]
  );

  if (loading) return <Loading />;

  return (
    <Box display={"flex"} flexDirection={"column"} height={"100%"} gap={2}>
      <Stack gap={4}>
        <HealthProgramSection
          emptyMessage={t(translationPath + "not_participate")}
          title={t(translationPath + "myPrograms")}
          programs={inPrograms}
        />

        <HealthProgramSection
          emptyMessage={t(translationPath + "no_found")}
          title={t(translationPath + "otherPrograms")}
          programs={otherPrograms}
        />
      </Stack>
    </Box>
  );
};

export default memo(HealthProgramsList);
