import { Stack, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IContentVideo } from "../../models/content";

interface IVIdeoThumProps {
  video: IContentVideo;
  onClick: (v: IContentVideo) => void;
  selected: boolean;
}

export const getYoutubeVideoId = (url: string) => {
  var regExp =
    /(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&?/#]+)/;
  var match = url.match(regExp);

  if (match && match[1]) {
    return match[1];
  }
};

const VideoThumb: React.FC<IVIdeoThumProps> = ({
  video,
  onClick,
  selected,
}) => {
  const id = video.link ? getYoutubeVideoId(video.link) : null;

  const { t } = useTranslation();

  const [title, setTitle] = useState("");

  useEffect(() => {
    if (!id) return;

    const fetchData = async () => {
      const data = await fetch(
        `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${id}&format=json`
      );
      const json = await data.json();
      setTitle(json.title);
    };
    fetchData();
  }, [id, title]);

  const onClickHandler = () => {
    onClick(video);
  };

  return (
    <Stack
      flexDirection={"row"}
      alignItems={"center"}
      gap={2}
      onClick={onClickHandler}
      sx={[
        {
          cursor: "pointer",
          p: 1,
          borderRadius: 2,
          "&:hover": {
            opacity: 0.8,
          },
        },
        selected &&
          ((t) => ({
            backgroundColor:
              t.palette.mode === "dark"
                ? t.palette.grey[900]
                : t.palette.grey[100],
          })),
      ]}
    >
      {id && (
        <img
          src={`https://img.youtube.com/vi/${id}/hqdefault.jpg`}
          alt="video thumbnail"
          width={100}
          style={{ borderRadius: 8 }}
        />
      )}

      {!id && <Typography>{t("page.news.no_video")}</Typography>}

      {title && (
        <Typography
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
          }}
        >
          {title}
        </Typography>
      )}
    </Stack>
  );
};

export default memo(VideoThumb);
