import {
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ISpecialty } from "../../../../../models/medic-guide";
import { useCallback, useEffect, useRef, useState } from "react";

const translationPath = "components.medicGuideFilter.";

const MedicGuideSpecialty = ({
  specialties,
}: {
  specialties: ISpecialty[];
}) => {
  const { t } = useTranslation();
  const textElementRef = useRef<HTMLInputElement | null>(null);
  const [showMore, setShowMore] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const compareSize = useCallback(() => {
    if (!textElementRef.current) return;
    const textLength = specialties.map((s) => s.name).join(", ").length;
    const lengthCheck = matches ? 80 : 100;
    const compare =
      textElementRef.current.clientHeight > 41 && textLength >= lengthCheck;

    setShowMore(compare);
    setShowButton(compare);
  }, [matches, specialties]);

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
  }, [compareSize]);

  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize);
    },
    [compareSize]
  );

  return (
    <Stack flexDirection={"row"} flexWrap={"wrap"} columnGap={1} mt={1}>
      <Typography
        variant="subtitle2"
        sx={(theme) => ({
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[800]
              : theme.palette.grey[400],
        })}
      >
        {t(translationPath + "specialties")}:
      </Typography>
      <div
        ref={textElementRef}
        style={{
          maxHeight: showMore ? 41 : undefined,
          overflow: "hidden",
          textOverflow: showMore ? "ellipsis" : undefined,
          display: "-webkit-box",
          WebkitLineClamp: showMore ? 2 : undefined,
          WebkitBoxOrient: "vertical",
        }}
      >
        <Typography variant="subtitle2">
          {specialties.map((s) => s.name).join(", ")}
        </Typography>
      </div>
      {showButton && (
        <Link
          onClick={() => setShowMore((state) => (state = !state))}
          underline="none"
          sx={{ cursor: "pointer", fontSize: 14 }}
        >
          {showMore
            ? `${t(translationPath + "view_more")}`
            : `${t(translationPath + "view_less")}`}
        </Link>
      )}
    </Stack>
  );
};

export default MedicGuideSpecialty;
