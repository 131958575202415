import { Box } from "@mui/material";
import { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useDownload from "../../../hooks/useDownload";
import { useViewFile } from "../../../hooks/useViewFile";
import { tokenTemporaryService } from "../../../services/tokenTemorary.service";
import Loading from "../../Loading";
import RenderPDF from "../../RenderPDF/RenderPDF";
import NoData from "../../UI/NoData";

const EXAMS_ROUTE = process.env.REACT_APP_APP + "/api/v1/LaboratorialExams/";

const EXAMS_TASY_ROUTE =
  process.env.REACT_APP_APP + "/api/v1/LaboratorialExams/Tasy/";

const translationPath = "components.examsPdf.";

const ExamsPdf = ({
  id,
  examType,
  date,
  individualId,
}: {
  id: string;
  examType: string;
  date: string;
  individualId?: string;
}) => {
  const { t } = useTranslation();

  const isLogginTemporary = tokenTemporaryService.hasTemporaryAccessToken();

  const { downloadFile, loading: dLoading } = useDownload();

  const downloadHandler = useCallback(async () => {
    downloadFile(
      isLogginTemporary ? EXAMS_TASY_ROUTE : EXAMS_ROUTE,
      id,
      `${t("components.examsDownload.report")}-${date.substring(0, 10)}`,
      {
        examType:
          examType === "LABORATORIAL"
            ? "LaboratorialExam"
            : "NonLaboratorialExam",
        individualId,
      }
    );
  }, [downloadFile, t, isLogginTemporary, individualId, date, examType, id]);

  const items = useMemo(() => {
    return {
      examType:
        examType === "LABORATORIAL"
          ? "LaboratorialExam"
          : "NonLaboratorialExam",
      individualId,
    };
  }, [examType, individualId]);

  const { fileData, fileSize, loading } = useViewFile({
    url: isLogginTemporary ? EXAMS_TASY_ROUTE : EXAMS_ROUTE,
    id,
    items,
  });

  if (loading) {
    return (
      <Box
        width={300}
        height={300}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Loading />
      </Box>
    );
  }

  if (!fileData || fileSize === 0)
    return (
      <Box p={2} width={"100%"} height={"100%"}>
        <NoData message={t(translationPath + "no_data")} />
      </Box>
    );

  return (
    <RenderPDF
      file={fileData}
      downloadButtonProps={{
        loading: dLoading,
        onDownload: downloadHandler,
      }}
    />
  );
};

export default memo(ExamsPdf);
