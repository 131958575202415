import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { memo } from "react";
import { IActionButtonProps } from "./models";

export const ActionButton = memo(
  ({ icon: Icon, tooltip, disabled, loading, onClick }: IActionButtonProps) => {
    return (
      <Tooltip title={tooltip}>
        <IconButton size="small" disabled={disabled} onClick={onClick}>
          {loading ? <CircularProgress size={18} /> : <Icon fontSize="small" />}
        </IconButton>
      </Tooltip>
    );
  }
);
