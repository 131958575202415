import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { Box, CircularProgress } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMimeType } from "../../../../../../hooks/useMimeType";
import { ActionButton } from "./ActionButton/ActionButton";
import { IActionsProps } from "./models";

const translationPath = "components.reportsModal.actions.";

export const Actions = memo(
  ({
    isActive,
    isLoading,
    selectedFile,
    viewHandler,
    funcHandler,
    handleDownloadFile,
  }: IActionsProps) => {
    const { t } = useTranslation();

    const { detectMimeType } = useMimeType();

    const [shouldOpenFile, setShouldOpenFile] = useState(false);

    const openInNewTabHandler = useCallback(async () => {
      await funcHandler();
      setShouldOpenFile(true);
    }, [funcHandler]);

    useEffect(() => {
      if (!shouldOpenFile || !selectedFile) return;

      const mimeTypeMap: any = {
        jpg: "image/jpeg",
        jpeg: "image/jpeg",
        png: "image/png",
        gif: "image/gif",
        txt: "text/plain",
        pdf: "application/pdf",
        zip: "application/zip",
      };

      const mimeType = detectMimeType(selectedFile);

      if (!mimeType) return;

      const byteCharacters = atob(selectedFile);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], {
        type: mimeTypeMap[mimeType.toLowerCase()] || "application/pdf",
      });

      const blobUrl = URL.createObjectURL(blob);

      const newTab = window.open();
      if (newTab) newTab.location.href = blobUrl;

      setShouldOpenFile(false);
    }, [selectedFile, shouldOpenFile, detectMimeType]);

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {isLoading && <CircularProgress size={18} />}
        <ActionButton
          icon={OpenInNewRoundedIcon}
          tooltip={t(`${translationPath}open`)}
          disabled={isLoading}
          onClick={openInNewTabHandler}
        />
        <ActionButton
          icon={VisibilityRoundedIcon}
          tooltip={t(`${translationPath}view`)}
          disabled={isActive || isLoading}
          onClick={viewHandler}
        />
        <ActionButton
          icon={DownloadRoundedIcon}
          tooltip={t(`${translationPath}download`)}
          disabled={isLoading}
          onClick={handleDownloadFile}
        />
      </Box>
    );
  }
);
