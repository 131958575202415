import { createContext } from "react";
import { Navigate } from "react-router-dom";

import { useInstalledExtensions } from "../hooks/useInstalledExtensions";

interface IDomain {
  extensionCode: string;
}

export const Extensions = createContext({
  extensionCode: "",
});

const ExtensionsContextProvider = ({
  children,
  value,
  redirect = true,
}: {
  children: any;
  value: IDomain;
  redirect?: boolean;
}) => {
  const [hasExtension] = useInstalledExtensions(value.extensionCode);

  return (
    <>
      {hasExtension && (
        <Extensions.Provider value={value}>{children}</Extensions.Provider>
      )}
      {!hasExtension && redirect && <Navigate to={`/no-access`} />}
    </>
  );
};

export default ExtensionsContextProvider;
