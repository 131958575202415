import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { IColumnList } from "./models";
import { memo } from "react";

const ColumnList = ({
  columnVisibilityModel,
  getColumnName,
  handleChange,
}: IColumnList) => {
  return (
    <Box px={2}>
      <FormControl component="fieldset" variant="standard">
        <FormGroup>
          {Object.keys(columnVisibilityModel).map((key) => {
            return (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    size="small"
                    checked={columnVisibilityModel[key]}
                    onChange={(event) => handleChange(key, event)}
                    name={key}
                  />
                }
                label={getColumnName(key)}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default memo(ColumnList);
