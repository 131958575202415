import { z } from "zod";

const translationPath = "page.login.errors.";

export const loginFormSchema = z.object({
  username: z.string().nonempty(translationPath + "login"),
  password: z.string().min(6, translationPath + "password"),
});

export type TLoginFormData = z.infer<typeof loginFormSchema>;
