import { Box, Typography } from "@mui/material";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../../UI/Button";

const translationPath = "components.formSucceeded.";

export const FormSucceeded = memo(() => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const redirectUserHandler = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <Box
      sx={{
        px: 4,
        width: "100%",
        height: "calc(100vh - 15rem)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 4,
      }}
    >
      <img
        src="/form-success.png"
        style={{ maxHeight: 180 }}
        alt="form succeeded illustration"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          textAlign: "center",
        }}
      >
        <Typography variant="h4" fontWeight="bold">
          {t(translationPath + "title")}
        </Typography>
        <Typography
          sx={(t) => ({
            color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
          })}
        >
          {t(translationPath + "subtitle")}
        </Typography>
      </Box>
      <Button variant="contained" onClick={redirectUserHandler}>
        {t(translationPath + "button")}
      </Button>
    </Box>
  );
});
