import { Stack, Typography } from "@mui/material";

const ErrorMessage = ({
  children,
  errorTitle,
}: {
  children: any;
  errorTitle: string;
}) => {
  return (
    <Stack alignItems={"center"}>
      {children}
      <Stack textAlign={"center"} mt={1}>
        <Typography variant="h6" fontWeight={"bold"} lineHeight={1.2}>
          {errorTitle}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ErrorMessage;
