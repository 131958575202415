import { Box } from "@mui/material";
import { memo } from "react";
import { INoContentContainerProps } from "./models";

export const NoContentContainer = memo(
  ({ children }: INoContentContainerProps) => {
    return (
      <Box
        sx={{
          width: 300,
          minHeight: 300,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </Box>
    );
  }
);
