import { Box } from "@mui/material";
import { memo } from "react";

import { useAppSelector } from "../../../../store/store";
import Logo from "./Logo";
import HeaderRight from "./HeaderRight";
import { IHeaderProps } from "./models";
import { AccessPortalBtn } from "./AccessPortalBtn/AccessPortalBtn";

const Header = ({
  alwaysShowLogo = false,
  accessPortalBtn = false,
}: IHeaderProps) => {
  const { logoInHeader } = useAppSelector((state) => state.theme);

  return (
    <Box
      component="header"
      sx={(t) => ({
        width: "100%",
        justifyContent:
          logoInHeader || alwaysShowLogo ? "space-between" : "end",
        [t.breakpoints.down("sm")]: {
          justifyContent: "space-between",
        },
        borderBottom: `${logoInHeader || alwaysShowLogo ? 1 : 0}px solid ${
          t.palette.divider
        }`,
        display: "flex",
        alignItems: "center",
        paddingInline: 2,
        [t.breakpoints.up("md")]: {
          paddingInline: logoInHeader ? 15 : 6,
        },
      })}
    >
      <Logo alwaysShowLogo={alwaysShowLogo} />
      {accessPortalBtn && <AccessPortalBtn />}
      {!accessPortalBtn && <HeaderRight />}
    </Box>
  );
};

export default memo(Header);
