import {
  Button as MuiButton,
  CircularProgress,
  ButtonTypeMap,
  Stack,
} from "@mui/material";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";

type IButton = {
  loading?: boolean;
  children: any;
} & DefaultComponentProps<ButtonTypeMap>;

const Button: React.FC<IButton> = ({ loading, children, ...buttonProps }) => {
  return (
    <MuiButton disabled={loading ? true : false} {...buttonProps}>
      <Stack direction={'row'} gap={2} justifyContent={'center'} alignItems={'center'}>
          {loading && <CircularProgress size={25} color={buttonProps.variant === 'contained' ? 'inherit' : 'primary'} />}
          {children}
      </Stack>
    </MuiButton>
  );
};

export default Button;
