import GlobalAxios from "../axios/axios-config";
import { IPrivilege, IUser } from "../models/user";

const USER_NAME_ID = "username";
const ESTABLISHMENT_ID = "establishmentId";
const BASE_URL = process.env.REACT_APP_IDENTIY;

const getUserName = () => localStorage.getItem(USER_NAME_ID);

const saveUserName = (username: string) =>
  localStorage.setItem(USER_NAME_ID, username);

const removeUserName = () => localStorage.removeItem(USER_NAME_ID);

const saveEstablishmentId = (id: string) =>
  localStorage.setItem(ESTABLISHMENT_ID, id);

const getEstablishmentId = () => localStorage.getItem(ESTABLISHMENT_ID)

const removeEstablishmentId = () => localStorage.removeItem(ESTABLISHMENT_ID);

const userMe = () => GlobalAxios.get<IUser>(BASE_URL + "/api/v1/Users/Me");

const userMePrivileges = () =>
  GlobalAxios.get<IPrivilege[]>(BASE_URL + "/api/v1/Users/Me/Privileges");

export const userService = {
  userMe,
  userMePrivileges,
  saveUserName,
  getUserName,
  removeUserName,
  saveEstablishmentId,
  removeEstablishmentId,
  getEstablishmentId
};
