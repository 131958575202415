import { Button } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormInput from "../../../../../../../components/UI/Inputs/FormInput";
import { TAppointment } from "../../../../schema";
import { IWhatsappNumberModalProps } from "./models";

const translationPath = "page.schedules.newSchedule.confirm.";

export const WhatsappNumberModal = memo(
  ({ onSaveWhatsappNumber, onCloseModal }: IWhatsappNumberModalProps) => {
    const { t } = useTranslation();

    const { getValues, setValue, setError, control } =
      useFormContext<TAppointment>();

    const newWhatsappNumber = useWatch({ name: "whatsappNumber", control });

    const currentWhatsappNumber = useMemo(
      () => getValues("whatsappNumber") ?? null,
      [getValues]
    );

    const cancelHandler = useCallback(() => {
      setValue("whatsappNumber", currentWhatsappNumber);
      onCloseModal();
    }, [setValue, onCloseModal, currentWhatsappNumber]);

    const confirmHandler = useCallback(() => {
      if (!newWhatsappNumber || newWhatsappNumber.length === 0) {
        setError("whatsappNumber", {
          type: "required",
          message: `${t(`${translationPath}errors.whatsapp_number`)}`,
        });
        return;
      }
      onSaveWhatsappNumber(newWhatsappNumber ?? null);
      onCloseModal();
    }, [newWhatsappNumber, onSaveWhatsappNumber, onCloseModal, setError, t]);

    return (
      <Grid container rowSpacing={2}>
        <Grid item xs={12} sm={12}>
          <FormInput
            name="whatsappNumber"
            label={t(`${translationPath}whatsapp_number`)}
            size="small"
            fullWidth
            autoFocus
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            gap: 1,
          }}
        >
          <Button size="small" variant="outlined" onClick={cancelHandler}>
            {t(`${translationPath}cancel`)}
          </Button>
          <Button size="small" variant="contained" onClick={confirmHandler}>
            {t(`${translationPath}confirm`)}
          </Button>
        </Grid>
      </Grid>
    );
  }
);
