import { z } from "zod";
import { CPF_VALIDATOR } from "../../utils/Validators/regexValidators";

const translationPath = "components.medicCardShare.errors.";

export const medicCardShareSchema = z.object({
  document: z.string().regex(CPF_VALIDATOR, translationPath + "document"),
  name: z.string().nonempty(translationPath + "name"),
  beneficiaryCardId: z.string(),
  terms: z.boolean().refine((val) => val, {
    message: translationPath + "terms",
  }),
});

export type TMedicShareForm = z.infer<typeof medicCardShareSchema>;
