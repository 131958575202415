import { CircularProgress, IconButton, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IDownloadPageButtonProps {
  onClick: () => any;
  loading: boolean;
  error: any;
}

const DownloadPageButton: React.FC<IDownloadPageButtonProps> = ({
  onClick,
  loading,
  error,
}) => {
  const [onError, setOnError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      setOnError(true);
      setTimeout(() => {
        setOnError(false);
      }, 1000);
    }
  }, [error]);

  return (
    <IconButton onClick={onClick}>
      {loading && <CircularProgress size={25} />}
      {!loading && !onError && <DownloadIcon />}
      {onError && (
        <Typography
          variant="body2"
          sx={(theme) => ({ color: theme.palette.error.main })}
        >
          {t("components.downloadPageButton.error")}
        </Typography>
      )}
    </IconButton>
  );
};

export default DownloadPageButton;
