import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import useFetch from "../../../hooks/useFetch";
import { notificationSliceActions } from "../../../store/slices/notification";
import { authService } from "../../../services/auth.service";

import Button from "../../UI/Button";
import { useNavigate } from "react-router-dom";
import useSiteApp from "../../../hooks/useSiteApp";

const translationPath = "components.resendEmail.";

const ResendEmail = ({
  userName,
  onShowResendEmailForm,
  onEmailResent,
}: {
  userName?: string;
  onShowResendEmailForm?: () => void;
  onEmailResent?: () => void;
}) => {
  const { sendRequest, loading } = useFetch(
    authService.resendEmailConfirmation
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { config } = useSiteApp();

  const resendEmailHandler = useCallback(async () => {
    const { success } = await sendRequest({
      userName: config.isLoginCpf
        ? userName?.replaceAll(".", "").replaceAll("-", "")
        : userName,
      softwareCode: "3",
    });
    if (success) {
      dispatch(
        notificationSliceActions.showNotification({
          message: `${t(translationPath + "success")}`,
          type: "success",
        })
      );
      if (onEmailResent) onEmailResent();
      navigate(`/email-confirmation/${userName}`);
    }
  }, [
    sendRequest,
    userName,
    dispatch,
    t,
    navigate,
    onEmailResent,
    config.isLoginCpf,
  ]);

  return (
    <>
      <Button
        loading={loading}
        variant="outlined"
        onClick={userName ? resendEmailHandler : onShowResendEmailForm}
        sx={{ mt: 1 }}
      >
        {t(translationPath + "button")}
      </Button>
    </>
  );
};

export default ResendEmail;
