import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import useFetch from "../../hooks/useFetch";
import { getNews } from "../../services/news.service";
import { IGetNewsById } from "../../models/news";
import PagesContainer from "../../components/UI/PagesContainer";
import ContentPage from "../../components/Content/ContentPage";
import NoData from "../../components/UI/NoData";
import Loading from "../../components/Loading";
import { useAppSelector } from "../../store/store";

const News = () => {
  const { id } = useParams();

  const actualLang = useAppSelector((state) => state.language.languageId);

  const [news, setNews] = useState<IGetNewsById>();

  const { sendRequest, loading } = useFetch(getNews);

  const loadNews = useCallback(async () => {
    if (!id || !actualLang) return;
    const { data, success } = await sendRequest({
      Id: id,
      LanguageId: actualLang,
    });
    if (data && success) {
      setNews(data);
    }
  }, [sendRequest, id, actualLang]);

  useEffect(() => {
    loadNews();
  }, [loadNews]);

  if (loading) return <Loading />;

  if (!news) return <NoData />;

  return (
    <PagesContainer paddinRight={2}>
      <ContentPage content={news} notShowVideoInFront />
    </PagesContainer>
  );
};

export default News;
