import { z } from "zod";

const individualSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  {
    invalid_type_error:
      "page.schedules.insurance.addInsurance.individual.error",
    required_error: "page.schedules.insurance.addInsurance.individual.error",
  }
);

const insuranceSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
  },
  {
    required_error: "page.schedules.insurance.addInsurance.insurance.error",
    invalid_type_error: "page.schedules.insurance.addInsurance.insurance.error",
  }
);

export const addInsuranceSchema = z.object({
  insurance: insuranceSchema,
  individual: individualSchema,
  code: z.string().optional(),
  validate: z.date().optional().nullable(),
});

export type TAddHealth = z.infer<typeof addInsuranceSchema>;
