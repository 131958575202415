import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useFetch from "../../../../../hooks/useFetch";
import { IGroupExam } from "../../../model";
import { ScheduleAppointmentService } from "../../../appointment.service";
import AutoComplete from "../../../../../components/UI/Inputs/AutoComplete";

const ExamGroup = () => {
  const [examGroups, setExamGroups] = useState<IGroupExam[]>([]);

  const { sendRequest } = useFetch(ScheduleAppointmentService.getExamGroups);

  const { t } = useTranslation();

  const fetch = useCallback(async () => {
    const { success, data } = await sendRequest(null);

    if (success && data) {
      setExamGroups(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <AutoComplete
      options={examGroups.map((s) => ({
        id: String(s.groupingIdentifier),
        name: s.groupingName,
      }))}
      getOptionLabel={(option) => {
        return option.name;
      }}
      size="small"
      label={t("page.schedules.newSchedule.search.examGroup")}
      name="search.examGroup"
      sx={{ mb: 2 }}
    />
  );
};

export default memo(ExamGroup);
