import { FormProvider, useForm } from "react-hook-form";
import { Grid, SxProps, Theme } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import { TFamilyMember, familyMemberSchema } from "./schema";
import DateInput from "../../../../components/UI/Inputs/DateInput";
import FormInput from "../../../../components/UI/Inputs/FormInput";
import SelectKinship from "./SelectKinship";
import SelectGender from "./SelectGender";
import Button from "../../../../components/UI/Button";
import { useFamilyGroup } from "./useFamilyGroup";
import Loading from "../../../../components/UI/Loading";
import { KEYS } from "./AddFamilyGroup";

interface IAddFamilyGroupFormProps {
  sx?: SxProps<Theme>;
  onSuccess: () => void;
}

const AddFamilyGroupForm: React.FC<IAddFamilyGroupFormProps> = ({
  sx,
  onSuccess,
}) => {
  const methods = useForm<TFamilyMember>({
    resolver: zodResolver(familyMemberSchema),
  });

  const { t } = useTranslation();

  const { getLoading, isEditMode, loading, onSubmit } = useFamilyGroup(
    methods,
    onSuccess
  );

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={1}
        sx={[
          (t) => ({
            pr: 1,
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {getLoading && <Loading />}
        <Grid item xs={12} sm={6}>
          <FormInput
            size="small"
            fullWidth
            label={t("page.schedules.addFamilyGroup.name.label")}
            name={KEYS.name}
            disabled={isEditMode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInput
            size="small"
            name={KEYS.document}
            type="text"
            fullWidth
            label={t("page.schedules.addFamilyGroup.document.label")}
            mask="000.000.000-00"
            disabled={isEditMode}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <DateInput
            hasDefaultDate={false}
            disableFuture
            label={t("page.schedules.addFamilyGroup.birthDate.label")}
            name={KEYS.birthDate}
            disabled={isEditMode}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <SelectKinship />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectGender disabled={isEditMode} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormInput
            size="small"
            fullWidth
            label={t("page.schedules.addFamilyGroup.nationality.label")}
            name={KEYS.nationality}
            disabled={isEditMode}
          />
        </Grid>
        <Grid container item xs={12} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            onClick={methods.handleSubmit(onSubmit)}
            loading={loading}
          >
            {t(`page.schedules.addFamilyGroup.${isEditMode ? "edit" : "save"}`)}
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default memo(AddFamilyGroupForm);
