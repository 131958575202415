import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IPrivilege, IPrivilegeState } from "../../models/user";

const PRIVILEGE_INITIAL_STATE: IPrivilegeState = {
  domainId: "",
  privileges: [],
};

export const slice = createSlice({
  name: "privilege",
  initialState: PRIVILEGE_INITIAL_STATE,
  reducers: {
    savePrivilege(state: IPrivilegeState, action: PayloadAction<IPrivilege[]>) {
      return { ...state, privileges: action.payload };
    },
    saveDomain(state: IPrivilegeState, action: PayloadAction<string>) {
      return { ...state, domainId: action.payload };
    },
    removePrivilege(state) {
      return (state = PRIVILEGE_INITIAL_STATE);
    },
  },
});

export const privilegeSliceActions = slice.actions;
export default slice.reducer;
