import { Card, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import { ReactNode } from "react";
import PaperComponent from "../UI/PaperComponent";

const EmergencyCareServiceCard = ({
  onClick,
  padding,
  icon,
  title,
  description,
}: {
  onClick?: () => void;
  padding?: number;
  icon: ReactNode;
  title: string;
  description: string;
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Card
      sx={(theme) => ({
        padding: padding ? padding : 2,
        width: "100%",
        maxWidth: 400,
        maxHeight: 200,
        border: `2px solid transparent`,
        cursor: "pointer",
        backgroundColor:
          theme.palette.mode === "light" ? theme.palette.grey[100] : "#121212",
      })}
      elevation={0}
      onClick={onClick}
    >
      <Grid container sx={{ flexDirection: "row" }}>
        <Grid
          item
          sm={2}
          xs={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: matches ? "center" : "start",
          }}
        >
          <PaperComponent
            sx={{
              width: 60,
              height: 60,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
            }}
          >
            {icon}
          </PaperComponent>
        </Grid>
        <Grid
          item
          sm={10}
          xs={9}
          paddingLeft={matches ? 2 : 0}
          sx={(theme) => ({
            "&:hover ": {
              color: theme.palette.primary.main,
            },
          })}
        >
          <Typography variant="h6" lineHeight={1.2} mb={1}>
            {title}
          </Typography>
          <Typography
            variant="subtitle2"
            lineHeight={1.2}
            sx={(theme) => ({
              color:
                theme.palette.mode === "light"
                  ? theme.palette.grey[800]
                  : theme.palette.grey[400],
            })}
          >
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default EmergencyCareServiceCard;
