import Box from "@mui/material/Box";
import { ReactNode } from "react";
import { StepContextProvider } from "../../../store/contexts/StepContext";
import StepperHeader from "./StepperHeader";
import StepperActions, { ILastButtonProps } from "./StepperActions";
import { Stack } from "@mui/material";

const StepperComponent = ({
  steps,
  children,
  lastButton,
  showFirstButton = true,
  loading,
}: {
  steps: string[];
  children: ReactNode;
  lastButton?: ILastButtonProps;
  showFirstButton?: boolean;
  loading?: boolean;
}) => {
  return (
    <StepContextProvider>
      <Stack
        sx={{
          height: "100%",
          paddingInline: 1,
        }}
      >
        <StepperHeader steps={steps} />

        <Box sx={{ height: "100%", overflow: "auto" }}>{children}</Box>

        <StepperActions
          stepsLength={steps.length}
          lastButton={lastButton}
          showFirstButton={showFirstButton}
          loading={loading}
        />
      </Stack>
    </StepContextProvider>
  );
};

export default StepperComponent;
