import { Button } from "@4uhub/lib4uhub";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { memo } from "react";
import { IFirstStep } from "./models";

export const FirstStep = memo(({ evaluationForm, onStartForm }: IFirstStep) => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        width: "100%",
        height: matches ? "100%" : "calc(100% - 5rem)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 4,
      }}
    >
      <Box
        sx={{
          px: 4,
          width: "100%",
          maxWidth: 1200,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
        }}
      >
        <img
          src="/search.png"
          style={{ maxHeight: 225 }}
          alt="search illustration"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography variant="h5" fontWeight="bold">
            {evaluationForm.title}
          </Typography>
          <div
            style={{
              height: "100%",
              overflow: "auto",
            }}
            dangerouslySetInnerHTML={{
              __html: evaluationForm.visualDescription.replace(
                /<[^>]+>/g,
                (match) => {
                  return match.replace(
                    /^<\w+/g,
                    (tag) =>
                      `${tag} style="color: ${
                        theme.palette.grey[
                          theme.palette.mode === "light" ? 600 : 400
                        ]
                      };"`
                  );
                }
              ),
            }}
          />
          <Button
            variant="contained"
            startIcon={<PlayCircleFilledWhiteIcon />}
            onClick={onStartForm}
          >
            Iniciar
          </Button>
        </Box>
      </Box>
    </Box>
  );
});
