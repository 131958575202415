import { IPortalBanner } from "../../../models/banners";

const LOCAL_BANNERS_KEY = "home_banners";

export const LocalBannersService = {
  get: (): IPortalBanner[] => {
    const local = localStorage.getItem(LOCAL_BANNERS_KEY);

    if (local) {
      const parsed = JSON.parse(local) as IPortalBanner[];

      return parsed;
    }

    return [];
  },

  set: (banners: IPortalBanner[]) => {
    localStorage.setItem("home_banners", JSON.stringify(banners));
  },
};
