import { Stack, SvgIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MedicNotFoundIcon } from "../../../../UI/Menus/DynamicIcon";

const translationPath = "components.medicGuideFilter.";

const NoMedics = () => {
  const { t } = useTranslation();

  return (
    <Stack
      width={"100%"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <SvgIcon
        component={MedicNotFoundIcon}
        fontSize={"large"}
        inheritViewBox
      />
      <Typography variant="subtitle1" textAlign={"center"}>
        {t(translationPath + "no_medic_found")}
      </Typography>
    </Stack>
  );
};

export default NoMedics;
