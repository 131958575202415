import { IconButton, useTheme } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactChild, memo } from "react";

interface IBannersCarouselProps {
  currentSlide: number;
  changeSlide: (index: number) => void;
  children: ReactChild[];
  onClickItem?: (index: number, item: React.ReactNode) => void;
}

const BannersCarousel = ({
  changeSlide,
  currentSlide,
  children,
  onClickItem,
}: IBannersCarouselProps) => {
  const theme = useTheme();

  return (
    <Carousel
      showIndicators={children.length > 1}
      showThumbs={false}
      showStatus={false}
      autoPlay
      infiniteLoop
      onClickItem={onClickItem}
      stopOnHover
      dynamicHeight={true}
      useKeyboardArrows
      selectedItem={currentSlide}
      onChange={(index) => changeSlide(index)}
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        return (
          <span
            style={{
              margin: 2,
              color: theme.palette.primary.main,
              opacity: isSelected ? 1 : 0.5,
              cursor: "pointer",
            }}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            key={index}
            role="button"
            tabIndex={0}
            aria-label={`${label} ${index + 1}`}
          >
            <FiberManualRecordIcon sx={{ fontSize: 10 }} />
          </span>
        );
      }}
      renderArrowPrev={(clickHandler, hasPrev) => {
        return (
          hasPrev && (
            <IconButton
              onClick={clickHandler}
              sx={{
                position: "absolute",
                zIndex: 10,
                top: "45%",
                left: "0%",
              }}
            >
              <ChevronLeftIcon color="primary" />
            </IconButton>
          )
        );
      }}
      renderArrowNext={(clickHandler, hasNext) => {
        return (
          hasNext && (
            <IconButton
              onClick={clickHandler}
              sx={{
                position: "absolute",
                zIndex: 10,
                top: "45%",
                right: "0%",
              }}
            >
              <ChevronRightIcon color="primary" />
            </IconButton>
          )
        );
      }}
    >
      {children}
    </Carousel>
  );
};

export default memo(BannersCarousel);
