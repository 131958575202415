import GlobalAxios from "../axios/axios-config";
import { authApi } from "./auth.service";
import { tokenService } from "./token.service";

const ROUTE = process.env.REACT_APP_APP + "/api/v1/EstablishmentParam/GetParam";

export const getParam = (ParamTypeCode: number) => {
  if (tokenService.hasAccessTokens()) {
    return GlobalAxios.get<{ value: string }[]>(`${ROUTE}/${ParamTypeCode}`);
  } else {
    return authApi.get<{ value: string }[]>(`${ROUTE}/${ParamTypeCode}`);
  }
};
