import { Box, Typography } from "@mui/material";
import { memo, useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  TWhatsappNumberForm,
  whatsappNumberSchema,
} from "./whatsappNumberSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import FormInput from "../../../Inputs/FormInput";
import Button from "../../../Button";
import useFetch from "../../../../../hooks/useFetch";
import {
  getWhatsappNumber,
  updateWhatsappNumber,
} from "../../../../../services/whatsappNumber.service";
import Loading from "../../../../Loading";
import { IWhatsappNumberModal } from "./models";
import { notificationSliceActions } from "../../../../../store/slices/notification";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../../store/store";
import ModalComponent from "../../../Modal";
import { tokenService } from "../../../../../services/token.service";

const translationPath = "components.whatsappNumber.";

export const WhatsappNumberModal = memo(
  ({ open, onClose }: IWhatsappNumberModal) => {
    const isLogged = tokenService.hasAccessTokens();

    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const { sendRequest, loading: gLoading } = useFetch(getWhatsappNumber);

    const { sendRequest: update, loading: uLoading } =
      useFetch(updateWhatsappNumber);

    const methods = useForm<TWhatsappNumberForm>({
      resolver: zodResolver(whatsappNumberSchema),
    });

    const { handleSubmit, reset } = methods;

    const fetchWhatsappNumber = useCallback(async () => {
      const { data, success } = await sendRequest(null);
      if (data && success) {
        reset({ ...data, value: data.value ?? "" });
      }
    }, [sendRequest, reset]);

    const onSubmitHandler = useCallback(
      async (values: TWhatsappNumberForm) => {
        const { data, success } = await update({
          ...values,
          number: values.value
            .replaceAll("+", "")
            .replaceAll("(", "")
            .replaceAll(")", ""),
        });
        if (data && success) {
          onClose();
          dispatch(
            notificationSliceActions.showNotification({
              message: `${t(translationPath + "success")}`,
              type: "success",
            })
          );
        }
      },
      [onClose, update, dispatch, t]
    );

    useEffect(() => {
      if (isLogged && open) fetchWhatsappNumber();
    }, [fetchWhatsappNumber, isLogged, open]);

    const loading = gLoading || uLoading;

    return (
      <ModalComponent open={open} onClose={onClose}>
        <FormProvider {...methods}>
          <Box
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmitHandler)}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              minWidth: 300,
            }}
          >
            <Typography variant="h6" textAlign="center">
              {t(translationPath + "title")}
            </Typography>
            {loading && (
              <Box
                sx={{
                  pb: 4,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Loading />
              </Box>
            )}
            {!loading && (
              <Box
                sx={{
                  mt: 2,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <FormInput
                  name="value"
                  label={t(translationPath + "whatsapp_number")}
                  size="small"
                  mask={"+00(00)000000000"}
                  fullWidth
                  autoFocus
                />
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <Button
                    fullWidth
                    variant="outlined"
                    disabled={loading}
                    size="small"
                    onClick={onClose}
                  >
                    {t(translationPath + "cancel")}
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={loading}
                    loading={uLoading}
                    size="small"
                    type="submit"
                  >
                    {t(translationPath + "save")}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </FormProvider>
      </ModalComponent>
    );
  }
);
