import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import LegendsModalList from "./LegendsModalList";

const LegendsModal = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="h6" textAlign={"center"} fontWeight={"bold"} mb={1}>
        {t("components.medicGuideFilter.legends")}
      </Typography>
      <LegendsModalList />
    </Box>
  );
};

export default LegendsModal;
