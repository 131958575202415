import GlobalAxios from "../axios/axios-config";
import { IExtensionConfig } from "../models/extensions";

const EXTENSION_CONFIG_ROUTE =
  process.env.REACT_APP_APP + "/api/v1/ExtensionConfig/GetExtensionConfig";

interface IExtensionConfigProps {
  ExtensionCode: string;
  Identifier: string;
}

export class ExtensionService {
  getExtensionConfig = (params: IExtensionConfigProps) => {
    return GlobalAxios.get<IExtensionConfig[]>(EXTENSION_CONFIG_ROUTE, {
      params,
    });
  };
}

export default ExtensionService;
