import { Stack, SvgIcon, Tooltip } from "@mui/material";
import { ILegend } from "../../../../../../models/medic-guide";
import { ACBA, ADICQ, AIQG, AONA, APALC, E, N, P, Q, R } from "./Legends";
import { useTranslation } from "react-i18next";

const translationPath = "components.legends.";

const LegendsIconsList = ({ legend }: { legend: ILegend }) => {
  const { t } = useTranslation();

  return (
    <Stack flexDirection={"row"} gap={0.5} mb={1}>
      {legend.isAcba && (
        <Tooltip title={t(translationPath + "international")}>
          <SvgIcon component={ACBA} fontSize="large" inheritViewBox />
        </Tooltip>
      )}
      {legend.isAdicq && (
        <Tooltip title={t(translationPath + "national")}>
          <SvgIcon component={ADICQ} fontSize="large" inheritViewBox />
        </Tooltip>
      )}
      {legend.isAiqg && (
        <Tooltip title={t(translationPath + "international")}>
          <SvgIcon
            component={AIQG}
            width={200}
            fontSize="large"
            inheritViewBox
          />
        </Tooltip>
      )}
      {legend.isAnsNotivisaIntegration && (
        <Tooltip title={t(translationPath + "events_comunication")}>
          <SvgIcon
            component={N}
            fontSize="small"
            sx={{ mt: 0.8 }}
            inheritViewBox
          />
        </Tooltip>
      )}
      {legend.isAnsQualissIntegration && (
        <Tooltip title={t(translationPath + "monitored_quality")}>
          <SvgIcon
            component={Q}
            fontSize="small"
            sx={{ mt: 0.8 }}
            inheritViewBox
          />
        </Tooltip>
      )}
      {legend.isAona && (
        <Tooltip title={t(translationPath + "national")}>
          <SvgIcon component={AONA} fontSize="large" inheritViewBox />
        </Tooltip>
      )}
      {legend.isApalc && (
        <Tooltip title={t(translationPath + "national")}>
          <SvgIcon component={APALC} fontSize="large" inheritViewBox />
        </Tooltip>
      )}
      {legend.isHealthResidenceIntegration && (
        <Tooltip title={t(translationPath + "residence_professional")}>
          <SvgIcon
            component={R}
            fontSize="small"
            sx={{ mt: 0.8 }}
            inheritViewBox
          />
        </Tooltip>
      )}
      {legend.isProfSpecialistIntegration && (
        <Tooltip title={t(translationPath + "specialty_professional")}>
          <SvgIcon
            component={P}
            fontSize="small"
            sx={{ mt: 0.8 }}
            inheritViewBox
          />
        </Tooltip>
      )}
      {legend.isSpecialistIntegration && (
        <Tooltip title={t(translationPath + "specialist_title")}>
          <SvgIcon
            component={E}
            fontSize="small"
            sx={{ mt: 0.8 }}
            inheritViewBox
          />
        </Tooltip>
      )}
    </Stack>
  );
};

export default LegendsIconsList;
