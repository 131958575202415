import { memo } from "react";
import { useFormContext } from "react-hook-form";
import FormInput from "../../../UI/Inputs/FormInput";
import { TStepForm } from "../stepSchema";
import { IJustificationFieldProps } from "./models";

export const JustificationField = memo(
  ({
    requiredJustificationLessThan,
    questionAlternatives,
  }: IJustificationFieldProps) => {
    const { watch } = useFormContext<TStepForm>();

    const alternative = watch("questionAlternative");

    const currentAlternative = questionAlternatives.find(
      (alt) => alt.id === alternative
    );

    if (
      !currentAlternative ||
      !requiredJustificationLessThan ||
      Number(currentAlternative.nrOption) > requiredJustificationLessThan
    )
      return null;

    return (
      <FormInput
        name="justification"
        label={"Justificativa"}
        size="small"
        fullWidth
        multiline
        minRows={4}
      />
    );
  }
);
