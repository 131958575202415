import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IMedicCard } from "../../models/medic-card";

const CARDS_INITIAL_STATE: IMedicCard[] = [];

export const slice = createSlice({
  name: "cards",
  initialState: CARDS_INITIAL_STATE,
  reducers: {
    saveCard(state: IMedicCard[], action: PayloadAction<IMedicCard>) {
      if (!state.some((oldState) => oldState.id === action.payload.id)) {
        state.push(action.payload);
      }
      return state;
    },
    removeRemove(state) {
      return (state = CARDS_INITIAL_STATE);
    },
  },
});

export const cardsSliceActions = slice.actions;

export default slice.reducer;

// saveFrontCard(state: IShareCardStore[], action: PayloadAction<string>) {
//   // Verifica se já existe um objeto no array
//   if (state.length > 0) {
//     // Atualiza o objeto existente com a nova informação
//     state[state.length - 1].front = action.payload;
//   } else {
//     // Se não existir nenhum objeto, cria um novo
//     state.push({ front: action.payload, back: "" });
//   }
// },
// saveBackCard(state: IShareCardStore[], action: PayloadAction<string>) {
//   // Verifica se já existe um objeto no array
//   if (state.length > 0) {
//     // Atualiza o objeto existente com a nova informação
//     state[state.length - 1].back = action.payload;
//   } else {
//     // Se não existir nenhum objeto, cria um novo
//     state.push({ front: "", back: action.payload });
//   }
// },
