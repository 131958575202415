import { useParams } from "react-router-dom";
import AuthCardContainer from "../../components/Auth/AuthCardContainer/AuthCardContainer";
import LoginForm from "../../components/Auth/LoginForm/LoginForm";
import LoginTemporary from "../../components/Auth/LoginTemporary/LoginTemporary";

const LoginPage = () => {
  const { type } = useParams();

  if (type) {
    return (
      <AuthCardContainer>
        <LoginTemporary />
      </AuthCardContainer>
    );
  }

  return (
    <AuthCardContainer>
      <LoginForm />
    </AuthCardContainer>
  );
};

export default LoginPage;
