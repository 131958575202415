import { Box, CircularProgress, Typography } from "@mui/material";

const InputFileProgress = ({ progress }: { progress: number | null }) => {
  if (!progress) {
    return null;
  }

  return (
    <Box sx={{ marginLeft: 2, position: "relative", display: "inline-flex" }}>
      <CircularProgress
        sx={{ fontSize: 20 }}
        variant="determinate"
        value={progress}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${progress}%`}</Typography>
      </Box>
    </Box>
  );
};

export default InputFileProgress;
