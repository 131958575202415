import { z } from "zod";

const translationPath = "page.changePassword.errors.";

export const changePasswordSchema = z
  .object({
    currentPassword: z.string().nonempty(translationPath + "actual_password"),
    newPassword: z.string().nonempty(translationPath + "new_password"),
    confirmNewPassword: z
      .string()
      .nonempty(translationPath + "confirm_new_password"),
  })
  .superRefine((val, ctx) => {
    if (val.newPassword !== val.confirmNewPassword) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: translationPath + "passwords_match",
        path: ["confirmNewPassword"],
      });
    }
  });

export type TChangePasswordForm = z.infer<typeof changePasswordSchema>;
