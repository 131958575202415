import GlobalAxios from "../axios/axios-config";
import { IBanner, IBannerRequestParams } from "../models/banners";
import { authApi } from "./auth.service";

const BASIC_URL = process.env.REACT_APP_APP;

export const getAllBanners = (searchParams?: IBannerRequestParams) => {
  return GlobalAxios.get<IBanner[]>(
    BASIC_URL + "/api/v1/Banners/Authenticate",
    {
      params: searchParams,
    }
  );
};

export const getAllVisitBanners = (searchParams?: IBannerRequestParams) => {
  return authApi.get<IBanner[]>(BASIC_URL + "/api/v1/Banners", {
    params: searchParams,
  });
};

export const notShowBanners = (bannerId: string) => {
  return GlobalAxios.post(BASIC_URL + "/api/v1/BannerUserClick", {
    bannerId,
  });
};
