import { IFaq, INewComment } from "../models/faq";
import { authApi } from "./auth.service";

const FAQ_ROUTE = process.env.REACT_APP_APP + "/api/v1/Faq/";

export class FaqService {
  create = (item: INewComment) => {
    return authApi.post(FAQ_ROUTE + "SendFaqComment", item);
  };

  updateItemById = ({ id }: { id: string }) => {
    return authApi.put(FAQ_ROUTE + "IncreaseWasUseful", {
      faqQuestionId: id,
    });
  };

  list = (lang: string) => {
    return authApi.get<IFaq[]>(FAQ_ROUTE + "list", {
      params: {
        LanguageId: lang,
      },
    });
  };
}

export default FaqService;
