import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IAccordionProps } from "./models";
import { OverflowTooltip } from "../TooltipOverflow";

const AccordionComponent = ({
  title,
  action,
  helperText,
  children,
}: IAccordionProps) => {
  return (
    <Accordion
      elevation={0}
      disableGutters
      sx={(theme) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#1E1E1E" : "#f4f4f4",
        borderRadius: 1,
        "&:before": {
          display: "none",
        },
      })}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Stack
          width={"100%"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={4}
        >
          <Stack display={"grid"} gridTemplateAreas={"auto 1fr auto"}>
            <OverflowTooltip
              width={"100%"}
              text={title}
              tooltip={title}
              fontWeight="bold"
              variant="subtitle1"
              textTransform="none"
            />
          </Stack>
          <Stack
            minWidth={helperText ? "15%" : undefined}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"end"}
            gap={1}
          >
            {helperText && (
              <OverflowTooltip
                text={helperText.text}
                tooltip={helperText.text}
                variant={helperText.variant ?? "subtitle2"}
                color={helperText.color}
                textTransform="none"
              />
            )}
            <Stack flexDirection={"row"}>
              {action &&
                action.map((act) => (
                  <Tooltip
                    title={act.tooltipText}
                    key={act.tooltipText}
                    disableHoverListener={act.loading}
                  >
                    <IconButton
                      size={act.size}
                      color={act.color}
                      onClick={(event) => {
                        event.stopPropagation();
                        act.onClick();
                      }}
                    >
                      {!act.loading ? act.icon : <CircularProgress size={20} />}
                    </IconButton>
                  </Tooltip>
                ))}
            </Stack>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionComponent;
