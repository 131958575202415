import { memo } from "react";
import { IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import LogoutIcon from "@mui/icons-material/Logout";

import { tokenService } from "../../../../services/token.service";
import useHealthProgram from "./useHealthProgram";
import { IHealthProgram } from "../../../../models/health-programs";

interface IHealthProgramHeaderButtonProps {
  data: IHealthProgram;
}

const HealthProgramHeaderButton: React.FC<IHealthProgramHeaderButtonProps> = ({
  data,
}) => {
  const { t } = useTranslation();

  const { requestToEnter } = useHealthProgram(data);

  const isLogged = !!tokenService.getAccessToken();

  let component = (
    <Typography sx={(t) => ({ color: t.palette.primary.main })}>
      {t("page.health_programs.seeMore")}
    </Typography>
  );

  if (data.denialReason) {
    component = <ErrorOutlineIcon color={"error"} />;
  }

  if (data.hasRequestedToEnter) {
    component = <WatchLaterIcon sx={{ color: "#FFCC00" }} />;
  }

  if (!isLogged) {
    component = <LogoutIcon />;
  }

  return <IconButton onClick={() => requestToEnter()}>{component}</IconButton>;
};

export default memo(HealthProgramHeaderButton);
