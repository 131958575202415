export interface IPermission {
  hasPermission: boolean;
}

export enum Permissions {
  ALL = "All",
  CREATE = "Create",
  UPDATE = "Update",
  DELETE = "Delete",
  READ = "Read",
}

export enum Domains {
  COPARTICIPATION = "coparticipation",
  IRPF = "incometax",
  AUTH = "authorization",
  PAYMENTSLIP = "paymentslip",
  EMEGENCY_CARE = "promptservice",
  TELEPHONECALL = "telephonecall",
  REFUND = "refund",
  HEALTH_PROGRAM = "healthprogramportalapp",
  EXAMS = "tasyexamportal",
}

export type Permission = "All" | "Create" | "Update" | "Delete" | "Read";
