const DOMAIN_SESSION_STORAGE_KEY = "domain";
const DEFAULT_DEV_DOMAIN = "portalitamed.dev.4uhub.com.br";
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

const setDomainInLocalStorage = (domain: string) => {
  localStorage.setItem(DOMAIN_SESSION_STORAGE_KEY, domain);
};

export const getDomainFromLocalStorage = () =>
  localStorage.getItem(DOMAIN_SESSION_STORAGE_KEY) || DEFAULT_DEV_DOMAIN;

export const getDomain = () => {
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);

  const siteDomain = window.location.hostname;

  const isLocalHost = siteDomain === "localhost";

  const parameterDomain = urlParams.get("domain");

  if (ENVIRONMENT === "development" || isLocalHost) {
    if (parameterDomain) {
      setDomainInLocalStorage(parameterDomain);
      return parameterDomain;
    }
  }

  if (isLocalHost) {
    return getDomainFromLocalStorage();
  }
  
  return siteDomain;
};
