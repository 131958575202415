import { Paper, SxProps, Theme } from "@mui/material";

const PaperComponent = ({
  children,
  sx,
}: {
  children: any;
  sx?: SxProps<Theme> | undefined;
}) => {
  return (
    <Paper
      elevation={0}
      sx={[
        (theme) => ({
          backgroundColor:
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Paper>
  );
};

export default PaperComponent;
