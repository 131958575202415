import { Button, Stack, Typography } from "@mui/material";
import AbcIcon from "@mui/icons-material/Abc";
import { useTranslation } from "react-i18next";

const translationPath = "components.emailErrorCode.";

const EmailErrorCode = ({ onCloseModal }: { onCloseModal: () => void }) => {
  const { t } = useTranslation();
  return (
    <Stack flexDirection={"column"} alignItems={"center"} gap={2}>
      <AbcIcon sx={(t) => ({ fontSize: 62, color: t.palette.error.light })} />
      <Stack textAlign={"center"}>
        <Typography variant="h6" fontWeight={"bold"}>
          {t(translationPath + "title")}
        </Typography>
        <Typography>{t(translationPath + "description")}</Typography>
      </Stack>
      <Button
        variant="contained"
        onClick={onCloseModal}
        fullWidth
        sx={(t) => ({
          backgroundColor: t.palette.error.light,
          "&:hover": { backgroundColor: t.palette.error.dark },
        })}
      >
        {t(translationPath + "button")}
      </Button>
    </Stack>
  );
};

export default EmailErrorCode;
