import { Box, Grid, Typography } from "@mui/material";
import { memo } from "react";
import useDate from "../../../../../hooks/useDate";
import { ISchedule } from "../../../model";

interface IHourProps {
  schedule: ISchedule;
  onPress: (schedule: ISchedule) => void;
  selected: boolean;
}

const Hour: React.FC<IHourProps> = ({ schedule, selected, onPress }) => {
  const { dayjs } = useDate();

  const onClickHandler = () => {
    onPress(schedule);
  };

  return (
    <Grid item xs={"auto"}>
      <Box
        sx={(t) => ({
          borderRadius: t.shape.borderRadius,
          padding: 1,
          backgroundColor: !selected
            ? t.palette.divider
            : t.palette.primary.main,
          cursor: "pointer",
          "&:hover": {
            opacity: 0.7,
          },
        })}
        onClick={onClickHandler}
      >
        <Typography
          sx={{ color: selected ? "white" : "unset" }}
          variant="body2"
        >
          {dayjs(schedule.calendarStartDate).format("LT")}
        </Typography>
      </Box>
    </Grid>
  );
};

export default memo(Hour);
