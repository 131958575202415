import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useFetch from "../../../../../hooks/useFetch";
import { notificationSliceActions } from "../../../../../store/slices/notification";
import { useAppDispatch } from "../../../../../store/store";
import { ScheduleAppointmentService } from "../../../appointment.service";
import { ETasyScheduleTypeCode } from "../../../model";
import { TAppointment } from "../../schema";

export const useConfirm = (reschedule: boolean = false) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { sendRequest: SchedConsultation, error: consultationError } = useFetch(
    ScheduleAppointmentService.calendarConsultationScheduling
  );

  const { sendRequest: SchedExam, error: examError } = useFetch(
    ScheduleAppointmentService.calendarExamScheduling
  );

  const { sendRequest: cancelExam } = useFetch(
    ScheduleAppointmentService.calendarExamScheduleCancellation
  );

  const { sendRequest: cancelConsultation } = useFetch(
    ScheduleAppointmentService.calendarConsultationScheduleCancellation
  );

  const cancelSchedule = useCallback(
    async (v: TAppointment) => {
      await (v.search.type === ETasyScheduleTypeCode.CONSULTATION
        ? cancelConsultation
        : cancelExam)({
        calendarDate: v.search.date.toString(),
        cancellationReasonCode: "1",
        cancellationReasonDescription: "Reagendamento",
        identifier: String(v.schedule.identifier),
        identifierCalendar:
          v.search.type === ETasyScheduleTypeCode.CONSULTATION
            ? String(v.search.professional?.id)
            : String(v.search.examGroup.id),
      });
    },
    [cancelConsultation, cancelExam]
  );

  const scheduleConsultation = useCallback(
    async (v: TAppointment) => {
      setLoading(true);

      if (reschedule) {
        await cancelSchedule(v);
      }

      const { success } = await (v.search.type ===
        ETasyScheduleTypeCode.CONSULTATION
        ? SchedConsultation
        : SchedExam)({
        identifier: v.schedule.identifier,
        insuranceCode: v.insurance.id,
        pacientIndividualIdentifier: v.patient.id,
        anticipate: v.antecipate,
        whatsappNumber:
          v.antecipate && v.whatsappNumber ? v.whatsappNumber : null,
      });

      if (success) {
        navigate("/schedule");
        dispatch(
          notificationSliceActions.showNotification({
            message: t("page.schedules.newSchedule.success"),
            type: "success",
          })
        );
      }

      setLoading(false);
    },
    [
      SchedConsultation,
      SchedExam,
      navigate,
      dispatch,
      t,
      reschedule,
      cancelSchedule,
    ]
  );

  const error = useMemo(
    () => consultationError || examError,
    [consultationError, examError]
  );

  return {
    scheduleConsultation,
    loading,
    error,
  };
};
