import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ILoginTemporary, ITemporaryUser } from "../../models/authorizations";
import useFetch from "../../hooks/useFetch";
import { hasUserService } from "../../services/exams.service";
import { tokenTemporaryService } from "../../services/tokenTemorary.service";

interface ITemporaryAccessProps {
  children: ReactNode;
}

const TemporaryAccessContext = createContext<ITemporaryUser>({
  userTemporary: {
    token: "",
    name: "",
    id: "",
  },
  hasUser: false,
  fetchTemporaryUser: () => {},
});

export const TemporaryAccessProvider: React.FC<ITemporaryAccessProps> = ({
  children,
}) => {
  const isTemporaryAccess = tokenTemporaryService.hasTemporaryAccessToken();

  const [userTemporary, setUserTemporary] = useState<ILoginTemporary>({
    id: "",
    name: "",
    token: "",
  });

  const [hasUser, setHasUser] = useState<boolean>(false);

  const { sendRequest } = useFetch(hasUserService);

  const fetchTemporaryUser = useCallback((tempUser: ILoginTemporary) => {
    setUserTemporary(tempUser);
  }, []);

  const fetchHasUser = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (data && success) {
      setHasUser(data.hasUser);
    }
  }, [sendRequest]);

  useEffect(() => {
    if (isTemporaryAccess) {
      fetchHasUser();
    }
  }, [fetchHasUser, isTemporaryAccess]);

  const value = useMemo(
    () => ({
      userTemporary,
      hasUser,
      fetchTemporaryUser,
    }),
    [userTemporary, hasUser, fetchTemporaryUser]
  );

  return (
    <TemporaryAccessContext.Provider value={value}>
      {children}
    </TemporaryAccessContext.Provider>
  );
};

export const useTemporaryAccess = () => {
  const context = useContext(TemporaryAccessContext);
  return context;
};
