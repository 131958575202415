import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { SwitchInput } from "../../../../UI/Inputs/Switch";
import AutoComplete, { ISelectType } from "../../../../UI/Inputs/AutoComplete";
import Button from "../../../../UI/Button";
import { useTranslation } from "react-i18next";
import MedicalGuideService from "../../../../../services/medicalGuide.service";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { TMedicGuideFilterForm } from "./MedicGuideFilterSchema";
import { IMedicCities } from "../../../../../models/medic-guide";
import AutoCompleteCity from "../../../AutoCompleteCity/AutoCompleteCity";
import FormInput from "../../../../UI/Inputs/FormInput";

const translationPath = "components.medicGuideFilter.";

const MEDICAL_SPECIALTIES_ROUTE = "GetMedicalSpecialties/";
const medicalSpecialtiesService = new MedicalGuideService<ISelectType>(
  MEDICAL_SPECIALTIES_ROUTE
);

const MEDICAL_PRACTICE_ROUTE = "GetMedicalPracticeAreas/";
const medicalPracticeService = new MedicalGuideService<ISelectType>(
  MEDICAL_PRACTICE_ROUTE
);

const MEDICAL_CITIES_ROUTE = "GetMedicalGuideCities/";
const medicalCitiesService = new MedicalGuideService<IMedicCities>(
  MEDICAL_CITIES_ROUTE
);

const MEDICAL_HEALTH_PLAN_ROUTE = "GetHealthPlans/";
const medicalHealthPlansService = new MedicalGuideService<ISelectType>(
  MEDICAL_HEALTH_PLAN_ROUTE
);

const MEDICAL_TYPES_ROUTE = "GetProviderTypes/";
const medicalTypesService = new MedicalGuideService<ISelectType>(
  MEDICAL_TYPES_ROUTE
);

const MedicGuideFilterFields = ({
  filterData,
  clearFilters,
  loading,
}: {
  filterData: TMedicGuideFilterForm | undefined;
  clearFilters: () => void;
  loading: boolean;
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { setValue, reset } = useFormContext<TMedicGuideFilterForm>();

  useEffect(() => {
    if (filterData) {
      setValue("city", filterData.city);
      setValue("healthPlan", filterData.healthPlan);
      setValue(
        "isUrgentEmergencyIntegration",
        filterData.isUrgentEmergencyIntegration
      );
      setValue("medicalPracticeArea", filterData.medicalPracticeArea);
      setValue("medicalSpecialty", filterData.medicalSpecialty);
      setValue("name", filterData.name);
      setValue("providerType", filterData.providerType);
      setValue("search", filterData.search);
    }
  }, [filterData, setValue]);

  const cleanFiltersHandler = () => {
    clearFilters();
    reset();
  };

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} sm={12}>
        <SwitchInput
          name="isUrgentEmergencyIntegration"
          label={t(translationPath + "emergency")}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <AutoComplete
          getOptionLabel={(option) => {
            return option.name;
          }}
          size="small"
          label={t(translationPath + "plan")}
          name="healthPlan"
          request={medicalHealthPlansService.list}
          sx={{ mb: 2 }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <AutoCompleteCity
          getOptionLabel={(option) => {
            return option.city.name;
          }}
          size="small"
          label={t(translationPath + "city")}
          name="city"
          sx={{ mb: 2 }}
          request={medicalCitiesService.list}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <AutoComplete
          getOptionLabel={(option) => {
            return option.name;
          }}
          size="small"
          label={t(translationPath + "specialty")}
          name="medicalSpecialty"
          request={medicalSpecialtiesService.list}
          sx={{ mb: 2 }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <AutoComplete
          getOptionLabel={(option) => {
            return option.name;
          }}
          size="small"
          label={t(translationPath + "occupation_area")}
          name="medicalPracticeArea"
          request={medicalPracticeService.list}
          sx={{ mb: 2 }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <AutoComplete
          getOptionLabel={(option) => {
            return option.name;
          }}
          size="small"
          label={t(translationPath + "provider_type")}
          name="providerType"
          request={medicalTypesService.list}
          sx={{ mb: 2 }}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormInput
          name="name"
          label={t(translationPath + "name")}
          sx={{ mb: 2 }}
          size="small"
          fullWidth
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        display={"flex"}
        flexDirection={matches ? "column" : "row"}
        justifyContent={!matches ? "end" : "center"}
        gap={1}
      >
        <Button
          variant="outlined"
          fullWidth={matches}
          disabled={loading}
          onClick={cleanFiltersHandler}
        >
          {t(translationPath + "clean")}
        </Button>
        <Button
          variant="contained"
          loading={loading}
          type="submit"
          fullWidth={matches}
        >
          {t(translationPath + "search")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default MedicGuideFilterFields;
