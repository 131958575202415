import axios from "axios";
import i18n from "../i18n";
import { getDomain } from "../services/domain.service";
import { router } from "../App";
import { tokenTemporaryService } from "../services/tokenTemorary.service";

const TemporaryAxios = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    client_id: process.env.CLIENT_ID,
  },
});

TemporaryAxios.interceptors.request.use((config) => {
  config.headers.Domain = getDomain();

  // Add extension code do calendar routes
  if (config.url?.startsWith(process.env.REACT_APP_CALENDAR!)) {
    config.headers.ExtensionCode = 25;
  }

  return config;
});

TemporaryAxios.interceptors.request.use((config) => {
  config.headers["Accept-Language"] = i18n.language;

  return config;
});

//Add the access token of store in all request
export const AddBearerTemporaryHeader = () => {
  TemporaryAxios.interceptors.request.use(function (config) {
    let token = tokenTemporaryService.getTemporaryAccessToken();
    if (!tokenTemporaryService.hasTemporaryAccessToken()) {
      logoutTemporaryUser();
    } else {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  });
};

TemporaryAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error?.response?.status === 403) {
      logoutTemporaryUser();
    }

    return Promise.reject(error);
  }
);

const logoutTemporaryUser = () => {
  tokenTemporaryService.removeTemporaryAccessToken();
  tokenTemporaryService.removeTemporaryAccessName();
  router.navigate("/", {
    replace: true,
  });
};

export default TemporaryAxios;
