import { Divider, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DisplayDate from "../../components/DisplayDate";
import PaperComponent from "../../components/UI/PaperComponent";
import { IAuthorization } from "../../models/authorizations";

const translationPath = "page.authorization.";

const AuthorizationBasicData = ({ auth }: { auth: IAuthorization }) => {
  const { t } = useTranslation();

  const [providerLabel, setProviderLabel] = useState("");
  const [provider, setProvider] = useState("");
  useEffect(() => {
    if (auth.providerRequesterName) {
      setProvider(auth.providerRequesterName);
      setProviderLabel(`${t(translationPath + "provider")}`);
    }
    if (!auth.providerRequesterName && auth.doctorRequesterName) {
      setProvider(auth.doctorRequesterName);
      setProviderLabel(`${t(translationPath + "doctor")}`);
    } else {
      setProvider(auth.providerExecutorName);
      setProviderLabel(`${t(translationPath + "provider")}`);
    }
  }, [
    auth.providerRequesterName,
    auth.doctorRequesterName,
    auth.providerExecutorName,
    t,
  ]);

  return (
    <PaperComponent sx={{ minWidth: 300, maxWidth: 800 }}>
      <Grid container p={2}>
        <Grid item sm={6}>
          <Typography
            variant="subtitle1"
            sx={(theme) => ({
              color:
                theme.palette.mode === "light"
                  ? theme.palette.grey[600]
                  : theme.palette.grey[400],
            })}
          >
            {t(translationPath + "authorization_code")}
          </Typography>
          <Typography variant="subtitle1">
            {auth?.authorizationIntegrationId}
          </Typography>
        </Grid>
        <Grid item sm={6} textAlign={"end"}>
          <Typography
            variant="subtitle1"
            sx={(theme) => ({
              color:
                theme.palette.mode === "light"
                  ? theme.palette.grey[600]
                  : theme.palette.grey[400],
            })}
          >
            {t(translationPath + "issue_date")}
          </Typography>
          <DisplayDate typography={{ variant: "subtitle1" }}>
            {dayjs(auth?.issueDate)}
          </DisplayDate>
        </Grid>

        <Divider sx={{ width: "100%", my: 1 }} />

        <Grid item sm={6}>
          <Typography
            variant="subtitle1"
            sx={(theme) => ({
              color:
                theme.palette.mode === "light"
                  ? theme.palette.grey[600]
                  : theme.palette.grey[400],
            })}
          >
            {t(translationPath + "password_code")}
          </Typography>
          <Typography variant="subtitle1">
            {auth?.passwordCode ? auth?.passwordCode : "-"}
          </Typography>
        </Grid>
        <Grid item sm={6} textAlign={"end"}>
          <Typography
            variant="subtitle1"
            sx={(theme) => ({
              color:
                theme.palette.mode === "light"
                  ? theme.palette.grey[600]
                  : theme.palette.grey[400],
            })}
          >
            {t(translationPath + "password_expiration")}
          </Typography>
          <DisplayDate typography={{ variant: "subtitle1" }}>
            {dayjs(auth?.passwordCodeExpirationDate)}
          </DisplayDate>
        </Grid>

        <Divider sx={{ width: "100%", my: 1 }} />

        <Grid item sm={6}>
          <Typography
            variant="subtitle1"
            sx={(theme) => ({
              color:
                theme.palette.mode === "light"
                  ? theme.palette.grey[600]
                  : theme.palette.grey[400],
            })}
          >
            {t(translationPath + "situation")}
          </Typography>
          <Typography variant="subtitle1">
            {auth?.documentTypeCurrentSituation}
          </Typography>
        </Grid>
        <Grid item sm={6} textAlign={"end"}>
          <Typography
            variant="subtitle1"
            sx={(theme) => ({
              color:
                theme.palette.mode === "light"
                  ? theme.palette.grey[600]
                  : theme.palette.grey[400],
            })}
          >
            {t(translationPath + "doc_type")}
          </Typography>
          <Typography variant="subtitle1">{auth?.documentType}</Typography>
        </Grid>

        {auth.beneficiaryName && (
          <>
            <Divider sx={{ width: "100%", my: 1 }} />
            <Grid item sm={12}>
              <Typography
                variant="subtitle1"
                sx={(theme) => ({
                  color:
                    theme.palette.mode === "light"
                      ? theme.palette.grey[600]
                      : theme.palette.grey[400],
                })}
              >
                {t(translationPath + "beneficiary")}
              </Typography>
              <Typography variant="subtitle1">
                {auth.beneficiaryName}
              </Typography>
            </Grid>
          </>
        )}

        {provider && (
          <>
            <Divider sx={{ width: "100%", my: 1 }} />
            <Grid item sm={12}>
              <Typography
                variant="subtitle1"
                sx={(theme) => ({
                  color:
                    theme.palette.mode === "light"
                      ? theme.palette.grey[600]
                      : theme.palette.grey[400],
                })}
              >
                {providerLabel}
              </Typography>
              <Typography variant="subtitle1">{provider}</Typography>
            </Grid>
          </>
        )}
      </Grid>
    </PaperComponent>
  );
};

export default AuthorizationBasicData;
