import GlobalAxios from "../axios/axios-config";
import { IIrpfList } from "../models/irpf";

const LIST_URL =
  process.env.REACT_APP_APP + "/api/v1/Beneficiary/GetIncomeTaxStatementData";

export class IrpfService {
  getAll = () => {
    return GlobalAxios.get<IIrpfList[]>(LIST_URL);
  };
}

export default IrpfService;
