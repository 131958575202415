import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../hooks/useFetch";
import FormInput from "../UI/Inputs/FormInput";
import CloseIcon from "@mui/icons-material/Close";
import {
  TMedicSharedForm,
  medicCardSharedSchema,
} from "./MedicCardSharedSchema";
import MedicCard from "./MedicCard";
import MedicCardLacks from "./MedicCardLacks";
import Carousel from "../UI/Carousel";
import { useDispatch } from "react-redux";
import { cardsSliceActions } from "../../store/slices/cards";
import CardService from "../../services/card.service";
import { useAppSelector } from "../../store/store";
import { notificationSliceActions } from "../../store/slices/notification";
import useCards from "../../hooks/useCards";

const translationPath = "components.medicCardShared.";

const service = new CardService();

const MedicCardSharedForm = ({ onClose }: { onClose: () => void }) => {
  const methods = useForm<TMedicSharedForm>({
    resolver: zodResolver(medicCardSharedSchema),
  });
  const { sendRequest: share } = useFetch(service.getShareCard);
  const dispatch = useDispatch();
  const cardsAdded = useAppSelector((state) => state.cards);

  const { t } = useTranslation();
  const [openCard, setOpenCard] = useState(false);
  const [selected, setSelected] = useState(0);
  const { cardImage, cardBackImage, loadCardImage, vLoading } = useCards();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const {
    formState: { isSubmitSuccessful },
    reset,
    handleSubmit,
  } = methods;

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const onSubmitHandler = useCallback(
    async (values: TMedicSharedForm) => {
      const { success, data } = await share(values);
      if (success) {
        loadCardImage({
          visitUserData: {
            Document: values.Document,
            ShareCode: values.ShareCode,
          },
        });
        if (cardsAdded.some((object) => object.id === data.id)) {
          dispatch(
            notificationSliceActions.showNotification({
              message: `${t(translationPath + "errors.card_added")}`,
              type: "error",
            })
          );
          setOpenCard(false);
          onClose();
        }
        if (!cardsAdded.some((object) => object.id === data.id)) {
          dispatch(
            cardsSliceActions.saveCard({
              ...data,
              visitCard: { code: values.ShareCode, document: values.Document },
            })
          );
          setOpenCard(true);
        }
      }
    },
    [share, dispatch, cardsAdded, onClose, t, loadCardImage]
  );

  const onCloseHandler = () => {
    setOpenCard(false);
  };

  const handleCloseCard = () => {
    setOpenCard(false);
  };

  return (
    <>
      <Modal open={openCard} onClose={onCloseHandler}>
        <Stack>
          {matches && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                marginLeft: "80px",
                bottom: 25,
              }}
              position={"relative"}
            >
              <IconButton
                sx={{
                  position: "relative",
                  top: -140,
                  left: 590,
                  color: "#fff",
                  zIndex: 10,
                }}
                onClick={handleCloseCard}
              >
                <CloseIcon />
              </IconButton>
              <Box>
                <Carousel
                  onChange={(i) => setSelected(i)}
                  viewType="browser"
                  selected={selected}
                  slides={[
                    <Stack mt={2} alignItems={"center"}>
                      <MedicCard cardImage={cardImage} vLoading={vLoading} />
                    </Stack>,
                    <Stack mt={2} alignItems={"center"}>
                      <MedicCardLacks
                        cardImage={cardBackImage}
                        vLoading={vLoading}
                      />
                    </Stack>,
                  ]}
                />
              </Box>
            </Box>
          )}
          {!matches && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                bottom: -220,
              }}
              position={"relative"}
            >
              <IconButton
                sx={{
                  position: "relative",
                  top: 400,
                  left: 150,
                  color: "#fff",
                  zIndex: 10,
                }}
                onClick={handleCloseCard}
              >
                <CloseIcon />
              </IconButton>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Carousel
                  onChange={(i) => setSelected(i)}
                  selected={selected}
                  slides={[
                    <Stack alignItems={"center"}>
                      <MedicCard cardImage={cardImage} vLoading={vLoading} />
                    </Stack>,
                    <Stack alignItems={"center"}>
                      <MedicCardLacks
                        cardImage={cardBackImage}
                        vLoading={vLoading}
                      />
                    </Stack>,
                  ]}
                />
              </Box>
            </Box>
          )}
        </Stack>
      </Modal>
      <FormProvider {...methods}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitHandler)}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography variant="subtitle1" sx={{ textAlign: "center" }} mb={2}>
            {t(translationPath + "message")}
          </Typography>
          <FormInput
            size="small"
            autoFocus={true}
            name="Document"
            type="text"
            fullWidth
            label={t(translationPath + "document")}
            sx={{ mb: 2 }}
            mask="000.000.000-00"
          />
          <FormInput
            size="small"
            name="ShareCode"
            fullWidth
            type="text"
            label={t(translationPath + "code")}
            sx={{ mb: 2 }}
          />
          <Grid container justifyContent={"center"}>
            <Grid item sm={12}>
              <Button fullWidth variant="contained" type="submit">
                {t(translationPath + "see_card")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </>
  );
};

export default MedicCardSharedForm;
