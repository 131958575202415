import GlobalAxios from "../axios/axios-config";

const ROUTE =
  process.env.REACT_APP_APP + "/api/v1/Beneficiary/GetFinancialData/";

export class TicketService {
  getAll = (searchParams?: string) => {
    return GlobalAxios.get<any>(ROUTE + searchParams);
  };
}

export default TicketService;
