import React, { Fragment, memo } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Stack, Typography } from "@mui/material";
import HealthProgram from "../HealthProgramItem/HealthProgram";
import NoData from "../../UI/NoData";
import { IHealthProgram } from "../../../models/health-programs";

interface IHealthProgramsSection {
  programs: IHealthProgram[];
  title: string;
  emptyMessage: string;
}

const HealthProgramsSection: React.FC<IHealthProgramsSection> = ({
  emptyMessage,
  programs,
  title,
}) => {
  const { t } = useTranslation();

  const isEmpty = programs.length === 0;

  return (
    <Stack gap={1} width={"100%"}>
      <Typography
        sx={(t) => ({ fontWeight: "bold", color: t.palette.grey[400] })}
      >
        {title}
      </Typography>

      {isEmpty && <NoData message={t(emptyMessage)} />}

      {!isEmpty &&
        programs.map((program, index) => {
          return (
            <Fragment key={program.id}>
              <HealthProgram program={program} />
              {index !== programs.length - 1 && <Divider />}
            </Fragment>
          );
        })}
    </Stack>
  );
};

export default memo(HealthProgramsSection);
