import { z } from "zod";

const translationPath = "components.whatsappNumber.errors.";

export const whatsappNumberSchema = z.object({
  individualId: z.string(),
  value: z.string().min(1, translationPath + "whatsapp_number"),
});

export type TWhatsappNumberForm = z.infer<typeof whatsappNumberSchema>;
