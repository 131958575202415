import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  ArrayPath,
  FieldArray,
  FieldArrayWithId,
  UseFieldArrayReturn,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { memo, useCallback, useState } from "react";

interface IFormArray<T extends object> {
  formArray: UseFieldArrayReturn<T, any, any>;
  name: string;
  appendValue: FieldArray<T, ArrayPath<T>> | FieldArray<T, ArrayPath<T>>[];
  title: string;
  addButtonLabel?: string;
  fieldsObject: (index: number) => JSX.Element;
  errorMessage?: string;
  onDelete?: (id: string, secondId: string) => Promise<any>;
  onDeleteVoid?: (
    file: FieldArray<T, ArrayPath<T>> | FieldArray<T, ArrayPath<T>>
  ) => void;
}

const FormArray = <T extends object>({
  formArray,
  appendValue,
  title,
  addButtonLabel,
  fieldsObject,
  errorMessage,
  onDelete,
  onDeleteVoid,
}: IFormArray<T>) => {
  const { fields, remove, append } = formArray;
  const { id } = useParams();
  const { t } = useTranslation();
  const [deleteList, setDeleteList] = useState<string[]>([]);

  const addToDeletelist = (id: string) => {
    setDeleteList((oldState) => [...oldState, id]);
  };

  const removeFromDeletelist = (id: string) => {
    setDeleteList((oldState) => oldState.filter((sid) => sid !== id));
  };

  const isInDeleteList = (id: string) => {
    return !!deleteList.find((d) => d === id);
  };

  const handleDelete = useCallback(
    async (index: number, field: FieldArrayWithId<T, any, any>) => {
      if (onDelete && field.id && field.id.trim().length > 1 && id) {
        addToDeletelist(id);
        const response = await onDelete(id, field.id);
        if (response) {
          removeFromDeletelist(id);
          remove(index);
        }
      } else {
        remove(index);
      }
    },
    [onDelete, remove, id]
  );

  const handleDeleteVoid = useCallback(
    (index: number, field: FieldArrayWithId<T, any, any>) => {
      if (onDeleteVoid) {
        onDeleteVoid(field);
        remove(index);
      } else {
        remove(index);
      }
    },
    [onDeleteVoid, remove]
  );

  return (
    <Stack alignItems={"start"} spacing={1}>
      <Typography variant="h6">{title}</Typography>
      <FormHelperText error>{t(errorMessage || "")}</FormHelperText>
      <Stack spacing={3} width={"100%"}>
        {fields.map((field, index) => {
          return (
            <Box key={field.id || field.key}>
              <Stack direction={"row"} alignItems={"center"} mb={3}>
                <Grid container spacing={2} width={"100%"}>
                  {fieldsObject(index)}
                </Grid>
                {!isInDeleteList(field.id) && (
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      handleDeleteVoid(index, field);
                      if (!onDeleteVoid) {
                        handleDelete(index, field);
                      }
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
                {isInDeleteList(field.id) && (
                  <CircularProgress
                    size={30}
                    sx={(theme) => ({ marginLeft: theme.spacing(1) })}
                  />
                )}
              </Stack>
              {index !== fields.length - 1 && (
                <Divider variant="middle" light />
              )}
            </Box>
          );
        })}
      </Stack>
      <Button
        onClick={() => append(appendValue)}
        startIcon={<AddIcon />}
        sx={(theme) => ({
          marginLeft: theme.spacing(4),
        })}
      >
        {addButtonLabel || "Add"}
      </Button>
    </Stack>
  );
};

const genericMemo: <T>(component: T) => T = memo;

export default genericMemo(FormArray);
