import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";

import { IHealthProgram } from "../../../../models/health-programs";
import { useHealthProgramsContext } from "../../../../store/contexts/HelthProgramContext";

const useHealthProgram = (data: IHealthProgram) => {
  const { requestAccessHandler } = useHealthProgramsContext();

  const { t } = useTranslation();

  const confirm = useConfirm();

  const navigate = useNavigate();

  const navigateToProgram = useCallback(
    (contentId?: string) => {
      if (contentId) {
        navigate(data.id + "/" + contentId);
      } else {
        navigate(data.id);
      }
    },
    [navigate, data.id]
  );

  const enter = useCallback(() => {
    requestAccessHandler(data.id);
  }, [requestAccessHandler, data.id]);

  const requestToEnter = useCallback(
    (id?: string) => {
      if (data.isInProgram) {
        return navigateToProgram(id);
      }

      if (!data.hasRequestedToEnter) {
        if (data.denialReason) {
          confirm({
            description: data.denialReason,
            title: t("page.health_programs.refused.title"),
            cancellationText: t("page.health_programs.refused.button1"),
            confirmationText: t("page.health_programs.refused.button2"),
          })
            .then(enter)
            .catch(() => {});
        }
        return confirm({
          description: t("page.health_programs.requestToEnter.description"),
          title: t("page.health_programs.requestToEnter.title"),
          cancellationText: t("page.health_programs.requestToEnter.button1"),
          confirmationText: t("page.health_programs.requestToEnter.button2"),
        })
          .then(enter)
          .catch(() => {});
      }

      if (data.hasRequestedToEnter) {
        confirm({
          description: t("page.health_programs.hasRequested.description"),
          title: t("page.health_programs.hasRequested.title"),
          hideCancelButton: true,
          confirmationText: t("page.health_programs.hasRequested.button1"),
        }).catch(() => {});
      }
    },
    [enter, data, t, confirm, navigateToProgram]
  );

  return { requestToEnter };
};

export default useHealthProgram;
