import { Box, IconButton } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { memo, useState } from "react";

import { IContentMediaFile } from "../../../models/content";
import NewsImgFullScreen from "./ContentImgFullScreen";
import ContentGalleryItem from "./ContentGalleryItem";

interface IContentGalleryProps {
  images: IContentMediaFile[];
}

const ContentGallery = ({ images }: IContentGalleryProps) => {
  const [index, setIndex] = useState(0);

  const [open, setOpen] = useState(false);

  const onCloseModal = () => setOpen(false);

  const onOpenModal = () => {
    setOpen(true);
  };

  if (images.length === 0) {
    return null;
  }

  return (
    <>
      <NewsImgFullScreen
        open={open}
        index={index}
        onChange={setIndex}
        onClose={onCloseModal}
        newsImages={images}
      />
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
      >
        <Box width={"100%"} position={"relative"}>
          <IconButton
            size="small"
            sx={(t) => ({
              position: "absolute",
              right: 0,
              zIndex: 100,
              boxShadow: 1,
              backgroundColor: t.palette.grey[500],
              opacity: 0.9,
              "&:hover": {
                backgroundColor: t.palette.grey[600],
              },
            })}
            onClick={onOpenModal}
          >
            <FullscreenIcon sx={{ color: "white" }} />
          </IconButton>
          <Carousel
            showThumbs={false}
            showStatus={false}
            showIndicators={images.length > 1}
            stopOnHover
            selectedItem={index}
            autoPlay={!open}
            infiniteLoop
            interval={5000}
            onChange={setIndex}
          >
            {images.map((img) => (
              <ContentGalleryItem
                img={img}
                onClick={onOpenModal}
                key={img.id}
              />
            ))}
          </Carousel>
        </Box>
      </Box>
    </>
  );
};

export default memo(ContentGallery);
