import { Stack, useMediaQuery, useTheme } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import Information from "../../components/UI/Information/Information";
import NoData from "../../components/UI/NoData";
import PagesContainer from "../../components/UI/PagesContainer";
import useFetch from "../../hooks/useFetch";
import { IExams } from "../../models/exams";
import { getExams, getTasyExams } from "../../services/exams.service";
import { tokenService } from "../../services/token.service";
import { tokenTemporaryService } from "../../services/tokenTemorary.service";
import { useAppSelector } from "../../store/store";
import ExamsList from "./ExamsList";
import ExamsTable from "./ExamsTable";
import Filter from "./Filter";

const translationPath = "page.exams.";

export interface IFilter {
  StartDate: Dayjs | null;
  EndDate: Dayjs | null;
  ExamType: string;
  IndividualId?: string;
}

const ExamsPage = () => {
  const { t } = useTranslation();

  const { individual } = useAppSelector((state) => state.user);

  const profiles = individual.profiles;

  const isDoctor =
    profiles.length > 0 &&
    profiles.some((p) => p.code === "9" || p.code === "10");

  const [exams, setExams] = useState<IExams[]>([]);

  const [rowCount, setRowCount] = useState<number>(0);

  const [pageCount, setPageCount] = useState(0);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  const isLogged = !!tokenService.hasAccessTokens();

  const { sendRequest: getExamsRequest, loading } = useFetch(
    isLogged ? getExams : getTasyExams
  );

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const isTempoararyAccess = !!tokenTemporaryService.hasTemporaryAccessToken();

  const [filter, setFilter] = useState<IFilter>({
    ExamType: "all",
    StartDate: dayjs().subtract(12, "month"),
    EndDate: dayjs(),
  });

  const handlePageChange = (page: number) => {
    setPaginationModel((prev) => ({ ...prev, page: page - 1 }));
  };

  const fetch = useCallback(async () => {
    if (!filter) return;

    const { data, success } = await getExamsRequest({
      StartDate:
        filter.StartDate && filter.StartDate.isValid()
          ? filter.StartDate.toISOString().substring(0, 10)
          : undefined,
      EndDate:
        filter.EndDate && filter.EndDate.isValid()
          ? filter.EndDate.toISOString().substring(0, 10)
          : undefined,
      ExamType: filter.ExamType === "all" ? undefined : filter.ExamType,
      IndividualId: filter.IndividualId,
      PageNumber: paginationModel.page + 1,
      PageSize: paginationModel.pageSize,
    });

    if (success && data) {
      setExams(data.items);
      setPageCount(data.totalPages);
      setRowCount(data.totalCount);
    } else {
      setExams([]);
    }
  }, [getExamsRequest, filter, paginationModel]);

  useEffect(() => {
    if (isDoctor && filter && !filter.IndividualId) return;
    fetch();
  }, [isDoctor, filter, fetch]);

  if (!isLogged && !isTempoararyAccess) {
    return <Navigate to="/login/exams" />;
  }

  return (
    <PagesContainer
      height={"100%"}
      title={t(translationPath + "exams")}
      rowActions={
        <Information
          title={t(translationPath + "date_range_info")}
          iconSize="small"
          iconColor="action"
        />
      }
    >
      <Filter isDoctor={isDoctor} filter={filter} onFilterChange={setFilter} />
      {!matches && (
        <ExamsTable
          data={exams}
          isDoctor={isDoctor}
          individualId={filter.IndividualId}
          tableProps={{
            onPaginationModelChange: setPaginationModel,
            paginationModel,
            rowCount,
            loading,
            slots: {
              noRowsOverlay: () => (
                <Stack height={"100%"} justifyContent={"center"}>
                  <NoData />
                </Stack>
              ),
            },
          }}
        />
      )}
      {matches && (
        <ExamsList
          data={exams}
          page={paginationModel.page + 1}
          onPageChange={handlePageChange}
          count={pageCount}
        />
      )}
    </PagesContainer>
  );
};

export default ExamsPage;
