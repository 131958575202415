import { z } from "zod";

const translationPath = "components.step.errors.";

export const stepSchema = z.object({
  questionAlternative: z
    .string({
      required_error: translationPath + "alternative",
    })
    .min(1, translationPath + "alternative"),
  justification: z.string().nullish(),
});

export type TStepForm = z.infer<typeof stepSchema>;
