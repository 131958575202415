import { IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

import LanguageIcon from "@mui/icons-material/Language";
import ModalComponent from "../Modal";
import { useState } from "react";
import ChangeLanguageForm from "../../ChangeLanguageForm";

const LanguageSwitch = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const openModalHandler = () => setOpenModal(true);
  const closeModalHandler = () => setOpenModal(false);

  return (
    <>
      <ModalComponent open={openModal} onClose={closeModalHandler}>
        <ChangeLanguageForm onClose={closeModalHandler} />
      </ModalComponent>
      <Tooltip
        title={t("components.languageSwitch.change_language")}
        placement="right"
      >
        <IconButton onClick={openModalHandler} color="inherit" size="small">
          <LanguageIcon color="action" fontSize="small"  />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default LanguageSwitch;
