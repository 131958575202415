import { Box } from "@mui/material";
import { memo, useEffect, useState } from "react";

import { IContentMediaFile } from "../../../models/content";
import ContentGallerySubtitle from "./ContentGallerySubtitle";
import { useAppSelector } from "../../../store/store";

interface IContentGalleryItem {
  img: IContentMediaFile;
  onClick: () => void;
}

const ContentGalleryItem: React.FC<IContentGalleryItem> = ({
  img,
  onClick,
}) => {
  const [isPortrait, setIsPortrait] = useState(false);

  const languageId = useAppSelector((state)=> state.language.languageId);

  const src = img.cdnDomain + "/" + img.filePath;

  const alt = img.contentMediaFileCaptions.find(
    (cap) => cap.language.id === languageId
  )?.caption;

  useEffect(() => {
    const image = new Image();

    image.src = src;

    image.onload = () => {
      if (image.height > image.width) {
        setIsPortrait(true);
      } else {
        setIsPortrait(false);
      }
    };
  }, [src]);

  return (
    <Box
      sx={(t) => ({
        maxHeight: 400,
        width: "100%",
        height: "100%",
        backgroundColor: t.palette.background.default,
        textAlign: "center",
        position: "relative",
        cursor: "pointer",
      })}
      onClick={onClick}
    >
      <img
        style={{
          height: "100%",
          width: isPortrait ? "auto" : "100%",
          borderRadius: 6,
          objectFit: "cover",
        }}
        src={src}
        alt={alt}
      />
      {alt && <ContentGallerySubtitle alt={alt} />}
    </Box>
  );
};

export default memo(ContentGalleryItem);
