const ACCESS_TOKENS_ID = "access_tokens";
const REFRESH_TOKENS_ID = "refresh_tokens";

const getAccessToken = (): string | null => {
  return localStorage.getItem(ACCESS_TOKENS_ID);
};

const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKENS_ID);
};

const saveAccessToken = (accessToken: string) => {
  localStorage.setItem(ACCESS_TOKENS_ID, accessToken);
};

const saveRefreshToken = (resfreshToken: string) => {
  localStorage.setItem(REFRESH_TOKENS_ID, resfreshToken);
};

const removeRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKENS_ID);
};

const removeAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKENS_ID);
};

const getTokens = () => ({
  refresh_token: getRefreshToken(),
  access_token: getAccessToken(),
});

const saveTokens = (acessToken: string, refresh_token: string) => {
  saveAccessToken(acessToken);
  saveRefreshToken(refresh_token);
};

const removeAllTokens = () => {
  localStorage.removeItem(REFRESH_TOKENS_ID);
  localStorage.removeItem(ACCESS_TOKENS_ID);
};

const removeToken = () => {
  removeRefreshToken();
  removeAccessToken();
};

const hasAccessTokens = () => !!getAccessToken();

const hasRefreshTokens = () => !!getRefreshToken();

export const tokenService = {
  getTokens,
  removeToken,
  saveTokens,
  hasAccessTokens,
  hasRefreshTokens,
  removeAllTokens,
  getAccessToken,
};
