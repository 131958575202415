import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IIrpfHeaderProps {
  children: any;
  planName: string;
}

const translationPath = "page.irpf.";

const IrpfHeader = ({ children, planName }: IIrpfHeaderProps) => {
  const { t } = useTranslation();

  return (
    <Stack direction={"column"} alignItems={"start"} spacing={2} >
      <Stack direction={"row"} spacing={1}>
        <Typography
          variant="h6"
          sx={(theme) => ({
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[600]
                : theme.palette.grey[400],
          })}
        >
          {t(translationPath + "plan")}:
        </Typography>
        <Typography variant="h6">{planName}</Typography>
      </Stack>
      {children}
    </Stack>
  );
};

export default IrpfHeader;
