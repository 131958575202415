import { Grid } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import FormArray from "../../../../components/UI/FromArray";
import { useTranslation } from "react-i18next";
import { TRefundForm } from "../RefundSchema";
import InputFileForm from "../../../../components/UI/Inputs/InputFile/InputFileForm";
import { IInputFile } from "../../../../models/input-file";

const translationPath = "page.refundsPage.refund.";

const RefundFiles = ({
  onDeleteFileHandler,
}: {
  onDeleteFileHandler: (file: IInputFile) => void;
}) => {
  const { t } = useTranslation();
  const { control, formState } = useFormContext<TRefundForm>();

  const onDeleteVoidHandler = (data: any) => {
    if (data.file) {
      onDeleteFileHandler(data.file);
    }
  };

  const addressesFieldArray = useFieldArray<TRefundForm, "files", "key">({
    control,
    name: "files",
    keyName: "key",
  });

  return (
    <Grid item xs={12}>
      <FormArray
        title={t(translationPath + "files")}
        name="files"
        errorMessage={formState.errors?.files?.message}
        addButtonLabel={t(translationPath + "add_file") || "Add File"}
        formArray={addressesFieldArray}
        onDeleteVoid={onDeleteVoidHandler}
        appendValue={[
          {
            file: {
              id: "",
            },
          },
        ]}
        fieldsObject={(index) => {
          const file = `files.${index}.file`;
          return (
            <Grid item xs={12} sm={12}>
              <InputFileForm
                name={file}
                fileInputProps={{
                  accept: ".jpg, ,jpeg, .png, .webp, .pdf",
                  label: `${t(translationPath + "file")}`,
                  maxFileSize: 2,
                  mediaTypeCode: "1",
                  mediaTypeIdentifier: "RefundMediaType",
                  parentName: "IndividualBeneficiaryRefund",
                  type: "Private",
                  onFileSelected: onDeleteFileHandler,
                  inputProps: {
                    size: "small",
                  },
                }}
              />
            </Grid>
          );
        }}
      />
    </Grid>
  );
};

export default RefundFiles;
