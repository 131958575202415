import { Box, Typography } from "@mui/material";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import { useTranslation } from "react-i18next";

const translationPath = "components.notificationNotFound.";

const NotificationNotFound = () => {
  const { t } = useTranslation();

  return (
    <Box
      mt={2}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={1}
    >
      <NotificationsOffIcon fontSize="large" />
      <Typography>{t(translationPath + "message")}</Typography>
    </Box>
  );
};

export default NotificationNotFound;
