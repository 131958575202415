import { Box, TextField, TextFieldProps } from "@mui/material";
import React, { FC, memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IMaskInput } from "react-imask";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask: any;
  unmask: any;
  definitions: any;
}

type IFormInputProps = {
  name: string;
  mask?: any;
  unmask?: boolean;
  overwrite?: boolean;
  definitions?: any;
  customHelperText?: string;
  handleBlur?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => void;
} & TextFieldProps;

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        inputRef={ref}
        unmask={props.unmask}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        onChange={onChange}
      />
    );
  }
);

const FormInput: FC<IFormInputProps> = ({
  name,
  mask,
  definitions,
  overwrite,
  unmask,
  customHelperText,
  handleBlur,
  ...otherProps
}) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={""}
      render={({ fieldState: { error }, field }) => {
        const inputProps = {
          inputComponent: TextMaskCustom as any,
          inputProps: {
            mask: mask,
            unmask: unmask,
            definitions: definitions,
            inputRef: field.ref,
          },
        };

        const endAdornment =
          otherProps.inputProps && otherProps.inputProps.maxLength ? (
            <Box
              sx={(t) => ({
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: otherProps.multiline ? "start" : "center",
                color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
              })}
            >
              {field.value?.length}/{otherProps.inputProps.maxLength}
            </Box>
          ) : (
            otherProps.InputProps?.endAdornment
          );

        const combinedInputProps = mask
          ? {
              ...otherProps.InputProps,
              endAdornment,
              ...inputProps,
            }
          : { ...otherProps.InputProps, endAdornment };

        return (
          <TextField
            {...field}
            {...otherProps}
            value={field.value || ""}
            onBlur={handleBlur}
            error={!!error}
            InputProps={combinedInputProps}
            helperText={
              error
                ? t(error?.message || "")
                : customHelperText
                ? customHelperText
                : ""
            }
          />
        );
      }}
    />
  );
};

export default memo(FormInput);
