import { createContext, useCallback, useContext, useState } from "react";
import { IEvalMovAnswerEncSatisfaction } from "../../models/form-satisfaction";

interface IEvaluationFormContext {
  answers: IEvalMovAnswerEncSatisfaction[];
  addAnswer: (newAnswer: IEvalMovAnswerEncSatisfaction) => void;
  updateAnswer: (updatedAnswer: IEvalMovAnswerEncSatisfaction) => void;
}

interface IEvaluationFormProviderProps {
  children: JSX.Element;
  initialAnswers: IEvalMovAnswerEncSatisfaction[];
}

const EvaluationFormContext = createContext<IEvaluationFormContext>({
  answers: [],
  addAnswer: () => {},
  updateAnswer: () => {},
});

export const EvaluationFormProvider: React.FC<IEvaluationFormProviderProps> = ({
  initialAnswers,
  children,
}) => {
  const [answers, setAnswers] =
    useState<IEvalMovAnswerEncSatisfaction[]>(initialAnswers);

  const addAnswer = useCallback((newAnswer: IEvalMovAnswerEncSatisfaction) => {
    setAnswers((prevState) => [...prevState, newAnswer]);
  }, []);

  const updateAnswer = useCallback(
    (updatedAnswer: IEvalMovAnswerEncSatisfaction) => {
      setAnswers((prevState) => {
        return prevState.map((q) =>
          q.questionId === updatedAnswer.questionId
            ? {
                ...q,
                questionAlternativeId: updatedAnswer.questionAlternativeId,
                justification: updatedAnswer.justification,
              }
            : q
        );
      });
    },
    []
  );
  return (
    <EvaluationFormContext.Provider
      value={{
        answers,
        addAnswer,
        updateAnswer,
      }}
    >
      {children}
    </EvaluationFormContext.Provider>
  );
};

export const useEvaluationFormContext = () => {
  const context = useContext(EvaluationFormContext);

  return context;
};
