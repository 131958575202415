import { Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";

import Table from "../../../../components/UI/Table/Table";
import NoData from "../../../../components/UI/NoData";
import useDate from "../../../../hooks/useDate";
import DeleteInsurance from "./DeleteInsurance";
import EditInsurance from "./EditInsurance";
import { IInsuranceFavorite } from "../model";
import { memo } from "react";

const translationPath = "page.schedules.insurance.";

interface IRefundsTableProps {
  insurance: IInsuranceFavorite[];
  onDelete: (id: string) => void;
  loading?: boolean;
}

const InsuranceTable = ({
  insurance,
  onDelete,
  loading,
}: IRefundsTableProps) => {
  const { t } = useTranslation();

  const { dayjs } = useDate();

  const columns: GridColDef<IInsuranceFavorite>[] = [
    {
      field: "insuranceName",
      headerName: `${t(translationPath + "insuranceName")}`,
      flex: 1,
      minWidth: 200,
    },
    {
      field: "individual",
      headerName: `${t(translationPath + "individual")}`,
      flex: 1,
      minWidth: 200,
      renderCell: ({ row: { individual } }) => individual.name,
    },
    {
      field: "cardNumber",
      headerName: `${t(translationPath + "cardNumber")}`,
      flex: 1,
    },
    {
      field: "cardValidity",
      headerName: `${t(translationPath + "cardValidity")}`,
      flex: 1,
      renderCell: ({ row: { cardValidity } }) =>
        dayjs(cardValidity).isValid()
          ? dayjs(cardValidity).add(1, "day").format("L")
          : "--",
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      maxWidth: 100,
      renderCell: ({ row: { code } }) => {
        if (code === "1" || code === "2") {
          return null;
        }

        return (
          <Stack flexDirection={"row"} gap={1}>
            <DeleteInsurance id={code} onDelete={onDelete} />
            <EditInsurance id={code} />
          </Stack>
        );
      },
    },
  ];

  return (
    <Grid item container xs={12}>
      <Table
        columns={columns}
        loading={loading}
        rows={insurance}
        rowHeight={70}
        getRowId={(d) => d.code}
      />

      {insurance.length === 0 && (
        <Grid container mt={5}>
          <Grid item xs={12} sm={12}>
            <NoData />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(InsuranceTable);
