import { useCallback } from "react";

type TReturnDiff = "days" | "hours" | "minutes";

export const useDiffDates = () => {
  const calculateDiffDates = useCallback(
    (date: string, returnType: TReturnDiff): number | null => {
      const dateParams = new Date(date);
      const currentDate = new Date();

      if (isNaN(dateParams.getTime())) {
        return null;
      }

      const diffInMilliseconds = Math.abs(
        currentDate.getTime() - dateParams.getTime()
      );

      switch (returnType) {
        case "days":
          return Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
        case "hours":
          return Math.floor(diffInMilliseconds / (1000 * 60 * 60));
        case "minutes":
          return Math.floor(diffInMilliseconds / (1000 * 60));
        default:
          return null;
      }
    },
    []
  );

  return {
    calculateDiffDates,
  };
};
