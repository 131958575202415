import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { hexToRgbA } from "../../../../utils/utils";
import { IMessageProps } from "./models";

const translationPath = "components.checkedIn.";

export const Message = memo(({ roomName }: IMessageProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={(t) => ({
          p: 2,
          width: "fit-content",
          height: "fit-content",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: hexToRgbA(t.palette.success.main, 0.2),
          borderRadius: 80,
        })}
      >
        <VerifiedOutlinedIcon color="success" sx={{ fontSize: 70 }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={(t) => ({
            color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
          })}
        >
          {t(`${translationPath}message`)}
        </Typography>
        <Typography variant="h5" fontWeight="bold">
          {roomName ?? "-"}
        </Typography>
      </Box>
    </>
  );
});
