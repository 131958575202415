import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { isAxiosError } from "axios";
import { useTranslation } from "react-i18next";

import { EBannerLocale, IPortalBanner } from "../../../models/banners";
import { useInstalledExtensions } from "../../../hooks/useInstalledExtensions";
import useFetch from "../../../hooks/useFetch";
import {
  getAllBanners,
  getAllVisitBanners,
  notShowBanners,
} from "../../../services/banner.service";
import { tokenService } from "../../../services/token.service";
import { notificationSliceActions } from "../../slices/notification";
import { useAppDispatch, useAppSelector } from "../../store";
import { transformBanner } from "./utils";
import { BannersContext } from "./BannersContext";
import { LocalBannersService } from "../../../components/Banners/HomeBanners/LocalBanners.service";

const translation = "components.homeBanners.";

interface IBannersProviderProps {
  children: React.ReactNode;
}

export const BannersProvider: React.FC<IBannersProviderProps> = memo(
  ({ children }) => {
    const [banners, setBanners] = useState<IPortalBanner[]>([]);

    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    const token = tokenService.getAccessToken();

    const [hasExtension] = useInstalledExtensions("7");

    const { sendRequest } = useFetch(
      token ? getAllBanners : getAllVisitBanners
    );

    const { sendRequest: hideBanner } = useFetch(notShowBanners);

    const actualLang = useAppSelector((state) => state.language.languageId);

    const loadBanners = useCallback(async () => {
      if (!actualLang || !hasExtension) return;
      const { data, success } = await sendRequest({
        LanguageId: actualLang,
        OnlyNewBanners: token ? true : false,
      });
      if (data && success) {
        setBanners(data.map(transformBanner));
      }
    }, [sendRequest, hasExtension, actualLang, token]);

    const showSuccessNotification = useCallback(() => {
      dispatch(
        notificationSliceActions.showNotification({
          message: t(translation + "notification"),
          type: "success",
        })
      );
    }, [dispatch, t]);

    const removeBanner = useCallback((bannerId: string) => {
      setBanners((old) => old.filter((o) => o.id !== bannerId));
    }, []);

    const hideRequest = useCallback(
      async (bannerId: string) => {
        const { success } = await hideBanner(bannerId);

        if (success) {
          showSuccessNotification();
          removeBanner(bannerId);
        }
      },
      [hideBanner, showSuccessNotification, removeBanner]
    );

    const closeLocalBanner = useCallback(
      (banner: IPortalBanner) => {
        const bannersLocal = LocalBannersService.get();

        LocalBannersService.set([
          ...bannersLocal.filter((b) => b.id !== banner.id),
          banner,
        ]);

        removeBanner(banner.id);
        showSuccessNotification();
      },
      [showSuccessNotification, removeBanner]
    );

    const closeBanner = useCallback(
      async (banner: IPortalBanner) => {
        if (!token) {
          closeLocalBanner(banner);
        } else {
          await hideRequest(banner.id);
        }
      },
      [token, hideRequest, closeLocalBanner]
    );

    const closeAllBanners = useCallback(
      async (pBanners: IPortalBanner[]) => {
        if (token) {
          try {
            await Promise.all(
              pBanners.map(async (banner, i) => {
                if (token) {
                  await notShowBanners(banner.id);
                }
                removeBanner(banner.id);
              })
            );
            dispatch(
              notificationSliceActions.showNotification({
                message: t(translation + "all_banners_notification"),
                type: "success",
              })
            );
          } catch (error) {
            if (isAxiosError(error)) {
              dispatch(
                notificationSliceActions.showNotification({
                  message: error.response?.data.title,
                  type: "error",
                })
              );
            }
          }
        } else {
          const bannersLocal = LocalBannersService.get();

          setBanners([]);

          LocalBannersService.set([
            ...bannersLocal.filter(
              (bannerLocal) => !pBanners.find((b) => b.id === bannerLocal.id)
            ),
            ...pBanners,
          ]);

          dispatch(
            notificationSliceActions.showNotification({
              message: t(translation + "all_banners_notification"),
              type: "success",
            })
          );
        }
      },
      [dispatch, t, token, removeBanner]
    );

    const haveBanners = useCallback(
      (locale: EBannerLocale) => {
        return banners.some((b) =>
          b.bannerLocals.some((local) => local.code === locale)
        );
      },
      [banners]
    );

    useEffect(() => {
      loadBanners();
    }, [loadBanners]);

    const value = useMemo(
      () => ({
        banners,
        setBanners,
        closeBanner,
        closeAllBanners,
        haveBanners,
      }),
      [banners, setBanners, closeBanner, closeAllBanners, haveBanners]
    );

    return (
      <BannersContext.Provider value={value}>
        {children}
      </BannersContext.Provider>
    );
  }
);
