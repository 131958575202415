import { useTranslation } from "react-i18next";
import {
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import EmergencyCareCard from "../../components/VirtualEmergencyCare/EmergencyCareCard";
import { IBeneficiaries } from "../../models/topmed";
import EmergencyCareService from "../../services/emergencyCare.service";
import useFetch from "../../hooks/useFetch";
import PagesContainer from "../../components/UI/PagesContainer";

const translationPath = "page.emergencyCareScheduling.";

const beneficiariesService = new EmergencyCareService();

const EmergencyCareScheduling = () => {
  const { t } = useTranslation();
  const [beneficiaries, setBeneficiaries] = useState<IBeneficiaries>();

  const [isLoadingRegister, setIsLoadingRegister] = useState(false);

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const { sendRequest, loading } = useFetch(
    beneficiariesService.getBeneficiaries
  );

  const loadBeneficiaries = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (success) {
      setBeneficiaries(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    loadBeneficiaries();
  }, [loadBeneficiaries]);

  const hasDependents =
    beneficiaries?.dependents && beneficiaries?.dependents.length !== 0;

  return (
    <PagesContainer
      redirectTo={"/schedule-teleconsultation"}
      title={t(translationPath + "appointment_scheduling")}
      loading={loading}
    >
      <Grid item sm={12} mb={2} display={"flex"} alignItems={"center"} gap={2}>
        <Typography
          variant="h6"
          sx={(theme) => ({
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[700]
                : theme.palette.grey[400],
          })}
          lineHeight={1.2}
        >
          {t(translationPath + "choose_patient")}
        </Typography>
      </Grid>

      {beneficiaries && (
        <>
          <Grid item sm={12} mb={2}>
            <Grid container gap={2}>
              <Grid item xs={12} md={12} sm={6} lg={4}>
                <EmergencyCareCard
                  padding={matches ? 2 : 1}
                  beneficiary={beneficiaries.currentUser}
                  onRegister={setIsLoadingRegister}
                  isLoadingRegister={isLoadingRegister}
                />
              </Grid>
            </Grid>
          </Grid>
          {hasDependents && (
            <>
              <Divider />
              <Grid item sm={12} mt={2}>
                <Typography variant="h6" mb={1}>
                  {t(translationPath + "dependents")}
                </Typography>
                <Stack
                  display={"flex"}
                  flexDirection={"row"}
                  flexWrap={"wrap"}
                  gap={2}
                >
                  {beneficiaries.dependents.map((dependent) => (
                    <EmergencyCareCard
                      padding={matches ? 2 : 1}
                      beneficiary={dependent}
                      key={dependent.beneficiaryId}
                      onRegister={setIsLoadingRegister}
                      isLoadingRegister={isLoadingRegister}
                    />
                  ))}
                </Stack>
              </Grid>
            </>
          )}
        </>
      )}
    </PagesContainer>
  );
};

export default EmergencyCareScheduling;
