import { Grid, Stack, Typography, useTheme } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { INavigationLink } from "../../../../models/navigation";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ModalComponent from "../../Modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DynamicIcon from "../DynamicIcon";

interface IMenuItemsProps {
  toggleMenu: (open: boolean) => void;
  items: INavigationLink[];
}

const MenuItems = ({ toggleMenu, items }: IMenuItemsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const onCloseHandler = () => setOpen(false);

  return (
    <>
      <ModalComponent open={open} onClose={onCloseHandler}>
        <Grid container>
          <Typography variant="h6">
            {t("components.menu.whatsapp.title")}
          </Typography>
          <Typography variant="subtitle1">
            {t("components.menu.whatsapp.description")}
          </Typography>
        </Grid>
      </ModalComponent>
      <Stack direction={"column"} spacing={2}>
        {items.map((item) => (
          <NavLink
            key={item.link}
            to={item.link !== "/settings" ? item.link : location.pathname}
            onClick={
              item.link !== "/settings"
                ? () => toggleMenu(false)
                : () => {
                    toggleMenu(false);
                    setOpen(true);
                  }
            }
            style={({ isActive }) => ({
              textDecoration: "none",
              color:
                item.link === "/settings"
                  ? theme.palette?.text?.secondary
                  : isActive
                  ? theme.palette?.primary.main
                  : theme.palette?.text?.secondary,
            })}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              p={2}
              sx={(theme) => ({
                height: 50,
                borderBottom:
                  theme.palette.mode === "light"
                    ? `1px solid ${theme.palette.grey[300]}`
                    : `1px solid ${theme.palette.grey[200]}`,
              })}
            >
              <Stack direction={"row"} gap={2} alignItems={"center"}>
                <DynamicIcon icon={item.iconClass} />
                <Typography fontWeight={600}>{t(item.name)}</Typography>
              </Stack>
              <ArrowForwardIosIcon />
            </Stack>
          </NavLink>
        ))}
      </Stack>
    </>
  );
};

export default MenuItems;
