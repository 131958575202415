import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";

interface IStepContext {
  activeStep: number;
  errors: number[];
  setStep: (step: number) => void;
  nextStep: () => void;
  backStep: () => void;
  onError: (index: number) => void;
  onRemoveError: (index: number) => void;
  defineFields: (newFields: string[]) => void;
}

interface IStepContextProviderProps {
  children: ReactNode;
  intialStep?: number;
}

const stepContext = createContext<IStepContext>({
  activeStep: 0,
  errors: [],
  setStep: (step: number): void => {},
  nextStep: (): void => {},
  backStep: (): void => {},
  onError: (index: number) => {},
  onRemoveError: (index: number) => {},
  defineFields: (newFields: string[]) => {},
});

export const StepContextProvider: React.FC<IStepContextProviderProps> = ({
  intialStep = 0,
  children,
}) => {
  const [activeStep, setActiveStep] = useState(intialStep);
  const [errors, setErrors] = useState<number[]>([]);
  const [fields, setFields] = useState<string[]>([]);
  const { trigger } = useFormContext();

  const defineFields = useCallback((newFields: string[]) => {
    setFields(newFields);
  }, []);

  const nextStep = () => {
    if (fields.length !== 0) {
      const isValid = trigger(fields);
      isValid.then((value) => {
        if (value) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          onRemoveError(activeStep);
          return;
        } else {
          return;
        }
      });
    }
    if (fields.length === 0 || activeStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const backStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const setStep = (step: number) => {
    setActiveStep(step);
  };

  const onError = useCallback((index: number) => {
    setErrors((currentErrors) => [...currentErrors, index]);
  }, []);

  const onRemoveError = useCallback((index: number) => {
    setErrors((currentErrors) =>
      currentErrors.filter((error) => error !== index)
    );
  }, []);

  return (
    <stepContext.Provider
      value={{
        activeStep,
        errors,
        nextStep,
        backStep,
        setStep,
        onError,
        onRemoveError,
        defineFields,
      }}
    >
      {children}
    </stepContext.Provider>
  );
};

export const useStep = () => {
  const context = useContext(stepContext);

  return context;
};
