import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { notificationSliceActions } from "../store/slices/notification";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useDownload from "../hooks/useDownload";
import { IReceivableAccounts, ITicket } from "../models/history-payment";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import useFetch from "../hooks/useFetch";
import TicketService from "../services/ticket.service";

interface IShareTicketProps {
  ticket: IReceivableAccounts;
}

const translationPath = "components.downloadTicket.";
const service = new TicketService();

const DOWNLOAD_URL =
  process.env.REACT_APP_APP + "/api/v1/Beneficiary/GetPaymentSlip/";

const DownloadTicket = ({ ticket }: IShareTicketProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { downloadFile, loading } = useDownload();
  const [selectedTicket, setSelectedTicket] = useState<ITicket>();

  const { sendRequest } = useFetch(service.getAll);

  const loadHistory = useCallback(async () => {
    const { data, success } = await sendRequest(
      ticket.numberAccountIntegrationId
    );

    if (success) {
      setSelectedTicket(data);
    }
  }, [sendRequest, ticket.numberAccountIntegrationId]);

  useEffect(() => {
    loadHistory();
  }, [loadHistory]);

  const onCopyToClipboardHandler = () => {
    const copyText = selectedTicket!.billNumber;
    navigator.clipboard.writeText(copyText);
    dispatch(
      notificationSliceActions.showNotification({
        message: `${t(translationPath + "code_copied")}`,
        type: "success",
      })
    );
  };

  const onDownloadHandler = () => {
    downloadFile(
      DOWNLOAD_URL,
      ticket.numberAccountIntegrationId,
      dayjs(selectedTicket?.dueDate).format("DD-MM-YYYY")
    );
  };

  return (
    <Grid item container maxWidth={450}>
      {!selectedTicket && (
        <Grid
          item
          container
          minWidth={300}
          minHeight={150}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
      {selectedTicket && (
        <>
          <Grid item xs={12} sm={12}>
            <Typography
              variant="subtitle2"
              sx={(theme) => ({
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.grey[600]
                    : theme.palette.grey[400],
              })}
            >
              {t(translationPath + "ticket_value")}
            </Typography>
            <Typography
              variant="h6"
              sx={(theme) => ({
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.primary.light
                    : theme.palette.primary.dark,
              })}
            >
              R$ {selectedTicket?.amount.toLocaleString("pt-BR")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              variant="subtitle2"
              sx={(theme) => ({
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.grey[600]
                    : theme.palette.grey[400],
              })}
            >
              {t(translationPath + "ticket_payment_date")}
            </Typography>
            <Typography variant="h6">
              {!selectedTicket.dueDate && "-"}
              {selectedTicket && !dayjs(selectedTicket.dueDate).isValid() && (
                <Typography variant="body2">-</Typography>
              )}
              {selectedTicket &&
                dayjs(selectedTicket.dueDate).format("DD/MM/YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="subtitle2"
              sx={(theme) => ({
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.grey[600]
                    : theme.palette.grey[400],
              })}
            >
              {t(translationPath + "payment_code")}
            </Typography>
            <Typography variant="subtitle1" sx={{ wordWrap: "break-word" }}>
              {selectedTicket?.billNumber}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} mb={2}>
            <Button
              variant="text"
              size="small"
              startIcon={<ContentCopyIcon />}
              onClick={onCopyToClipboardHandler}
            >
              {t(translationPath + "copy_code")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button
              variant="contained"
              size="small"
              onClick={onDownloadHandler}
              startIcon={!loading ? <DownloadIcon /> : undefined}
              fullWidth
              disabled={loading ? true : false}
            >
              {loading && (
                <Stack
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={1}
                >
                  <CircularProgress size={25} />
                  <Typography variant="button" pt={0.5}>
                    {t(translationPath + "downloading")}
                  </Typography>
                </Stack>
              )}
              {!loading && t(translationPath + "download_ticket")}
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default DownloadTicket;
