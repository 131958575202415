import GlobalAxios from "../axios/axios-config";
import {
  IAuthorizationsParams,
  IBeneficiaryAuthorization,
} from "../models/authorizations";

const AUTHORIZATIONS_ROUTE =
  process.env.REACT_APP_APP + "/api/v1/Beneficiary/GetBeneficiaryAuthorization";

export class AuthorizationService {
  getAll = (searchParams?: any) => {
    return GlobalAxios.get<IBeneficiaryAuthorization[]>(AUTHORIZATIONS_ROUTE, {
      params: searchParams,
    });
  };

  getAuthorizationsBus = (searchParams: IAuthorizationsParams) => {
    return GlobalAxios.get<IBeneficiaryAuthorization[]>(
      AUTHORIZATIONS_ROUTE + "4uBus",
      {
        params: searchParams,
      }
    );
  };
}

export default AuthorizationService;
