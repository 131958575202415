import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../store/store";
import { Permission, Permissions } from "../models/permission";
import useDomain from "./useDomain";
import { ALL_APP_DOMAIN } from "../store/DomainContext";

const useRole = (permissions: Permission[]) => {
  const { domainName } = useDomain();

  const privileges = useAppSelector((state) => state.privilege.privileges);

  const [hasPermission, setHasPermission] = useState(false);

  const checkPrivileges = useCallback(() => {
    const actualPermissions = privileges.filter(
      (item) =>
        item.domainName === domainName || item.domainName === ALL_APP_DOMAIN
    );

    const actualActions = actualPermissions.map((p) => p.actions).flat();

    if (actualActions?.includes(Permissions.ALL)) {
      setHasPermission(true);
    } else {
      const check = permissions.every(
        Set.prototype.has,
        new Set(actualActions)
      );
      if (check) {
        setHasPermission(true);
      }
    }
  }, [privileges, domainName, permissions]);

  useEffect(() => {
    checkPrivileges();
  }, [checkPrivileges]);

  return hasPermission;
};

export default useRole;
