import { memo, useCallback, useEffect, useMemo, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { IFamilyMember } from "../../FamilyGroup/model";
import useFetch from "../../../../hooks/useFetch";
import { FamilyGroupService } from "../../FamilyGroup/familyGroup.service";
import SelectIconForm from "../../../../components/UI/SelectIcon/SelectIconForm";
import { IItem } from "../../../../components/UI/SelectIcon/model";
import IconButton from "../../../../components/UI/SelectIcon/IconButton";
import ModalComponent from "../../../../components/UI/Modal";
import AddFamilyGroupForm from "../../FamilyGroup/AddFamilyGroup/AddFamilyGroupForm";

const Patient = () => {
  const [patients, setPatients] = useState<IFamilyMember[]>([]);

  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const { sendRequest } = useFetch(FamilyGroupService.get);

  const fetch = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (success && data) {
      setPatients(data);
    }
  }, [sendRequest]);

  const onClickHandler = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    fetch();
  };

  const optionsWithIcon: IItem[] = useMemo(
    () =>
      patients
        .filter((o) => !!o.individual)
        .map((o) => ({
          name: o.individual!.name,
          id: o.individual!.id,
          individualId: o.individual!.id,
          icon: (
            <PersonIcon
              fontSize="medium"
              sx={{ fontSize: 30, color: "white" }}
            />
          ),
        })),
    [patients]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <>
      <ModalComponent open={open} onClose={handleClose}>
        <Typography variant="h5">
          {t("page.schedules.newSchedule.addFamilyGroup")}
        </Typography>
        <AddFamilyGroupForm onSuccess={handleClose} />
      </ModalComponent>
      <SelectIconForm
        options={optionsWithIcon}
        name="patient"
        multi={false}
        addIcon={
          <IconButton
            item={{
              icon: <GroupAddIcon />,
              id: "add_button",
              name: t("page.schedules.newSchedule.addFamilyGroup"),
            }}
            sx={{ opacity: 0.5 }}
            onClick={onClickHandler}
          />
        }
      />
    </>
  );
};

export default memo(Patient);
