import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TMedicShareForm, medicCardShareSchema } from "./MedicCardShareSchema";
import { IMedicCard } from "../../models/medic-card";
import FormInput from "../UI/Inputs/FormInput";
import FormCheckBox from "../UI/Inputs/FormCheckBox";

interface IMedicCardShareProps {
  card: IMedicCard;
  onClose: () => void;
  loading: boolean;
  onSubmit: (values: TMedicShareForm) => void;
}

const translationPath = "components.medicCardShare.";

const MedicCardShareForm = ({
  card,
  onClose,
  loading,
  onSubmit,
}: IMedicCardShareProps) => {
  const methods = useForm<TMedicShareForm>({
    resolver: zodResolver(medicCardShareSchema),
  });
  const { t } = useTranslation();

  const {
    formState: { isSubmitSuccessful },
    reset,
    handleSubmit,
  } = methods;

  useEffect(() => {
    if (isSubmitSuccessful) {
      //   reset();
    }
  }, [isSubmitSuccessful, reset]);

  const { id } = card;

  const onSubmitHandler = useCallback(
    (values: TMedicShareForm) => {
      onSubmit({ ...values, beneficiaryCardId: id });
    },
    [id, onSubmit]
  );

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitHandler)}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginTop: 2,
        }}
      >
        <Typography variant="subtitle1" sx={{ textAlign: "center" }} mb={2}>
          {t(translationPath + "share_with", {
            cardCode: card.cardCode,
            individualName: card.individual.name.toUpperCase(),
          })}
        </Typography>
        <FormInput
          size="small"
          autoFocus={true}
          name="document"
          type="text"
          fullWidth
          label={t(translationPath + "document")}
          sx={{ mb: 2 }}
          mask="000.000.000-00"
        />
        <FormInput
          size="small"
          name="name"
          fullWidth
          type="text"
          label={t(translationPath + "name")}
          sx={{ mb: 2 }}
        />
        <FormInput
          size="small"
          name="beneficiaryCardId"
          value={card.id}
          fullWidth
          type="text"
          label={t(translationPath + "name")}
          sx={{ display: "none" }}
        />
        <Typography variant="subtitle2" mb={1}>
          {t(translationPath + "responsibility_message")}
        </Typography>
        <Stack mb={1}>
          <FormCheckBox
            name="terms"
            label={t(translationPath + "read") || ""}
          />
        </Stack>
        <Grid container direction={"row"} spacing={2}>
          <Grid item sm={6}>
            <Button
              fullWidth
              variant="outlined"
              disabled={loading ? true : false}
              onClick={onClose}
            >
              {t(translationPath + "cancel")}
            </Button>
          </Grid>
          <Grid item sm={6}>
            <Button
              fullWidth
              variant="contained"
              disabled={loading ? true : false}
              type="submit"
            >
              {loading && <CircularProgress size={25} />}
              {!loading && t(translationPath + "share")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default MedicCardShareForm;
