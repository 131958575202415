import { Box, Tooltip } from "@mui/material";
import IconButton from "../../UI/IconButton";
import { useTranslation } from "react-i18next";
import { IZoomControlProps } from "./models";

import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { memo, useState } from "react";

const translationPath = "components.pagesActions.";

const ZoomControl = ({ scale, setScale }: IZoomControlProps) => {
  const { t } = useTranslation();

  const [zoomOutTooltip, setZoomOutTooltip] = useState(false);

  const [zoomInTooltip, setZoomInTooltip] = useState(false);

  return (
    <Box display={"flex"} flexDirection={"row"}>
      <Tooltip
        title={t(translationPath + "zoom_out")}
        disableHoverListener
        open={zoomOutTooltip}
        onMouseEnter={() => {
          if (scale !== 1) {
            setZoomOutTooltip(true);
          }
        }}
        onMouseLeave={() => setZoomOutTooltip(false)}
      >
        <Box>
          <IconButton
            onClick={() => setScale((oldState) => (oldState = oldState - 0.5))}
            disabled={scale === 1}
            size="small"
          >
            <ZoomOutIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
      <Tooltip
        title={t(translationPath + "zoom_in")}
        disableHoverListener
        open={zoomInTooltip}
        onMouseEnter={() => {
          if (scale !== 3) {
            setZoomInTooltip(true);
          }
        }}
        onMouseLeave={() => setZoomInTooltip(false)}
      >
        <Box>
          <IconButton
            onClick={() =>
              setScale((oldState: any) => (oldState = oldState + 0.5))
            }
            disabled={scale === 3}
            size="small"
          >
            <ZoomInIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default memo(ZoomControl);
