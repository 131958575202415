import { Stack, SxProps, Theme, Typography } from "@mui/material";
import { memo } from "react";

interface IRowProps {
  label: string;
  value: string | React.ReactNode;
  sx?: SxProps<Theme>;
}

const Row: React.FC<IRowProps> = memo(({ label, value, sx }) => {
  return (
    <Stack gap={1}>
      <Typography variant="body2">{label}</Typography>

      {typeof value === "string" ? (
        <Typography fontWeight={600} sx={sx}>
          {value}
        </Typography>
      ) : (
        value
      )}
    </Stack>
  );
});

export default memo(Row);
