import { memo } from "react";
import { IFamilyMember } from "../model";
import { Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";

import NoData from "../../../../components/UI/NoData";
import useDate from "../../../../hooks/useDate";
import Table from "../../../../components/UI/Table/Table";
import DeleteFamilyGroup from "./DeleteFamilyGroup";
import EditFamilyGroup from "./EditFamilyGroup";

interface IFamilyGroupTableProps {
  familyGroupMembers: IFamilyMember[];
  onDelete: (id: string) => void;
  loading?: boolean;
}

const TRANSLATION_PATH = "page.schedules.addFamilyGroup.";

const FamilyGroupTable: React.FC<IFamilyGroupTableProps> = ({
  familyGroupMembers,
  onDelete,
  loading,
}) => {
  const { t } = useTranslation();

  const { dayjs } = useDate();

  const columns: GridColDef<IFamilyMember>[] = [
    {
      field: "individual.name",
      headerName: t(TRANSLATION_PATH + "individual")!,
      flex: 1,
      renderCell: ({ row: { individual } }) => individual.name,
    },
    {
      field: "IKinshipDegree",
      headerName: t(TRANSLATION_PATH + "kinshipDegree.label")!,
      flex: 1,
      renderCell: ({ row: { kinshipDegree } }) => kinshipDegree?.name || "Eu",
    },
    {
      field: "individual.document",
      headerName: t(TRANSLATION_PATH + "document.label")!,
      flex: 1,
      renderCell: ({ row: { individual } }) => individual.document,
    },
    {
      field: "individual.birthDate",
      headerName: t(TRANSLATION_PATH + "birthDate.label")!,
      flex: 1,
      renderCell: ({ row: { individual } }) =>
        dayjs(individual.birthDate).format("L"),
    },
    {
      field: "individual.gender",
      headerName: t(TRANSLATION_PATH + "gender.label")!,
      flex: 1,
      renderCell: ({ row: { individual } }) => individual.gender?.name,
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      maxWidth: 100,
      renderCell: ({ row: { id } }) => {
        if (!id) {
          return null;
        }

        return (
          <Stack flexDirection={"row"} gap={1}>
            <DeleteFamilyGroup id={id} onDelete={onDelete} />
            <EditFamilyGroup id={id} />
          </Stack>
        );
      },
    },
  ];

  return (
    <Grid item container xs={12} sm={12} md={12} xl={12}>
      <Table
        columns={columns}
        rows={familyGroupMembers}
        rowHeight={70}
        loading={loading}
        getRowId={({ individual }) => individual.id}
      />

      {familyGroupMembers.length === 0 && (
        <Grid container mt={5}>
          <Grid item xs={12} sm={12}>
            <NoData />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(FamilyGroupTable);
