import { ThemeOptions } from "@mui/material";
import { TAppreanceCode } from "../store/slices/configurations";
import { authApi } from "./auth.service";
import { TTheme } from "../models/theme";

const URL = process.env.REACT_APP_APP;

const getFaviconEl = () => {
  return document.getElementById("favicon") as HTMLLinkElement;
};

export const setFavicon = (link: string) => {
  const favicon = getFaviconEl();
  if (favicon) {
    favicon.href = link;
  }
};

const getTitleEl = () => {
  return document.getElementById("title");
};

export const setTitle = (title: string) => {
  const titleEl = getTitleEl();
  if (titleEl) {
    titleEl.innerText = title;
  }
};

export const controlMode = (
  { palette, ...options }: TTheme & ThemeOptions,
  mode: TAppreanceCode
): ThemeOptions => {
  if (mode !== "1" && mode !== "2") {
    throw new Error("Invalid mode. Please provide '1' or '2'.");
  }

  if (options.darkPalette && mode === "2") {
    palette = {
      ...options.darkPalette,
    };
  }

  const optsWithMode: ThemeOptions = {
    ...options,
    palette: {
      ...palette,
      mode: mode === "1" ? "light" : "dark",
    },
  };

  return optsWithMode;
};

interface ISiteApp {
  id: string;
  frontConfig: string;
}
interface IGetSiteAppConfig {
  id: string;
  domain: string;
  siteApp: ISiteApp;
}

export const getSiteAppConfig = () => {
  return authApi.get<IGetSiteAppConfig>(URL + "/api/v1/SiteApp");
};
