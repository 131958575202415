import { memo, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Paper, Stack } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { useAppDispatch, useAppSelector } from "../../../store/store";
import { themeSliceActions } from "../../../store/slices/theme";
import { INavigationLink } from "../../../models/navigation";

import SideMenuItem from "./SideMenuItem";
import usePermission from "../../../hooks/usePermission";
import { useInstalledExtensions } from "../../../hooks/useInstalledExtensions";
import { Extension } from "../../../models/intalled-extensions";
import { tokenService } from "../../../services/token.service";

const SideMenu = () => {
  const token = tokenService.hasAccessTokens();

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [telephoneInstalled] = useInstalledExtensions(Extension.TELEPHONE);
  const [promptInstalled] = useInstalledExtensions(Extension.EMERGENCY_CARE);
  const [virtualCardInstalled] = useInstalledExtensions(Extension.CARDS);
  const [whatsappInstalled] = useInstalledExtensions(Extension.WHATSAPP);
  const [scheduleInstalled] = useInstalledExtensions(Extension.SCHEDULE);

  const hasPhonePermission = usePermission({
    domain: "telephonecall",
  });

  const hasTeleconsultationPermission = usePermission({
    domain: "promptservice",
  });

  const cardPermission = usePermission({
    domain: "card",
  });

  const { logo, logo_dark, mode, name, menuOpen, favicon, favicon_dark } =
    useAppSelector((state) => state.theme);

  const items: INavigationLink[] = useMemo(() => {
    const initialItems = [
      {
        name: t("components.menu.home"),
        iconClass: "HomeRoundedIcon",
        link: "/",
        end: true,
      },
    ];

    if (whatsappInstalled) {
      initialItems.push({
        name: t("components.menu.whats"),
        iconClass: "WhatsAppIcon",
        link: "/whats",
        end: false,
      });
    }

    if (telephoneInstalled || promptInstalled) {
      if (hasPhonePermission || hasTeleconsultationPermission) {
        initialItems.push({
          name: t("components.menu.virtualEmergencyCare"),
          iconClass: "LocalHospitalIcon",
          link: "/schedule-teleconsultation",
          end: false,
        });
      }
    }

    if (scheduleInstalled && token) {
      initialItems.push({
        name: t("components.menu.schedule"),
        iconClass: "EventIcon",
        link: "/schedule",
        end: false,
      });
    }

    if (virtualCardInstalled) {
      if (token) {
        if (cardPermission) {
          initialItems.splice(1, 0, {
            name: t("components.menu.cards"),
            iconClass: "CreditCardRoundedIcon",
            link: "/cards",
            end: false,
          });
        }
      } else {
        initialItems.splice(1, 0, {
          name: t("components.menu.cards"),
          iconClass: "CreditCardRoundedIcon",
          link: "/cards",
          end: false,
        });
      }
    }

    return initialItems;
  }, [
    t,
    hasPhonePermission,
    hasTeleconsultationPermission,
    telephoneInstalled,
    promptInstalled,
    virtualCardInstalled,
    scheduleInstalled,
    cardPermission,
    token,
    whatsappInstalled,
  ]);

  const toggleMenuHandler = () => {
    dispatch(themeSliceActions.toggleMenu());
  };

  const menuOpenLogo = mode === "1" ? logo : logo_dark ? logo_dark : logo;

  const menuClosedLogo = useMemo(() => {
    if (favicon) {
      if (mode === "1") {
        return favicon;
      }
      return favicon_dark || favicon;
    }

    return logo;
  }, [logo, favicon_dark, favicon, mode]);

  return (
    <Box
      sx={{
        height: "100vh",
        width: menuOpen ? "220px" : "80px",
      }}
    >
      <Paper
        elevation={1}
        sx={(theme) => ({
          height: "100%",
          padding: theme.spacing(menuOpen ? 3 : 2),
          backgroundColor:
            theme.palette.mode === "light" ? "#fff" : "undefined",
          borderRadius: 0,
        })}
      >
        <Stack height={"100%"}>
          <Link to="/">
            <img
              src={menuOpen ? menuOpenLogo : menuClosedLogo}
              width={"100%"}
              style={{
                cursor: "pointer",
              }}
              alt={name}
            />
          </Link>

          <Stack
            alignItems={"start"}
            width={"fit-content"}
            margin={"auto"}
            spacing={1}
            position={"relative"}
            mt={3}
          >
            <IconButton
              onClick={toggleMenuHandler}
              sx={(t) => ({
                position: "absolute",
                right: menuOpen ? -40 : -30,
                backgroundColor:
                  t.palette.mode === "light" ? "#fff" : t.palette.grey[900],
                boxShadow: "0px 0px 1px 1px rgb(0 0 0 / 8%)",
              })}
              size="small"
            >
              <ArrowForwardIosIcon
                sx={{
                  transform: `rotate(${menuOpen ? 180 : 0}deg)`,
                  fontSize: 15,
                }}
                fontSize="small"
              />
            </IconButton>
            {items.map((item) => {
              return (
                <SideMenuItem
                  {...item}
                  key={item.link}
                  onlyIcon={!menuOpen}
                  end={item.end}
                />
              );
            })}
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};

export default memo(SideMenu);
