import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import useFetch from "../hooks/useFetch";
import { IMedicCard } from "../models/medic-card";
import BeneficiaryCard from "../components/MedicCard/BeneficiaryCard";
import ModalComponent from "../components/UI/Modal";
import MedicCardSharedForm from "../components/MedicCard/MedicaCardSharedForm";
import CardService from "../services/card.service";
import NoData from "../components/UI/NoData";
import { useAppSelector } from "../store/store";
import { tokenService } from "../services/token.service";
import PagesContainer from "../components/UI/PagesContainer";
import { useCallback, useEffect, useState } from "react";

const cardService = new CardService();

const translationPath = "page.cards.";

const CardsPage = () => {
  const { sendRequest, loading } = useFetch(cardService.getAll);

  const cardsShared = useAppSelector((state) => state.cards);

  const [cards, setCards] = useState<IMedicCard[]>([]);

  const cardPermission = useAppSelector((state) =>
    state.privilege.privileges.find((priv) => priv.domainName === "card")
  );

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const token = tokenService.hasAccessTokens();

  const loadCards = useCallback(async () => {
    if (token && cardPermission) {
      const { data, success } = await sendRequest(undefined);
      if (success) {
        setCards(data);
      }
    }
  }, [sendRequest, token, cardPermission]);

  const onSubmitHandler = () => {
    loadCards();
  };

  useEffect(() => {
    loadCards();
  }, [loadCards]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let FilteredCards = cardsShared;

  if (token) {
    FilteredCards = cards;
  }

  const planNames = FilteredCards.filter((item, index, historyPayments) => {
    const filtered = historyPayments.findIndex(
      (elem) => elem.planName === item.planName
    );
    return index === filtered;
  });

  const planNamesOrdered = planNames.sort(function (a, b) {
    return a.planName < b.planName ? -1 : a.planName > b.planName ? 1 : 0;
  });

  if (token && !cardPermission) {
    return <Navigate to={"/no-access"} />;
  }

  return (
    <>
      <ModalComponent open={open} onClose={handleClose} dialogMaxWidth={"md"}>
        <MedicCardSharedForm onClose={handleClose} />
      </ModalComponent>

      <PagesContainer
        loading={loading}
        title={t(translationPath + "card")}
        rowActions={
          !token && (
            <Button
              size="small"
              variant="outlined"
              onClick={handleOpen}
              startIcon={<AddIcon />}
            >
              {t(translationPath + "show_shared_card")}
            </Button>
          )
        }
      >
        <Stack paddingBottom={4}>
          {planNamesOrdered.map((plan) => (
            <Grid key={plan.id}>
              <Typography mb={2} variant="h6">
                {t(translationPath + "plan")}: {plan.planName}
              </Typography>
              <Stack spacing={2}>
                {FilteredCards.filter(
                  (card) => card.planName === plan.planName
                ).map((item) => (
                  <BeneficiaryCard
                    key={item.id}
                    card={item}
                    onSubmit={onSubmitHandler}
                    canShare={item.cardType === "Owner"}
                  />
                ))}
              </Stack>
            </Grid>
          ))}
        </Stack>

        {token && cards.length === 0 && <NoData />}
      </PagesContainer>
    </>
  );
};

export default CardsPage;
