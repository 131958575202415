import { useStep } from "../../../store/contexts/StepContext";

const StepComponent = ({
  step,
  children,
}: {
  step: number;
  children: JSX.Element | null;
}) => {
  const { activeStep } = useStep();

  if (activeStep === step) return children;
  return null;
};

export default StepComponent;
