import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { useLayoutEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import AccessTemporaryMessage from "../components/AccessTemporaryMessage";
import DocumentModal from "../components/Docs/DocumentModal";
import BottomMenu from "../components/UI/Menus/BottomMenu";
import Header from "../components/UI/Menus/Header/Header";
import SideMenu from "../components/UI/Menus/SideMenu";
import { useGaPageView } from "../hooks/useGaPageView";
import { tokenService } from "../services/token.service";
import { TemporaryAccessProvider } from "../store/contexts/TemporaryAccessContext";
import { themeSliceActions } from "../store/slices/theme";
import { useAppDispatch, useAppSelector } from "../store/store";

const RootLayout = () => {
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  useGaPageView(pathname);

  const theme = useTheme();

  const token = tokenService.hasAccessTokens();

  const { logoInHeader } = useAppSelector((state) => state.theme);

  const sideMenu = useAppSelector((state) => state.theme.sideMenu);

  const userConfig = useAppSelector((state) => state.configurations);

  const visitUserMode = localStorage.getItem("theme");

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  useLayoutEffect(() => {
    if (token) {
      dispatch(
        themeSliceActions.setThemeMode(
          userConfig.webAppearance.code === "1" ? "1" : "2"
        )
      );
    }
    if (!token && visitUserMode) {
      dispatch(
        themeSliceActions.setThemeMode(visitUserMode === "1" ? "1" : "2")
      );
    }
  }, [dispatch, token, visitUserMode, userConfig]);

  return (
    <Stack
      sx={(t) => ({
        height: "100vh",
        flexDirection: "row",
      })}
    >
      {matches && sideMenu && <SideMenu />}

      {!matches && <BottomMenu />}

      <DocumentModal />

      <Stack width={"100%"}>
        <Header />

        <AccessTemporaryMessage />

        <motion.main
          style={{
            height: `calc(100% - ${logoInHeader ? 76 : 56}px)`,
          }}
          key={pathname}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
        >
          <TemporaryAccessProvider>
            <Outlet />
          </TemporaryAccessProvider>
        </motion.main>
      </Stack>
    </Stack>
  );
};

export default RootLayout;
