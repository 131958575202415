import { AddBearerTemporaryHeader } from "../axios/temprary-axios-config";
import i18n from "../i18n";
import ConfigurationsService from "../services/configurations.service";
import LanguagesService from "../services/languages.service";
import { tokenService } from "../services/token.service";
import { tokenTemporaryService } from "../services/tokenTemorary.service";
import { userService } from "../services/user.service";
import { configurationsSliceActions } from "../store/slices/configurations";
import { languageSliceActions } from "../store/slices/language";
import { permissionSliceActions } from "../store/slices/permission";
import { privilegeSliceActions } from "../store/slices/privilege";
import { userSliceActions } from "../store/slices/user";
import store from "../store/store";
import { saveConfig } from "../store/thunks/ConfigurationsThunk";

const configService = new ConfigurationsService();

const languagesService = new LanguagesService();

const rootLoader = async () => {
  try {
    const { data: languages } = await languagesService.list();

    store.dispatch(languageSliceActions.saveLanguageOptions(languages));

    store.dispatch(languageSliceActions.saveLanguage(i18n.language));

    if (tokenService.hasAccessTokens()) {
      const { data: privileges } = await userService.userMePrivileges();

      const { data: userData } = await userService.userMe();

      const { data: userConfig } = await configService.getAll();

      userService.saveEstablishmentId(userData.establishmentId);

      store.dispatch(userSliceActions.saveUser(userData));

      store.dispatch(privilegeSliceActions.savePrivilege(privileges));

      store.dispatch(
        permissionSliceActions.savePermission({ hasPermission: true })
      );

      if (userConfig.id) {
        store.dispatch(
          configurationsSliceActions.saveConfigs(userConfig)
        );
      } else {
        await store.dispatch(
          saveConfig({
            language: { id: store.getState().language.languageId, name: "" },
          })
        );
      }

      store.dispatch(
        languageSliceActions.saveLanguageId(userConfig.language.id)
      );

      i18n.changeLanguage(userConfig.language.code);

      return {
        privileges,
        userData,
      };
    }

    if (tokenTemporaryService.hasTemporaryAccessToken()) {
      AddBearerTemporaryHeader();
      return true;
    }

    return null;
  } catch (error) {}
};

export default rootLoader;
