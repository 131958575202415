import { Box, Tooltip } from "@mui/material";
import { memo, useCallback } from "react";
import IconButton from "../../../UI/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import { IPrintPdfProps } from "./models";
import { useTranslation } from "react-i18next";

const translationPath = "components.pagesActions.";

export const PrintPdf = memo(({ file }: IPrintPdfProps) => {
  const { t } = useTranslation();

  const onClickHandler = useCallback(() => {
    let blob;

    if (typeof file === "string") {
      const base64Response = atob(file.split(",")[1]);
      const byteArray = new Uint8Array(base64Response.length);
      for (let i = 0; i < base64Response.length; i++) {
        byteArray[i] = base64Response.charCodeAt(i);
      }
      blob = new Blob([byteArray], { type: "application/pdf" });
    } else if (file instanceof File) {
      blob = file;
    } else {
      return;
    }

    const url = URL.createObjectURL(blob);
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.onload = () => {
        newWindow.print();
      };
    }
  }, [file]);

  return (
    <Tooltip title={t(translationPath + "print")}>
      <Box>
        <IconButton size="small" onClick={onClickHandler}>
          <PrintIcon fontSize="small" />
        </IconButton>
      </Box>
    </Tooltip>
  );
});
