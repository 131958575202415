import { Stack, Typography } from "@mui/material";

const MedicGuideContact = ({
  label,
  contact,
}: {
  label: string;
  contact: string;
}) => {
  return (
    <Stack flexDirection={"row"} gap={1}>
      <Typography
        variant="subtitle2"
        fontSize={15}
        sx={(theme) => ({
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[800]
              : theme.palette.grey[400],
        })}
      >
        {label}
      </Typography>
      <Typography variant="subtitle2" fontSize={15}>
        {contact}
      </Typography>
    </Stack>
  );
};

export default MedicGuideContact;
