import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
} from "@mui/material";
import dayjs from "dayjs";
import i18n from "../../../i18n";

interface ISelectInputProps {
  label: string;
  onChangeHandler: (e: SelectChangeEvent) => void;
  value: string;
  startDate: string;
  actualDate: dayjs.Dayjs;
  sx?: SxProps<Theme>;
}

const SelectMonthYear = ({
  label,
  onChangeHandler,
  value,
  startDate,
  actualDate,
  sx,
}: ISelectInputProps) => {
  const monthArray = [];
  const locale = i18n.language;

  const initalDate = dayjs(startDate).subtract(1, "month");
  const monthsDiff = actualDate.diff(initalDate, "month");

  for (let i = 0; i < monthsDiff; i++) {
    const month = actualDate
      .locale(locale)
      .subtract(i, "month")
      .format("MM/YYYY - MMMM");
    monthArray.push(month);
  }

  return (
    <FormControl fullWidth sx={sx}>
      <InputLabel id="select-input">{label}</InputLabel>
      <Select
        id="select-input"
        label={label}
        onChange={onChangeHandler}
        value={value}
        size="small"
        fullWidth
        MenuProps={{
          style: {
            maxHeight: 300,
          },
        }}
      >
        {monthArray.map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectMonthYear;
