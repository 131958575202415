import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useFetch from "../../../../../hooks/useFetch";
import AutoComplete from "../../../../../components/UI/Inputs/AutoComplete";
import { cancelSchema } from "./schema";
import { ScheduleAppointmentService } from "../../../appointment.service";
import { ISelectType } from "../../../../../components/UI/Inputs/AutoCompleteText";
import { ETasyScheduleTypeCode } from "../../../model";

const keys = cancelSchema.keyof().enum;

interface IReasonProps {
  code: string;
}

const Reason: React.FC<IReasonProps> = ({ code }) => {
  const [options, setOptions] = useState<ISelectType[]>([]);

  const { t } = useTranslation();

  const { sendRequest } = useFetch(
    ScheduleAppointmentService.getCalendarExamCancellationReason
  );

  const { sendRequest: consultationRequest } = useFetch(
    ScheduleAppointmentService.getCalendarConsultationCancellationReason
  );

  const fetch = useCallback(async () => {
    const { data, success } = await (code === ETasyScheduleTypeCode.CONSULTATION
      ? consultationRequest(null)
      : sendRequest(null));
    if (data && success) {
      setOptions(
        data.map((d) => ({
          id: String(d.identifier),
          code: d.cancellationReasonCode,
          name: d.cancellationReasonName,
        }))
      );
    }
  }, [sendRequest, code, consultationRequest]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <AutoComplete
      getOptionLabel={(option) => {
        return option.name;
      }}
      size="small"
      label={t("page.schedules.scheduleDetails.cancel.reason.label")}
      placeholder={
        t("page.schedules.scheduleDetails.cancel.reason.placeholder")!
      }
      name={keys.reason}
      options={options}
    />
  );
};

export default memo(Reason);
