import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Tooltip } from "@mui/material";
import { memo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useDownload from "../../../../hooks/useDownload";
import { tokenTemporaryService } from "../../../../services/tokenTemorary.service";
import IconButton from "../../../UI/IconButton";
import { OverflowTooltip } from "../../../UI/TooltipOverflow";
import { IFileItemProps } from "./model";
import { OpenFile } from "./OpenFile/OpenFile";

const translationPath = "components.fileItem.";

const DOWNLOADFILE_TEMPORARY_ROUTE =
  process.env.REACT_APP_APP + "/api/v1/LaboratorialExams/file/Tasy";
const DOWNLOADFILE_ROUTE =
  process.env.REACT_APP_APP + "/api/v1/LaboratorialExams/file";

const FileItem = ({
  sequential,
  sequencialControl,
  currentImageIdentifier,
  previewLoading,
  disabled,
  onDownloading,
  getCurrentImageIdentifier,
}: IFileItemProps) => {
  const { downloadFileParams, loading } = useDownload();

  const { t } = useTranslation();

  const isTemporary = tokenTemporaryService.hasTemporaryAccessToken();

  const handleDownloadFile = useCallback(
    (imageIdentifier: number) => {
      const url = isTemporary
        ? DOWNLOADFILE_TEMPORARY_ROUTE
        : DOWNLOADFILE_ROUTE;
      downloadFileParams(url, {
        Identifier: sequencialControl,
        ImageIdentifier: imageIdentifier,
      });
    },
    [downloadFileParams, isTemporary, sequencialControl]
  );

  const handleClick = () => {
    handleDownloadFile(sequential.sequencialImageControl);
  };

  const handlePreView = useCallback(() => {
    const fileNameArray = sequential.medicalCertificateFile.split(".");
    const format = fileNameArray[fileNameArray.length - 1];

    getCurrentImageIdentifier(sequential, format);
  }, [sequential, getCurrentImageIdentifier]);

  const openFileInNewTab = useCallback(async () => {
    const fileNameArray = sequential.medicalCertificateFile.split(".");
    const format = fileNameArray[fileNameArray.length - 1];

    const file = await getCurrentImageIdentifier(sequential, format);

    if (!file) {
      return null;
    }
    return file;
  }, [getCurrentImageIdentifier, sequential]);

  useEffect(() => {
    if (loading) {
      onDownloading(true);
    } else {
      onDownloading(false);
    }
  }, [loading, onDownloading]);

  return (
    <Box
      sx={(t) => ({
        p: 1,
        width: "100%",
        minWidth: 200,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor:
          currentImageIdentifier === sequential.sequencialImageControl
            ? t.palette.mode === "light"
              ? t.palette.grey[200]
              : t.palette.grey[800]
            : t.palette.background.default,
        borderRadius: 1,
        borderBottom: 1,
        borderBottomColor: t.palette.divider,
        transition: "ease-in-out 0.1s",
        "&:hover": {
          backgroundColor:
            currentImageIdentifier !== sequential.sequencialImageControl
              ? t.palette.mode === "light"
                ? t.palette.grey[100]
                : t.palette.grey[900]
              : undefined,
        },
      })}
    >
      <OverflowTooltip
        text={`${t(`${translationPath}image`)} ${
          sequential.sequencialImageControl
        }`}
        tooltip={`${t(`${translationPath}image`)} ${
          sequential.sequencialImageControl
        }`}
        textTransform="none"
        variant="subtitle2"
      />
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <OpenFile
          fileName={`${t(`${translationPath}image`)} ${
            sequential.sequencialImageControl
          }`}
          loading={previewLoading}
          onOpenFileInNewTab={openFileInNewTab}
        />
        <Tooltip title={t(translationPath + "preview")} placement="bottom">
          <Box>
            <IconButton
              disabled={previewLoading}
              size="small"
              onClick={handlePreView}
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
          </Box>
        </Tooltip>
        <Tooltip title={t(translationPath + "download_file")} placement="right">
          <Box>
            <IconButton
              loading={loading}
              disabled={disabled}
              size="small"
              onClick={handleClick}
            >
              <DownloadIcon fontSize="small" />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default memo(FileItem);
