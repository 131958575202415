import { Button } from "@mui/material";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import TodayRoundedIcon from "@mui/icons-material/TodayRounded";
import { IScheduleAppointment } from "../../model";
import CancelSchedule from "./CancelSchedule/CancelSchedule";

interface IRescheduleButton {
  tasySchedulingTypeCode: string;
  appointment: IScheduleAppointment;
}

const RescheduleButton: React.FC<IRescheduleButton> = ({
  appointment,
  tasySchedulingTypeCode,
}) => {
  const { t } = useTranslation();
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const navigate = useNavigate();

  const handleCancelled = () => {
    navigate("/schedule/newSchedule");
  };

  return (
    <>
      {tasySchedulingTypeCode && appointment && (
        <CancelSchedule
          data={appointment}
          type={tasySchedulingTypeCode}
          onClose={() => setOpenCancelModal(false)}
          open={openCancelModal}
          onCancelled={handleCancelled}
          reschedule
        />
      )}
      <Button
        startIcon={<TodayRoundedIcon />}
        onClick={() => setOpenCancelModal(true)}
      >
        {t("page.schedules.scheduleDetails.reschedule")}
      </Button>
    </>
  );
};

export default memo(RescheduleButton);
