import { FormHelperText } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

interface IFormErrorMessageProps {
  message: string;
}

const FormErrorMessage: React.FC<IFormErrorMessageProps> = ({ message }) => {
  const { t } = useTranslation();
  return (
    <FormHelperText
      sx={(theme) => ({
        color: theme.palette.error.main,
        marginLeft: theme.spacing(2),
      })}
    >
      {t(message)}
    </FormHelperText>
  );
};

export default memo(FormErrorMessage);
