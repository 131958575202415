import { useEffect, useState } from "react";
import { Divider, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";

import NavigatorCardList from "../components/UI/HomeNavigation/NavigationCardList";
import MedicGuide from "../components/UI/MedicGuide";
import NavigationCardsPage from "../components/UI/NavigationCardsPage";
import HomeBanners from "../components/Banners/HomeBanners/HomeBanners";
import { useInstalledExtensions } from "../hooks/useInstalledExtensions";
import { Extension } from "../models/intalled-extensions";
import { useAppSelector } from "../store/store";
import { tokenService } from "../services/token.service";
import NewsList from "../components/News/NewsList";
import PagesContainer from "../components/UI/PagesContainer";
import FullBanners from "../components/Banners/FullBanners/FullBanners";
import { EBannerLocale } from "../models/banners";
import { useBanners } from "../store/contexts/BannersContext/useBanners";

const WelcomePage = () => {
  const theme = useTheme();

  const { haveBanners } = useBanners();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [hasExtension] = useInstalledExtensions(Extension.MEDIC_GUIDE);

  const [cardInstalled] = useInstalledExtensions(Extension.CARDS);

  const [hasNewsExtension] = useInstalledExtensions(Extension.NEWS);

  const [hasCardPermission, setHasCardPermission] = useState(false);

  const cardPermission = useAppSelector((state) =>
    state.privilege.privileges.find((priv) => priv.domainName === "card")
  );

  const { logoInHeader } = useAppSelector((state) => state.theme);

  const hasMiniBannersAbove = haveBanners(EBannerLocale.MINI_BANNER_ABOVE);

  const hasFullBanner = haveBanners(EBannerLocale.FULL_BANNER);

  const hasMiniBannerBelow = haveBanners(EBannerLocale.MINI_BANNER_BELOW);

  useEffect(() => {
    if (tokenService.getAccessToken()) {
      if (cardInstalled && cardPermission) {
        setHasCardPermission(true);
        return;
      }
      setHasCardPermission(false);
      return;
    }
    if (!tokenService.getAccessToken() && cardInstalled) {
      setHasCardPermission(true);
      return;
    }
  }, [cardInstalled, cardPermission]);

  return (
    <>
      <HomeBanners />
      <PagesContainer
        disablePadding
        hideHeader
        height={matches ? "calc(100% - 5rem)" : "100%"}
      >
        <Grid container direction={"row"} width={"100%"} gap={2}>
          {hasFullBanner && (
            <FullBanners
              localeCode={EBannerLocale.FULL_BANNER}
              sx={[
                !logoInHeader &&
                  ((t) => ({
                    [t.breakpoints.up("md")]: {
                      paddingInline: logoInHeader ? 15 : 6,
                    },
                  })),
              ]}
            />
          )}
          <Grid
            item
            xs={12}
            xl={6}
            sx={(t) => ({
              paddingInline: 2,
              [t.breakpoints.up("md")]: {
                paddingInline: logoInHeader ? 15 : 6,
              },
            })}
          >
            <Stack gap={2}>
              {hasExtension && (
                <>
                  <MedicGuide />
                  <Divider />
                </>
              )}

              {hasMiniBannersAbove && (
                <FullBanners localeCode={EBannerLocale.MINI_BANNER_ABOVE} />
              )}

              <NavigatorCardList />

              {hasMiniBannerBelow && (
                <FullBanners localeCode={EBannerLocale.MINI_BANNER_BELOW} />
              )}

              {hasCardPermission && <NavigationCardsPage />}

              {hasNewsExtension && <NewsList />}
            </Stack>
          </Grid>
        </Grid>
      </PagesContainer>
    </>
  );
};

export default WelcomePage;
