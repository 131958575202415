import { useMediaQuery, useTheme } from "@mui/material";
import { memo } from "react";
import { Link } from "react-router-dom";

import { useAppSelector } from "../../../../store/store";
import { ILogoProps } from "./models";

const Logo = ({ alwaysShowLogo = false }: ILogoProps) => {
  const { breakpoints } = useTheme();

  const matches = useMediaQuery(breakpoints.up("md"));

  const { logo, logo_dark, mode, name, logoInHeader } = useAppSelector(
    (state) => state.theme
  );

  const headerLogo = mode === "1" ? logo : logo_dark ? logo_dark : logo;

  const showLogo = !matches || logoInHeader;

  if (!showLogo && !alwaysShowLogo) {
    return null;
  }

  return (
    <Link to={"/"}>
      <img
        src={headerLogo}
        width={"120px"}
        alt={name}
        style={{
          cursor: "pointer",
        }}
      />
    </Link>
  );
};

export default memo(Logo);
