import { Stack } from "@mui/material";
import Button from "../UI/Button";
import { useNotificationsUser } from "../../store/contexts/NotificationsUserContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/store";

const translationPath = "components.unreadNotificationsActions.";

interface IUnreadNotificationsActionsProps {
  onClose: () => void;
}

const UnreadNotificationsActions = ({
  onClose,
}: IUnreadNotificationsActionsProps) => {
  const { t } = useTranslation();

  const notificationPrivileges = useAppSelector((state) =>
    state.privilege.privileges.filter((p) => p.domainName === "notification")
  );
  const hasReadPermission = !!notificationPrivileges.find((p) =>
    p.actions.some((a) => a === "Read")
  );
  const hasUpdatePermission = !!notificationPrivileges.find((p) =>
    p.actions.some((a) => a === "Update")
  );

  const navigate = useNavigate();

  const {
    unreadNotifications,
    markAllUnreadNotificationAsReadHandler,
    markAllNotificationAsReadHandler,
  } = useNotificationsUser();

  const viewAllHandler = () => {
    navigate("/notifications");
    onClose();
  };

  const markAllAsReadHandler = () => {
    markAllNotificationAsReadHandler();
    markAllUnreadNotificationAsReadHandler();
  };

  return (
    <Stack
      mt={0.5}
      width={"100%"}
      flexDirection={"row"}
      justifyContent={"space-between"}
    >
      {hasReadPermission && (
        <Button
          size="small"
          fullWidth={unreadNotifications.length === 0 || !hasUpdatePermission}
          sx={{ minWidth: 100, textTransform: "initial" }}
          onClick={viewAllHandler}
        >
          {t(translationPath + "view_all")}
        </Button>
      )}
      {unreadNotifications.length !== 0 && hasUpdatePermission && (
        <Button
          size="small"
          fullWidth
          sx={{ textTransform: "initial" }}
          onClick={markAllAsReadHandler}
        >
          {t(translationPath + "mark_all_as_read")}
        </Button>
      )}
    </Stack>
  );
};

export default UnreadNotificationsActions;
