import { Box, Stack, Typography } from "@mui/material";
import {
  DateCalendar,
  DayCalendarSkeleton,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import { Day } from "./Day";
import useDate from "../../../../../hooks/useDate";

interface IScheduleCalendarProps {
  datesLoading: boolean;
  date?: Dayjs | null;
  onDateChange: (d: Dayjs) => void;
  onMonthChange: (month: Dayjs) => void;
  dates: Dayjs[];
  defaultValue?: Dayjs;
}

const ScheduleCalendar: React.FC<IScheduleCalendarProps> = ({
  datesLoading,
  date,
  onDateChange,
  onMonthChange,
  dates,
  defaultValue,
}) => {
  const { t } = useTranslation();

  const { dayjs } = useDate();

  const adapterLocale = dayjs.locale();

  return (
    <Stack>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={adapterLocale}
      >
        <DateCalendar
          disablePast
          referenceDate={defaultValue}
          loading={datesLoading}
          value={date}
          onChange={onDateChange}
          dayOfWeekFormatter={(_, date: Dayjs) =>
            date.format("dddd").charAt(0).toUpperCase()
          }
          onMonthChange={onMonthChange}
          renderLoading={() => <DayCalendarSkeleton />}
          slots={{
            day: Day,
          }}
          slotProps={{
            day: {
              highlightedDays: dates,
            } as any,
          }}
        />
      </LocalizationProvider>
      <Stack alignItems={"center"} flexDirection={"row"} gap={2}>
        <Box
          sx={(t) => ({
            border: `1px solid ${t.palette.primary.main}`,
            borderRadius: 10,
            height: 10,
            width: 10,
          })}
        />
        <Typography>
          {t("page.schedules.newSchedule.schedule.freeDays")}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default memo(ScheduleCalendar);
