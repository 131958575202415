import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { IInformationProps } from "./models";
import { memo } from "react";

const Information = ({ title, iconSize, iconColor }: IInformationProps) => {
  return (
    <Tooltip title={title}>
      <InfoIcon fontSize={iconSize} color={iconColor} />
    </Tooltip>
  );
};

export default memo(Information);
