import { memo } from "react";
import NewsCardText from "./NewsCardText";

interface INewsCardContentProps {
  title: string;
  summary: string;
}

const NewsCardContent = ({ title, summary }: INewsCardContentProps) => {
  return (
    <>
      <NewsCardText fontWeight="bold" placement="top">
        {title}
      </NewsCardText>
      <NewsCardText>{summary}</NewsCardText>
    </>
  );
};

export default memo(NewsCardContent);
