import { Box, CircularProgress } from "@mui/material";

const Loading = () => {
    return <Box
      sx={{
        height: "100%",
        width: "100%",
        zIndex: 100,
        position:'absolute',
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        top:0,
        left:0,
      }}
    >
      <CircularProgress size={100}/>
    </Box>
};

export default Loading;
