import {
  CircularProgress,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const LoadingMedics = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Stack
      mt={5}
      width={matches ? "50%" : "100%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <CircularProgress size={35} />
    </Stack>
  );
};

export default LoadingMedics;
