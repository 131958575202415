import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";

export const Day = (
  props: PickersDayProps<Dayjs> & { highlightedDays?: Dayjs[] }
) => {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isHighlighted =
    highlightedDays.some((d) => d.isSame(day, "d")) && !outsideCurrentMonth;

  return (
    <PickersDay
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      sx={(t) => ({
        border: isHighlighted
          ? `1px solid ${t.palette.primary.main}`
          : undefined,
      })}
      disabled={!isHighlighted}
    />
  );
};
