import { IconButton } from "@mui/material";

import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const ResultActions = ({
  phone,
  address,
}: {
  phone: string | null;
  address: string;
}) => {
  const coords = JSON.parse(localStorage.getItem("coords") || "");

  const openAddress = () => {
    if (coords) {
      const addressEnconded = encodeURIComponent(address);
      window.open(
        "https://www.google.com/maps/dir/" +
          coords.lat +
          "," +
          coords.lng +
          "/" +
          addressEnconded
      );
    }
  };

  return (
    <>
      {phone && (
        <IconButton
          size="small"
          sx={(theme) => ({
            border: `1px solid ${theme.palette.primary.main}`,
          })}
          onClick={() => window.open(`tel:0${phone.substring(2)}`)}
        >
          <PhoneIcon color="primary" />
        </IconButton>
      )}
      <IconButton
        size="small"
        sx={(theme) => ({
          backgroundColor:
            theme.palette.mode === "light"
              ? theme.palette.primary.light
              : theme.palette.primary.dark,
          color: theme.palette.getContrastText(theme.palette.primary.main),
          border: "1px solid transparent",
          "&:hover": {
            backgroundColor:
              theme.palette.mode === "light"
                ? theme.palette.primary.dark
                : theme.palette.primary.main,
          },
        })}
        onClick={openAddress}
      >
        <LocationOnIcon />
      </IconButton>
    </>
  );
};

export default ResultActions;
