import { useCallback, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useFetch from "../../../hooks/useFetch";
import { ScheduleInsuranceService } from "./insurance.service";
import InsuranceTable from "./InsuranceTable/InsuranceTable";
import { IInsuranceFavorite } from "./model";
import PagesContainer from "../../../components/UI/PagesContainer";

const Insurance = () => {
  const [insurances, setInsurances] = useState<IInsuranceFavorite[]>([]);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { sendRequest, loading } = useFetch(ScheduleInsuranceService.list);

  const fetch = useCallback(async () => {
    const { data, success } = await sendRequest(undefined);

    if (data && success) {
      setInsurances(data);
    }
  }, [sendRequest]);

  const handleDelete = useCallback((id: string) => {
    setInsurances((data) => data.filter((i) => i.code !== id));
  }, []);

  const handleClick = () => {
    navigate("add");
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <PagesContainer
      title={t("page.schedules.insurance.title")}
      subtitle={t("page.schedules.insurance.subtitle")!}
      rowActions={
        <Button variant="contained" onClick={handleClick}>
          {t("page.schedules.insurance.add")}
        </Button>
      }
    >
      <InsuranceTable
        insurance={insurances}
        onDelete={handleDelete}
        loading={loading}
      />
    </PagesContainer>
  );
};

export default Insurance;
