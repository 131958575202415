import dayjs from "dayjs";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import Button from "../../../../components/UI/Button";
import { ITasySchedConfirmationRule } from "../../model";
import { useConfirmation } from "./useConfirmation";

interface IConfirmationProps {
  rule?: ITasySchedConfirmationRule;
  date: string;
  identifierCalendar: number;
  identifier: number;
  type: string;
  onConfirmed: () => void;
}

export const Confirmation: React.FC<IConfirmationProps> = memo(
  ({ date, rule, onConfirmed, ...other }) => {
    const { t } = useTranslation();

    const { confirm, loading } = useConfirmation({
      ...other,
      date,
      onConfirmed,
    });

    const momentDate = useMemo(() => dayjs(date), [date]);

    const [now, setNow] = useState(new Date());

    const isAfter = useMemo(
      () => dayjs(now).isAfter(momentDate),
      [now, momentDate]
    );

    useEffect(() => {
      if (!rule || isAfter) {
        return;
      }

      // Set up the timer
      const timer = setInterval(() => {
        setNow(new Date());
      }, 1000);

      // Clean up the timer
      return () => clearInterval(timer);
    }, [isAfter, rule]);

    const diferenceInMinutes = useMemo(
      () => momentDate.diff(now, "minutes"),
      [now, momentDate]
    );

    if (!isAfter && rule) {
      return (
        <Button
          disabled={diferenceInMinutes > rule.time}
          loading={loading}
          onClick={confirm}
          variant="contained"
          startIcon={<CheckRoundedIcon />}
        >
          {t("page.schedules.scheduleDetails.confirm.button")}
        </Button>
      );
    }

    return null;
  }
);
