import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback } from "react";
import useFetch from "../../../hooks/useFetch";
import { authService } from "../../../services/auth.service";
import { notificationSliceActions } from "../../../store/slices/notification";
import { useDispatch } from "react-redux";
import FormInput from "../../UI/Inputs/FormInput";
import {
  TForgetPasswordForm,
  forgetPasswordFormSchema,
} from "./ForgetPasswordSchema";
import { useNavigate } from "react-router-dom";

const ForgetPasswordForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const methods = useForm<TForgetPasswordForm>({
    resolver: zodResolver(forgetPasswordFormSchema),
  });

  const { handleSubmit } = methods;

  const { sendRequest: sendEmail, loading } = useFetch(authService.sendEmail);

  const onSubmitHandler = useCallback(
    async (user: TForgetPasswordForm) => {
      const { success } = await sendEmail({
        userName: user.userName.replaceAll(".", "").replaceAll("-", ""),
        softwareCode: "3",
      });
      if (success) {
        dispatch(
          notificationSliceActions.showNotification({
            message: `${t(
              "components.forgetPasswordForm.notification_email_sent"
            )}`,
            type: "success",
          })
        );
        navigate(`/reset-password/${user.userName}`);
      }
    },
    [sendEmail, dispatch, t, navigate]
  );

  return (
    <>
      <FormProvider {...methods}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitHandler)}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography variant="h6" align="center" mb={3}>
            {t("components.forgetPasswordForm.title")}
          </Typography>
          <Typography variant="subtitle2" align="center" mb={3}>
            {t("components.forgetPasswordForm.subtitle")}
          </Typography>
          <FormInput
            size="small"
            autoFocus={true}
            name="userName"
            fullWidth
            label={t("components.forgetPasswordForm.login")}
            sx={{ mb: 2 }}
            mask={"000.000.000-00"}
          />
          <Button variant="contained" type="submit" disabled={loading}>
            {loading && <CircularProgress size={20} />}
            {!loading && t("components.forgetPasswordForm.button")}
          </Button>
        </Box>
      </FormProvider>
    </>
  );
};

export default ForgetPasswordForm;
