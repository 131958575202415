import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";

import useFetch from "../../hooks/useFetch";
import AuthorizationService from "../../services/authorizations.service";
import { IAuthorization } from "../../models/authorizations";
import AuthorizationBasicData from "./AuthorizationBasicData";
import { TabPanel } from "../../components/TabPanel";
import Procedures from "./Procedures";
import useRole from "../../hooks/useRole";
import PaperComponent from "../../components/UI/PaperComponent";
import PagesContainer from "../../components/UI/PagesContainer";

const service = new AuthorizationService();

const translationPath = "page.authorization.";

const Authorization = () => {
  const { t } = useTranslation();
  const params = useParams();
  const [auth, setAuth] = useState<IAuthorization>();
  const [value, setValue] = useState(0);
  const readPermission = useRole(["Read"]);

  const { sendRequest, loading } = useFetch(service.getAuthorizationsBus);

  const loadAuthorizations = useCallback(async () => {
    const { data, success } = await sendRequest({
      StartDate: params.initialDate?.replace("-", "/") || "",
      EndDate: params.endDate?.replace("-", "/") || "",
    });
    if (success) {
      data.filter((d) =>
        d.requestAuthorizations?.map((auth) => {
          if (auth.authorizationIntegrationId === Number(params.id)) {
            setAuth(auth);
          }
          return auth;
        })
      );
    }
  }, [sendRequest, params.initialDate, params.endDate, params.id]);

  useEffect(() => {
    if (readPermission) {
      loadAuthorizations();
    }
  }, [loadAuthorizations, readPermission]);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <PagesContainer
      height={"calc(100vh - 7rem)"}
      loading={loading}
      title={t(translationPath + "authorization")}
    >
      <Stack direction={"column"} width={"100%"}>
        {auth && <AuthorizationBasicData auth={auth} />}
        {auth?.requestAuthorizationItens && (
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              minWidth: 300,
              maxWidth: 800,
              mt: 1,
            }}
          >
            <Tabs value={value} onChange={handleChange}>
              <Tab
                label={t(translationPath + "procedures")}
                {...a11yProps(0)}
              />
              <Tab label={t(translationPath + "history")} {...a11yProps(1)} />
            </Tabs>
            <TabPanel index={0} value={value} key={0}>
              {auth?.requestAuthorizationItens.map((procedure) => (
                <Procedures
                  key={procedure.procedureIntegrationId}
                  procedure={procedure}
                />
              ))}
            </TabPanel>
            <TabPanel index={1} value={value} key={1}>
              <PaperComponent sx={{ minWidth: 300, maxWidth: 800, py: 2 }}>
                <Box
                  sx={{
                    width: "fit-content",
                    px: 2,
                    py: 0.5,
                    borderTopRightRadius: 20,
                    borderBottomRightRadius: 20,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={(theme) => ({
                      color:
                        theme.palette.mode === "light"
                          ? theme.palette.grey[600]
                          : theme.palette.grey[400],
                    })}
                  >
                    {t(translationPath + "service_protocol")}:
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontWeight={"bold"}
                    fontSize={18}
                  >
                    {auth?.serviceProtocolNumber}
                  </Typography>
                </Box>
              </PaperComponent>
            </TabPanel>
          </Box>
        )}
      </Stack>
    </PagesContainer>
  );
};

export default Authorization;
