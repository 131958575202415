import { useContext } from "react";

import { EBannerLocale } from "../../../models/banners";
import { BannersContext } from "./BannersContext";
import { LocalBannersService } from "../../../components/Banners/HomeBanners/LocalBanners.service";

interface IUseBannersProps {
  localeCode?: EBannerLocale;
}

export const useBanners = (props?: IUseBannersProps) => {
  const context = useContext(BannersContext);

  if (context === undefined) {
    throw new Error("useBanners must be used with BannersContext");
  }

  let filteredBanners = context.banners;

  if (props) {
    const { localeCode } = props;

    if (localeCode) {
      filteredBanners = filteredBanners.filter((banner) => {
        const isBannerLocal =
          banner.bannerLocals.findIndex((l) => l.code === localeCode) !== -1;

        if (isBannerLocal) {
          if (localeCode === EBannerLocale.OPEN_SITE_APP) {
            const localBanners = LocalBannersService.get();

            const bannerLocal = localBanners.find(
              (local) => local.id === banner.id
            );

            if (bannerLocal) {
              return bannerLocal.date !== banner.date;
            }
          }

          return true;
        }

        return false;
      });
    }
  }

  return { ...context, banners: filteredBanners };
};
