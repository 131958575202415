import { Box, CircularProgress, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ExtensionService from "../services/extension.service";
import useFetch from "../hooks/useFetch";

const extensionService = new ExtensionService();

const translationPath = "page.whatsapp.";

const WhatsApp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { sendRequest, loading } = useFetch(
    extensionService.getExtensionConfig
  );

  const redirectUserHandler = useCallback(async () => {
    const { data, success } = await sendRequest({
      Identifier: "WhatsAppNumber",
      ExtensionCode: "19",
    });
    if (data && success) {
      window.open(`https://wa.me/${data[0].value}`);
      navigate(-1);
    }
  }, [navigate, sendRequest]);

  useEffect(() => {
    redirectUserHandler();
  }, [redirectUserHandler]);

  return (
    <>
      {loading && (
        <Box
          height={"100%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
        >
          <CircularProgress size={60} />
          <Typography variant="h6">
            {t(translationPath + "redirect")}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default WhatsApp;
