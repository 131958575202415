import {
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import { useTranslation } from "react-i18next";
import { TMedicGuideFilterForm } from "../Results/Filters/MedicGuideFilterSchema";
import AutoCompleteVoice from "../../AutoCompleteVoice/AutoCompleteVoice";
import { useState } from "react";
import ModalComponent from "../../../UI/Modal";
import LegendsModal from "../Results/Addressess/Legends/LegendsModal/LegendsModal";
import { useParams } from "react-router-dom";

const translationPath = "page.medicGuide.";

interface IMedicGuideHeaderProps {
  openModal: (value: boolean) => void;
  filterData?: TMedicGuideFilterForm;
}

const MedicGuideHeader = ({
  filterData,
  openModal,
}: IMedicGuideHeaderProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { search, "*": secondParam } = useParams();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const onCloseModal = () => setOpen(false);

  return (
    <>
      <Grid
        item
        container
        xs={12}
        spacing={!matches ? 4 : 0}
        justifyContent={"space-between"}
      >
        <Grid item sm={10}>
          <Typography variant="h5" fontWeight={700}>
            {t(translationPath + "title")}
          </Typography>
          {!filterData && (
            <Stack flexDirection={"row"} gap={1}>
              <Typography variant="subtitle1">
                {t(translationPath + "show_result")}
              </Typography>
              <Typography variant="subtitle1" fontWeight={700}>
                {secondParam
                  ? `"${search + `/${secondParam}`}"`
                  : `"${search}"`}
              </Typography>
            </Stack>
          )}
          <Stack mt={2} width={matches ? "50%" : "100%"}>
            <AutoCompleteVoice
              label="Pesquisar"
              SearchAsYouTypeParams={(v) => ({ Search: v })}
            />
          </Stack>
        </Grid>
        <Grid item sm={2}>
          <Stack width={"100%"} flexDirection={"row"} justifyContent={"end"}>
            <Tooltip title={t("components.medicGuideFilter.legends")}>
              <IconButton size="small" onClick={() => setOpen(true)}>
                <SubtitlesIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("components.medicGuideFilter.detailed_search")}>
              <IconButton size="small" onClick={() => openModal(true)}>
                <TuneIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      <ModalComponent
        open={open}
        onClose={onCloseModal}
      >
        <LegendsModal />
      </ModalComponent>
    </>
  );
};

export default MedicGuideHeader;
