import GlobalAxios from "../axios/axios-config";
import { IDocumentAcceptance } from "../models/document-acceptance";
import { authApi } from "./auth.service";

const DOC_ACCEPTANCE_ROUTE =
  process.env.REACT_APP_APP + "/api/v1/DocAcceptanceUser";

interface IDocAcceptancesIds {
  docAcceptanceIds: string[];
}

export class DocAcceptanceService {
  create = (item: IDocAcceptancesIds) => {
    return GlobalAxios.post(DOC_ACCEPTANCE_ROUTE, item);
  };

  getAll = (languageId?: any) => {
    return GlobalAxios.get<IDocumentAcceptance[]>(DOC_ACCEPTANCE_ROUTE, {
      params: languageId,
    });
  };

  list = (params?: any) => {
    return authApi.get<IDocumentAcceptance[]>(DOC_ACCEPTANCE_ROUTE + "/list", {
      params: params,
    });
  };

  getDocumentsAccepted = (languageId: string) => {
    return GlobalAxios.get<IDocumentAcceptance[]>(
      DOC_ACCEPTANCE_ROUTE + `/accepted/${languageId}`
    );
  };
}

export default DocAcceptanceService;
