import { Stack, TextField } from "@mui/material";
import Button from "../../UI/Button";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import FaqService from "../../../services/faq.service";
import useFetch from "../../../hooks/useFetch";

const faqService = new FaqService();
const translationPath = "components.avaliationInput.";

const AvaliationInput = ({
  faqQuestionId,
  onRated,
  onBack,
}: {
  faqQuestionId: string;
  onRated: (value: boolean) => void;
  onBack: () => void;
}) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState("");

  const { sendRequest, loading } = useFetch(faqService.create);

  const onComment = useCallback(async () => {
    const { success } = await sendRequest({
      comment: comment,
      faqQuestionId: faqQuestionId,
    });
    if (success) {
      onRated(true);
    }
  }, [sendRequest, onRated, comment, faqQuestionId]);

  return (
    <motion.main
      style={{
        paddingTop: 5,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
    >
      <TextField
        size="small"
        fullWidth
        multiline
        minRows={2}
        placeholder={`${t(translationPath + "placeholder")}`}
        onChange={(e) => setComment(e.target.value)}
        sx={(t) => ({
          backgroundColor:
            t.palette.mode === "light"
              ? t.palette.background.default
              : t.palette.grey[900],
          borderRadius: 2,
        })}
      />
      <Stack mt={1} flexDirection={"row"} gap={1}>
        <Button size="small" variant="outlined" onClick={onBack}>
          {t(translationPath + "back")}
        </Button>
        <Button
          disabled={comment.length === 0 || loading}
          size="small"
          variant="contained"
          onClick={onComment}
          loading={loading}
        >
          {!loading && t(translationPath + "button")}
          {loading && t(translationPath + "sending")}
        </Button>
      </Stack>
    </motion.main>
  );
};

export default AvaliationInput;
