import dayjs from "dayjs";
import { IPeriodOption } from "./models";
import { useTranslation } from "react-i18next";
import { useCallback, useLayoutEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material";

interface IUsePeriod {
  defaultValue?: string;
  years: number;
  monthsPerPeriod: number;
}

export const usePeriod = ({
  defaultValue,
  years,
  monthsPerPeriod,
}: IUsePeriod) => {
  const {
    i18n: { language },
  } = useTranslation();

  const locale = language;

  const [options, setOptions] = useState<IPeriodOption[]>([]);

  const [value, setValue] = useState<string>("");

  const periodOptions = useCallback(() => {
    const optionsList: IPeriodOption[] = [];

    const totalMonths = years * 12;

    let endDate = dayjs(new Date());

    for (let i = 0; i < totalMonths; i += monthsPerPeriod) {
      const startDate = endDate
        .subtract(monthsPerPeriod, "months")
        .startOf("month");

      optionsList.push({
        name: `${startDate.locale(locale).format("MMMM/YYYY")} - ${endDate
          .locale(locale)
          .format("MMMM/YYYY")}`,
        startDate: startDate.toISOString().substring(0, 10),
        endDate: endDate.startOf("day").toISOString().substring(0, 10),
      });

      endDate = startDate.subtract(1, "day").endOf("month");
    }

    setOptions(optionsList);
    setValue(
      defaultValue ?? `${optionsList[0].startDate} ${optionsList[0].endDate}`
    );
  }, [locale, defaultValue, years, monthsPerPeriod]);

  useLayoutEffect(() => {
    periodOptions();
  }, [periodOptions]);

  const onChange = (e: SelectChangeEvent<string>) => {
    setValue(e.target.value);
  };

  return { value, options, onChange };
};
