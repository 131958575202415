import { Theme } from "@mui/material";
import { memo } from "react";

export function jsonToFormData<T extends object>(json: T) {
  const formData = new FormData();
  Object.keys(json).forEach((key) => {
    const value = json[key as keyof T];
    if (typeof value === "number") {
      formData.append(key, Number(value).toString());
    } else if (typeof value === "string") {
      formData.append(key, value);
    } else if ((value as any) instanceof Date) {
      formData.append(key, (value as any).toISOString());
    } else if (typeof value === "object" && value !== null) {
      formData.append(key, JSON.stringify(value));
    } else {
      throw new Error(`Unexpected type for value ${key}`);
    }
  });
  return formData;
}

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export const getInitials = (str: string) => {
  const stringArr = str.split(" ");
  return stringArr[0].charAt(0) + stringArr[1].charAt(0);
};

export const hexToRgbA = (hex: string, opacity: number) => {
  let c: string;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("").join("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]].join("");
    }
    c = "0x" + c;
    return (
      "rgba(" +
      [
        (parseInt(c) >> 16) & 255,
        (parseInt(c) >> 8) & 255,
        parseInt(c) & 255,
      ].join(",") +
      `,${opacity})`
    );
  }
  throw new Error("Bad Hex");
};

export const genericMemo: <T>(component: T) => T = memo;

export const defaultScrollTheme = (t: Theme) => ({
  "&::-webkit-scrollbar": {
    width: "0.6em",
    height: "0.6em",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    backgroundColor:
      t.palette.mode === "light" ? t.palette.grey[400] : t.palette.grey[900],
    borderRadius: 5,
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor:
      t.palette.mode === "light" ? t.palette.grey[200] : t.palette.grey[700],
    borderRadius: 5,
  },
});
