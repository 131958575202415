import { Grid, Typography } from "@mui/material";
import { memo } from "react";
import { IProcedureQuantityProps } from "./models";

export const ProcedureQuantity = memo(
  ({ label, quantity }: IProcedureQuantityProps) => {
    return (
      <Grid item xs={12} sm={6} display={"flex"} gap={1}>
        <Typography
          sx={(theme) => ({
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[600]
                : theme.palette.grey[400],
          })}
          variant="subtitle1"
        >
          {label}:
        </Typography>
        <Typography variant="subtitle1">
          {quantity === 0 || quantity === 0.0 ? 0 : quantity.toFixed(2)}
        </Typography>
      </Grid>
    );
  }
);
