import { SxProps, TextField, Theme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ChangeEvent, memo, useEffect, useState } from "react";
import useDebounce from "../../../hooks/useDebounce";

interface ISearchInput {
  onSearch: (term: string) => void;
  placeholder?: string;
  focus?: boolean;
  label?: string;
  sx?: SxProps<Theme>;
}

const SearchInput: React.FC<ISearchInput> = ({
  onSearch,
  placeholder,
  label,
  focus = true,
  sx,
}) => {
  const [value, setValue] = useState<string>("");

  const searchQuery = useDebounce(value, 500);

  useEffect(() => {
    onSearch(searchQuery);
  }, [searchQuery, onSearch]);

  const onChangeHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(event.target.value);
  };

  return (
    <TextField
      fullWidth
      sx={sx}
      onChange={onChangeHandler}
      id="input-with-icon-textfield"
      label={label ? label : "Search"}
      placeholder={placeholder}
      size="small"
      autoFocus={focus}
      InputProps={{
        endAdornment: <SearchIcon />,
      }}
      variant="outlined"
    />
  );
};

export default memo(SearchInput);
