import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import Button from "../../UI/Button";
import { TSymptomsForm, symptomsSchema } from "./SymptomsSchema";
import useFetch from "../../../hooks/useFetch";
import EmergencyCareService from "../../../services/emergencyCare.service";
import ModalComponent from "../../UI/Modal";
import QueueModal from "./QueueModal";
import LastCalls from "./LastCalls";
import FormInput from "../../UI/Inputs/FormInput";

const translationPath = "components.emergencyCareSymptoms.";
const queueService = new EmergencyCareService();

const EmergencyCareSymptoms = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const [link, setLink] = useState("");

  const { sendRequest, loading } = useFetch(queueService.serviceQueue);

  const methods = useForm<TSymptomsForm>({
    resolver: zodResolver(symptomsSchema),
  });

  const { handleSubmit } = methods;

  const onSubmitHandler = useCallback(
    async (values: TSymptomsForm) => {
      if (id) {
        const { data, success } = await sendRequest({
          beneficiaryId: id,
          symptoms: values.symptoms,
        });
        if (success) {
          setOpen(true);
          if (data.url) {
            setLink(data.url);
          }
          setMessage(data.message);
        }
      }
    },
    [sendRequest, id]
  );

  const onCloseHandler = () => setOpen(false);

  return (
    <FormProvider {...methods}>
      <ModalComponent open={open} onClose={onCloseHandler}>
        <QueueModal url={link} onClose={onCloseHandler} message={message} />
      </ModalComponent>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitHandler)}
        sx={{
          marginTop: 3,
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <LastCalls open={open} />

        <Typography variant="h6" mb={2}>
          {t(translationPath + "symptoms")}
        </Typography>

        <FormInput
          autoFocus={true}
          size="small"
          name="symptoms"
          placeholder={`${t(translationPath + "beneficiary_symptoms")}`}
          fullWidth
          multiline
          minRows={4}
          sx={{ mb: 2 }}
        />

        <Stack
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"end"}
          gap={2}
        >
          <Button
            size="small"
            variant="outlined"
            onClick={() => navigate(-1)}
            sx={{ fontSize: 13 }}
          >
            {t(translationPath + "cancel")}
          </Button>
          <Button
            size="small"
            variant="contained"
            type="submit"
            disabled={loading}
          >
            {loading && (
              <>
                <CircularProgress size={20} />
                {t(translationPath + "requesting")}
              </>
            )}
            {!loading && t(translationPath + "request_service")}
          </Button>
        </Stack>
      </Box>
    </FormProvider>
  );
};

export default EmergencyCareSymptoms;
