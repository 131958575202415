import { Box, Card, CircularProgress, Grid } from "@mui/material";

import { useCallback } from "react";
import EmergencyCareService from "../../services/emergencyCare.service";
import useFetch from "../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import SchedulingBeneficiary from "./SchedulingBeneficiary";
import { IBeneficiaryData } from "../../models/topmed";

const beneficiariesService = new EmergencyCareService();

const EmergencyCareCard = ({
  padding,
  beneficiary,
  isLoadingRegister,
  onRegister,
  onClick,
}: {
  padding?: number;
  beneficiary: IBeneficiaryData;
  isLoadingRegister: boolean;
  onClick?: () => void;
  onRegister?: (value: boolean) => void;
}) => {
  const navigate = useNavigate();

  const { sendRequest: register, loading } = useFetch(
    beneficiariesService.registerPerson
  );

  const onRegisterPerson = useCallback(async () => {
    if (onRegister) {
      onRegister(true);
      const { success } = await register(beneficiary.beneficiaryId);
      if (success) {
        navigate(
          `/schedule-teleconsultation/virtual-emergency-care/${beneficiary.beneficiaryId}`
        );
        onRegister(false);
      } else {
        onRegister(false);
      }
    }
  }, [navigate, register, beneficiary.beneficiaryId, onRegister]);

  return (
    <Card
      sx={(theme) => ({
        padding: padding ? padding : 2,
        width: "100%",
        maxWidth: 450,
        maxHeight: 200,
        border: `2px solid transparent`,
        cursor: "pointer",
        position: "relative",
        backgroundColor:
          theme.palette.mode === "light" ? theme.palette.grey[100] : "#121212",
      })}
      onClick={() => {
        if (onClick) onClick();
        if (loading && isLoadingRegister) return;
        if (!loading && !isLoadingRegister) {
          onRegisterPerson();
        }
      }}
      elevation={0}
    >
      {loading && (
        <Box
          sx={{
            bottom: 2,
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Grid
        container
        sx={{ flexDirection: "row", opacity: !loading ? 1 : 0.25 }}
      >
        <SchedulingBeneficiary beneficiary={beneficiary} />
      </Grid>
    </Card>
  );
};

export default EmergencyCareCard;
