import { createSvgIcon } from "@mui/material";

export const ACBA = createSvgIcon(
  <svg viewBox="0 0 400 200" xmlns="http://www.w3.org/2000/svg">
    <path d="m293.85 157.26h-9.761v-15.042h10.037c1.917 0 3.567 0.341 4.949 1.023 1.401 0.682 2.479 1.623 3.235 2.821s1.134 2.58 1.134 4.147c0 2.138-0.765 3.853-2.295 5.143-1.53 1.272-3.963 1.908-7.299 1.908z" />
    <path d="m292.97 134.12h-8.876v-14.018h9.097c2.654 0 4.7 0.627 6.138 1.88 1.456 1.254 2.184 2.903 2.184 4.949 0 1.493-0.377 2.775-1.133 3.844-0.738 1.069-1.751 1.898-3.042 2.488-1.272 0.571-2.728 0.857-4.368 0.857z" />
    <path d="m354.97 144.79-6.957-21.428h-0.443l-6.971 21.428h14.371z" />
    <path
      d="m58.861 167.05h-39.201l54.245-157.13-59.905 61.971v121.12h372v-82.186l-28.116-38.93h-123.28l-38.931 38.93v-103.81h-64.668l55.172 160.05h-39.2l-12.81-39.455h-62.472l-12.835 39.455zm192.56-36.802h12.11c-0.387-3.281-1.272-6.193-2.654-8.737-1.364-2.544-3.134-4.691-5.309-6.442-2.175-1.77-4.673-3.115-7.493-4.037-2.802-0.922-5.843-1.382-9.124-1.382-4.977 0-9.456 1.142-13.438 3.428-3.981 2.267-7.133 5.567-9.456 9.899-2.322 4.331-3.484 9.585-3.484 15.76 0 6.156 1.143 11.4 3.429 15.732 2.304 4.332 5.438 7.641 9.401 9.926 3.981 2.286 8.497 3.429 13.548 3.429 3.631 0 6.884-0.544 9.76-1.632 2.894-1.106 5.382-2.599 7.465-4.479 2.102-1.898 3.77-4.046 5.005-6.442 1.253-2.415 2.037-4.922 2.35-7.521l-12.11-0.055c-0.277 1.511-0.775 2.857-1.493 4.037-0.701 1.179-1.586 2.184-2.655 3.014-1.069 0.811-2.295 1.428-3.677 1.852-1.364 0.424-2.848 0.636-4.452 0.636-2.857 0-5.373-0.7-7.548-2.101s-3.871-3.475-5.087-6.221c-1.199-2.747-1.798-6.139-1.798-10.175 0-3.927 0.599-7.263 1.798-10.009 1.198-2.765 2.884-4.867 5.059-6.304 2.176-1.457 4.728-2.185 7.659-2.185 1.622 0 3.125 0.231 4.507 0.691 1.401 0.461 2.636 1.125 3.705 1.991 1.069 0.867 1.945 1.917 2.627 3.152 0.682 1.217 1.133 2.608 1.355 4.175zm20.695-19.824v56.626h24.193c4.24 0 7.807-0.682 10.701-2.047 2.894-1.364 5.078-3.216 6.552-5.557 1.494-2.359 2.24-5.041 2.24-8.046 0-2.783-0.59-5.18-1.769-7.189-1.18-2.027-2.719-3.603-4.618-4.728-1.88-1.124-3.898-1.733-6.055-1.825v-0.553c1.972-0.442 3.723-1.189 5.253-2.239 1.549-1.069 2.765-2.433 3.65-4.092 0.885-1.678 1.327-3.622 1.327-5.834 0-2.839-0.7-5.346-2.101-7.521-1.401-2.194-3.493-3.908-6.277-5.143-2.783-1.235-6.258-1.852-10.423-1.852h-22.673zm48.407 56.626h12.829l4.201-12.913h20.445l4.193 12.913h12.829l-19.52-56.626h-15.429l-19.548 56.626z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
    <path d="m103.64 33.562 21.258 65.475h-43.91l21.3-65.475h1.352z" />
  </svg>,
  "ACBA"
);

export const ADICQ = createSvgIcon(
  <svg viewBox="0 0 400 200" xmlns="http://www.w3.org/2000/svg">
    <path d="m212.41 145.56h-6.648v-31.622h6.672c3.094 0 5.697 0.549 7.808 1.647s3.699 2.809 4.762 5.133c1.08 2.324 1.62 5.319 1.62 8.983 0 3.696-0.54 6.715-1.62 9.055-1.063 2.324-2.659 4.043-4.786 5.157-2.111 1.098-4.714 1.647-7.808 1.647z" />
    <path d="m342.67 137.31h-8.896l6.345 8.38c-0.86 0.176-1.767 0.264-2.719 0.264-2.53 0-4.738-0.621-6.623-1.864-1.886-1.243-3.352-3.067-4.4-5.472-1.031-2.405-1.547-5.359-1.547-8.862s0.516-6.457 1.547-8.862c1.048-2.405 2.514-4.229 4.4-5.472 1.885-1.243 4.093-1.864 6.623-1.864 2.531 0 4.738 0.621 6.624 1.864s3.344 3.067 4.375 5.472c1.048 2.405 1.572 5.359 1.572 8.862s-0.524 6.457-1.572 8.862c-0.561 1.31-1.25 2.448-2.065 3.413l-3.664-4.721z" />
    <path
      d="m52.703 154.54h-30.846l44.65-129.54h-4.8562l-47.651 49.432v100.57h371v-64.773l-34.037-35.795h-192.31v-49.432h-50.357l44.587 129.54h-30.846l-10.08-31.094h-49.157l-10.1 31.094zm142.59 0h17.55c5.028 0 9.347-0.992 12.957-2.978 3.626-1.985 6.406-4.834 8.34-8.547 1.95-3.712 2.925-8.151 2.925-13.316 0-5.15-0.975-9.572-2.925-13.269-1.934-3.696-4.698-6.529-8.292-8.498-3.593-1.986-7.88-2.978-12.86-2.978h-17.695v49.586zm59.993 0v-49.586h-10.467v49.586h10.467zm41.706-32.226h10.588c-0.338-2.873-1.112-5.424-2.321-7.651-1.192-2.228-2.739-4.108-4.641-5.642-1.902-1.549-4.085-2.728-6.551-3.535-2.45-0.807-5.109-1.21-7.977-1.21-4.352 0-8.268 1.001-11.749 3.002-3.481 1.985-6.237 4.875-8.267 8.668-2.031 3.793-3.046 8.394-3.046 13.801 0 5.391 0.999 9.984 2.997 13.777 2.015 3.793 4.754 6.691 8.219 8.692 3.481 2.002 7.43 3.002 11.846 3.002 3.174 0 6.019-0.476 8.533-1.428 2.53-0.969 4.706-2.276 6.527-3.923 1.837-1.662 3.296-3.543 4.375-5.641 1.096-2.115 1.781-4.31 2.055-6.586l-10.588-0.048c-0.242 1.323-0.677 2.502-1.305 3.535-0.613 1.033-1.386 1.913-2.321 2.639-0.935 0.71-2.007 1.251-3.215 1.622-1.193 0.371-2.49 0.557-3.892 0.557-2.498 0-4.698-0.613-6.6-1.84-1.901-1.227-3.384-3.043-4.448-5.448-1.047-2.405-1.571-5.375-1.571-8.91 0-3.438 0.524-6.36 1.571-8.765 1.048-2.421 2.522-4.261 4.424-5.52 1.902-1.275 4.134-1.913 6.696-1.913 1.418 0 2.732 0.202 3.941 0.605 1.224 0.404 2.304 0.985 3.239 1.744 0.935 0.758 1.7 1.678 2.296 2.76 0.597 1.065 0.992 2.284 1.185 3.656zm55.744 27.385c1.892-1.688 3.483-3.738 4.775-6.151 2.047-3.793 3.07-8.394 3.07-13.801s-1.023-10.008-3.07-13.801c-2.031-3.793-4.803-6.683-8.316-8.668-3.497-2.001-7.429-3.002-11.797-3.002-4.399 0-8.348 1.001-11.845 3.002-3.497 1.985-6.261 4.875-8.291 8.668-2.031 3.793-3.046 8.394-3.046 13.801 0 5.391 1.015 9.984 3.046 13.777 2.03 3.793 4.794 6.691 8.291 8.692 3.497 2.002 7.446 3.002 11.845 3.002 3.116 0 6.01-0.505 8.682-1.515l3.985 4.905h9.767l-7.096-8.909z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
    <path d="m87.937 49.343 16.728 51.602h-34.552l16.76-51.602h1.0637z" />
  </svg>,
  "ADICQ"
);

export const AIQG = createSvgIcon(
  <svg viewBox="0 0 400 200" xmlns="http://www.w3.org/2000/svg">
    <path d="m284.95 143.84h-11.47l8.181 10.838c-1.11 0.228-2.278 0.342-3.506 0.342-3.262 0-6.108-0.804-8.539-2.412-2.431-1.607-4.322-3.966-5.672-7.077-1.33-3.11-1.995-6.931-1.995-11.461s0.665-8.35 1.995-11.461c1.35-3.11 3.241-5.469 5.672-7.077 2.431-1.607 5.277-2.411 8.539-2.411 3.263 0 6.109 0.804 8.54 2.411 2.431 1.608 4.311 3.967 5.641 7.077 1.351 3.111 2.026 6.931 2.026 11.461s-0.675 8.351-2.026 11.461c-0.724 1.695-1.612 3.166-2.663 4.415l-4.723-6.106z" />
    <path
      d="m13 69.136v127.86h373v-88.182l-30.718-39.682h-118.48l-43.882 46.296v-112.43h-56.829l55.972 163.14h-39.769l-12.995-40.215h-63.377l-13.021 40.215h-39.768l56.051-163.14h-6.9402l-59.241 66.136zm225.29 97v-64.132h-13.495v64.132h13.495zm59.644-6.261c2.439-2.183 4.491-4.835 6.156-7.955 2.639-4.906 3.958-10.856 3.958-17.85 0-6.993-1.319-12.943-3.958-17.849-2.618-4.906-6.192-8.643-10.721-11.211-4.509-2.588-9.579-3.883-15.21-3.883-5.672 0-10.762 1.295-15.271 3.883-4.509 2.568-8.072 6.305-10.69 11.211s-3.927 10.856-3.927 17.849c0 6.973 1.309 12.912 3.927 17.818s6.181 8.653 10.69 11.242 9.599 3.883 15.271 3.883c4.017 0 7.748-0.653 11.193-1.96l5.139 6.344h12.591l-9.148-11.522zm60.596-41.18c0.789 1.169 1.402 2.515 1.839 4.039h13.713c-0.436-3.173-1.423-6.075-2.961-8.705-1.537-2.651-3.522-4.937-5.953-6.858-2.41-1.921-5.173-3.403-8.29-4.447-3.117-1.064-6.483-1.597-10.098-1.597-4.218 0-8.134 0.741-11.75 2.224-3.615 1.482-6.784 3.643-9.505 6.482-2.722 2.839-4.842 6.304-6.358 10.396-1.496 4.071-2.244 8.706-2.244 13.904 0 6.764 1.257 12.609 3.771 17.536 2.535 4.906 6.067 8.695 10.596 11.367 4.53 2.652 9.787 3.977 15.771 3.977 5.36 0 10.118-1.085 14.274-3.257 4.155-2.192 7.417-5.302 9.786-9.331 2.369-4.05 3.553-8.894 3.553-14.53v-8.079h-26.71v10.208h13.709c-0.055 2.564-0.635 4.797-1.741 6.701-1.142 2.005-2.805 3.56-4.986 4.666-2.161 1.086-4.769 1.629-7.823 1.629-3.366 0-6.285-0.814-8.758-2.443-2.472-1.649-4.384-4.039-5.734-7.171-1.351-3.131-2.026-6.931-2.026-11.398 0-4.468 0.685-8.247 2.057-11.336 1.371-3.111 3.283-5.48 5.734-7.109 2.473-1.628 5.34-2.442 8.602-2.442 1.746 0 3.335 0.219 4.769 0.657 1.454 0.439 2.742 1.076 3.864 1.911 1.143 0.814 2.109 1.816 2.899 3.006z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
    <path d="m129.89 96.814-21.567-66.739h-1.371l-21.609 66.739h44.547z" />
  </svg>,
  "AIQG"
);

export const AONA = createSvgIcon(
  <svg viewBox="0 0 400 200" xmlns="http://www.w3.org/2000/svg">
    <path d="m246.91 135.09c0-4.021-0.604-7.413-1.813-10.174-1.191-2.761-2.874-4.855-5.05-6.282s-4.724-2.141-7.644-2.141c-2.921 0-5.469 0.714-7.645 2.141s-3.869 3.521-5.078 6.282c-1.19 2.761-1.785 6.153-1.785 10.174s0.595 7.413 1.785 10.174c1.209 2.761 2.902 4.855 5.078 6.282s4.724 2.141 7.645 2.141c2.92 0 5.468-0.714 7.644-2.141s3.859-3.521 5.05-6.282c1.209-2.761 1.813-6.153 1.813-10.174z" />
    <path d="m350.51 119.64 7.02 21.544h-14.501l7.034-21.544h0.447z" />
    <path
      d="m14 70.058v123.94h371v-84.802l-30.553-39.14h-120.03l-39.283 43.489v-106.55h-57.201l54.166 156.56h-39.555l-12.926-39.666h-63.036l-12.951 39.666h-39.555l54.243-156.56h-3.2138l-61.106 63.058zm245.16 65.035c0 6.208-1.181 11.49-3.543 15.845-2.344 4.355-5.543 7.681-9.598 9.979-4.036 2.28-8.574 3.419-13.614 3.419-5.078 0-9.635-1.148-13.671-3.446s-7.226-5.625-9.57-9.98c-2.343-4.355-3.515-9.627-3.515-15.817 0-6.208 1.172-11.49 3.515-15.845 2.344-4.355 5.534-7.672 9.57-9.951 4.036-2.298 8.593-3.447 13.671-3.447 5.04 0 9.578 1.149 13.614 3.447 4.055 2.279 7.254 5.596 9.598 9.951 2.362 4.355 3.543 9.637 3.543 15.845zm56.741-28.465v56.93h-10.435l-24.858-35.831h-0.419v35.831h-12.08v-56.93h10.602l24.663 35.804h0.502v-35.804h12.025zm19.829 56.93h-12.945l19.725-56.93h15.567l19.697 56.93h-12.945l-4.23-12.982h-20.63l-4.239 12.982z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
    <path d="m108.81 29.356 21.451 65.827h-44.308l21.493-65.827h1.364z" />
  </svg>,
  "AONA"
);

export const APALC = createSvgIcon(
  <svg viewBox="0 0 400 200" xmlns="http://www.w3.org/2000/svg">
    <path d="m213.87 130.07h-7.107v-16.537h7.059c2.03 0 3.706 0.347 5.028 1.041 1.321 0.678 2.304 1.638 2.949 2.881 0.661 1.227 0.991 2.664 0.991 4.31 0 1.63-0.33 3.075-0.991 4.334-0.645 1.243-1.628 2.219-2.949 2.93-1.305 0.694-2.965 1.041-4.98 1.041z" />
    <path d="m256 116.29 6.083 18.765h-12.564l6.094-18.765h0.387z" />
    <path
      d="m15 76.136v98.864h371v-64.773l-30.633-34.091h-153.16l-42.546 39.205v-90.341h-50.357l44.587 129.54h-30.846l-10.08-31.094h-49.157l-10.1 31.094h-30.846l44.65-129.54h-6.5581l-45.95 51.136zm181.29 78.409v-49.586h19.532c3.755 0 6.954 0.718 9.597 2.155 2.643 1.42 4.658 3.397 6.044 5.932 1.402 2.518 2.103 5.423 2.103 8.716s-0.709 6.198-2.128 8.716c-1.418 2.519-3.473 4.48-6.164 5.884-2.675 1.404-5.914 2.106-9.718 2.106h-8.799v16.077h-10.467zm46.891 0h-11.217l17.091-49.586h13.489l17.067 49.586h-11.217l-3.665-11.307h-17.876l-3.672 11.307zm42.389 0v-49.586h10.467v40.943h21.224v8.643h-31.691zm80.377-32.226h-10.588c-0.193-1.372-0.588-2.591-1.184-3.656-0.596-1.082-1.362-2.002-2.297-2.76-0.934-0.759-2.014-1.34-3.239-1.744-1.209-0.403-2.522-0.605-3.94-0.605-2.563 0-4.795 0.638-6.696 1.913-1.902 1.259-3.377 3.099-4.424 5.52-1.048 2.405-1.572 5.327-1.572 8.765 0 3.535 0.524 6.505 1.572 8.91 1.063 2.405 2.546 4.221 4.448 5.448 1.901 1.227 4.101 1.84 6.599 1.84 1.402 0 2.7-0.186 3.892-0.557 1.209-0.371 2.281-0.912 3.215-1.622 0.935-0.726 1.709-1.606 2.321-2.639 0.628-1.033 1.064-2.212 1.305-3.535l10.588 0.048c-0.274 2.276-0.958 4.471-2.054 6.586-1.08 2.098-2.539 3.979-4.376 5.641-1.821 1.647-3.996 2.954-6.527 3.923-2.514 0.952-5.358 1.428-8.533 1.428-4.416 0-8.364-1-11.845-3.002-3.465-2.001-6.205-4.899-8.219-8.692-1.999-3.793-2.998-8.386-2.998-13.777 0-5.407 1.015-10.008 3.046-13.801s4.787-6.683 8.268-8.668c3.481-2.001 7.397-3.002 11.748-3.002 2.869 0 5.528 0.403 7.977 1.21 2.466 0.807 4.65 1.986 6.551 3.535 1.902 1.534 3.449 3.414 4.642 5.642 1.208 2.227 1.982 4.778 2.32 7.651z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
    <path d="m88.937 49.343 16.728 51.602h-34.552l16.76-51.602h1.0637z" />
  </svg>,
  "APALC"
);

export const E = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" fill="none">
    <path
      d="M86.1944 32.2326V70.0071H154.336V97.7208H86.1944V135.682H160.17V163.395H51.8095V21.4064L8 67.2093V194H192V6H161.333V32.2326L86.1944 32.2326Z"
      fill="currentColor"
    />
  </svg>,
  "E"
);

export const N = createSvgIcon(
  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
    <path d="m146.07 4h-57.02l-10.269 5.655 65.75 95.917h1.54v-101.57z" />
    <path d="m36.374 37-28.374 28.409v131.59h184v-193h-9.053v166.68h-32.001l-76.239-110.43h-1.2834v110.43h-37.05v-133.68z" />
  </svg>,
  "N"
);

export const P = createSvgIcon(
  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
    <path d="m145.94 3.0204 0.065-0.02036h46v194h-184v-127.86l32.754-32.965v134.37h37.05v-57.18h31.146c13.462 0 24.928-2.497 34.397-7.492 9.527-4.995 16.8-11.97 21.82-20.926 5.02-8.956 7.529-19.29 7.529-31.001 0-11.712-2.481-22.046-7.444-31.002-4.536-8.335-10.975-14.976-19.317-19.924z" />
    <path d="m102.96 83.483h-25.157v-58.817h24.985c7.188 0 13.12 1.2344 17.798 3.703 4.678 2.4112 8.157 5.8271 10.439 10.248 2.339 4.3631 3.508 9.4726 3.508 15.328 0 5.7984-1.169 10.937-3.508 15.415-2.282 4.4205-5.761 7.8938-10.439 10.42-4.62 2.4686-10.496 3.7029-17.626 3.7029z" />
  </svg>,
  "P"
);

export const Q = createSvgIcon(
  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
    <path d="m124.93 100.49h-30.057l21.438 28.135c-2.908 0.591-5.97 0.886-9.187 0.886-8.5483 0-16.008-2.086-22.379-6.259-6.3708-4.173-11.326-10.297-14.865-18.372-3.4848-8.0749-5.2273-17.992-5.2273-29.753s1.7425-21.678 5.2273-29.752c3.5119-8.0124 8.4177-14.104 14.718-18.275l16.705-6.014c1.881-0.2284 3.821-0.3426 5.821-0.3426 8.549 0 16.009 2.0865 22.38 6.2594 6.37 4.173 11.298 10.297 14.783 18.372 3.539 8.0749 5.309 17.992 5.309 29.752s-1.77 21.678-5.309 29.753c-1.898 4.399-4.225 8.218-6.979 11.459l-12.378-15.849z" />
    <path d="m136.46 155.56 13.466 16.468h32.997l-23.973-29.911c6.391-5.667 11.768-12.551 16.132-20.652 6.915-12.735 10.373-28.181 10.373-46.336s-3.458-33.6-10.373-46.336c-5.219-9.688-11.888-17.62-20.005-23.796h36.925v190h-184v-121.33l23.236-23.126c-1.6253 7.5524-2.4379 15.747-2.4379 24.583 0 18.101 3.4304 33.519 10.291 46.255s16.199 22.463 28.015 29.184c11.816 6.72 25.156 10.08 40.021 10.08 10.526 0 20.304-1.696 29.333-5.088z" />
  </svg>,
  "Q"
);

export const R = createSvgIcon(
  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
    <path d="m42.126 36.454v120.06h32.151v-53.961h23.469l28.804 53.961h35.492l-32.264-59.137c0.464-0.2014 0.923-0.4087 1.376-0.6219 8.266-3.8649 14.578-9.4146 18.934-16.649 4.356-7.284 6.534-15.98 6.534-26.088 0-10.059-2.153-18.804-6.46-26.237-4.257-7.4822-10.469-13.255-18.637-17.318-0.309-0.1566-0.621-0.3102-0.935-0.4608h61.411v180h-184v-121.4l34.126-32.151z" />
    <path d="m74.276 76.685h21.83c6.1377 0 11.237-0.8424 15.296-2.5271s7.079-4.2118 9.059-7.5813c2.029-3.3694 3.044-7.5565 3.044-12.561 0-5.0542-1.015-9.3155-3.044-12.784-1.98-3.4685-5.025-6.0947-9.133-7.8785-4.059-1.8334-9.183-2.7501-15.37-2.7501h-21.681v46.082z" />
  </svg>,
  "R"
);
