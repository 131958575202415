import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Button from "./UI/Button";
import { useNavigate } from "react-router-dom";
import { useTemporaryAccess } from "../store/contexts/TemporaryAccessContext";

import LoginIcon from "@mui/icons-material/Login";
import PersonIcon from "@mui/icons-material/Person";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import { tokenTemporaryService } from "../services/tokenTemorary.service";
import { useAppSelector } from "../store/store";

const translationPath = "components.accessTemporaryMessage.";

const AccessTemporaryMessage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { hasUser } = useTemporaryAccess();

  const isTemporaryAccess = !!tokenTemporaryService.getTemporaryAccessToken();

  const theme = useTheme();

  const logoInHeader = useAppSelector((s) => s.theme.logoInHeader);

  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const clickHandler = () => {
    if (hasUser) {
      navigate("/login");
    } else {
      navigate("/register");
    }
  };

  if (!isTemporaryAccess) {
    return null;
  }

  return (
    <Box
      sx={(t) => ({
        paddingInline: 2,
        mt: 1,
        [t.breakpoints.up("md")]: {
          paddingInline: logoInHeader ? 15 : 6,
        },
      })}
    >
      <Box
        sx={(t) => ({
          pb: 0.5,
          px: 2,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          border: 1,
          borderColor:
            t.palette.mode === "light"
              ? t.palette.primary.light
              : t.palette.primary.dark,
          color:
            t.palette.mode === "light"
              ? t.palette.primary.light
              : t.palette.primary.dark,
          borderRadius: 1,
        })}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          flexWrap={"wrap"}
          gap={!matches ? undefined : 2}
          columnGap={!matches ? 1 : undefined}
        >
          <Typography fontWeight={"bold"}>
            {t(translationPath + "access_temporary")}
          </Typography>
          {!hasUser && (
            <Typography variant="subtitle2" lineHeight={1.2}>
              {t(translationPath + "register")}
            </Typography>
          )}
          {hasUser && (
            <Typography variant="subtitle2" lineHeight={1.2}>
              {t(translationPath + "login")}
            </Typography>
          )}
        </Box>
        <Button
          size="small"
          startIcon={hasUser ? <LoginIcon /> : <PersonIcon />}
          onClick={clickHandler}
        >
          {hasUser
            ? t(translationPath + "login_btn")
            : t(translationPath + "register_btn")}
        </Button>
      </Box>
    </Box>
  );
};

export default memo(AccessTemporaryMessage);
