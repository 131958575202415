import GlobalAxios from "../axios/axios-config";
import { TMedicSharedForm } from "../components/MedicCard/MedicCardSharedSchema";
import {
  IGetBeneficiaryCard,
  IMedicCard,
  IShareCard,
  ISharedCardImage,
} from "../models/medic-card";
import { ISearchParams } from "../models/requests";
import { authApi } from "./auth.service";

const CARD_ROUTE = process.env.REACT_APP_APP + "/api/v1/Beneficiary/";

export class CardService {
  create = (item: IShareCard) => {
    return GlobalAxios.post(CARD_ROUTE + "ShareCard", item);
  };

  getAll = (serchParams?: ISearchParams) => {
    return GlobalAxios.get<IMedicCard[]>(CARD_ROUTE + "GetBeneficiaryCard", {
      params: serchParams,
    });
  };

  deleteItemById = (id: string) => {
    return GlobalAxios.delete(CARD_ROUTE + "DeleteShareCard/" + id);
  };

  getShareCard = (serchParams?: TMedicSharedForm) => {
    return authApi.get<IMedicCard>(CARD_ROUTE + "GetShareCard", {
      params: serchParams,
    });
  };

  getShareCardImage = (searchParams?: ISharedCardImage) => {
    return authApi.post(CARD_ROUTE + "ShareCardImage", searchParams);
  };

  getBeneficiaryCard = (item: IGetBeneficiaryCard) => {
    return GlobalAxios.get<string>(
      CARD_ROUTE + item.beneficiaryId + "/Card/" + item.side
    );
  };
}

export default CardService;
