import { useCallback, useState } from "react";
import { tokenService } from "../services/token.service";
import useFetch from "./useFetch";
import CardService from "../services/card.service";
import { IMedicCard, TCardSide } from "../models/medic-card";
import { TMedicSharedForm } from "../components/MedicCard/MedicCardSharedSchema";

const service = new CardService();

export interface IUseCardsProps {
  card?: IMedicCard;
  verticalView?: boolean;
  visitUserData?: TMedicSharedForm;
  side?: TCardSide;
}

const useCards = () => {
  const [cardImage, setCardImage] = useState<string>();
  const [cardBackImage, setCardBackImage] = useState<string>();
  const token = tokenService.getAccessToken();

  const { sendRequest, loading } = useFetch(service.getBeneficiaryCard);
  const { sendRequest: visitRequest, loading: vLoading } = useFetch(
    service.getShareCardImage
  );

  const loadCardImage = useCallback(
    async (cardData: IUseCardsProps) => {
      if (token && cardData.card) {
        const { data, success } = await sendRequest({
          beneficiaryId: cardData.card.id,
          side: "Front",
        });
        if (success) {
          setCardImage(`data:image/png;base64,${data}`);
        }
        const { data: dataBack, success: successBack } = await sendRequest({
          beneficiaryId: cardData.card.id,
          side: "Back",
        });
        if (successBack) {
          setCardBackImage(`data:image/png;base64,${dataBack}`);
        }
      }
      if (!token && cardData.visitUserData) {
        const { data, success } = await visitRequest({
          code: cardData.visitUserData.ShareCode,
          document: cardData.visitUserData.Document.replaceAll(
            ".",
            ""
          ).replaceAll("-", ""),
          side: "Front",
        });
        if (success) {
          setCardImage(`data:image/png;base64,${data}`);
        }
        const { data: dataBack, success: successBack } = await visitRequest({
          code: cardData.visitUserData.ShareCode,
          document: cardData.visitUserData.Document.replaceAll(
            ".",
            ""
          ).replaceAll("-", ""),
          side: "Back",
        });
        if (successBack) {
          setCardBackImage(`data:image/png;base64,${dataBack}`);
        }
      }
    },
    [sendRequest, token, visitRequest]
  );

  return { cardImage, cardBackImage, loadCardImage, loading, vLoading };
};

export default useCards;
