import { useCallback, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import { TAddHealth } from "./schema";
import useFetch from "../../../../hooks/useFetch";
import { ScheduleInsuranceService } from "../insurance.service";

export const useInsurance = (
  methods: UseFormReturn<TAddHealth>,
  onSuccess?: () => void
) => {
  const [loading, setLoading] = useState(false);

  const { sendRequest: save } = useFetch(ScheduleInsuranceService.create);

  const { sendRequest: getById, loading: getLoading } = useFetch(
    ScheduleInsuranceService.getById
  );

  const { sendRequest: edit } = useFetch(ScheduleInsuranceService.edit);

  const { id } = useParams();

  const isEditMode = !!id;

  const get = useCallback(async () => {
    if (!id) return;
    const { data, success } = await getById(id);

    if (data && success) {
      methods.reset({
        code: data.cardNumber,
        insurance: {
          id: String(data.code || data.insuranceIntegrationId),
          name: data.insuranceName,
        },
        individual: data.individual,
        validate: data.cardValidity
          ? dayjs(data.cardValidity).add(1, "day").toDate()
          : undefined,
      });
    }
  }, [getById, id, methods]);

  const fetchSave = useCallback(
    async (v: TAddHealth) => {

      const { success } = await save({
        cardNumber: v.code,
        cardValidity: v.validate ? dayjs(v.validate).format("YYYY-MM-DD"): undefined,
        insuranceIntegrationId: v.insurance.id,
        insuranceName: v.insurance.name,
        individualId: v.individual.id,
      });

      if (success) {
        onSuccess && onSuccess();
      }
    },
    [onSuccess, save]
  );

  const fetchEdit = useCallback(
    async (v: TAddHealth) => {
      if (!id) return;
      const { success } = await edit({
        id: id,
        data: {
          cardNumber: v.code,
          cardValidity: dayjs(v.validate).format("YYYY-MM-DD"),
          insuranceIntegrationId: v.insurance.id,
          insuranceName: v.insurance.name,
          individualId: v.individual.id,
        },
      });

      if (success) {
        onSuccess && onSuccess();
      }
    },
    [onSuccess, edit, id]
  );

  const onSubmit = useCallback(
    async (v: TAddHealth) => {
      setLoading(true);
      if (isEditMode) {
        await fetchEdit(v);
      } else {
        await fetchSave(v);
      }
      setLoading(false);
    },
    [fetchEdit, fetchSave, isEditMode]
  );

  useEffect(() => {
    get();
  }, [get]);

  return { isEditMode, onSubmit, loading, getLoading };
};
