import { useCallback, useMemo } from "react";

export const useMimeType = () => {
  const signatures: any = useMemo(() => {
    return {
      JVBERi0: "application/pdf",
      R0lGODdh: "image/gif",
      R0lGODlh: "image/gif",
      iVBORw0KGgo: "image/png",
      "/9j/": "image/jpg",
    };
  }, []);

  const detectMimeType = useCallback(
    (base64: string) => {
      let mimeType = null;

      for (let s in signatures) {
        if (base64.indexOf(s) === 0) {
          mimeType = String(signatures[s]);
        }
      }

      return mimeType;
    },
    [signatures]
  );

  return {
    detectMimeType,
  };
};
