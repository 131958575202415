import { ISelectType } from "../components/UI/Inputs/AutoComplete";
import { authApi } from "./auth.service";

const LANGUAGES_ROUTE = process.env.REACT_APP_APP + "/api/v1/Language";

export class LanguagesService {
  list = () => {
    return authApi.get<ISelectType[]>(LANGUAGES_ROUTE + "/list");
  };
}

export default LanguagesService;
