import { FormatMoney } from "format-money-js";

export const formatedDate = (date: Date) => {
  return (
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
  );
};

export const formatedValue = (value: number) =>
  value.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });

export const formatValue = (value: number, decimals?: number) => {
  const fm = new FormatMoney({
    decimals: decimals || 2,
  });
  return value && value !== 0
    ? fm
        .from(value, {
          symbol: "R$",
          separator: ".",
          decimalPoint: ",",
        })
        ?.toString()
    : "-";
};

export const convertBinaryToFile = (dataUrl: string, fileName: string) => {
  let arr = dataUrl.split(","),
    mime = "application/pdf",
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
};

export const PHONE_NUMBER_REGEX = /^(\d{2})(\d{2})(\d{9})\d*$/;
