import {
  ptBR,
  enUS,
  esES,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack, useMediaQuery, useTheme } from "@mui/material";

import { ITable, pageState } from "../../../models/table";
import useFetch from "../../../hooks/useFetch";
import SearchInput from "../Inputs/SearchInput";
import { NoRowsMessage } from "./NoRowsMessage";
import ColumnsSelect from "./ColumnsSelect/ColumnsSelect";
import BaseTable from "./BaseTable";

const DEFAULT_PAGE_ROWS = 20;

const Table = <T extends object>({
  columns,
  rows,
  height,
  sortModel,
  serviceProps,
  macroFilters,
  searchByPlaceholder,
  maxFiltersWidth,
  maxSearchByWidth,
  defaultHideColumns,
  pageSizeOptions,
  rowHeight,
  service,
  rowAction,
  getRowId,
  loading: userLoading,
}: ITable<T>) => {
  const {
    i18n: { language: locale },
  } = useTranslation();

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [data, setData] = useState<T[]>([]);

  const [pageInfo, setPageInfo] = useState<pageState>();

  const [searchBy, setSearchBy] = useState<string>();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: DEFAULT_PAGE_ROWS,
  });

  const [rowCountState, setRowCountState] = useState<number>(
    pageInfo?.totalRowCount || 0
  );

  const { sendRequest, loading } = useFetch(service);

  const fetchExams = useCallback(async () => {
    const { data, success } = await sendRequest({
      PageSize: paginationModel.pageSize,
      PageNumber: paginationModel.page + 1,
      SearchBy: searchBy,
      ...serviceProps,
    });
    if (data && success) {
      setData(data.items);
      setRowCountState(data.totalCount);
      setPageInfo({
        totalRowCount: data.totalCount,
      });
    }
  }, [
    sendRequest,
    paginationModel.pageSize,
    paginationModel.page,
    searchBy,
    serviceProps,
  ]);

  useEffect(() => {
    if (service) {
      fetchExams();
    }
  }, [fetchExams, service]);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageInfo?.totalRowCount !== undefined
        ? pageInfo?.totalRowCount
        : prevRowCountState
    );
  }, [pageInfo?.totalRowCount, setRowCountState]);

  const localeText = () => {
    switch (locale) {
      case "pt-BR":
        return ptBR.components.MuiDataGrid.defaultProps.localeText;
      case "en-US":
        return enUS.components.MuiDataGrid.defaultProps.localeText;
      case "es":
        return esES.components.MuiDataGrid.defaultProps.localeText;
      default:
        return ptBR.components.MuiDataGrid.defaultProps.localeText;
    }
  };

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(
      columns.reduce(
        (a, v) => ({
          ...a,
          [v.field]: defaultHideColumns?.includes(v.field) ? false : true,
        }),
        {}
      )
    );

  return (
    <>
      {service && (
        <Stack
          maxWidth={maxFiltersWidth}
          flexDirection={"row"}
          flexWrap={"wrap"}
          alignItems={"center"}
          justifyContent={matches ? undefined : "space-between"}
          gap={1}
        >
          {searchByPlaceholder && (
            <Stack width={maxSearchByWidth ?? "100%"}>
              <SearchInput
                label="Pesquisar"
                placeholder={searchByPlaceholder}
                onSearch={setSearchBy}
                focus
              />
            </Stack>
          )}
          <Stack
            display={"flex"}
            flex={1}
            flexDirection={"row"}
            alignItems={"end"}
            justifyContent={"space-between"}
          >
            {macroFilters}
            <ColumnsSelect
              columns={columns}
              columnVisibilityModel={columnVisibilityModel}
              VisibilityChanges={(v) => setColumnVisibilityModel(v)}
            />
          </Stack>
        </Stack>
      )}
      <BaseTable
        rows={rows || data}
        columns={columns || []}
        loading={loading || userLoading}
        initialState={{
          sorting: {
            sortModel: sortModel,
          },
        }}
        sx={{
          maxHeight: height ? height : "none",
        }}
        onRowClick={rowAction}
        hideFooterPagination={!service}
        hideFooter={!service}
        autoHeight={!service}
        disableRowSelectionOnClick
        rowHeight={rowHeight}
        getRowId={getRowId}
        paginationModel={paginationModel}
        rowCount={rowCountState}
        paginationMode={service && "server"}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={pageSizeOptions}
        columnVisibilityModel={columnVisibilityModel}
        localeText={localeText()}
        slots={{
          noRowsOverlay: service ? NoRowsMessage : undefined,
        }}
      />
    </>
  );
};

export default Table;
