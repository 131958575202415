import {
  CircularProgress,
  Grid,
  Pagination,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { INews, INewsPagination } from "../../models/news";
import useFetch from "../../hooks/useFetch";
import { getAllNews } from "../../services/news.service";
import NewsCard from "../../components/News/NewsCard";
import NoNews from "../../components/News/NoNews";
import SearchVoice from "../../components/UI/SearchVoice/SearchVoice";
import PagesContainer from "../../components/UI/PagesContainer";
import { useAppSelector } from "../../store/store";

const translationPath = "page.allNews.";

const AllNewsPage = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const actualLang = useAppSelector((state) => state.language.languageId);

  const [news, setNews] = useState<INews[]>([]);

  const [search, setSearch] = useState("");

  const [pagination, setPagination] = useState<INewsPagination>({
    pageNumber: 1,
  });

  const { sendRequest, loading } = useFetch(getAllNews);

  const loadNewsHandler = useCallback(async () => {
    if (!actualLang) return;
    const { data, success } = await sendRequest({
      LanguageId: actualLang,
      SearchBy: search,
      PageSize: 10,
      PageNumber: pagination.pageNumber,
    });
    if (data && success) {
      setNews(data.items);
      setPagination({
        pageNumber: data.pageNumber,
        totalCount: data.totalCount,
        totalPages: data.totalPages,
      });
    }
  }, [actualLang, sendRequest, search, pagination.pageNumber]);

  useEffect(() => {
    loadNewsHandler();
  }, [loadNewsHandler, search]);

  const changePageHandler = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPagination((oldValue) => ({ ...oldValue, pageNumber: value }));
  };

  const hasNews = news && news.length !== 0;

  const onSearch = useCallback((text: string) => {
    setSearch(text);
  }, []);

  return (
    <PagesContainer
      title={t(translationPath + "title")}
      height={matches ? "calc(100vh - 14rem)" : "calc(100vh - 14rem)"}
      paddinRight={2}
    >
      <Stack mb={2} width={matches ? "95%" : "50%"}>
        <SearchVoice
          onSearch={onSearch}
          label={t(translationPath + "search_label")}
          placeholder={t(translationPath + "search_placeholder")}
        />
      </Stack>
      {loading && (
        <Stack
          mt={1}
          width={"100%"}
          flexDirection={"row"}
          justifyContent={"center"}
        >
          <CircularProgress />
        </Stack>
      )}
      {!loading && !hasNews && (
        <NoNews message={t(translationPath + "no_news")} />
      )}
      {!loading && hasNews && (
        <>
          <Grid container spacing={1}>
            {news.length !== 0 &&
              news.map((item) => (
                <Grid item xs={12} sm={6} key={item.id}>
                  <NewsCard news={item} />
                </Grid>
              ))}
          </Grid>
          {pagination.totalPages && pagination.totalPages > 1 && (
            <Stack
              mt={2}
              width={"100%"}
              flexDirection={"row"}
              justifyContent={"center"}
            >
              <Pagination
                count={pagination.totalPages}
                page={pagination.pageNumber}
                onChange={changePageHandler}
              />
            </Stack>
          )}
        </>
      )}
    </PagesContainer>
  );
};

export default AllNewsPage;
