import { Grid, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import useFetch from "../../hooks/useFetch";
import AuthorizationService from "../../services/authorizations.service";
import {
  IAuthorizationsParams,
  IBeneficiaryAuthorization,
} from "../../models/authorizations";
import AuthorizationsCard from "./AuthorizationsCard";
import Loading from "../../components/Loading";
import NoData from "../../components/UI/NoData";
import useRole from "../../hooks/useRole";
import PagesContainer from "../../components/UI/PagesContainer";
import { PeriodSelect } from "../../components/UI/Inputs/PeriodSelect/PeriodSelect";
import { usePeriod } from "../../components/UI/Inputs/PeriodSelect/usePeriod";

const translationPath = "page.authorizations.";

const service = new AuthorizationService();

const AuthorizationsPage = () => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const searchedStartDate = searchParams.get("StartDate");
  const searchedEndDate = searchParams.get("EndDate");

  const { value, options, onChange } = usePeriod({
    monthsPerPeriod: 6,
    years: 2,
    defaultValue:
      searchedStartDate && searchedEndDate
        ? `${searchedStartDate} ${searchedEndDate}`
        : undefined,
  });

  const [beneficiaryAuth, setBeneficiaryAuth] = useState<
    IBeneficiaryAuthorization[]
  >([]);

  const [filterParams, setFilterParams] = useState<IAuthorizationsParams>({
    StartDate: searchedStartDate ?? value.split(" ")[0],
    EndDate: searchedEndDate ?? value.split(" ")[1],
  });

  const readPermission = useRole(["Read"]);

  const { sendRequest, loading } = useFetch(service.getAuthorizationsBus);

  const loadAuthorizations = useCallback(async () => {
    const { data, success } = await sendRequest(filterParams);
    if (success) setBeneficiaryAuth(data);
  }, [sendRequest, filterParams]);

  useEffect(() => {
    setFilterParams({
      StartDate: searchedStartDate ?? value.split(" ")[0],
      EndDate: searchedEndDate ?? value.split(" ")[1],
    });
  }, [value, searchedStartDate, searchedEndDate]);

  useEffect(() => {
    if (readPermission) {
      loadAuthorizations();
    }
  }, [loadAuthorizations, readPermission]);

  const searchHandler = (selectedValue: string) => {
    const selectedStartDate = selectedValue.split(" ")[0];
    const selectedEndDate = selectedValue.split(" ")[1];

    setSearchParams((state) => {
      state.set("StartDate", selectedStartDate ?? "");
      return state;
    });
    setSearchParams((state) => {
      state.set("EndDate", selectedEndDate ?? "");
      return state;
    });
  };

  const selectPeriodHandler = (e: SelectChangeEvent<string>) => {
    onChange(e);
    searchHandler(e.target.value);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <PagesContainer
      title={t(translationPath + "authorizations")}
      headerBottom={
        <PeriodSelect
          label={t(translationPath + "select_period")}
          value={value}
          sx={{ mt: 1 }}
          options={options}
          onChange={selectPeriodHandler}
        />
      }
    >
      {beneficiaryAuth.length === 0 && <NoData />}
      {beneficiaryAuth.length > 0 &&
        beneficiaryAuth.map((auth) => (
          <Grid container key={auth.planName}>
            <Stack direction={"row"} alignItems={"center"} spacing={1} mb={1}>
              <Typography
                variant="subtitle1"
                sx={(theme) => ({
                  color:
                    theme.palette.mode === "light"
                      ? theme.palette.grey[600]
                      : theme.palette.grey[400],
                })}
              >
                {t(translationPath + "plan_name")}:
              </Typography>
              <Typography variant="h6" mb={2}>
                {auth.planName}
              </Typography>
            </Stack>
            <Grid container spacing={1}>
              {auth.requestAuthorizations &&
                auth.requestAuthorizations?.map((authorization) => (
                  <Grid
                    item
                    sm={12}
                    key={authorization.authorizationIntegrationId}
                  >
                    <AuthorizationsCard
                      initialDate={filterParams.StartDate.slice(0, 7).replace(
                        "/",
                        "-"
                      )}
                      endDate={filterParams.EndDate.slice(0, 7).replace(
                        "/",
                        "-"
                      )}
                      auth={authorization}
                    />
                  </Grid>
                ))}
            </Grid>
            <Stack
              width={"100%"}
              display={"flex"}
              direction={"row"}
              justifyContent={"center"}
              mt={2}
            >
              {!auth.requestAuthorizations && <NoData />}
            </Stack>
          </Grid>
        ))}
    </PagesContainer>
  );
};

export default AuthorizationsPage;
