import { useTranslation } from "react-i18next";
import AuthCardContainer from "../../components/Auth/AuthCardContainer/AuthCardContainer";
import RegisterForm from "../../components/Auth/RegisterForm/RegisterForm";

const RegisterPage = () => {
  const { t } = useTranslation();

  return (
    <AuthCardContainer
      link="/login"
      textLink={
        t("page.register.has_register") ||
        "Already registered? Access your account"
      }
    >
      <RegisterForm />
    </AuthCardContainer>
  );
};

export default RegisterPage;
