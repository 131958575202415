import { UseFormReturn } from "react-hook-form";
import { TFamilyMember } from "./schema";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import useFetch from "../../../../hooks/useFetch";
import { FamilyGroupService } from "../familyGroup.service";

export const useFamilyGroup = (
  methods: UseFormReturn<TFamilyMember>,
  onSuccess?: () => Promise<void> | void
) => {
  const [loading, setLoading] = useState(false);

  const { sendRequest: saveRequest } = useFetch(FamilyGroupService.create);

  const { sendRequest: updateRequest } = useFetch(FamilyGroupService.update);

  const { sendRequest: getRequest, loading: getLoading } = useFetch(
    FamilyGroupService.getById
  );

  const { id } = useParams();

  const isEditMode = !!id;

  const fetchSave = useCallback(
    async (v: TFamilyMember) => {
      const { success } = await saveRequest({
        ...v,
        genderTypeId: v.gender.id,
        kinshipDegreeId: v.kinship.id,
        country: v.nationality,
        birthDate: dayjs(v.birthDate).format("YYYY-MM-DD"),
      });
      if (success) {
        onSuccess && (await onSuccess());
      }
    },
    [saveRequest, onSuccess]
  );

  const fetchGet = useCallback(async () => {
    if (!id) return;

    const { data, success } = await getRequest(id);

    if (success && data) {
      const birthday = dayjs(data.individual.birthDate).toDate();
      methods.reset({
        birthDate: birthday,
        document: data.individual.document,
        gender: data.individual.gender || undefined,
        kinship: {
          id: data.kinshipDegree?.id,
          name: data.kinshipDegree?.name,
        },
        name: data.individual.name,
        nationality: data.individual.country || undefined,
      });
    }
  }, [getRequest, methods, id]);

  const fetchUpdate = useCallback(
    async (v: TFamilyMember) => {
      if (!id) return;
      const { success } = await updateRequest({
        id: id,
        kinshipDegreeId: v.kinship.id,
      });

      if (success) {
        onSuccess && (await onSuccess());
      }
    },
    [updateRequest, id, onSuccess]
  );

  const onSubmit = useCallback(
    async (v: TFamilyMember) => {
      setLoading(true);
      if (isEditMode) {
        await fetchUpdate(v);
      } else {
        await fetchSave(v);
      }
      setLoading(false);
    },
    [fetchSave, isEditMode, fetchUpdate]
  );

  useEffect(() => {
    fetchGet();
  }, [fetchGet]);

  return { isEditMode, onSubmit, getLoading, loading };
};
