import { IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "../../store/store";

import { saveConfig } from "../../store/thunks/ConfigurationsThunk";
import { themeSliceActions } from "../../store/slices/theme";
import { tokenService } from "../../services/token.service";

import ContrastIcon from "@mui/icons-material/Contrast";

const ThemeSwitch: React.FC = () => {
  const webAppearance = useAppSelector(
    (state) => state.configurations.webAppearance
  );
  const theme = useAppSelector((state) => state.theme.mode);
  const token = tokenService.hasAccessTokens();
  const darkMode = token ? webAppearance.code === "2" : theme === "1";

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onClickHandler = useCallback(() => {
    const mode = darkMode ? "1" : "2";

    if (token) {
      dispatch(
        saveConfig({
          webAppearance: {
            code: mode,
          },
        })
      );
    }

    dispatch(themeSliceActions.toggleTheme());
    localStorage.setItem("theme", theme === "1" ? "2" : "1");
  }, [dispatch, darkMode, token, theme]);

  return (
    <Tooltip
      title={t("components.themeSwitch.change_theme")}
      placement="left-end"
    >
      <motion.div
        animate={{ rotate: darkMode ? 180 : 0 }}
        transition={{ type: "tween", duration: 0.5, ease: "easeOut" }}
      >
        <IconButton onClick={onClickHandler} color="inherit" size="small">
          <ContrastIcon color="action" fontSize="small" />
        </IconButton>
      </motion.div>
    </Tooltip>
  );
};

export default ThemeSwitch;
