import { Grid, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import SelectMonthYear from "../UI/Inputs/SelectMonthYear";
import { ICoParticipations } from "../../models/co-participation";
import { formatValue } from "../../utils/conversions";
import DownloadPageButton from "../UI/DownloadPageButton";

const translationPath = "page.extractCoParticipation.";

interface IExtractCoParticipationHeader {
  actualDate: dayjs.Dayjs;
  date: string;
  coParticipations: ICoParticipations[];
  totalSum: number | undefined;
  onDownloadClick: () => any;
  donwloadLoading: boolean;
  onChangeHandler: (e: SelectChangeEvent) => void;
  loading: boolean;
  error: any;
}

const ExtractCoParticipationHeader = ({
  actualDate,
  date,
  coParticipations,
  totalSum,
  onChangeHandler,
  onDownloadClick,
  donwloadLoading,
  loading,
  error,
}: IExtractCoParticipationHeader) => {
  const { t } = useTranslation();

  return (
    <Grid
      item
      container
      alignItems={"center"}
      xs={12}
      mt={1}
    >
      <Grid item xs={12} sm={6} md={6} xl={6} mb={2} flexDirection={"row"}>
        <Grid container>
          <Grid item>
            <SelectMonthYear
              label={t(translationPath + "select_date")}
              actualDate={actualDate}
              startDate={"2020-01-01"}
              value={date}
              onChangeHandler={(e) => onChangeHandler(e)}
            />
          </Grid>
          {!loading && (
            <Grid item xs={"auto"}>
              <DownloadPageButton
                onClick={onDownloadClick}
                loading={donwloadLoading}
                error={error}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      {coParticipations.map(
        (item) =>
          item.coparticipationStatements?.length! >= 1 && (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              xl={6}
              justifyContent={"end"}
              key={item.planName}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"end"}
                gap={1}
              >
                <Typography
                  variant="h6"
                  sx={(theme) => ({
                    color:
                      theme.palette.mode === "light"
                        ? theme.palette.grey[600]
                        : theme.palette.grey[400],
                  })}
                >
                  {t(translationPath + "total")}:
                </Typography>
                <Typography variant="h5">
                  {formatValue(totalSum || 0)}
                </Typography>
              </Stack>
            </Grid>
          )
      )}
    </Grid>
  );
};

export default ExtractCoParticipationHeader;
