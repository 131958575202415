import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import BackButton from "../../components/UI/BackButton";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "../../components/UI/Inputs/FormInput";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import ChangePasswordService from "../../services/changePassword.service";
import { useDispatch } from "react-redux";
import { notificationSliceActions } from "../../store/slices/notification";
import { useTranslation } from "react-i18next";
import {
  TChangePasswordForm,
  changePasswordSchema,
} from "./changePasswordSchema";

const translationPath = "page.changePassword.";

const changePasswordService = new ChangePasswordService();

const ChangePasswordPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sendRequest } = useFetch(changePasswordService.create);
  const methods = useForm<TChangePasswordForm>({
    resolver: zodResolver(changePasswordSchema),
  });

  const {
    formState: { isSubmitSuccessful },
    reset,
    handleSubmit,
  } = methods;

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const onSubmitHandler = useCallback(
    async (values: TChangePasswordForm) => {
      const { success } = await sendRequest({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      });
      if (success) {
        dispatch(
          notificationSliceActions.showNotification({
            message: `${t(translationPath + "success_message")}`,
            type: "success",
          })
        );
      }
    },
    [sendRequest, dispatch, t]
  );

  return (
    <Grid container width={"100%"}>
      <Grid item sm={12}>
        <BackButton iconButton />
      </Grid>
      <Grid item container sm={12} md={12} xl={12} pt={5}>
        <Stack direction={"column"} width={"100%"}>
          <Grid item sm={12} mb={2}>
            <Typography variant="h5">
              {t(translationPath + "change_password")}
            </Typography>
          </Grid>
        </Stack>
        <Grid item container justifyContent={"start"}>
          <Grid item xs={12} sm={12} md={12} xl={5}>
            <FormProvider {...methods}>
              <Box
                component="form"
                autoComplete="off"
                onSubmit={handleSubmit(onSubmitHandler)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <FormInput
                  size="small"
                  autoFocus={true}
                  name="currentPassword"
                  fullWidth
                  label={t(translationPath + "actual_password")}
                  type="password"
                  sx={{ mb: 2 }}
                />
                <FormInput
                  size="small"
                  name="newPassword"
                  fullWidth
                  type="password"
                  label={t(translationPath + "new_password")}
                  sx={{ mb: 2 }}
                />
                <FormInput
                  size="small"
                  name="confirmNewPassword"
                  fullWidth
                  type="password"
                  label={t(translationPath + "confirm_new_password")}
                  sx={{ mb: 2 }}
                />
                <Button variant="contained" type="submit">
                  {t(translationPath + "save")}
                </Button>
              </Box>
            </FormProvider>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChangePasswordPage;
