import GlobalAxios from "../axios/axios-config";
import i18n from "../i18n";
import { getDomain } from "./domain.service";

const FILE_ROUTE = process.env.REACT_APP_FILEMANAGER + "/api/v1/Upload";

export type TFileType = "Public" | "Private";

export interface IUploadFileProps {
  Type: TFileType;
  ParentName: string;
  MediaTypeCode: string;
  File: File;
  MediaTypeIdentifier: string;
}

interface IUploadFile {
  data: IUploadFileProps;
  progressCallback: (progress: number) => void;
}
export class UploadFileService {
  controller = new AbortController();

  upload = (item: IUploadFile) => {
    const formData = new FormData();
    formData.append("File", item.data.File);
    formData.append("MediaTypeCode", item.data.MediaTypeCode);
    formData.append("ParentName", item.data.ParentName);
    formData.append("Type", item.data.Type);
    formData.append("MediaTypeIdentifier", item.data.MediaTypeIdentifier);

    return GlobalAxios.post(FILE_ROUTE, formData, {
      signal: this.controller.signal,
      headers: {
        "Content-Type": "multipart/form-data",
        Domain: getDomain(),
        "Accept-Language": i18n.language,
      },
      onUploadProgress: (event) => {
        if (event.total) {
          let progress: number = Math.round((event.loaded * 100) / event.total);
          item.progressCallback(progress);
        }
      },
    });
  };
}

export default UploadFileService;
