import { Box } from "@mui/material";
import { memo } from "react";
import { useMimeType } from "../../../../../hooks/useMimeType";
import RenderPDF from "../../../../RenderPDF/RenderPDF";

interface IFileViewProps {
  file: string;
  fullWidth?: boolean;
}

export const FileView = memo(({ file, fullWidth = false }: IFileViewProps) => {
  const { detectMimeType } = useMimeType();

  const mimeType = detectMimeType(file);

  if (mimeType !== "application/pdf") {
    return (
      <Box
        sx={{
          pl: fullWidth ? undefined : 2,
          height: "fit-content",
          width: fullWidth ? "100%" : "75%",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <img
          src={`data:image/${mimeType};base64,${file}`}
          alt="preview"
          style={{
            width: "auto",
            maxWidth: 580,
            height: "auto",
            maxHeight: 1080,
            borderRadius: 10,
          }}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        pl: fullWidth ? undefined : 2,
        height: "80vh",
        width: fullWidth ? "100%" : "75%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <RenderPDF
        file={`data:application/pdf;base64,${file}`}
        downloadButtonProps={{ onDownload: () => {}, loading: false }}
        width={"100%"}
      />
    </Box>
  );
});
