import {
  Box,
  Card,
  CardMedia,
  Chip,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { INews } from "../../models/news";
import HideImageIcon from "@mui/icons-material/HideImage";
import { useLocation, useNavigate } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useTranslation } from "react-i18next";
import { OverflowTooltip } from "../UI/TooltipOverflow";
import NewsCardContent from "./NewsCardContent";

const translationPath = "components.newsCard.";

interface INewsCardProps {
  news: INews;
}

const NewsCard = ({ news }: INewsCardProps) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const {
    publicationDate,
    readingTime,
    title,
    summary,
    contentTags,
    contentMediaFiles,
    contentConfigId,
  } = news;
  const file = contentMediaFiles.length !== 0 ? contentMediaFiles[0] : null;

  const hasFile = file && file.cdnDomain;

  return (
    <Card
      sx={(theme) => ({
        width: "100%",
        height: 150,
        display: "flex",
        cursor: "pointer",
        backgroundColor:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        border:
          theme.palette.mode === "light"
            ? `1px solid ${theme.palette.grey[100]}`
            : `1px solid ${theme.palette.grey[900]}`,
      })}
      elevation={0}
      onClick={() => navigate(`/news/${contentConfigId}`)}
    >
      {hasFile && (
        <CardMedia
          component="img"
          sx={{ width: "40%" }}
          image={file.cdnDomain + "/" + file.filePath}
          alt={""}
        />
      )}
      {!hasFile && (
        <Box
          width={"40%"}
          height={"100%"}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={(theme) => ({
            backgroundColor:
              theme.palette.mode === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
          })}
        >
          <HideImageIcon fontSize="large" />
        </Box>
      )}
      <Box
        pl={2}
        pr={1}
        py={1}
        width={"60%"}
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        position={"relative"}
      >
        <Box mb={0.5} display={"flex"} flexDirection={"row"} gap={0.5}>
          {contentTags.map((tag, index) => {
            if (matches && index <= 1) {
              return (
                <Chip
                  size="small"
                  label={tag.name}
                  id={tag.name + index}
                  variant="outlined"
                  key={tag.name + index}
                />
              );
            }
            if (!matches && pathname.includes("news") && index <= 4) {
              return (
                <Chip
                  size="small"
                  label={tag.name}
                  id={tag.name + index}
                  variant="outlined"
                  key={tag.name + index}
                />
              );
            }
            if (!matches && pathname === "/" && index <= 1) {
              return (
                <Chip
                  size="small"
                  label={tag.name}
                  id={tag.name + index}
                  variant="outlined"
                  key={tag.name + index}
                />
              );
            }
            return false;
          })}
        </Box>
        <NewsCardContent title={title} summary={summary} />
        <Box
          width={"100%"}
          paddingRight={4}
          position={"absolute"}
          bottom={5}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={0.5}
          >
            <Tooltip title={t(translationPath + "reading_time")}>
              <AccessTimeIcon fontSize="small" />
            </Tooltip>
            <OverflowTooltip
              text={`${readingTime} min.`}
              tooltip={`${readingTime} min.`}
            />
          </Box>
          <Typography variant="body2">
            {new Date(publicationDate).toLocaleDateString("pt-BR")}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default NewsCard;
