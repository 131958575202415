import {
  TextFieldProps,
  TextFieldPropsSizeOverrides,
  Typography,
} from "@mui/material";
import { FC, memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OverridableStringUnion } from "@mui/types";
import InputFile from "./InputFIle";
import { TFileType } from "../../../../services/uploadFile.service";
import { IInputFile } from "../../../../models/input-file";

interface fileInput {
  type: TFileType;
  parentName: string;
  mediaTypeCode: string;
  mediaTypeIdentifier: string;
  label: string;
  /**
   * accept define os tipos de arquivos aceitos
   */
  accept: string;
  /**
   * maxFileSize define o tamanho máximo permitido em MB
   */
  maxFileSize: number;
  inputProps: TextFieldProps;
  onFileSelected?: (file: IInputFile) => void;
  size?: OverridableStringUnion<
    "small" | "medium",
    TextFieldPropsSizeOverrides
  >;
}

type IFileInput = {
  name: string;
  fileInputProps: fileInput;
};

const InputFileForm: FC<IFileInput> = ({ name, fileInputProps }) => {
  const { control, getValues } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      render={({ fieldState: { error }, field }) => {
        return (
          <>
            <InputFile
              name={name}
              accept={fileInputProps.accept}
              label={fileInputProps.label}
              maxSize={fileInputProps.maxFileSize}
              oldValue={getValues(name)}
              onFileSelected={fileInputProps.onFileSelected}
              size={fileInputProps.size}
              value={field.value}
              onChange={field.onChange}
              uploadFileProps={{
                mediaTypeCode: fileInputProps.mediaTypeCode,
                MediaTypeIdentifier: fileInputProps.mediaTypeIdentifier,
                parentName: fileInputProps.parentName,
                type: fileInputProps.type,
              }}
            />
            <Typography color={"error"} variant="body2">
              {error ? t(error?.message || "") : ""}
            </Typography>
          </>
        );
      }}
    />
  );
};

export default memo(InputFileForm);
