import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { ISelectType } from "../UI/Inputs/AutoComplete";

interface ISelectLanguageProps {
  value: string;
  onSelect: (event: SelectChangeEvent) => any;
  languages: ISelectType[];
}

const SelectLanguage: React.FC<ISelectLanguageProps> = ({
  onSelect,
  value,
  languages,
}) => {
  return (
    <Select value={value} onChange={onSelect} fullWidth>
      {languages.map((l) => (
        <MenuItem value={l.code} key={l.id}>
          <Stack direction={"row"} spacing={1}>
            <Typography>{l.name}</Typography>
          </Stack>
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectLanguage;
