import { z } from "zod";

export const resetPasswordFormSchema = z
  .object({
    newPassword: z
      .string()
      .nonempty("components.resetPasswordForm.new_password_required"),
    confirmNewPassword: z
      .string()
      .nonempty("components.resetPasswordForm.confirm_new_password_required"),
    confirmationCode: z
      .string()
      .nonempty("components.resetPasswordForm.confirmation_code_required"),
  })
  .refine((data) => data.newPassword === data.confirmNewPassword, {
    message: `${"components.resetPasswordForm.passwords_match"}`,
    path: ["confirmNewPassword"], // path of error
  });

export type TNewPassword = z.infer<typeof resetPasswordFormSchema>;
