import { Box, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { DatePickerUncontrolled } from "../DatePickerUncontrolled/DatePickerUncontrolled";
import { IDateRangeProps } from "./models";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import "dayjs/locale/en";
import "dayjs/locale/es";
import { useTranslation } from "react-i18next";

const translationPath = "components.dateRange.";

export const DateRange = memo(
  ({
    startDateValue,
    endDateValue,
    clearable = true,
    autoFocus = false,
    disableFuture,
    maxEndDate,
    onChangeStartDate,
    onChangeEndDate,
  }: IDateRangeProps) => {
    const { t } = useTranslation();

    const [focus, setFocus] = useState<"start" | "end" | undefined>(
      autoFocus ? "start" : undefined
    );

    const changeStartDate = useCallback(
      (date: dayjs.Dayjs | null) => {
        onChangeStartDate(date);
        if (date && !endDateValue && clearable) {
          setFocus("end");
        }
      },
      [onChangeStartDate, endDateValue, clearable]
    );

    const changeEndDate = useCallback(
      (date: dayjs.Dayjs | null) => {
        onChangeEndDate(date);
        if (!startDateValue && date && clearable) {
          setFocus("start");
        } else {
          setFocus(undefined);
        }
      },
      [onChangeEndDate, startDateValue, clearable]
    );

    useEffect(() => {
      if (endDateValue && startDateValue && startDateValue > endDateValue) {
        onChangeEndDate(null);
      }
    }, [endDateValue, startDateValue, onChangeEndDate]);

    return (
      <Box display={"flex"} alignItems={"center"} gap={1}>
        <DatePickerUncontrolled
          value={startDateValue}
          label={`${t(translationPath + "start_date")}`}
          open={focus === "start" ? true : false}
          autoFocus={focus === "start" ? true : false}
          isClearable={clearable}
          disableFuture={disableFuture}
          onChange={changeStartDate}
        />
        <Typography>-</Typography>
        <DatePickerUncontrolled
          value={endDateValue}
          label={`${t(translationPath + "end_date")}`}
          open={focus === "end" ? true : false}
          minDate={startDateValue ?? undefined}
          maxDate={maxEndDate ?? undefined}
          isClearable={clearable}
          disableFuture={disableFuture}
          onChange={changeEndDate}
        />
      </Box>
    );
  }
);
