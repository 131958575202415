import { Box, Chip } from "@mui/material";
import { IContentTag } from "../../models/content";

interface IContentTags {
  tags: IContentTag[];
}

const ContentTags = ({ tags }: IContentTags) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      flexWrap={"wrap"}
      gap={1}
    >
      {tags.map((tag, index) => (
        <Chip
          size="small"
          variant="outlined"
          label={tag.name}
          key={tag.name + index}
        />
      ))}
    </Box>
  );
};

export default ContentTags;
