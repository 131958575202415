import { Box, Typography } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { useTranslation } from "react-i18next";

const translationPath = "components.rated.";

const Rated = () => {
  const { t } = useTranslation();

  return (
    <Box
      mt={2}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      sx={{
        p: 2,
      }}
    >
      <CheckCircleRoundedIcon color="success" fontSize="large" />
      <Typography variant="subtitle2" fontWeight={"bold"}>
        {t(translationPath + "message")}
      </Typography>
    </Box>
  );
};

export default Rated;
